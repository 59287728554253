import React from 'react'

import { MiProjectStatus, WHSEvalStatus } from '@app/evaluation-core/types'
import { shadeColor } from '@app/evaluation-core/evaluation'
import * as S from './styled'
import { EvalStatus, Statuses } from '@app/courses/domain/ratingStatus'
import theme from '@app/ui/theme'

const STATUS_MAPPER: { [key in WHSEvalStatus]: EvalStatus } = {
  '0': EvalStatus.NOT_STARTED,
  '1': EvalStatus.PREPARING,
  '2': EvalStatus.IN_PROGRESS,
  '3': EvalStatus.FINALIZED,
}
const STATUS_TO_TEXT: { [key in WHSEvalStatus]: string } = {
  '0': 'Not started',
  '1': 'Preparing',
  '2': 'In progress',
  '3': 'Finalized',
}
const STATUS_TO_COLOR: { [key in WHSEvalStatus]: string } = {
  '0': '#000',
  '1': '#ccc',
  '2': 'rgb(255, 193, 7)',
  '3': '#2B9E33',
}

const EvalStatusIndicator: React.FC<{
  status: EvalStatus
  finalizedAt?: string | null | undefined
  showLabel?: boolean
  size?: number
}> = ({ status, finalizedAt = null, showLabel = true, size = 32 }) => {
  const getEvalStatusColor = (status: EvalStatus) => {
    return STATUS_TO_COLOR[status]
  }

  const getEvalStatusText = (status: EvalStatus, finalizedAt?) => {
    if (status == EvalStatus.FINALIZED) {
      return {
        main: 'Finalized',
        sub: `${new Date(finalizedAt).toLocaleDateString('sv-SE')}`,
      }
    } else return { main: STATUS_TO_TEXT[status] }
  }

  return (
    <div
      style={{
        display: 'flex',
        gap: 4,
        alignItems: 'center',
      }}
    >
      <>
        <S.ItemStatusColor color={getEvalStatusColor(status)} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {showLabel && (
            <>
              <S.ItemStatusMainText>
                {getEvalStatusText(status).main}
              </S.ItemStatusMainText>
              {getEvalStatusText(status, finalizedAt).sub && (
                <S.ItemStatusSubText>
                  {getEvalStatusText(status, finalizedAt).sub}
                </S.ItemStatusSubText>
              )}
            </>
          )}
        </div>
      </>
    </div>
  )
}

export default EvalStatusIndicator
