import React, { useEffect } from 'react'
import {
  FBREF_COURSE_EVALUATION,
  FBREF_COURSE_EVALUATIONS,
} from '@app/evaluation-core/refs'
import firebase from 'firebase'
import { Eval } from '@app/evaluation-core/types'
import { EvaluationDoc } from '../../app/pages/CreateRatingPage/CreateRatingPage'

export function useRealTimeEvals(evaluations: EvaluationDoc[] | undefined) {
  const [realTimeEvals, setRealTimeEvals] = React.useState<Map<string, Eval>>(
    new Map()
  )
  const [v1Evals, setV1Evals] = React.useState<Map<string, Eval>>(new Map())
  const [isLoadingV1, setIsLoadingV1] = React.useState<boolean>(false)
  const [listeners, setListeners] = React.useState<EvaluationDoc[]>([])

  useEffect(() => {
    if (listeners?.length > 0) {
      listeners.map((evaluation) => {
        firebase
          .database()
          .ref(FBREF_COURSE_EVALUATION(evaluation.id))
          .off('value')
      })
    }

    if (!evaluations) return

    setListeners(
      evaluations.map((evaluation) => {
        firebase
          .database()
          .ref(FBREF_COURSE_EVALUATION(evaluation.id))
          .get()
          .then((snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val() as Eval
              const key = snapshot.key

              if (!key) return

              setRealTimeEvals((previousValue) => {
                previousValue.set(key, data)
                return new Map(previousValue)
              })
            }
          })

        firebase
          .database()
          .ref(FBREF_COURSE_EVALUATION(evaluation.id))
          .on('value', (snapshot) => {
            if (snapshot.exists()) {
              const data = snapshot.val() as Eval
              const key = snapshot.key

              if (!key) return

              setRealTimeEvals((previousValue) => {
                previousValue.delete(key)
                previousValue.set(key, data)
                return new Map(previousValue)
              })
            }
          })

        return evaluation
      })
    )
  }, [evaluations])

  const loadV1Evals = () => {
    setIsLoadingV1(true)

    firebase
      .database()
      .ref(FBREF_COURSE_EVALUATIONS())
      .get()
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val() as Record<string, Eval>
          const map = new Map<string, Eval>()

          Object.entries(data).map(([key, value]) => {
            if (value?.holeIds === undefined)
              setV1Evals((previousValue) => {
                previousValue.delete(key)
                previousValue.set(key, value)
                return new Map(previousValue)
              })
          })

          setIsLoadingV1(false)
        }
      })
  }

  return { realTimeEvals, v1Evals, loadV1Evals, isLoadingV1 }
}
