import { getCourseSetting, setCourseSetting } from '@app/evaluation-core'
import { useEffect, useMemo, useRef, useState } from 'react'

import { State } from '@app/state/types'
import { TeamRole } from '@app/evaluation-core/types'
import { getCurrentUser } from '@app/evaluation-core/evaluation'
import { useEvaluation } from '.'
import useEvaluationUser from './useEvaluationUser'
import { useSelector } from 'react-redux'
import { useSnackbar } from '../../app/snackbar'
import useUser from './useUser'

type AdjustmentType = number | undefined

const roundToNearestTenth = (value: number) => Math.round(value * 10) / 10

const keyCalcsImpToM = {
  rough_height: 25.4,
  'wind_1-9': 0.44704,
  'wind_10-18': 0.44704,
  bunk_depth_default: 0.3048,
}

const keyCalcsMToImp = {
  rough_height: 25.4,
  'wind_1-9': 0.44704,
  'wind_10-18': 0.44704,
  bunk_depth_default: 0.3048,
}

const getMeasurementAdjustedValues = (
  value: number,
  key: string,
  measurement: 'imperial' | 'metric'
) => {
  if (measurement === 'imperial') {
    if (key.includes('rough')) {
      return {
        imperial: value,
        metric: Math.round(value * keyCalcsImpToM[key]),
      }
    }
    return {
      imperial: value,
      metric: roundToNearestTenth(value * keyCalcsImpToM[key]),
    }
  }
  return {
    metric: value,
    imperial: roundToNearestTenth(value / keyCalcsMToImp[key]),
  }
}

export type CourseSettingInput = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>

export default (
  key: string,
  measurement: 'imperial' | 'metric'
): [
  AdjustmentType,
  any,
  () => Promise<null | undefined>,
  React.MutableRefObject<CourseSettingInput | undefined>,
  AdjustmentType
] => {
  const { currentId } = useEvaluation()
  const adjustments = useSelector((state: State) => state.adjustments)
  const ref = useRef<CourseSettingInput>()
  const [openSnackbar] = useSnackbar()
  const evalUser = useEvaluationUser()
  const courseSettings = adjustments.courseSettings

  const defaultValue = useMemo(
    () => getCourseSetting(key, adjustments),
    [courseSettings, adjustments]
  ) as number

  const [value, setValue] = useState<{ imperial: number; metric: number }>({
    imperial: getMeasurementAdjustedValues(defaultValue, key, 'imperial')
      .imperial,
    metric: getMeasurementAdjustedValues(defaultValue, key, 'imperial').metric,
  })

  useEffect(() => {
    const { imperial, metric } = getMeasurementAdjustedValues(
      defaultValue,
      key,
      'imperial'
    )
    if (value.imperial !== imperial || value.metric === metric) {
      setValue({ imperial, metric })
      if (ref.current) {
        ref.current.value = value[measurement]
      }
    }
  }, [adjustments.courseSettings, measurement])

  const setStateValue = (newValue: number) => {
    const { imperial, metric } = getMeasurementAdjustedValues(
      newValue,
      key,
      measurement
    )
    if (value.imperial !== imperial || value.metric !== metric) {
      setValue({ imperial, metric })
    }
  }

  const setCourseSettingValue = () => {
    const NOT_TEAM_LEADER = 'Only the team leader can adjust course settings.'
    const doesNotHaveLeaderRights = !evalUser.isAdmin
    if (doesNotHaveLeaderRights) {
      return openSnackbar(NOT_TEAM_LEADER)
    }
    const { imperial, metric } = getMeasurementAdjustedValues(
      defaultValue,
      key,
      'imperial'
    )
    if (value.imperial !== imperial || value.metric !== metric) {
      setCourseSetting(key, currentId as string, value.imperial)
    }
  }

  return [
    value[measurement],
    setStateValue,
    setCourseSettingValue,
    ref,
    value.imperial,
  ]
}
