import { AccountType, UserState } from '@app/auth/types'
import { EvaluationUser, TeamRole } from '@app/evaluation-core/types'

import { State } from '@app/state/types'
import { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import firebase from 'firebase'
import { signOut } from '@app/auth/domain'
import { useSnackbar } from '@app/snackbar'

export default () => {
  const [openSnackbar] = useSnackbar()
  const superUsers = [2404, 3215]
  const stateUser = useSelector<State, UserState>((state) => state.user)
  const user = useMemo(() => {
    const isAdminUser =
      stateUser?.accountType === AccountType.Interal ||
      superUsers.includes(stateUser?.id)
    const isTeamLeader = stateUser?.accountType === AccountType.SGFTeamLeader
    const isMiAdmin = +stateUser?.accountType >= +AccountType.Interal
    return {
      ...stateUser,
      isAdmin: isAdminUser,
      isMiAdmin: isMiAdmin,
      isTeamLeader,
    }
  }, [stateUser?.id, stateUser])

  //TODO: This is just a temporary solution to check if the user is logged in on another device. This should be done in a more secure way. /VS
  useEffect(() => {
    const userId = stateUser?.id
    if (!userId) return

    const sessionRef = firebase.database().ref(`sessions/${userId}`)
    const onSessionChange = async (snapshot) => {
      try {
        const session = snapshot.val()
        if (session !== stateUser?.sessionID) {
          await signOut()
          openSnackbar(
            'Your session has expired, or you have logged in on another device.'
          )
        }
      } catch (error) {
        console.error('Error handling session change:', error)
      }
    }

    sessionRef.on('value', onSessionChange)

    return () => {
      sessionRef.off('value', onSessionChange)
    }
  }, [stateUser?.id, openSnackbar])

  return user
}
