import theme from '@app/ui/theme'

export const COURSES_ROUTES = [
  'Courses',
  'Forms',
  'Overview',
  'Evaluation',
  'Preparation',
  'Finalize',
]

export const TAB_MENU_ICON_STYLE = (active = false) => ({
  color: active ? theme.colors.white : '#a5a5a5',
  fontSize: '20px',
})
