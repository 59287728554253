import { AdjustmentTypes, getAdjustmentValue } from '@app/evaluation-core'
import Feather from '@ovaeasy/react-native-vector-icons/Feather'
import React, { useEffect, useRef, useState } from 'react'
import {
  AdmSectionHeading,
  AdmSectionSeparator,
  AdmSectionSideHeader,
  AdmSectionSideItem,
  AdmSectionSideItemBox,
  AdmSectionSubText,
  Label,
  LabelText,
  ResetAdjustmentButton,
  ResetAdjustmentButtonContent,
} from './Adjustment.styles'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { LockStatus } from '@app/evaluation-core/types'
import Selector from '@app/ui/Selector'
import StepperContainer from '@app/ui/StepperContainer'
import { AdjustmentInput } from '@app/ui/input'
import { useEvaluation } from '@common/hooks'
import useAdjustment from '@common/hooks/useAdjustment'
import useEvaluationLockedState from '@common/hooks/useEvaluationLockedState'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import useSetAdjustment from '@common/hooks/useSetAdjustment'
import { originalValueFunctions } from '../helpers'
import { Tweener } from '../tweener'
import { AdmParameter } from '../types'
import TableValue from './TableValue'
import { TableValueSeparator } from './TableValue.styles'
import {
  LoadingContainer,
  LoadingDefaultInputContainer,
} from './AdjustmentFactors.styles'
import SideItemSkeleton from './skeletons/SideItemSkeleton'
import OptionalAdjustmentSkeleton from './skeletons/OptionalAdjuatmentSkeleton'

interface AdmParameterItemProps {
  param: AdmParameter
  lz?: number
}
type Input = React.InputHTMLAttributes<HTMLInputElement>

export const AdmParameterItem = (props: AdmParameterItemProps) => {
  const adjustments = useAdjustment()
  const lockStatus = useEvaluationLockedState()
  const [setAdjustment] = useSetAdjustment()

  const { currentId, evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  const item = getAdjustmentValue(
    props.param.format as any,
    view,
    props.param.adjustmentKey as string,
    adjustments
  ) as number
  const [inputValue, setInputValue] = useState<number | string>(item)
  const inputRef = useRef<Input>()

  const getOriginalValue = (key: string) => {
    const fn = originalValueFunctions[key]
    if (typeof fn === 'function') {
      return fn({
        view,
        adjustments,
        defaultCourseSettings: evaluation.defaultCourseSettings,
      })
    }
    return undefined
  }

  useEffect(() => {
    if (item !== inputValue) {
      if (inputRef.current) {
        inputRef.current.value = item
      }
      setInputValue(item)
    }
  }, [item, lockStatus])

  const type = props.param.type ?? 'stepper-input'

  const getInputComponent = () => {
    const { inputDisabled, inputType, selectorLabels, selectorValues } =
      props.param
    const canEdit = lockStatus === LockStatus.UNLOCKED

    switch (type) {
      case 'select':
        if (!selectorValues) return

        const setValue = (value) => {
          if (lockStatus !== LockStatus.LOCKED) {
            setInputValue(value)
          }
          setAdjustment(
            props.param.format as AdjustmentTypes,
            view,
            props.param.adjustmentKey as string,
            currentId as string,
            value
          )
          if (lockStatus === LockStatus.LOCKED && inputRef.current) {
            if (inputRef.current) {
              inputRef.current.value = item
            }
          }
        }

        return (
          <div style={{ height: '100%', width: '100%' }}>
            <Selector
              setValue={setValue}
              values={selectorValues}
              labels={selectorLabels}
              currentValue={inputValue}
            />
          </div>
        )
      default:
        const onChange = ({ target }) => {
          if (lockStatus !== LockStatus.LOCKED) {
            setInputValue(+target.value)
          }
          setAdjustment(
            props.param.format as AdjustmentTypes,
            view,
            props.param.adjustmentKey as string,
            currentId as string,
            +target.value
          )
          if (lockStatus === LockStatus.LOCKED && inputRef.current) {
            inputRef.current.value = item
          }
        }

        if (inputDisabled) {
          return (
            <div
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {!adjustments || !adjustments.adjustments ? (
                <LoadingDefaultInputContainer isReadOnly={true}>
                  <LoadingContainer>
                    <Feather name="loader" size={16} />
                  </LoadingContainer>
                </LoadingDefaultInputContainer>
              ) : (
                <>
                  <AdjustmentInput
                    isReadonly={inputDisabled}
                    disabled={!canEdit}
                    type={inputType ?? 'number'}
                    value={inputValue}
                  />
                  {props.param?.unit && <span>{props.param.unit}</span>}
                </>
              )}
            </div>
          )
        }
        return (
          <>
            {!adjustments || !adjustments.adjustments ? (
              <LoadingDefaultInputContainer isReadOnly={false}>
                <LoadingContainer>
                  <Feather name="loader" size={16} />
                </LoadingContainer>
              </LoadingDefaultInputContainer>
            ) : (
              <>
                <AdjustmentInput
                  // @ts-ignore
                  ref={inputRef}
                  onFocus={(event) => event.target.select()}
                  isReadonly={inputDisabled}
                  disabled={!canEdit}
                  type={inputType ?? 'number'}
                  defaultValue={inputValue}
                  onChange={({ target }) => setInputValue(+target.value)}
                  onBlur={onChange}
                />
                {props.param?.unit && <span>{props.param.unit}</span>}
              </>
            )}
          </>
        )
    }
  }

  const getOriginalValueComponent = () => {
    const originalValue = getOriginalValue(props.param.adjustmentKey!)
    if (!originalValue || originalValue === '') {
      return null
    }
    return (
      <OriginalValueComponent
        param={props.param}
        originalValue={originalValue}
        lockStatus={lockStatus}
        setAdjustment={setAdjustment}
        view={view}
        currentId={currentId}
      />
    )
  }
  const parametersSkeleton = (!props || !props.param || !view) && (
    <Skeleton
      width="100%"
      style={{
        marginBottom: 16,
        minHeight: 16,
      }}
    />
  )
  return (
    <>
      {parametersSkeleton}
      {props.param.type === 'label' && (
        <Label>
          <LabelText>{props.param.label}</LabelText>
        </Label>
      )}
      {parametersSkeleton}
      {!props.param && <TableValueSeparator />}
      {props.param.type === 'table-value' && (
        <>
          <TableValueSeparator />
          <TableValue value={item} label={props.param.label} />
        </>
      )}
      {parametersSkeleton}
      {props.param.type !== 'table-value' && props.param.type !== 'label' && (
        <>
          <StepperContainer
            label={props.param.label as string}
            secondaryLabel={props.param.secondaryLabel}
            adjustment={item}
            adjustmentKey={props.param.adjustmentKey}
            lz={props.lz}
            InputComponent={getInputComponent()}
            notExpandible={props.param.notExpandible}
            inputType={props.param.inputType ?? 'number'}
            ResetAdjComponent={getOriginalValueComponent()}
          />

          {/* {!props.param.type && (
            <Stepper
              setValue={(value) => {
                // setAdjustment(
                //   props.param.format as AdjustmentTypes,
                //   view,
                //   props.param.adjustmentKey as string,
                //   currentId as string,
                //   +value
                // );
              }}
              values={props.param.stepperValues ?? [1, 5, 10]}
              currentValue={item}
              stepDefault={props.param.stepDefault}
              minValue={props.param.minValue}
            />
          )} */}
          {/*     </StepperContainer> */}
        </>
      )}
    </>
  )
}

export interface AdjustmentParametersProps {
  useLz?: boolean
  parameters?: AdmParameter[]
  tweener: boolean
  isSmall?: boolean
  subText?: string
}

export const AdjustmentParameters = (props: AdjustmentParametersProps) => {
  const { view } = useEvaluationUser()
  const adjustment = useAdjustment()

  const parameters =
    props && props.parameters && adjustment && view ? (
      props.parameters &&
      props.parameters[0] &&
      props.parameters.map((param, index) => {
        if (param.type === 'button' && param.buttonComponent !== undefined) {
          return (
            <>
              <AdmSectionSideItem key={param.adjustmentKey}>
                <AdmSectionSideItemBox>
                  {param.buttonComponent()}
                </AdmSectionSideItemBox>
              </AdmSectionSideItem>
            </>
          )
        }
        if (param.shouldRender) {
          if (param.shouldRender(adjustment, view)) {
            return (
              <>
                <AdmSectionSideItem>
                  <AdmSectionSideItemBox>
                    <AdmParameterItem
                      param={param}
                      lz={props.useLz ? view.shot : undefined}
                    />
                  </AdmSectionSideItemBox>
                </AdmSectionSideItem>

                <AdmSectionSeparator />
              </>
            )
          }
        } else {
          return (
            <>
              <AdmSectionSideItem>
                <AdmSectionSideItemBox>
                  <AdmParameterItem
                    param={param}
                    lz={props.useLz ? view.shot : undefined}
                  />
                </AdmSectionSideItemBox>
              </AdmSectionSideItem>
              <AdmSectionSeparator />
            </>
          )
        }
      })
    ) : (
      <>
        <SideItemSkeleton />
      </>
    )

  return (
    <>
      {props.parameters && props.parameters[0] && (
        <>
          <AdmSectionSideHeader>
            <AdmSectionHeading>Parameters</AdmSectionHeading>
            <AdmSectionSubText>{props.subText}</AdmSectionSubText>
          </AdmSectionSideHeader>
          {parameters}
        </>
      )}
      {(!props || !view) && <OptionalAdjustmentSkeleton />}
      {props.tweener && (
        <>
          <AdmSectionSideHeader>
            <AdmSectionHeading>Optional Adjustment</AdmSectionHeading>
          </AdmSectionSideHeader>
          <AdmSectionSideItem>
            <AdmSectionSideItemBox>
              <Tweener useLz={props.useLz} />
            </AdmSectionSideItemBox>
          </AdmSectionSideItem>
        </>
      )}
    </>
  )
}

type OriginalValueComponentProps = {
  param: AdmParameter
  lockStatus: LockStatus
  setAdjustment: (
    type: AdjustmentTypes,
    view: any,
    key: string,
    id: string,
    value: number
  ) => Promise<void>
  view: any
  currentId?: string | null
  originalValue: number | string
}

function OriginalValueComponent({
  param,
  lockStatus,
  setAdjustment,
  view,
  currentId,
  originalValue,
}: OriginalValueComponentProps) {
  const adjustments = useAdjustment()
  const [resetting, setResetting] = useState(false)
  const canEdit = lockStatus === LockStatus.UNLOCKED

  if (!canEdit || originalValue === '') {
    return null
  }

  const onClick = async () => {
    setResetting(true)
    setTimeout(() => setResetting(false), 2000)
    await setAdjustment(
      param.format as AdjustmentTypes,
      view,
      param.adjustmentKey as string,
      currentId as string,
      Number(originalValue)
    )
  }

  const valueWithResetIcon = (
    <>
      <div style={{ width: '100%', height: '50%' }}>
        <span
          style={{
            fontSize: '14px',
          }}
        >{`(${originalValue})`}</span>
      </div>

      <div style={{ width: '100%', height: '50%' }}>
        <Feather name="rotate-ccw" size={12} />
      </div>
    </>
  )

  const spinner = (
    <LoadingContainer>
      <Feather name="loader" size={16} />
    </LoadingContainer>
  )
  return (
    <>
      <ResetAdjustmentButton
        onClick={!resetting ? onClick : () => console.log('Invalid action')}
      >
        {resetting ? (
          spinner
        ) : (
          <ResetAdjustmentButtonContent>
            {!adjustments || !adjustments.adjustments
              ? spinner
              : valueWithResetIcon}
          </ResetAdjustmentButtonContent>
        )}
      </ResetAdjustmentButton>
    </>
  )
}
