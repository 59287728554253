import { TeamRole } from '@app/evaluation-core/types'

import { State } from '@app/state/types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import useUser from './useUser'

export default () => {
  const stateUser = useUser()
  const user = useSelector(
    (state: State) => state?.evaluation?.evaluation?.users[stateUser.id]
  )

  const memoEvalUser = useMemo(() => {
    return {
      ...user,
      isAdmin:
        user?.role === TeamRole.SYSTEM_ADMIN ||
        user?.role === TeamRole.LEADER ||
        stateUser.isAdmin,
      isMiAdmin: +stateUser.accountType >= 10,
      isTeamLeader: user?.role === TeamRole.LEADER || stateUser.isAdmin,
    }
  }, [stateUser, user])

  return memoEvalUser
}
