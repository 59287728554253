import React from 'react'

type MarkerProps = {
  fillColor: string
  borderColor?: string
  height?: number | string
  width?: number | string
}

const EvaluationLeader = ({
  fillColor,
  height = 30,
  width = 30,
}: MarkerProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 180 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="26.9922"
      y="95.9492"
      width="21"
      height="9.94784"
      transform="rotate(-41.3129 26.9922 95.9492)"
      fill={fillColor}
    />
    <rect
      x="137.604"
      y="81"
      width="21"
      height="9.94784"
      transform="rotate(41.5971 137.604 81)"
      fill={fillColor}
    />
    <rect
      x="85"
      y="128"
      width="21"
      height="9.94784"
      transform="rotate(-90 85 128)"
      fill={fillColor}
    />
    <circle cx="20" cy="104" r="20" fill={fillColor} />
    <circle cx="89.5" cy="40.5" r="14.5" fill={fillColor} />
    <path
      d="M180 104C180 115.046 171.046 124 160 124C148.954 124 140 115.046 140 104C140 92.9543 148.954 84 160 84C171.046 84 180 92.9543 180 104Z"
      fill={fillColor}
    />
    <circle cx="90" cy="142" r="20" fill={fillColor} />
    <circle cx="90" cy="58" r="51" stroke={fillColor} strokeWidth="14" />
    <rect x="62" y="66" width="56" height="14" fill={fillColor} />
    <rect x="63" y="64" width="54" height="2" fill={fillColor} />
    <rect x="64" y="63" width="52" height="1" fill={fillColor} />
    <rect x="65" y="62" width="50" height="1" fill={fillColor} />
    <rect x="67" y="61" width="46" height="1" fill={fillColor} />
    <rect x="68" y="60" width="44" height="1" fill={fillColor} />
    <rect x="70" y="59" width="40" height="1" fill={fillColor} />
    <rect x="74" y="58" width="32" height="1" fill={fillColor} />
  </svg>
)

export default EvaluationLeader
