import firebase from '@app/firebase'
import store, { persistor } from '@app/state'

export const signOut = async () => {
  store.dispatch({ type: 'RESET' })

  await persistor.purge()
  await persistor.flush()
  await persistor.persist()
}
