import { Spinner } from '@app/evaluation/components/Spinner'
import AntDesignIcon from '@ovaeasy/react-native-vector-icons/MaterialIcons'
import * as React from 'react'
import * as S from './HeaderBar.styled'
import { debounce } from 'throttle-debounce'
import {
  EvaluationCard,
  EvaluationCardIcon,
  RatingInputSave,
  RatingNameButton,
  RatingNameInput,
} from './Header.styles'
import Spacer from '@app/components/atoms/Spacer'

interface HeaderProps {
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  placeholder: string
  iconOnPress: () => void
  icon: any
  iconSize: number
  color: string
  debounceAmount: number
  isSmallScreen: boolean
  loading?: boolean
}

const HeaderBar = ({
  onChange,
  value,
  placeholder,
  icon,
  iconSize,
  color,
  iconOnPress,
  debounceAmount,
  isSmallScreen,
  loading,
}: HeaderProps) => {
  const onPress = debounce(debounceAmount, false, iconOnPress)
  return (
    <S.CardWrapper>
      <S.HeaderText>Save Rating</S.HeaderText>
      <Spacer height={4} />
      <S.SubText>
        If you would like to save an in progress rating without finalizing it,
        please name the rating below and press save. This will allow you to
        later reimport the rating and continue.
      </S.SubText>
      <Spacer height={4} />
      <RatingInputSave>
        <RatingNameInput width={'100%'}>
          <input
            style={{ fontSize: 16, alignSelf: 'flex-start', width: '100%' }}
            value={value}
            placeholder="Rating Save Name"
            onChange={onChange}
            className="courses-search-input"
          />
        </RatingNameInput>

        <Spacer height={8} />
        <RatingNameButton
          onClick={onPress}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <Spinner
              inInput
              style={{ height: '20px', width: '20px', marginLeft: '8px' }}
            />
          ) : (
            ' '
          )}
          Save
        </RatingNameButton>
      </RatingInputSave>
    </S.CardWrapper>
  )
}

export default HeaderBar
