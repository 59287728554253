import { FeaturesState } from './types'

export const ALL_FEATURES: FeaturesState = {
  features: {
    'new-adjustments': {
      enabled: true,
    },
    'recalculate-initial-values': {
      enabled: true,
    },
  },
}
