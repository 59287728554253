import styled, { keyframes } from 'styled-components'
import theme from '@app/ui/theme'

export const SearchContainer = styled.div`
  padding: 16px;
  overflow-y: hidden;
`

export const SearchInput = styled.input`
  width: 100%;
  max-width: 400px;
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.gray300};
  border-radius: 4px;
  margin-bottom: 16px;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.secondary2};
  }
`

export const UsersList = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

export const UserItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray200};
`

export const UserName = styled.div`
  font-weight: 500;
`

export const UserEmail = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray600};
`

export const NoResults = styled.div`
  text-align: center;
  padding: 16px;
  color: ${({ theme }) => theme.colors.gray500};
`

export const InvitedLabel = styled.span`
  color: ${({ theme }) => theme.colors.secondary2};
  margin-left: auto;
padding: 8px;
display: flex;
gap: 4px
align-items: center;
justify-content: center;
  `

export const CloseButton = styled.div`
  height: 42px;
  width: 42px;
  background-color: ${theme.colors.warning};
  border-radius: 20px;
  margin: 0px 10px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  color: white;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
`

const dotAnimation = keyframes`
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".";
  }
`

export const LoadingText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: ${theme.colors.secondary2};
  &:after {
    content: '.';
    animation: ${dotAnimation} 1.5s steps(3, end) infinite;
  }
`

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
