import React from 'react'
import { removeTeamMember } from '@app/evaluation-core/evaluation'

import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import { DropDownMenuItem } from '@app/ui/dropdown-menu/DropDownMenu.styles'
import { FormExGenderBtn } from '@app/evaluation-settings-menu/HeaderArea.styles'
import { TeamRole } from '@app/evaluation-core/types'
import fb from '@app/firebase'
import theme from '@app/ui/theme'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import useReadOnly from '../hooks/useReadOnly'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import { FaTrash, FaSync, FaCrown, FaArrowDown } from 'react-icons/fa'
import styled from 'styled-components'
import { Divider } from './Evaluation.styles'

interface TeamActionMenuProps {
  userId: string
}

export const TeamActionMenu = ({ userId }: TeamActionMenuProps) => {
  const evalUser = useEvaluationUser()
  const { currentId, evaluation } = useEvaluation()
  const isOwnUser = userId === evalUser?.userId?.toString()
  const handleReadOnly = useReadOnly()
  const users = Object.values(evaluation.users)
  const isPartOfEvalution =
    users.find((user) => evaluation?.userId.toString()) !== undefined

  const teamLeaders = users
    .filter((user) => user.role === TeamRole.LEADER)
    .map((user) => user.userId)

  const systemAdmins = users
    .filter((user) => user.role === TeamRole.SYSTEM_ADMIN)
    .map((user) => user.userId)

  const isAuthenticatedSystemAdmin = evalUser?.isAdmin
  const isSystemAdmin = systemAdmins?.includes(userId)
  const isTeamLeader = teamLeaders.includes(userId)

  if (!currentId || !evalUser) return null

  const canEdit =
    isPartOfEvalution && evaluation.status !== EvalStatus.FINALIZED
  const canRemove =
    canEdit &&
    !isOwnUser &&
    ((evalUser?.isAdmin && !teamLeaders.includes(userId)) ||
      isAuthenticatedSystemAdmin)

  const canDemote = evalUser?.isAdmin

  const disabledMessage = () => {
    if (!isPartOfEvalution)
      return 'You are not in this evaluation and cannot edit users.'
    else if (evaluation.status === EvalStatus.FINALIZED)
      return 'You can not edit users in a finalized evaluation.'
    else return ''
  }
  const removeUser = () => {
    if (!isAuthenticatedSystemAdmin && isSystemAdmin) {
      return
    }
    if (canRemove) {
      removeTeamMember(currentId, userId)
    }
  }

  const setToRole = async (role: TeamRole) => {
    // There can only be one leader
    if (role === TeamRole.LEADER)
      teamLeaders.forEach(async (id: string) => {
        await fb
          .ref(`evaluations/${evaluation.id}/users/${id}/role`)
          .set(TeamRole.EVALUATOR)
      })

    await fb.ref(`evaluations/${evaluation.id}/users/${userId}/role`).set(role)
  }

  const setToTeamLeader = async () => {
    await setToRole(TeamRole.LEADER)
  }

  const setToEditor = async () => {
    await setToRole(TeamRole.SYSTEM_ADMIN)
  }

  const demote = async () => {
    await fb
      .ref(`evaluations/${evaluation.id}/users/${userId}/role`)
      .set(TeamRole.EVALUATOR)
  }

  return (
    <DropDownMenu
      value={'Actions'}
      width={190}
      relative
      xAdj={-100}
      disabledState={{
        disabled: !canEdit,
        message: disabledMessage(),
      }}
    >
      {(isAuthenticatedSystemAdmin || !isSystemAdmin) && (
        <DropDownMenuItem
          disabled={!isPartOfEvalution || teamLeaders.includes(userId)}
          onPress={handleReadOnly(setToTeamLeader)}
        >
          <FormExGenderBtn>
            <span>Assign team leader</span>
            <div>
              <FaCrown name="gavel" size={20} color={theme.colors.primary} />
            </div>
          </FormExGenderBtn>
        </DropDownMenuItem>
      )}

      {isAuthenticatedSystemAdmin && !isSystemAdmin && (
        <DropDownMenuItem
          disabled={!isAuthenticatedSystemAdmin || teamLeaders.includes(userId)}
          onPress={handleReadOnly(setToEditor)}
        >
          <FormExGenderBtn>
            <span>Set to admin</span>
            <div>
              <FaSync size={20} color={'rgb(238, 0, 0)'} />
            </div>
          </FormExGenderBtn>
        </DropDownMenuItem>
      )}

      {canDemote && isSystemAdmin && (
        <DropDownMenuItem onPress={handleReadOnly(demote)}>
          <FormExGenderBtn>
            <span>Demote</span>
            <div>
              <FaArrowDown size={20} color={theme.colors.primary} />
            </div>
          </FormExGenderBtn>
        </DropDownMenuItem>
      )}

      {(isAuthenticatedSystemAdmin || !isSystemAdmin) && (
        <>
          <Divider />
          <DropDownMenuItem
            onPress={handleReadOnly(removeUser)}
            disabled={(!isAuthenticatedSystemAdmin && !canRemove) || isOwnUser}
          >
            <FormExGenderBtn>
              <span>Remove</span>
              <div>
                <FaTrash size={20} color={theme.colors.primary} />
              </div>
            </FormExGenderBtn>
          </DropDownMenuItem>
        </>
      )}
    </DropDownMenu>
  )
}
