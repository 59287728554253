import React from 'react'
import Svg from 'react-native-svg'

type MarkerProps = {
  fillColor: string
  borderColor: string
  height?: number | string
  width?: number | string
}

// with no shadow
{
  /* <svg
      width={width}
      height={height}
      viewBox={`0 0 42 54`}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.67363 24.8179L1.66949 24.7989L1.66471 24.7801C1.33329 23.4743 1.16667 22.1685 1.16667 20.6847C1.16667 9.95151 10.0598 1.16667 21 1.16667C31.9402 1.16667 40.8333 9.95151 40.8333 20.6847C40.8333 22.3406 40.6672 23.8413 40.4958 25.0312C39.9104 27.6273 38.4023 30.6525 36.3905 33.783C34.3789 36.9132 31.9152 40.0702 29.5133 42.8971C27.1137 45.7214 24.7892 48.2006 23.0639 49.9742C22.223 50.8386 21.5254 51.5344 21.0323 52.0195C20.5427 51.5342 19.8508 50.8389 19.0169 49.9748C17.3029 48.1987 14.9934 45.7145 12.6088 42.8796C10.222 40.0422 7.77325 36.8692 5.77265 33.715C3.76168 30.5444 2.25345 27.4694 1.67363 24.8179Z"
        fill={fillColor}
        stroke={fillColor}
        strokeWidth="2.33333"
      />
    </svg> */
}

const Marker = ({
  fillColor,
  borderColor = 'black',
  height,
  width,
}: MarkerProps) => (
  <Svg height={height} width={width}>
    <svg
      width={width}
      height={height}
      viewBox={`0 0 42 54`}
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.67363 24.8179L1.66949 24.7989L1.66471 24.7801C1.33329 23.4743 1.16667 22.1685 1.16667 20.6847C1.16667 9.95151 10.0598 1.16667 21 1.16667C31.9402 1.16667 40.8333 9.95151 40.8333 20.6847C40.8333 22.3406 40.6672 23.8413 40.4958 25.0312C39.9104 27.6273 38.4023 30.6525 36.3905 33.783C34.3789 36.9132 31.9152 40.0702 29.5133 42.8971C27.1137 45.7214 24.7892 48.2006 23.0639 49.9742C22.223 50.8386 21.5254 51.5344 21.0323 52.0195C20.5427 51.5342 19.8508 50.8389 19.0169 49.9748C17.3029 48.1987 14.9934 45.7145 12.6088 42.8796C10.222 40.0422 7.77325 36.8692 5.77265 33.715C3.76168 30.5444 2.25345 27.4694 1.67363 24.8179Z"
        fill={fillColor}
        stroke={borderColor}
        strokeWidth="2"
      />
    </svg>
  </Svg>
)

export default Marker
