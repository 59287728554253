import styled from 'styled-components'

export const TopContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
`

export const GoBackContainer = styled.div<{ portraitMode: boolean }>`
  margin-left: 20px;
  margin-top: 45px;
  margin-right: ${({ portraitMode }) => (portraitMode ? '40px' : '100px')};
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

export const NavigationContainer = styled.div`
  margin-top: 40px;
  @media only screen and (max-width: 834px) {
    margin-top: 20px;
  }
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
`

export const BackArrowContainer = styled.div`
  margin-top: 2px;
`

export const BackText = styled.span`
  margin-left: 10px;
`
