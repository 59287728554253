import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import styled from 'styled-components'
import S from 'styled-components/native'

export const MainContainer = styled.div`
  aspect-ratio: 1 / 1;
  width: 30%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  min-height: 450px;
  min-width: 450px;
`

export const InnerContainer = styled.div`
  padding: 36px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
export const ImageContainer = styled.div`
  width: 40%;
  margin-left: 'auto';
  margin-right: 'auto';
`

export const EmphasizedText = styled.span`
  font-size: 24px;
  font-weight: 800;
  text-align: 'center';
`
