import theme from '@app/ui/theme'
import { StackNavigationOptions } from '@react-navigation/stack/lib/typescript/src/types'

export const headerTitleStyle = {
  fontSize: 18,
  color: 'white',
  marginLeft: 8,
}

export const screenOptions: StackNavigationOptions = {
  animationEnabled: false,
  headerTitleStyle,
  headerTintColor: 'white',
  headerStyle: {
    backgroundColor: theme.colors.primary,
    borderBottomWidth: 0,
    borderBottomColor: theme.colors.light,
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 0,
    elevation: 2,
  },
}
