import { State } from '@app/state/types'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import useUser from '@common/hooks/useUser'
import { UserState } from '@app/auth/types'

// Framework agnostic logic
const findUser = (users: any[], userId: string) =>
  users?.find((user) => user.email === userId)

const userHasAvatar = (user: UserState) => {
  return (
    user?.profilePictureFileID &&
    user?.profilePictureFileID !== '0' &&
    user?.profilePictureFileID !== '-1'
  )
}

// Integration logic
const useAvatar = (userId: string | undefined) => {
  useFirestoreConnect(['users'])

  const data = useSelector((state: State) => ({
    users: state.firestore.ordered.users,
    loading: state.firestore.status.requesting.users,
  }))

  const user = useUser()

  if (data.loading) {
    return { user, data, mappingAvatar: null, avatarBackup: '' }
  }

  const userFromFirestore = findUser(data?.users, userId || '')

  const avatarBackup =
    userId === user?.id?.toString()
      ? user?.forename?.charAt(0)
      : userId?.charAt(0).toLocaleUpperCase() || ''

  const hasAvatar =
    userId !== user?.id?.toString()
      ? userHasAvatar(userFromFirestore)
      : userHasAvatar(user)

  const avatarUser = userId === user?.id?.toString() ? user : userFromFirestore
  const mappingAvatar =
    hasAvatar &&
    (userId === avatarUser.id.toString() || userId === avatarUser.email)
      ? `https://www.livegis.com/getfile.php?file=${avatarUser?.profilePictureFileID}`
      : null

  return { user, data, mappingAvatar, avatarBackup }
}

export default useAvatar
