import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Container = styled.div<{
  width?: number
  height?: number
  marginTop?: number
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  margin-top: ${({ marginTop }) => marginTop}px;
`
