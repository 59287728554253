import {
  Eval,
  EvaluationGroup,
  EvaluationLoading,
  EvaluationUser,
  Golfer,
  GolferShortName,
  GolferType,
  InitialEvaluation,
} from './types'

import { version } from '../../../package.json'
import { LRCourse } from '../types/Clubs'
import { RatingRevision } from '../types/Templates'

export const userView = () => ({
  hole: 0,
  adjustment: -1,
  golfer: GolferShortName.ScratchMen,
  tee: 0,
  shot: 0,
  collapsedTees: [],
})

export const initialGroups = (): EvaluationGroup[] => [
  {
    groupIndex: 0,
    name: 'Roll',
    shortName: 'ROLL',
    adjustment: 'roll',
    type: 'EPL',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },

  {
    groupIndex: 1,
    name: 'DOGLEG/FORCED LAY-UP',
    shortName: 'DL/FLU',
    adjustment: 'dogleg',
    type: 'EPL',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },
  {
    groupIndex: 2,
    name: 'Elevation',
    shortName: 'ELEV',
    adjustment: 'elevation',
    type: 'EPL',
    lzAdjustment: false,
    saveMode: 'tee',
    shotAdjustment: false,
  },
  {
    groupIndex: 3,
    name: 'Topography',
    shortName: 'TOPO',
    adjustment: 'topography',
    type: 'ORF',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },
  {
    groupIndex: 4,
    name: 'Fairway',
    shortName: 'FWY',
    adjustment: 'fairway',
    type: 'ORF',
    lzAdjustment: true,
    saveMode: 'normal',
    shotAdjustment: false,
  },
  {
    groupIndex: 5,
    name: 'Green Target',
    shortName: 'GT',
    adjustment: 'greentarget',
    type: 'ORF',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },
  {
    groupIndex: 6,
    name: 'Recoverability and Rough',
    shortName: 'R&R',
    adjustment: 'recoverrough',
    type: 'ORF',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },
  {
    groupIndex: 7,
    name: 'Bunkers',
    shortName: 'BUNK.',
    adjustment: 'bunkers',
    type: 'ORF',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },
  {
    groupIndex: 8,
    name: 'Crossing Obstacles',
    shortName: 'CROSS.',
    adjustment: 'crossingobstacles',
    type: 'ORF',
    saveMode: 'normal',
    lzAdjustment: false,
    shotAdjustment: true,
  },
  {
    groupIndex: 9,
    name: 'Lateral Obstacles',
    shortName: 'LAT.',
    adjustment: 'lateralobstacles',
    type: 'ORF',
    saveMode: 'normal',
    lzAdjustment: false,
    shotAdjustment: true,
  },
  {
    groupIndex: 10,
    name: 'Trees',
    shortName: 'TREES',
    adjustment: 'trees',
    type: 'ORF',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },
  {
    groupIndex: 11,
    name: 'Green Surface',
    shortName: 'SURF.',
    adjustment: 'greensurface',
    type: 'ORF',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },
  {
    groupIndex: 12,
    name: 'Psychological',
    shortName: 'PSYCH.',
    adjustment: 'psychological',
    type: 'ORF',
    lzAdjustment: false,
    saveMode: 'normal',
    shotAdjustment: false,
  },
]

export const initialGolfer = (): GolferType[] => [
  {
    typeIndex: 0,
    name: 'Scratch Men',
    shortName: GolferShortName.ScratchMen,
    playerType: Golfer.SCRATCH,
  },
  {
    typeIndex: 1,
    name: 'Bogey Men',
    shortName: GolferShortName.BogeyMen,
    playerType: Golfer.BOGEY,
  },
  {
    typeIndex: 2,
    name: 'Scratch Women',
    shortName: GolferShortName.ScratchWomen,
    playerType: Golfer.SCRATCH,
  },
  {
    typeIndex: 3,
    name: 'Bogey Women',
    shortName: GolferShortName.BogeyWomen,
    playerType: Golfer.BOGEY,
  },
]

export const initialLoadingState = (): EvaluationLoading => ({
  loading: 1,
  message: '',
  progress: 0,
})

export const initialEvaluation = (
  user: EvaluationUser,
  branchId: string,
  holeIds: string[],
  clubId: number,
  ratingRevision: RatingRevision | null
): InitialEvaluation => {
  return {
    id: '',
    userId: '',
    holes: [],
    users: {
      [user.userId]: user,
    },
    clubId,
    branchId,
    holeIds,
    adjustments: [],
    status: 0,
    settingsChecked: false,
    loading: initialLoadingState(),
    isMixed: false,
    version,
    ratingRevision,
  }
}

export const initialMixedEvaluation = (courseName: string, user: any): Eval => {
  return {
    id: '',
    userId: '',
    clubId: 0,
    courseName,
    courseId: '0',
    holes: [],
    users: {
      [user.userId]: user,
    },
    adjustments: [],
    status: 0,
    isMixed: true,
    ratingRevision: {
      rootEvaluation: '',
      parentEvaluation: '',
      ratingRevision: 0,
      ratingDate: '',
    },
    settingsChecked: false,
    loading: initialLoadingState(),
    version,
  }
}
