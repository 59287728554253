import { BranchStatus, LRClub, LRCourse } from '../../types/Clubs'
import { getCourseStatus } from './ratingStatus'

export const clubDisplayFilter = (
  club: LRClub,
  _,
  searchValue,
  filterStatusValue,
  regionFilterStatusValue,
  districtFilterStatusValue,
  displayClub = true
) => {
  if (
    !club?.courses?.some((course) => filterBySearch(course, club, searchValue))
  ) {
    displayClub = false
  }

  if (!filterByRegion(club, regionFilterStatusValue)) {
    displayClub = false
  }
  if (!filterByDistrict(club, districtFilterStatusValue)) {
    displayClub = false
  }
  if (
    displayClub &&
    !club?.courses?.some((course) =>
      filterByStatus(course, club?.branchStatus, filterStatusValue)
    )
  ) {
    displayClub = false
  }

  return displayClub
}

export const filterBySearch = (item, club, searchValue) => {
  if (club === null) {
    return true
  }
  if (
    (club?.name &&
      club.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) ||
    searchValue === ''
  ) {
    return true
  }

  return (
    (item.name &&
      item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) ||
    searchValue === ''
  )
}

export const filterByRegion = (club, regionFilterStatusValue) => {
  if (!club?.regionName) {
    return false
  }
  if (regionFilterStatusValue === '') {
    return true
  }
  const shouldHide =
    club?.regionName
      .toLowerCase()
      .indexOf(regionFilterStatusValue.toLowerCase()) !== -1

  return shouldHide
}

export const filterByDistrict = (club, districtFilterStatus) => {
  if (!club?.districtName || !club?.regionName) {
    return false
  }

  if (districtFilterStatus === '') {
    return true
  }

  const shouldHide =
    club.districtName
      .toLowerCase()
      .indexOf(districtFilterStatus.toLowerCase()) !== -1

  return shouldHide
}

export const filterByStatus = (
  item: LRCourse,
  branchStatus: BranchStatus,
  filterStatusValue: any
) =>
  getCourseStatus(branchStatus) === filterStatusValue ||
  filterStatusValue === -1

export const filterByVisibleClubs = (course, visibleClubs) =>
  visibleClubs.some((club) => club.id === course.golfClubID)
