import { useState, useEffect } from 'react'

const usePortraitMode = (): boolean => {
  const [portraitMode, setPortraitMode] = useState(
    () => window.innerWidth < window.innerHeight
  )

  useEffect(() => {
    const handleResize = () => {
      setPortraitMode(window.innerWidth < window.innerHeight)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return portraitMode
}

export default usePortraitMode
