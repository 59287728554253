import { ButtonContainer } from '@app/components/atoms/ContinueButton/styled'
import React, { useState, useEffect } from 'react'
import MaterialIcon from '@ovaeasy/react-native-vector-icons/MaterialIcons'
import { ContentHeading, ContentSeparator } from './Preparation.styles'
import * as S from './SearchInvitableRater.styles'
import { useApi } from '@common/hooks/useApi'
import { ActivityIndicator } from 'react-native'
import Spacer from '@app/components/atoms/Spacer'
import theme from '@app/ui/theme'
import useInvite from '@common/hooks/useInvite'
import { useEvaluation } from '@common/hooks'
import { useSnackbar } from '@app/snackbar'

type Props = {
  onClose?: () => void
}

type InvitableUser = {
  id: number
  forename: string
  lastname: string
  email: string
  gitEmail: string
  level: number
  accountType: number
  golfId: string
}

const SearchInvitableRatersModal = ({ onClose }: Props) => {
  const { data, isLoading: fetchingUsers } = useApi<InvitableUser[]>(
    '/evaluations/invitable'
  )
  const [searchTerm, setSearchTerm] = useState('')
  const [invitedUsers, setInvitedUsers] = useState<number[]>([])
  const [invitedCustomEmails, setInvitedCustomEmails] = useState<string[]>([])
  const [users, setUsers] = useState<InvitableUser[]>([])
  const { sendInvite } = useInvite()
  const evaluation = useEvaluation()
  const [openSnackbar] = useSnackbar()

  useEffect(() => {
    if (data) {
      setUsers(data)
    }
  }, [data])

  const filteredUsers = users.filter(
    (user) =>
      user.forename.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (
        user.forename.toLowerCase() +
        ' ' +
        user.lastname.toLowerCase()
      ).includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.gitEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.golfId.includes(searchTerm)
  )

  const handleInvite = async (userId?: number) => {
    try {
      if (!userId) {
        const isValidEmail = validateEmail(searchTerm)
        if (!isValidEmail) {
          openSnackbar('error', 'Invalid email address')
          return
        }
        setInvitedCustomEmails([...invitedCustomEmails, searchTerm])
        await sendInvite(searchTerm, evaluation.currentId, () =>
          console.log('Invitation sent')
        )
        return
      }
      const user = users.find((user) => user.id === userId)
      const email = user?.email || user?.gitEmail
      if (!email) {
        openSnackbar('error', 'User does not have an email address')
        return
      }
      if (invitedUsers.includes(userId)) {
        openSnackbar('error', 'User has already been invited')
        return
      }
      setInvitedUsers([...invitedUsers, userId])
      await sendInvite(email, evaluation.currentId, () =>
        console.log('Invitation sent')
      )
    } catch (error) {
      openSnackbar('error', 'Error sending invitation')
      setInvitedUsers(invitedUsers.filter((id) => id !== userId))
    }
  }

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }
  const isValidEmail = validateEmail(searchTerm)

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: -8, right: -8 }}>
          <S.CloseButton onClick={onClose}>X</S.CloseButton>
        </div>
        <ContentHeading>Invite Rater</ContentHeading>
        <ContentSeparator />
        <S.SearchContainer>
          <div
            style={{
              display: 'flex',
              gap: 4,
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <S.SearchInput
              type="text"
              placeholder="Enter email or search by name, email or Golf ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {invitedCustomEmails.includes(searchTerm) ? (
              <S.InvitedLabel style={{ marginLeft: 0 }}>
                <MaterialIcon name="check-circle" size={16} />
                Invitation Sent
              </S.InvitedLabel>
            ) : (
              <ButtonContainer
                style={{ opacity: isValidEmail ? 1 : 0.5 }}
                onClick={() => isValidEmail && handleInvite()}
              >
                <span style={{ color: 'white' }}>Invite</span>
              </ButtonContainer>
            )}
          </div>
          {fetchingUsers ? (
            <>
              <ActivityIndicator color={theme.colors.secondary2} size={36} />
              <Spacer height={4} />
              <S.LoadingText>Getting Users</S.LoadingText>
            </>
          ) : (
            <S.UsersList>
              {filteredUsers.map((user) => (
                <S.UserItem key={user.id}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <S.UserName>{`${user.forename || '-'} ${user.lastname} ${
                      user.golfId ? `(${user.golfId})` : ''
                    }`}</S.UserName>
                    <S.UserEmail>{user.email || user.gitEmail}</S.UserEmail>
                  </div>
                  {invitedUsers.includes(user.id) ? (
                    <S.InvitedLabel>
                      <MaterialIcon name="check-circle" size={16} />
                      Invitation Sent
                    </S.InvitedLabel>
                  ) : (
                    <ButtonContainer
                      style={{ minWidth: 140, marginLeft: 'auto' }}
                      onClick={() => handleInvite(user.id)}
                    >
                      <span style={{ color: 'white' }}>Invite</span>
                    </ButtonContainer>
                  )}
                </S.UserItem>
              ))}
              {filteredUsers.length === 0 && (
                <S.NoResults>No users found matching your search</S.NoResults>
              )}
            </S.UsersList>
          )}
        </S.SearchContainer>
      </div>
    </>
  )
}

export default SearchInvitableRatersModal
