import * as S from './styled'
import { Eval } from '@app/evaluation-core/types'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import React, { useState, useEffect, FC, useCallback } from 'react'
import { useSnackbar } from '@app/snackbar'
import useUser from '@common/hooks/useUser'
import {
  updateClubName,
  updateCourseName,
} from '@app/evaluation-core/actions/evaluationActions'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import DateInput from '../DatePicker/DatePicker'
import fb from '@app/firebase'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import {
  updateEvaluationComment,
  updateEvaluationDate,
  updateEvaluationDesciption,
  updateEvaluationManualVersion,
  updateEvaluationRatingRevision,
} from '@app/evaluation-core/evaluation'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import CourseScorecardModal from '../CourseScorecardModal/CourseScorecardModal'
import { LRClub } from '../../../types/Clubs'
import Feather from '../../../../mobile/__mocks__/@ovaeasy/react-native-vector-icons/Feather'
import { LoadingContainer } from '@app/evaluation-adjustments/components/AdjustmentFactors.styles'
import {
  ResetAdjustmentButton,
  ResetAdjustmentButtonContent,
} from '@app/evaluation-adjustments/components/Adjustment.styles'
import { View } from 'react-native'
import firebase from 'firebase'
import TeeColorPicker from '../TeeColorPicker'

interface CourseInfoProps {
  club?: LRClub
  evaluation: Eval
  gcId?: string
}

const CourseInfo: FC<CourseInfoProps> = ({ club, evaluation, gcId }) => {
  const user = useUser()
  const evalUser = useEvaluationUser()
  const [openSnackbar] = useSnackbar()

  const handleSaveField = async (field: string, value: any) => {
    if (evaluation.id) {
      const updateActions: { [key: string]: () => Promise<void> } = {
        clubName: () => updateClubName(value, evaluation.id as string),
        courseName: () => updateCourseName(value, evaluation.id as string),
        saveName: () =>
          fb.ref(`evaluations/${evaluation.id}/saveName`).set(value),
        evaluationDate: () =>
          updateEvaluationDate(evaluation.id as string, value),
        comment: () => updateEvaluationComment(evaluation.id as string, value),
        description: () => updateEvaluationDesciption(value),
        manualVersion: () => updateEvaluationManualVersion(value),
        ratingRevision: () => updateEvaluationRatingRevision(Number(value)),
      }
      await updateActions[field]?.()
    }
  }

  const copyToClipboard = async () => {
    if (evaluation.id) {
      await navigator.clipboard.writeText(evaluation.id)
      openSnackbar('Rating ID copied to clipboard.')
    }
  }

  const canEdit =
    evaluation?.status !== EvalStatus.FINALIZED &&
    (user.isAdmin ||
      Object.values(evaluation?.users).some((id) => +id.userId == user.id))
  const canEditFinalized =
    evaluation?.status === EvalStatus.FINALIZED &&
    (user.isAdmin ||
      Object.values(evaluation?.users).some((id) => +id.userId == user.id))

  const [scorecardModalVisible, setScorecardModalVisible] = useState(false)

  const onSaveTeeOptions = useCallback(async (teeColors: string[], teeNames: string[]) => {
    console.log({ teeColors, teeNames })
    for (let i = 0; i < teeColors.length; i++) {
      if (teeColors[i].startsWith('#')) {
        teeColors[i] = teeColors[i].substring(1)
      }

      if (
        teeColors[i] === '' ||
        teeColors[i] === undefined ||
        teeColors[i] === null ||
        teeColors[i].length !== 6
      ) {
        openSnackbar('Invalid color value found')
        return
      }
    }

    for (let i = 0; i < teeNames.length; i++) {
      if (teeNames[i] === '' || teeNames[i] === undefined || teeNames[i] === null) {
        openSnackbar('Invalid tee name found')
        return
      }
    }

    try {
      const updates = {}
      const snapshot = await firebase
        .database()
        .ref(`evaluations/${evaluation.id}/holes`)
        .get()

      if (snapshot.exists()) {
        snapshot.forEach((holeSnapshot) => {
          const holeNumber = holeSnapshot.key
          teeColors.forEach((newColor, teeIndex) => {
            updates[
              `evaluations/${evaluation.id}/holes/${holeNumber}/tees/${teeIndex}/color`
            ] = newColor
          })

          teeNames.forEach((newName, teeIndex) => {
            updates[
              `evaluations/${evaluation.id}/holes/${holeNumber}/tees/${teeIndex}/name`
            ] = newName
          })
        })

        console.log('updates', updates)

        await firebase.database().ref().update(updates)
        openSnackbar('Tee Info updated successfully')
      }
    } catch (error) {
      console.error('Error updating tee colors:', error)
      openSnackbar('Error updating tee info')
    }
  }, [evaluation, openSnackbar])

  if (!evaluation) return <></>

  return (
    <>
      <CourseScorecardModal
        courseId={Number(evaluation.courseId)}
        isVisible={scorecardModalVisible}
        closeFn={() => setScorecardModalVisible(false)}
      />
      <S.ContentHeading>
        {evaluation?.clubName}, {evaluation?.courseName}
        {/*
        <RoundedButton
          onPress={() => setScorecardModalVisible(true)}
          title={'Scorecard'}
          testId="btn-start-course"
          style={{ width: '150px', height: '34px' }}
        />
        */}
      </S.ContentHeading>
      <AdminControls
        user={user}
        evaluation={evaluation}
        copyToClipboard={copyToClipboard}
      />

      <div>
        <TextInput
          label="Club name"
          additionalText="Use capital letters."
          defaultValue={evaluation?.clubName as string}
          onBlur={(value) => handleSaveField('clubName', value)}
          disabled={!canEdit && !canEditFinalized}
          canReset={true}
          resetValue={club?.name}
        />
      </div>

      <TextInput
        label="Course name"
        defaultValue={evaluation?.courseName as string}
        onBlur={(value) => handleSaveField('courseName', value)}
        disabled={!canEdit && !canEditFinalized}
        canReset={true}
        resetValue={club?.name}
      />
      <TextInput
        label="Rating Name"
        additionalText="This text will appear in the Slope Tables."
        defaultValue={evaluation?.saveName as string}
        onBlur={(value) => handleSaveField('saveName', value)}
        disabled={!canEdit && !canEditFinalized}
      />
      <DateField
        label="Rating Date"
        additionalText="This date will appear in the FINALIZED Slope Tables."
        defaultValue={evaluation?.evaluationDate as string}
        onChange={(date) => handleSaveField('evaluationDate', date)}
        disabled={!canEdit && !canEditFinalized}
      />
      <TextInput
        label="Rating Revision"
        additionalText="Optional. Specify a revision number for the rating."
        defaultValue={
          evaluation?.ratingRevision
            ? String(evaluation?.ratingRevision?.ratingRevision)
            : ''
        }
        onBlur={(value) => handleSaveField('ratingRevision', Number(value))}
        disabled={!canEdit && !canEditFinalized}
        isInputWithButton={false}
        buttonTitle="Save"
      />

      <SelectInputComponent
        defaultValue={evaluation?.manualVersion as string}
        description="The years version of the manual that this rating was evaluated with."
        inputWidth="306px"
        label="Manual Version"
        onChange={(value) => handleSaveField('manualVersion', value)}
        options={[
          { value: '2020', label: '2020' },
          { value: '2024', label: '2024' },
        ]}
        disabled={!canEdit}
      />

      <TextArea
        label="Rating Description"
        defaultValue={evaluation?.description as string}
        onBlur={(value) => handleSaveField('description', value)}
        disabled={!canEdit && !canEditFinalized}
      />
      <TextArea
        label="Rating Comments"
        defaultValue={evaluation?.comment as string}
        onBlur={(value) => handleSaveField('comment', value)}
        disabled={!canEdit && !canEditFinalized}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span>Rating Tee Colors</span>
        <TeeColorPicker
          tees={evaluation?.holes.at(0)?.tees}
          onSave={onSaveTeeOptions}
        />
      </div>
    </>
  )
}

interface AdminControlsProps {
  user: ReturnType<typeof useUser>
  evaluation: Eval
  copyToClipboard: () => Promise<void>
}

const AdminControls: FC<AdminControlsProps> = ({
  user,
  evaluation,
  copyToClipboard,
}) => {
  if (!user.isAdmin) return null
  return (
    <div style={{ margin: 12, display: 'flex' }}>
      <div
        onClick={copyToClipboard}
        style={{ cursor: 'pointer', marginRight: 6 }}
      >
        <FeatherIcon name="clipboard" size={18} />
      </div>
      <span>{`Rating ID: ${evaluation?.id}`}</span>
    </div>
  )
}

interface TextInputProps {
  label: string
  defaultValue: string
  onBlur: (value: string) => void
  disabled: boolean
  additionalText?: string
  isInputWithButton?: boolean
  buttonTitle?: string
  isDefault?: boolean
  canReset?: boolean
  resetValue?: string
}

const TextInput: FC<TextInputProps> = ({
  label,
  defaultValue,
  onBlur,
  disabled,
  additionalText,
  isInputWithButton,
  buttonTitle,
  isDefault,
  canReset,
  resetValue,
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const inputWithButtonStyles = {
    fontSize: 16,
    alignSelf: 'flex-start',
    width: '100%',
  }

  const defaultInputStyles = {
    width: 300,
  }

  const inputStyles = isInputWithButton
    ? inputWithButtonStyles
    : defaultInputStyles

  return (
    <S.CourseOptionView>
      <div>
        <text>
          <span style={{ fontWeight: isDefault ? 600 : 400 }}>{label}</span>
          {additionalText && <span>{additionalText}</span>}
        </text>
        <div
          style={{
            display: 'flex',
            width: isInputWithButton ? 306 : undefined,
          }}
        >
          {canReset && (
            <OriginalValueComponent
              canEdit={true}
              originalValue={resetValue ?? ''}
              setValue={async (value) => onBlur(value)}
            />
          )}

          <input
            style={inputStyles}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => onBlur(value)}
            onFocus={(e) => e.target.select()}
            disabled={disabled}
          />
          {isInputWithButton && (
            <RoundedButton
              title={buttonTitle as string}
              onPress={() => onBlur(value)}
              style={{ marginLeft: 8 }}
            />
          )}
        </div>
      </div>
    </S.CourseOptionView>
  )
}

interface DateFieldProps {
  label: string
  additionalText: string
  defaultValue: string
  onChange: (date: Date) => void
  disabled: boolean
}

const DateField: FC<DateFieldProps> = ({
  label,
  additionalText,
  defaultValue,
  onChange,
  disabled,
}) => {
  const [date, setDate] = useState(new Date(defaultValue || new Date()))

  useEffect(() => {
    onChange(date)
  }, [date])

  return (
    <S.CourseOptionView inputWidth="300px">
      <div>
        <text>
          <span style={{ fontWeight: 400 }}>{label}</span>
          {additionalText && <span>{additionalText}</span>}
        </text>
        <div>
          <DateInput stateHook={[date, setDate]} disabled={disabled} />
        </div>
      </div>
    </S.CourseOptionView>
  )
}

interface TextAreaProps {
  label: string
  defaultValue: string
  onBlur: (value: string) => void
  disabled: boolean
}

const TextArea: FC<TextAreaProps> = ({
  label,
  defaultValue,
  onBlur,
  disabled,
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <S.CourseOptionView>
      <div>
        <span>{label}</span>
        <textarea
          style={{
            minWidth: 288,
            minHeight: 72,
            resize: 'none',
            padding: 8,
            lineHeight: '25px',
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={() => onBlur(value)}
          disabled={disabled}
        />
      </div>
    </S.CourseOptionView>
  )
}

interface Option {
  value: string
  label: string
}

interface SelectInputProps {
  label: string
  description?: string
  defaultValue: string
  disabled: boolean
  options: Option[]
  onChange: (value: string) => void
  inputWidth?: string
}

const SelectInputComponent: FC<SelectInputProps> = ({
  label,
  description,
  defaultValue,
  disabled,
  options,
  onChange,
  inputWidth = '306px',
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue)

  useEffect(() => {
    setSelectedValue(defaultValue)
  }, [defaultValue])

  return (
    <S.CourseOptionView inputWidth={inputWidth}>
      <div>
        <text>
          <span>{label}</span>
          {description && <span>{description}</span>}
        </text>
        <div style={{ display: 'flex' }}>
          <select
            disabled={disabled}
            value={selectedValue}
            onChange={(e) => {
              setSelectedValue(e.target.value)
              onChange(e.target.value)
            }}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </S.CourseOptionView>
  )
}

export default CourseInfo

type OriginalValueComponentProps = {
  canEdit?: boolean
  setValue: (value: string) => Promise<void>
  originalValue: string
}

function OriginalValueComponent({
  canEdit,
  setValue,
  originalValue,
}: OriginalValueComponentProps) {
  const [resetting, setResetting] = useState(false)

  if (!canEdit) {
    return null
  }

  const onClick = async () => {
    setResetting(true)
    setTimeout(() => setResetting(false), 2000)
    await setValue(originalValue)
  }

  const valueWithResetIcon = (
    <>
      <div style={{ width: 'auto', height: '50%' }}>
        <span
          style={{
            fontSize: '14px',
          }}
        >{`(${originalValue})`}</span>
      </div>

      <div style={{ width: 'auto', height: '50%' }}>
        <FeatherIcon name="rotate-ccw" size={12} />
      </div>
    </>
  )

  const spinner = (
    <LoadingContainer>
      <FeatherIcon name="loader" size={16} />
    </LoadingContainer>
  )
  return (
    <>
      <ResetAdjustmentButton
        onClick={!resetting ? onClick : () => console.log('Invalid action')}
        style={{ width: 'auto', marginRight: 8 }}
      >
        {resetting ? (
          spinner
        ) : (
          <ResetAdjustmentButtonContent>
            {valueWithResetIcon}
          </ResetAdjustmentButtonContent>
        )}
      </ResetAdjustmentButton>
    </>
  )
}
