import { CourseOptionView } from '@app/evaluation-settings-menu/HeaderArea.styles'
import images from '@app/images'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import { HSpacing } from '@app/ui/rounded-button/RoundedButton.styles'
import theme from '@app/ui/theme'
import AntDesignIcon from '@ovaeasy/react-native-vector-icons/AntDesign'
import * as React from 'react'
import { useWindowDimensions } from 'react-native'
import { EVALUATION_STATUS } from '../domain/ratingStatus'
import { SWEDEN_REGIONS } from '../hooks'
import { DistrictFilter } from './DistrictFilter'
import {
  CardIconsContainer,
  FilterCard,
  FilterCardIcon,
  FilterCardIconSearch,
  HeaderContainer,
} from './Header.styles'
import { RegionFilter } from './RegionFilter'

interface HeaderProps {
  onChangeSearchValue: (value: string) => void
  searchValue: string
  filterStatusValue: number
  onChangeFilterValue: (value: string) => void
  regionFilterStatusValue: string
  onChangeRegionFilterValue: (value: string) => void
  districtFilterStatusValue: string
  onChangeDistrictFilterValue: (value: string) => void
  tablet?: boolean
}

const LOGO_IMG_HEIGHT = 44
const SEARCH_ICON_SIZE = 24
const SEARCH_PLACEHOLDER = 'Search'
const FILTER_DROP_WIDTH = 260
const FILTER_DROP_LABEL = 'Filter'
const FILTER_STATUS_LABEL = 'Status'

const Header = ({
  onChangeSearchValue,
  searchValue,
  filterStatusValue,
  onChangeFilterValue,
  regionFilterStatusValue,
  onChangeRegionFilterValue,
  districtFilterStatusValue,
  onChangeDistrictFilterValue,
  tablet,
}: HeaderProps) => {
  const shouldHideLogo = useWindowDimensions().width < 1100
  const lessMargin = useWindowDimensions().width < 1230

  return (
    <HeaderContainer center={shouldHideLogo}>
      {!shouldHideLogo && (
        <img
          src={images.svg.LiveRateLogo}
          style={{ height: LOGO_IMG_HEIGHT }}
        />
      )}
      <FilterCard
        lessMargin={lessMargin}
        style={shouldHideLogo ? { marginLeft: 8, flex: 0.75 } : {}}
      >
        <input
          style={{ width: '100%' }}
          value={searchValue}
          placeholder={SEARCH_PLACEHOLDER}
          onChange={(event) => onChangeSearchValue(event.target.value)}
          className="courses-search-input"
        />
        <CardIconsContainer>
          <FilterCardIcon>
            <img
              src={images.svg.VerticalLine}
              style={{ height: LOGO_IMG_HEIGHT - 2, marginTop: 5 }}
            />
          </FilterCardIcon>
          <FilterCardIconSearch>
            <AntDesignIcon
              name="search1"
              size={SEARCH_ICON_SIZE}
              color={theme.colors.primary}
            />
          </FilterCardIconSearch>
        </CardIconsContainer>
      </FilterCard>
      <HSpacing size={10} />
      <div style={{ width: 100 }}>
        <DropDownMenu
          tablet={tablet}
          iconColor={theme.colors.white}
          value={FILTER_DROP_LABEL}
          style={{ fontWeight: 'bold' }}
          width={FILTER_DROP_WIDTH}
          backgroundColor={theme.colors.primary}
          color={'white'}
          yAdj={100}
        >
          <CourseOptionView>
            <div>
              <span>{FILTER_STATUS_LABEL}</span>

              <select
                style={{ width: '180px' }}
                onChange={(event) => onChangeFilterValue(event.target.value)}
              >
                <option value={-1} selected={filterStatusValue === -1}>
                  All
                </option>
                {EVALUATION_STATUS.map((item, i) => (
                  <option value={i} key={i} selected={filterStatusValue === i}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </CourseOptionView>
        </DropDownMenu>
      </div>
      <HSpacing size={10} />
      <div style={{ width: 100 }}>
        <RegionFilter
          onChangeFilterValue={onChangeRegionFilterValue}
          filterStatusValue={regionFilterStatusValue}
        />
      </div>
      <HSpacing size={10} />
      <div style={{ width: 100 }}>
        <DistrictFilter
          onChangeFilterValue={onChangeDistrictFilterValue}
          filterStatusValue={districtFilterStatusValue}
          region={SWEDEN_REGIONS.find(
            (region) => region.name === regionFilterStatusValue
          )}
        />
      </div>
    </HeaderContainer>
  )
}

export { Header }
