import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as S from './styled'

import HoleSelectorThree from '@app/components/molecules/HoleSelectorThree'
import { useMediaQuery } from 'react-responsive'

import { useApi } from '@common/hooks/useApi'
import {
  MiProjectBranch,
  ProjectCourse,
  ProjectHole,
} from '../../types/Project'
import { useRoute, RouteProp } from '@react-navigation/native'
import { ClubManagementStackParamList } from '@app/navigation/CoursesStack'
import { ProjectHoleWithCourseInfo } from '../CreateRatingTemplatePage/tabs/SelectHoles/SelectHoles'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import update from 'immutability-helper'

import { startEvaluation } from '@app/evaluation-core/evaluation'
import { useDrag, useDrop } from 'react-dnd'
import type { Identifier, XYCoord } from 'dnd-core'

import styled, { css } from 'styled-components'
import Spacer from '@app/components/atoms/Spacer'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'

import TwoComponentPage from '@app/components/molecules/TwoComponentPage'
import usePortraitMode from '@common/hooks/usePortraitMode'
import { usePreview } from 'react-dnd-preview'
import ScreenContainer from '@app/components/atoms/ScreenContainer'
import { navigate } from '@app/navigation'

type OwnProps = {}
type Props = OwnProps

type HoleProps = {
  holeId: string
  holeIndex: number
  par: number
  originHoleIndex: number
  courseName: string
  totalHoles: number
  onPressDelete?: (holeId: string) => void
  moveCard?: (dragIndex: number, hoverIndex: number) => void
  cardRef?: React.MutableRefObject<Element | null>
}

type Hole = {
  holeIndex: number
  courseName: string
  originHoleIndex: number
  holeId: string
}

const HolePreview: React.FC = () => {
  const preview =
    usePreview<
      Pick<
        HoleProps,
        | 'courseName'
        | 'holeId'
        | 'holeIndex'
        | 'originHoleIndex'
        | 'totalHoles'
        | 'par'
      >
    >()
  if (!preview.display) {
    return null
  }
  const { courseName, holeId, holeIndex, originHoleIndex, totalHoles, par } =
    preview.item

  return (
    <div style={{ ...preview.style }}>
      <Hole
        par={par}
        cardRef={preview.ref}
        holeId={holeId}
        holeIndex={holeIndex}
        originHoleIndex={originHoleIndex}
        courseName={courseName}
        totalHoles={totalHoles}
      />
    </div>
  )
}

const Hole = ({
  holeId,
  holeIndex,
  par,
  courseName,
  originHoleIndex,
  totalHoles,
  onPressDelete,
  moveCard,
  cardRef,
}: HoleProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [cardSelected, setCardIsSelected] = useState(false)
  const [{ handlerId, isOver, item }, drop] = useDrop<
    Hole,
    void,
    {
      handlerId: Identifier | null
      isOver: boolean
      item: Pick<
        HoleProps,
        'courseName' | 'holeId' | 'holeIndex' | 'originHoleIndex' | 'totalHoles'
      >
    }
  >(
    {
      accept: 'CARD',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
          isOver: !!monitor.isOver(),
          item: monitor.getItem(),
        }
      },
      drop: (item) => {
        if (typeof moveCard === 'function') {
          moveCard(holeIndex, item.holeIndex)
        }
      },
      hover(item: Hole, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.holeIndex
        const hoverIndex = holeIndex
        if (dragIndex === hoverIndex) {
          return
        }
        const hoverBoundingRect = ref.current?.getBoundingClientRect()
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
        const clientOffset = monitor.getClientOffset() as XYCoord
        const hoverClientY = clientOffset.y - hoverBoundingRect.top
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }
      },
    },
    [holeIndex]
  )

  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: () => {
      return { holeId, holeIndex, courseName, originHoleIndex, totalHoles }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging() || cardSelected,
    }),
  })

  drag(drop(ref))

  const removeHole = () => {
    if (typeof onPressDelete === 'function') {
      return onPressDelete(holeId)
    }
  }

  let timerRef: ReturnType<typeof setTimeout> | null = null

  const handleTouchStart = () => {
    console.log('touchstart')
    timerRef = setTimeout(() => {
      setCardIsSelected(true)
    }, 500)
  }

  const handleTouchEnd = () => {
    if (timerRef) {
      clearTimeout(timerRef)
    }
    setCardIsSelected(false)
  }

  return (
    <S.HoleCard
      ref={ref}
      index={holeIndex}
      totalHoles={totalHoles}
      data-handler-id={handlerId}
      isDragging={isDragging}
      isOver={isOver}
      onMouseDown={handleTouchStart}
      onMouseUp={handleTouchEnd}
    >
      <S.HoleTitleContainer>
        <S.HoleTitle>Hole {holeIndex + 1}</S.HoleTitle>
      </S.HoleTitleContainer>
      <Spacer height={14} />
      <S.Divider />
      <Spacer height={14} />
      <S.HoleInformationContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <S.CourseName>{courseName}</S.CourseName>
          <S.GlobalHoleNumber>Hole {originHoleIndex + 1}</S.GlobalHoleNumber>
          <S.Par>Par {par}</S.Par>
        </div>
        <S.TrashIconContainer onClick={removeHole}>
          <FeatherIcon name="trash" size={20} color="white" />
        </S.TrashIconContainer>
      </S.HoleInformationContainer>
    </S.HoleCard>
  )
}

const StartEvaluationPage: FunctionComponent<Props> = () => {
  const route =
    useRoute<RouteProp<ClubManagementStackParamList, 'StartEvaluationPage'>>()
  const { data, isLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${route?.params?.clubId}`
  )
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1524px)' })
  const portraitMode = usePortraitMode()
  const ref = useRef<HTMLDivElement>(null)

  const [selectedCourse, setSelectedCourse] = useState<ProjectCourse>()
  const [ratingConfiguration, setRatingConfiguration] = useState<
    ProjectHoleWithCourseInfo[]
  >([])

  useEffect(() => {
    if (!selectedCourse) {
      setSelectedCourse(data?.courses[0])
    }
  }, [data])

  const amountOfColumns = useMemo(
    () => Math.ceil((Number(selectedCourse) || 18) / 9),
    [selectedCourse]
  )

  const removeHole = (holeId: string) => {
    const filteredRatingHoles = ratingConfiguration.filter(
      (ratingHole) => ratingHole.physicalID !== holeId
    )
    setRatingConfiguration(filteredRatingHoles)
  }

  const handleHolePress = (
    hole: ProjectHole,
    courseName: string,
    courseId: string,
    holeIsSelected: boolean
  ) => {
    if (holeIsSelected) {
      return removeHole(hole.physicalID)
    }
    setRatingConfiguration([
      ...ratingConfiguration,
      { ...hole, courseName, courseId },
    ])
  }

  const rateCoursePress = () => {
    if (!selectedCourse) return
    const selectedCourseHolesWithCourseName = selectedCourse.holes.map(
      (course) => ({ ...course, courseName: selectedCourse?.name })
    )
    const includesSomeFromCourse = ratingConfiguration.some((hole) =>
      selectedCourseHolesWithCourseName.some(
        (courseHole) => courseHole.physicalID === hole.physicalID
      )
    )
    if (includesSomeFromCourse) {
      const notIncludedHoles = selectedCourseHolesWithCourseName.filter(
        (hole) =>
          !ratingConfiguration.some(
            (courseHole) => courseHole.physicalID === hole.physicalID
          )
      )
      return setRatingConfiguration([
        ...ratingConfiguration,
        ...notIncludedHoles,
      ])
    }
    setRatingConfiguration([
      ...ratingConfiguration,
      ...selectedCourseHolesWithCourseName,
    ])
  }

  const handleCourseChange = (course: ProjectCourse) =>
    setSelectedCourse(course)

  const convertConfigurationToHoleIds = (config: ProjectHoleWithCourseInfo[]) =>
    config.map((hole) => hole.physicalID)

  const emptyText =
    !ratingConfiguration.length && isLoading
      ? 'Loading...'
      : 'Add the holes you wish to rate.'

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setRatingConfiguration((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    )
  }, [])

  return (
    <>
      <HolePreview />
      <TwoComponentPage
        navigateBack={() => navigate('ClubManagment', { return: true })}
        RightComponent={
          <S.HoleSelectorContainer>
            <HoleSelectorThree
              courses={data?.courses || []}
              selectedCourse={selectedCourse}
              rateCoursePress={rateCoursePress}
              handleDropdownChange={handleCourseChange}
              handleHolePress={handleHolePress}
              selectedHoles={ratingConfiguration}
            />
            <RoundedButton
              title="Start New Evaluation"
              onPress={() =>
                startEvaluation(
                  +route?.params?.clubId,
                  data?.projectBranchID as string,
                  convertConfigurationToHoleIds(ratingConfiguration)
                )
              }
            />
          </S.HoleSelectorContainer>
        }
        LeftComponent={
          <S.HoleContainer>
            {!ratingConfiguration.length && <div>{emptyText}</div>}
            {ratingConfiguration.map((hole, index, array) => (
              <Hole
                key={index}
                holeId={hole.physicalID}
                totalHoles={array.length}
                par={+hole.par}
                holeIndex={index}
                courseName={hole?.courseName || ''}
                originHoleIndex={+hole.courseHoleNumber - 1}
                onPressDelete={removeHole}
                moveCard={moveCard}
                cardRef={ref}
              />
            ))}
          </S.HoleContainer>
        }
      />
    </>
  )
}

export default StartEvaluationPage
