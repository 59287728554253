import styled from 'styled-components'
import theme from '@app/ui/theme'

export const SettingValueText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
`

export const CloseButton = styled.div`
  height: 42px;
  width: 42px;
  background-color: ${theme.colors.warning};
  border-radius: 20px;
  margin: 0px 10px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  color: white;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 500;
`
