import { CourseOptionView } from '@app/evaluation-settings-menu/HeaderArea.styles'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import theme from '@app/ui/theme'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useClubData } from '../hooks'

const FILTER_DROP_WIDTH = 260

interface HeaderProps {
  filterStatusValue: string
  onChangeFilterValue: (value: string) => void
}

const FILTER_STATUS_LABEL = 'Region'

const RegionFilter = ({
  filterStatusValue,
  onChangeFilterValue,
}: HeaderProps) => {
  const { clubs } = useClubData()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1524px)' })

  const regions = {
    ['Sweden']: ['Region A', 'Region B', 'Region C', 'Region D'],
  }
  return (
    <DropDownMenu
      tablet={isTabletOrMobile}
      iconColor={theme.colors.white}
      value={filterStatusValue || FILTER_STATUS_LABEL}
      width={FILTER_DROP_WIDTH}
      style={{ fontWeight: 'bold' }}
      backgroundColor={theme.colors.primary}
      color={'white'}
      yAdj={100}
    >
      <CourseOptionView>
        <div>
          <span>{FILTER_STATUS_LABEL}</span>
          <select
            style={{ width: '120px' }}
            onChange={(event) => onChangeFilterValue(event.target.value)}
          >
            <option value={''} selected={filterStatusValue === ''}>
              All
            </option>
            {regions['Sweden'].map((item, i) => (
              <option
                value={item}
                key={i}
                selected={filterStatusValue === item}
              >
                {item}
              </option>
            ))}
          </select>
        </div>
      </CourseOptionView>
    </DropDownMenu>
  )
}

export { RegionFilter }
