import {
  LiveCRNotificationStatus,
  NotificationEvent,
} from '@app/notifications/types'
import { useEffect, useMemo, useState } from 'react'

import { FBREF_EVALUATION_NOTIFICATIONS } from '@app/evaluation-core/refs'
import { State } from '@app/state/types'
import useEvaluation from './useEvaluation'
import useEvaluationUser from './useEvaluationUser'
import { useFirebaseConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { EvalStatus } from '@app/courses/domain/ratingStatus'

import useEvaluationLockedState from './useEvaluationLockedState'
import { LockStatus } from '@app/evaluation-core/types'

type HoleObject = Record<string, NotificationEvent>

type EvaluationNotificationObj = Record<string, HoleObject>

export type NotificationActionStatus = null | 'accepted' | 'rejected'

const cleanObject = (obj: { [key: string]: any }): EvaluationNotificationObj =>
  Object.fromEntries(Object.entries(obj))

const convertToHoleArray = (notifications: HoleObject) => {
  const holeArray: NotificationEvent[] = []
  if (!notifications) {
    return []
  }

  if (Object.keys(notifications)?.length > 0) {
    Object.entries(notifications).map(([key, value]) =>
      holeArray.push({ ...value, key })
    )
  }
  return holeArray
}

const shouldResetStatus = (holeNotifications: HoleObject) =>
  convertToHoleArray(holeNotifications).every(
    (item) => item.status !== LiveCRNotificationStatus.UNHANDLED
  )

export default (): [boolean, NotificationEvent[], HoleObject | never[]] => {
  const { currentId, evaluation } = useEvaluation()
  const { view, isAdmin } = useEvaluationUser()

  const [shouldShowModal, setShouldShowModal] = useState(false)

  const lockStatus = useEvaluationLockedState()
  if (lockStatus === LockStatus.READ_ONLY) {
    return [false, [], []]
  }

  useFirebaseConnect({
    path: FBREF_EVALUATION_NOTIFICATIONS(currentId),
    storeAs: 'notifications',
    queryParams: ['orderByChild=status', '0'],
  })

  const notifications = useSelector(
    ({ firebase: { data } }: State) => {
      if (!data.notifications) {
        return {}
      }
      const notifications = cleanObject(data.notifications)
      if (notifications && Object.values(notifications)?.length === 0) {
        return {}
      }
      return notifications
    },
    (prev, next) => {
      if (next[view?.hole]) {
        return shouldResetStatus(next[view?.hole])
      }
      return false
    }
  )

  const memoNotifications: NotificationEvent[] = useMemo(() => {
    if (!isAdmin) {
      return []
    }
    if (notifications && Object.values(notifications)?.length) {
      const unhandledNotifications: EvaluationNotificationObj = Object.entries(
        notifications
      ).reduce((acc, [holeNumber, holeObject]) => {
        const filteredObject = Object.entries(holeObject).filter(
          ([key, value]) => value.status === LiveCRNotificationStatus.UNHANDLED
        )

        acc[holeNumber] = Object.fromEntries(filteredObject)
        return acc
      }, {})
      if (!unhandledNotifications[view?.hole]) {
        return []
      }
      const holeNotifications = unhandledNotifications[view?.hole]
      const holeArray = convertToHoleArray(holeNotifications)
      return holeArray
    }
    return []
  }, [notifications, notifications[view?.hole], view?.hole])

  useEffect(() => {
    const shouldShowModal =
      memoNotifications?.length > 0 &&
      memoNotifications.some((item) => item.status === 0) &&
      isAdmin &&
      evaluation.status !== EvalStatus.FINALIZED

    setShouldShowModal(shouldShowModal)
  }, [view?.hole, memoNotifications, isAdmin])

  const holeObject = useMemo(() => {
    return memoNotifications?.length === 0 ? [] : notifications[view?.hole]
  }, [memoNotifications, view?.hole, notifications[view?.hole]])

  return [shouldShowModal, memoNotifications, holeObject]
}
