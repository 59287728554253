import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'
import { EvaluationUserView, Eval } from '@app/evaluation-core/types'
import { useDispatch } from 'react-redux'
import useEvaluation from './useEvaluation'
import { useEffect } from 'react'

function shotDoNotExist(evaluation: Eval, view: EvaluationUserView) {
  return !evaluation.holes[view?.hole].tees[view.tee].shots[view.golfer]?.find(
    (shotData) => shotData.shotIndex === view.shot
  )
}

export default (view: EvaluationUserView) => {
  const { evaluation } = useEvaluation()
  const dispatch = useDispatch<any>()
  useEffect(() => {
    if (view) {
      if (shotDoNotExist(evaluation, view)) {
        // Set the selected shot to the first one
        dispatch(
          setSelectedHole(view?.hole, view.tee, view.golfer, view.adjustment, 0)
        )
      }
    }
  }, [evaluation.holes[view?.hole]])
}
