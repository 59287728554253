// Theme Colors
export const color1 = '#25BBBF'
export const color2 = '#E8D6D6'
export const color3 = '#A49B9B'
export const color4 = '#7FCFCC'
export const color5 = '#B36B68'
export const color6 = '#395F60'
export const color7 = '#FFFFFF'
export const color8 = '#333333'
export const color9 = '#CCCCCC'
export const color10 = '#666666'
export const color11 = '#000000'
export const color12 = '#999999'
export const color13 = '#cecece'

//export const primary = "#151A1F";
//export const primary = "#605050";
//export const primary = "#F13333";
export const primary2 = '#151A20'
export const primary3 = '#AB4343'
export const secondary = '#707B8F'
//export const secondary2 = "#2F394B";
export const light = '#D1DCE2'
export const light2 = '#EAEEF4'
export const light3 = '#F9F9F9'
export const light4 = '#CECECE'
export const white = '#fff'
export const black = '#000'
export const dark = '#282828'
export const dark1 = 'rgba(0, 0, 0, 0.4)'
export const dark2 = 'rgba(0, 0, 0, 0.16)'
export const buttonText = '#4A4A4A'

//export const primary = "#605050";
//export const secondary2 = "#CB5A00";

export const reject = '#fff'
export const accept = '#228558'

export const prevField = '#FFE6CE'
export const newField = '#C2EBF4'

export const primary = '#282828'
export const secondary2 = '#4190e0'
export const secondaryLighter = '#5DA1E5'
export const background = '#e6e6e6'

export const updated = '#FFE0A7'
export const saved = '#C7FFDD'
export const yellow = '#FFE684'
export const warning = '#EE0000'
export const disabled = '#cecece'

//export const primary = "#384644";
//export const secondary2 = "#009F70";

//export const primary = "#14645A";
//export const secondary2 = "#009F70";

//export const primary = "#38433F";
//export const secondary2 = "#009B5D";
