import React from 'react'
import { Eval } from '@app/evaluation-core/types'
import { CourseOptionView } from '@app/evaluation-settings-menu/HeaderArea.styles'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import * as S from './styled'
import Spacer from '../../atoms/Spacer'
import { View, Text } from 'react-native'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import { useSnackbar } from '@app/snackbar'
import EvalStatusIndicator from '../CoursesList/EvalStatusIndicator'

interface InfoDropdownProps {
  evaluation: Eval
  xAdj?: number
  yAdj?: number
  relative?: boolean
}

const convertStatusToText = (status?: number) => {
  switch (true) {
    case status === EvalStatus.NOT_STARTED:
      return 'Not Started'
    case status === EvalStatus.PREPARING:
      return 'Preparing'
    case status === EvalStatus.IN_PROGRESS:
      return 'In Progress'
    case status === EvalStatus.FINALIZED:
      return 'Finalized'
    default:
      return 'Unknown'
  }
}

const InfoDropdown = ({
  evaluation,
  xAdj,
  yAdj,
  relative,
}: InfoDropdownProps) => {
  const [openSnackbar] = useSnackbar()

  const copyToClipboard = async () => {
    if (evaluation.id) {
      await navigator.clipboard.writeText(evaluation.id)
      openSnackbar('Rating ID copied to clipboard.')
    }
  }

  return (
    <DropDownMenu
      width={300}
      value={evaluation.courseName || 'Course'}
      xAdj={xAdj}
      yAdj={yAdj}
      relative={relative}
    >
      <CourseOptionView>
        <S.OuterContainer>
          <S.InfoContainer>
            <S.InfoTextContainer>
              <EvalStatusIndicator
                status={evaluation?.status}
                //finalizedAt={evaluation?.finalizedDate}
              />
            </S.InfoTextContainer>
          </S.InfoContainer>
          <Spacer height={8} />
          <S.InfoContainer>
            <S.InfoTextContainer>
              <span>Club: </span>
              <S.ValueText>{evaluation.clubName}</S.ValueText>
            </S.InfoTextContainer>
          </S.InfoContainer>
          <S.InfoContainer>
            <S.InfoTextContainer>
              <span>Course: </span>
              <S.ValueText>{evaluation.courseName}</S.ValueText>
            </S.InfoTextContainer>
          </S.InfoContainer>
          <S.InfoContainer>
            <S.InfoTextContainer>
              <span>Rating ID: </span>
              <div
                onClick={copyToClipboard}
                style={{
                  cursor: 'pointer',
                  marginRight: 6,
                  display: 'flex',
                  gap: '2px',
                  alignItems: 'center',
                }}
              >
                <FeatherIcon name="clipboard" size={16} />
                <S.ValueText>{evaluation.id}</S.ValueText>
              </div>
            </S.InfoTextContainer>
          </S.InfoContainer>
          <S.InfoContainer>
            <S.InfoTextContainer>
              <span>Rating Name</span>
              <S.ValueText>{evaluation?.saveName || 'N/A'}</S.ValueText>
            </S.InfoTextContainer>
          </S.InfoContainer>
          {evaluation.evaluationDate && (
            <S.InfoContainer>
              <S.InfoTextContainer>
                <span>Rating Date:</span>
                <S.ValueText>
                  {new Date(evaluation.evaluationDate).toLocaleDateString(
                    'sv-SE'
                  )}
                </S.ValueText>
              </S.InfoTextContainer>
            </S.InfoContainer>
          )}
          {evaluation.branchId && (
            <S.InfoContainer>
              <S.InfoTextContainer>
                <span>BranchId: </span>
                <S.ValueText>{evaluation.branchId}</S.ValueText>
              </S.InfoTextContainer>
            </S.InfoContainer>
          )}
          <S.InfoContainer>
            <S.InfoTextContainer>
              <span>Manual Version: </span>
              <S.ValueText>{evaluation.manualVersion || 'N/A'}</S.ValueText>
            </S.InfoTextContainer>
          </S.InfoContainer>
        </S.OuterContainer>
      </CourseOptionView>
    </DropDownMenu>
  )
}

export default InfoDropdown
