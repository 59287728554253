import React, { useEffect } from 'react'

import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import { getAdjustmentValue } from '@app/evaluation-core'
import { getPlayerShots } from '@common/hooks/getPlayerShots'
import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'
import useAdjustment from '@common/hooks/useAdjustment'
import { useDispatch } from 'react-redux'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

export enum Topography_Problem {
  MINOR = 0,
  MINOR_MODERATE = 1,
  MODERATELY_AWKWARD = 2,
  SIGNIFICANTLY_AWKWARD = 3,
  EXTREMELY_AWKWARD = 4,
}

const TOPOGRAPHY_FACTORS: AdmFactorGroup[] = [
  {
    factors: [],
  },
]

export const Topography = () => {
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const shots = getPlayerShots()
  const adjustments = useAdjustment()
  const dispatch = useDispatch<any>()

  useEffect(() => {
    if (shots >= 3 && view.shot === 0) {
      dispatch(
        setSelectedHole(view?.hole, view.tee, view.golfer, view.adjustment, 1)
      )
    } else if (shots === 1 && view.shot === 0) {
      dispatch(
        setSelectedHole(view?.hole, view.tee, view.golfer, view.adjustment, 0)
      )
    }
  }, [view?.hole, shots])

  if (!evaluation) return null

  const par =
    adjustments && getAdjustmentValue('hole', view, 'Par', adjustments)

  return (
    <Adjustment
      useLz={false}
      factors={TOPOGRAPHY_FACTORS}
      parameters={[
        {
          type: 'numeric-input',
          label: 'Change in Elevation from Approach Shot Landing Zone to Green',
          adjustmentKey: 'greenElevation',
          format: 'shot',
          unit: 'feet',
          shouldRender: (adjustment, view) => {
            const par3 = par === 3
            if (par3 && shots === 1) {
              return false
            }
            if (shots > 2 && view.shot === shots - 1) return true
            if (shots <= 2) return true

            return false
          },
        },
        {
          type: 'numeric-input',
          label: 'Change in Elevation from the Tee to Green',
          adjustmentKey: 'measuredElevation',
          format: 'tee',
          unit: 'feet',
          shouldRender: (adjustment, view) => {
            const oneShotHole = par === 3

            if (oneShotHole && shots === 1) {
              return true
            }

            return false
          },
        },
        {
          type: 'select',
          label: 'Stance or Lie in Landing Zone',
          inputDisabled: true,
          adjustmentKey: 'topo_stance',
          format: 'shot',
          selectorValues: [
            Topography_Problem.MINOR,
            Topography_Problem.MINOR_MODERATE,
            Topography_Problem.MODERATELY_AWKWARD,
            Topography_Problem.SIGNIFICANTLY_AWKWARD,
            Topography_Problem.EXTREMELY_AWKWARD,
          ],
          selectorLabels: [
            'Minor Problem',
            'Minor to Moderate',
            'Moderately Awkward',
            'Significantly Awkward',
            'Extremely Awkward',
          ],
          notExpandible: true,
          shouldRender: (adjustment, view) => {
            const par3 = par === 3
            if (par3 && view.golfer.includes('B')) {
              return true
            }
            if (par3 && shots > 1 && view.shot === shots - 1) {
              return true
            }
            if (shots === 2) {
              return true
            }
            if (shots >= 3) {
              return true
            }
            return false
          },
        },
        {
          type: 'table-value',
          label: 'Topography Rating',
          inputDisabled: true,
          adjustmentKey: 'topo_rating',
          format: 'shot',
        },
        {
          type: 'label',
          label: 'RATED AS A TWO-SHOT HOLE',
          shouldRender: (adjustment, view) => {
            const oneShotHole = par === 3
            if (oneShotHole && shots === 2) {
              return true
            }
            return false
          },
        },
      ]}
    />
  )
}
