import * as React from 'react'
import styled from 'styled-components'

interface FlexProps {
  row?: boolean
  col?: boolean
  center?: boolean
  flex?: number
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${(props: FlexProps) => `
    ${props.row && `flex-direction: row;`}
    ${props.col && `flex-direction: column;`}
    ${
      props.center &&
      `
        align-items: center;
        justify-content: center;
    `
    }
    ${props.flex && `flex: ${props.flex};`}
  `};
`
