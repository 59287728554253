import React from 'react'
import ReactPlayer, { SourceProps } from 'react-player'

import { VideoContainer, VideoCover } from './index.styles'

interface BackgroundVideoProps {
  source: string | string[] | SourceProps[] | MediaStream
  coverColor?: string
}

const BackgroundVideo = ({ source, coverColor }: BackgroundVideoProps) => (
  <VideoContainer>
    <ReactPlayer
      style={{ zIndex: -1 }}
      url={source}
      playing
      loop
      width="100%"
      height="100%"
    />
    <VideoCover color={coverColor} />
  </VideoContainer>
)

export default BackgroundVideo
