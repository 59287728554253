import React from 'react'
import { View } from 'react-native'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AdmFactorGroupContainer } from '../AdjustmentFactors.styles'

const AdmFactorGroupSkeleton = () => {
  const renderFactorSkeleton = () => (
    <View style={{ height: '50%', flexDirection: 'row', width: '100%' }}>
      <View
        style={{
          width: '25%',
          justifyContent: 'space-around',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Skeleton width="70px" height="40px" style={{ borderRadius: 7 }} />
        <Skeleton width="40px" height="40px" style={{ borderRadius: 3 }} />
      </View>
      <View style={{ width: '85%', flexDirection: 'column' }}>
        <Skeleton
          width="80%"
          height="17px"
          style={{ padding: 15, margin: 15 }}
        />
        <Skeleton
          width="80%"
          height="17px"
          style={{ padding: 15, margin: 15 }}
        />
        <Skeleton
          width="80%"
          height="25px"
          style={{ padding: 15, margin: 15 }}
        />
      </View>
    </View>
  )

  return (
    <AdmFactorGroupContainer>
      {Array.from({ length: 2 }, (_, index) => (
        <View
          key={index}
          style={{
            margin: 15,
          }}
        >
          {renderFactorSkeleton()}
        </View>
      ))}
      <View style={{ width: '85%', flexDirection: 'column' }}></View>
    </AdmFactorGroupContainer>
  )
}

export default AdmFactorGroupSkeleton
