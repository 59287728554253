export enum UserGender {
  'Female' = 0,
  'Male' = 1,
}

export enum AccountType {
  AppUser = '1',
  PinStaff = '4',
  ClubStaff = '5',
  ClubAdmin = '6',
  SGFStaff = '7',
  SGFTeamLeader = '8',
  Interal = '10',
}

export type User = {
  id: number
  forename: string
  email: string
  lastname: string
  handicap: number
  golfID: string
  sessionID?: string
  gender?: UserGender
  clubname: string
  accountType: AccountType
  activated?: string
  homeClubID: string
  country: string
  localization: string
  privacySetting: string
  distanceUnit: string
  distanceTo: string
  profilePictureFileID: string
  profilePictureURL: string
}

export type UserState = User & {
  isTeamLeader?: boolean
  isAdmin?: boolean
  token?: string
  refreshToken?: string
}

export type UserAction = {
  type: string
  value: UserState
}
