import { media } from '@app/ui'
import theme from '@app/ui/theme'
import styled from 'styled-components'

export const AdjustmentCard = styled.div`
  margin: 20px;
  padding: 10px;
  transition: 0.3s;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.light};
  margin-bottom: 60px;
  ${media.tablet} {
    width: 100%;
    max-width: 100%;
  }
`

export const AdjustmentSubheader = styled.div`
  color: #044c7d;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  text-align: center;
  font-weight: 600;
`

export const RatingTable = styled.div`
  width: 100%;
`

export const RatingTableTop = styled.div`
  color: ${theme.colors.white};
  font-size: 15px;
  font-weight: 400;
  background-color: #191919;
  text-align: center;
  line-height: 40px;
`

type StepperProps = {
  small?: boolean
}

export const RatingTableStepper = styled.div<StepperProps>`
  height: ${({ small }) => (small ? 50 : 60)}px;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  width: 100%;
  border-left: 1px solid ${theme.colors.light};
  border-top: 1px solid ${theme.colors.light};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  & > div {
    flex: 1;
    border-right: 1px solid ${theme.colors.light};
    text-align: center;
    text-align: center;
    line-height: 30px;
    font-size: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &:last-child > div {
    border-bottom: 1px solid ${theme.colors.light};
  }
`

export const RatingTableStepperMain = styled(RatingTableStepper)`
  height: 40px;
  font-weight: 460;
`

export const RatingTableStepperTop = styled(RatingTableStepper)``
export const RatingTableStepperLong = styled(RatingTableStepper)`
  height: 70px;
`
export const RatingTableStepperTopFairWay = styled(RatingTableStepper)`
  & > div:nth-child(2) {
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    height: 40px;
    flex: 3;
  }
  height: 80px;
`

export const FairWayHeader = styled.div`
  display: grid;
  text-align: center;
  grid-template-areas:
    'Men FW FW FW Women'
    'Men FM FM FM Women';
`

export const RowContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  height: 40px;

  flex-direction: row;
  & > div {
    height: 100%;
    display: flex;
    text-align: center;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
  }
  & > div:last-child {
    border-right: 0;
  }
`

export const FairwayMeasurementBlock = styled.div`
  &:not(:first-child):not(:last-child) {
    border-left: 0px solid;
  }
  &:last-child {
    border-left: 0px solid;
  }
  border: 1px solid black;
  width: 100%;
  text-align: center;
`

export const RatingTableStepperCenter = styled.div`
  flex: 0 0 calc(14.28%) !important;

  background-color: ${theme.colors.white};
`

export const RatingTableStepperCenterBox = styled(RatingTableStepperCenter)`
  background-color: ${(props: RatingTableSelectItemProps) =>
    props.selected ? theme.colors.primary2 : theme.colors.white};
  color: ${(props: RatingTableSelectItemProps) =>
    props.selected ? theme.colors.white : theme.colors.primary2};
  font-weight: 460;
  cursor: pointer;
`

export const RatingTableResult = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  color: #575b66;

  & > div:first-child {
    font-weight: 460;
  }

  & > div:last-child {
    font-weight: 460;
  }
`

export const AdjustmentsPropsContainer = styled.div`
  & > div > h3 {
    font-size: 20px;
    font-weight: 400;
  }
`

export const AdjustmentFactorsList = styled.div`
  background-color: ${theme.colors.white};
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
`

export const AdjustmentFactor = styled.div`
  background-color: ${theme.colors.white};
  margin: 0px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  font-size: 14px;

  & > div {
    flex: 1;
  }
`

interface AdjustmentFactorProps {
  selected?: boolean
  isReadonly?: boolean
  disabled?: boolean
  hideButton?: boolean
}
export const AdjustmentFactorButton = styled.button<AdjustmentFactorProps>`
  border: ${(props) =>
    !props.hideButton ? `1px solid ${theme.colors.light}` : 'none'};
  background: ${(props) => {
    if (props.selected) {
      return theme.colors.primary
    }
    if (props.isReadonly) {
      return theme.colors.light4
    }
    return theme.colors.white
  }};
  font-weight: 460;
  color: ${(props) =>
    props.selected ? theme.colors.white : theme.colors.black};
  cursor: ${({ isReadonly, disabled, hideButton }) =>
    isReadonly || disabled || hideButton ? 'default' : 'pointer'};
  font-size: 16px;
  border-radius: 4px;
  transition: all 0.1s ease;
  height: 40px;
  width: 50px;
`

export const AdjustmentFactorInput = styled.input`
  background: ${(props: AdjustmentFactorProps) =>
    props.selected ? '#2F394B' : '#d2dae5'};
  padding: 0;
  border: 0;
  margin: 20px;
  font-weight: 460;
  color: ${(props: AdjustmentFactorProps) =>
    props.selected ? '#fff' : '#2f394b'};
  cursor: pointer;
  max-width: 40px;
  height: 40px;
  text-align: center;
  font-size: 15px;
  &:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    opacity: 1;
  }
`

export const NoAdjustments = styled.div`
  margin: 40px;
  text-align: center;
  color: #666;
`

interface RatingTableSelectItemProps {
  selected?: boolean
}

export const RatingTableSelectItem = styled.div`
  cursor: pointer;
  background-color: ${(props: RatingTableSelectItemProps) =>
    props.selected ? theme.colors.primary : theme.colors.white};
  color: ${(props: RatingTableSelectItemProps) =>
    props.selected ? theme.colors.white : theme.colors.primary2};
  line-height: 30px;
  height: 30px;
  font-weight: 460 !important;
`

export const RowCell = styled(RatingTableSelectItem)`
  display: flex;
  flex: 1;
  border-right: 1px solid ${theme.colors.light};
  line-height: 20px !important;
  font-size: 15px !important;
  font-weight: 460 !important;
`

export const RowSubHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.light};
  & > div:last-child {
    border-right: 0 !important;
  }
`
export const RowSubHeaderCell = styled.div`
  width: 100%;
  border-right: 1px solid ${theme.colors.light};
  text-align: center;
  text-align: center;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
`

export const RowCellLong = styled(RowSubHeaderCell)`
  display: flex;
  flex: 2;
`

export const CalculatedRatingDisplay = styled.div`
  background-color: ${theme.colors.light};
  padding: 10px;
  border-radius: ${theme.borderRadius}px;
  font-weight: 460;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px;

  & > span:last-child {
    margin-left: 15px;
    margin-right: 15px;
  }
`

export const AdjustmentFactorLabel = styled.div`
  width: 30px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const OldContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
`
