import { LockStatus } from '@app/evaluation-core/types'
import { useSnackbar } from '@app/snackbar'
import useEvaluationLockedState from '@common/hooks/useEvaluationLockedState'

const useReadOnly = () => {
  const lockStatus = useEvaluationLockedState()
  const [openSnackbar] = useSnackbar()
  const handleReadOnlyStatus = (cb: () => void) => () => {
    if (lockStatus === LockStatus.READ_ONLY) {
      return openSnackbar('This evaluation is in read-only mode.')
    }
    cb()
  }
  return handleReadOnlyStatus
}

export default useReadOnly
