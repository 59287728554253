import images from '@app/images'
import theme from '@app/ui/theme'
import React, { FunctionComponent } from 'react'
import * as S from './styled'

type Props = {
  date?: Date | undefined
  dateString?: string | undefined | null
  showDate?: boolean
  showTime?: boolean
  showSeconds?: boolean
}

// Should be called with EITHER date or dateString
const DateTimeView: FunctionComponent<Props> = ({
  date = undefined,
  dateString = undefined,
  showDate = true,
  showTime = true,
  showSeconds = false,
}) => {
  if (dateString && dateString.length > 0) date = new Date(dateString)

  const displayDate = date ? date.toLocaleDateString('sv-SE') : ''
  let displayTime = date ? date.toLocaleTimeString('sv-SE') : ''
  const isValidDate = date ? isNaN(date.getTime()) : false

  if (isValidDate && !showSeconds) displayTime = displayTime.slice(0, 5)

  return isValidDate ? (
    <div style={{ flexDirection: 'row', display: 'inline-block' }}>N/A</div>
  ) : (
    <div style={{ flexDirection: 'row', display: 'inline-block' }}>
      {showDate && <S.DateContainer>{displayDate}</S.DateContainer>}
      {showTime && <S.TimeContainer>{displayTime}</S.TimeContainer>}
    </div>
  )
}

export default DateTimeView
