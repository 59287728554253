import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import { HoleMenuItem } from './HoleMenu.styles'
import { LockStatus } from '@app/evaluation-core/types'
import { MenuArea } from '@app/evaluation-modal/index.styles'
import React from 'react'
import { getSelectedHole } from '@app/evaluation-core'
import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'
import { useDispatch } from 'react-redux'
import { useEvaluation } from '@common/hooks'
import useEvaluationLockedState from '@common/hooks/useEvaluationLockedState'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { DefaultTheme, useTheme } from 'styled-components'
import { Theme } from '@app/ui/theme'

type Props = {
  reference: React.MutableRefObject<HTMLDivElement>
}

const HoleMenu = ({ reference }: Props) => {
  const { currentId, evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const dispatch = useDispatch<any>()
  const theme = useTheme() as Theme
  const lockStatus = useEvaluationLockedState()
  const isLocked = lockStatus === LockStatus.LOCKED

  if (!currentId || !evaluation) return null
  const _holeItems = evaluation.holes.map(
    ({ holeIndex, locked }, index: number) => (
      <HoleMenuItem
        key={index}
        onClick={() => dispatch(setSelectedHole(index))}
      >
        <div>
          <div className={view?.hole === index ? 'active' : undefined}></div>
        </div>
        Hole {holeIndex + 1}
        {locked === LockStatus.LOCKED && (
          <div
            style={{
              backgroundColor: theme.colors.white,
              marginLeft: 'auto',
              marginRight: 24,
            }}
          >
            <FeatherIcon
              class="status"
              name="lock"
              size={20}
              color={theme.colors.secondary2}
            />
          </div>
        )}
      </HoleMenuItem>
    )
  )

  return (
    <DropDownMenu
      value={`Hole ${(getSelectedHole(evaluation)?.holeIndex || 0) + 1}`}
      width={160}
      reference={reference}
      backgroundColor={isLocked ? theme.colors.secondary2 : ''}
      color={isLocked ? 'white' : 'black'}
      iconColor={isLocked ? 'white' : ''}
      style={{ left: 0, top: 0 }}
    >
      <MenuArea>{_holeItems}</MenuArea>
    </DropDownMenu>
  )
}

export default HoleMenu
