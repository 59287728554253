import { User } from '@app/auth/types'
import Spacer from '@app/components/atoms/Spacer'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import theme from '@app/ui/theme'
import { useAxios } from '@common/hooks/useAxios'
import useUser from '@common/hooks/useUser'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as S from './styled'

interface EditProfileOverlayProps {
  email: string
  firstname: string
  lastname: string
  handicap: number
  homeclub: string
}

function formatGolfId(numbers: string): string {
  numbers = numbers.replace(/\D/g, '')

  const groups = numbers.match(/.{1,6}/g) || []

  const golfId = groups.join('-').substring(0, 10)

  return golfId
}

const EditProfileOverlay = ({
  email,
  firstname,
  lastname,
  handicap,
  homeclub,
}: EditProfileOverlayProps) => {
  const [profileEmail, setProfileEmail] = useState(email || '')
  const [profileFirstName, setProfileFirstName] = useState(firstname || '')
  const [profileLastName, setProfileLastName] = useState(lastname || '')
  const [profileHandicap, setProfileHandicap] = useState(handicap)
  const [profileHomeclub, setProfileHomeclub] = useState(homeclub || '')

  const [golfId, setGolfId] = useState('')
  const [gitPassword, setGitPassword] = useState('')

  const user = useUser()
  const axios = useAxios('/users')

  const dispatch = useDispatch<any>()
  const INPUT_PLACEHOLDER = 'Tap here to start typing'

  const update: Partial<User> = {
    email: profileEmail,
    forename: profileFirstName,
    lastname: profileLastName,
    handicap: profileHandicap,
    clubname: profileHomeclub,
  }

  const hideModal = () => {
    dispatch({
      type: 'SET_MODAL',
      params: null,
    })
  }

  const editProfile = async () => {
    dispatch({ type: 'SET_USER', value: { ...user, ...update } })
    await axios.patch('/', update)
    hideModal()
  }

  const addGitId = async () => {
    await axios.patch('/integrations/git', {
      username: golfId,
      password: gitPassword,
    })

    dispatch({
      type: 'SET_USER',
      value: { ...user, golfID: golfId },
    })

    hideModal()
  }

  const SPACING = 16

  return (
    <S.Wrapper>
      <S.Header>
        <S.Title> Edit Profile</S.Title>
        <S.CloseButton onClick={hideModal}>X</S.CloseButton>
      </S.Header>
      <S.Line />
      <S.Content>
        <S.Label>Email</S.Label>
        <S.Input
          placeholder="sven@mappingindustries.se"
          disabled={!!profileEmail}
          defaultValue={profileEmail}
          onChange={({ target }) => {
            setProfileEmail(target.value)
          }}
        />
        <Spacer height={SPACING} />
        <S.Label>First name</S.Label>
        <S.Input
          placeholder="Sven"
          defaultValue={profileFirstName}
          onChange={({ target }) => {
            setProfileFirstName(target.value)
          }}
        />
        <Spacer height={SPACING} />
        <S.Label>Last name</S.Label>
        <S.Input
          placeholder="Svensson"
          defaultValue={profileLastName}
          onChange={({ target }) => {
            setProfileLastName(target.value)
          }}
        />
        {profileHomeclub && (
          <>
            <Spacer height={SPACING} />
            <S.Label>Home club</S.Label>
            <S.Input
              placeholder="Västerviks Golfklubb"
              defaultValue={profileHomeclub}
              disabled={!!profileHomeclub}
              onChange={({ target }) => {
                setProfileHomeclub(target.value)
              }}
            />
          </>
        )}

        {(user.country === 'Sweden' || !user.country) && !user.golfID && (
          <div
            style={{
              marginTop: SPACING,
              padding: 12,
              border: '1px solid #cecece',
              borderRadius: 5,
              background: theme.colors.prevField,
            }}
          >
            <S.SubTitle>Connect Golf ID to LiveRate Account</S.SubTitle>
            <Spacer height={SPACING} />
            <S.Label>Golf-ID</S.Label>
            <S.Input
              placeholder="123456-789"
              value={golfId}
              onChange={({ target }) => setGolfId(formatGolfId(target.value))}
            />
            <Spacer height={SPACING} />
            <S.Label>GIT Password</S.Label>
            <S.Input
              placeholder="GIT password"
              value={gitPassword}
              type="password"
              onChange={({ target }) => {
                setGitPassword(target.value)
              }}
            />
            <Spacer height={SPACING} />
            <RoundedButton
              title="Connect GIT"
              onPress={addGitId}
              style={{
                height: '50px',
                width: '50%',
                marginLeft: 'auto',
                backgroundColor: theme.colors.secondaryLighter,
              }}
              testId={'button'}
            />
          </div>
        )}
        <Spacer height={SPACING} />

        <S.ButtonContainer>
          <RoundedButton
            title="SAVE CHANGES"
            onPress={editProfile}
            style={{
              height: '50px',
              backgroundColor: theme.colors.secondary2,
            }}
            testId={'button'}
          />
        </S.ButtonContainer>
      </S.Content>
    </S.Wrapper>
  )
}

export default EditProfileOverlay
