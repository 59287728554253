import theme from '@app/ui/theme'
import styled from 'styled-components/native'
import _styled from 'styled-components'

export const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 420px;
  margin: 25px auto;
`
export const MenuDiv = styled.View`
  padding: 10px;
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.light};
  font-size: 15px;
`
export const ButtonContainer = styled.View`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`
export const TeeButton = styled.TouchableOpacity<{ selected: boolean }>`
  background: ${({ selected }) =>
    selected ? theme.colors.primary : '#c4c4c4'};
  height: 40px;
  width: 40px;
  display: flex;
  margin: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
`

export const TeeContainer = styled.View`
  background-color: ${theme.colors.white};
  border-radius: 15;
  justify-content: center;
  width: 420px;
`

export const InnerTeeContianer = styled.View`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

export const TitleText = styled.Text`
  font-weight: bold;
  font-size: 20px;
`

interface DropDownMenuItemProps {
  disabled?: boolean
}

export const MenuItem = styled.TouchableOpacity`
  display: block;
  padding: 10px;
  border-bottom-width: 1;
  border-bottom-color: ${theme.colors.light};
  font-size: 15px;
  opacity: ${(props: DropDownMenuItemProps) => (props.disabled ? '0.6' : 1)};
`

export const FormExGenderBtn = styled.View`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  & > div {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }
  & > div > div {
    width: 30px;
    text-align: center;
  }
`

export const StyledInput = _styled.textarea`
  padding: 5px;
  width: 97%;
  height: 72px;
  border: none;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.dark1};
  border-radius: ${theme.borderRadius}px;
  outline: none;
  font-size: 16px;
  font-family: 'Proxima Nova';
  color: #333;
  margin-right: 10px;
  -webkit-appearance: none;
  appearance: none;
`

export const CourseLengthErrorContainer = styled.View`
  display: flex;
  margin: 4px 0;
  max-width: 600px;
  flex-direction: column;
  gap: 4px;
  padding: 6px;
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.4);
`

export const CourseLengthWarningContainer = styled.View`
  display: flex;
  margin: 4px 0;
  max-width: 600px;
  flex-direction: column;
  gap: 4px;
  padding: 6px;
  border: 1px solid yellow;
  background-color: rgba(255, 255, 0, 0.4);
`
