import * as S from './styled'

import Avatar from '../Avatar'
import { EvaluationUser } from '@app/evaluation-core/types'
import React from 'react'

interface EvalTabItemProps {
  item: string
  active: boolean
  activeUsers?: EvaluationUser[]
  onPress: () => void
  sideBarCollapsed?: boolean
}

const EvalTabItem = ({
  item,
  active,
  activeUsers,
  onPress,
  sideBarCollapsed,
}: EvalTabItemProps) => {
  return (
    <S.EvalSideBarTabItem
      className={`eval-sidebar-btn ${active ? 'active' : null}`}
      style={{ position: 'relative' }}
      sideBarCollapsed={sideBarCollapsed}
    >
      <a onClick={onPress}>{item}</a>
      {!sideBarCollapsed && (
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            height: '100%',
            background: 'rgba(255,255,255,.5)',
            paddingRight: 8,
            paddingLeft: 8,
            minWidth: '25px',
          }}
        >
          {activeUsers?.map((user, i) => (
            <Avatar
              key={i}
              userId={user.view.email}
              size={20}
              userIndicatorSize={6}
              isLastIndex={i + 1 === activeUsers.length}
              userColor={user.view.color}
              zIndex={i}
            />
          ))}
        </div>
      )}
      {sideBarCollapsed && (
        <div
          style={{
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            paddingRight: 2,
            paddingLeft: 2,
            height: '100%',
          }}
        >
          {activeUsers?.map((user, i) => (
            <div
              style={{
                height: 10,
                width: 10,
                borderRadius: 25,
                background: user.view.color,
              }}
              key={i}
            />
          ))}
        </div>
      )}
    </S.EvalSideBarTabItem>
  )
}

export default EvalTabItem
