import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  padding-bottom: 40px;
`

export const ColorPickerHeader = styled.div`
  width: 100%;
  background-color: ${theme.colors.white};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  text-align: center;
  padding: ${theme.space[2]}px;
  box-sizing: border-box;
  cursor: pointer;
`
