import getEnv from '@app/environments/getEnv'
import { State } from '@app/state/types'
import type * as Axios from 'axios'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetch } from '../../common/fetch'
import useSWR, { SWRConfiguration } from 'swr'

export const attachInterceptors = (axiosInstance: Axios.AxiosInstance, user) =>
  axiosInstance.interceptors.request.use(async (req) => {
    if (user) {
      req.headers.authorization = getToken('access', user)
      req.headers.refreshtoken = getToken('refresh', user)
    }
    return req
  })

export const getToken = (type: 'refresh' | 'access', user) =>
  type === 'access' ? user?.token : user?.refreshToken

export const useApi = <T>(
  path: string,
  initialData?: T,
  options?: SWRConfiguration
) => {
  const { user } = useSelector((state: State) => ({
    user: state.user,
  }))

  const api = getEnv().api + path

  const axios = fetch.create({
    baseURL: api,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  async function fetcher(url: string) {
    const res = await axios.get(url)
    return res.data
  }

  useEffect(() => {
    attachInterceptors(axios, user)
  }, [user, axios])

  const { data, error, isLoading, mutate } = useSWR<T>(api, fetcher, {
    fallbackData: initialData,
    ...options,
  })

  return { data, error, isLoading, mutate }
}
