import { ModalAction, ModalState } from '@app/modal/types'

export const SET_MODAL = 'SET_MODAL'

export const MODAL_INITIAL_STATE: ModalState = null

export default function evaluation(
  state: ModalState = MODAL_INITIAL_STATE,
  action: ModalAction
) {
  switch (action.type) {
    case SET_MODAL:
      return action.params
    default:
      return state
  }
}
