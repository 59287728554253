import React, { createContext, useContext, useEffect, useState } from 'react'

import { Measurement } from '@app/evaluation-core/types'
import {
  RatingHole,
  RatingTemplateContextType,
  UpdateRatingHole,
} from './types'
import { createHoles, mergeHoleArrays } from './helpers'
import {
  CourseSettingsCamelCase,
  RatingTemplate,
} from '../../../../../../types/Templates'
import { useAxios } from '@common/hooks/useAxios'

const ratingHolesInitialState = []

const courseSettingsInitialState: CourseSettingsCamelCase = {
  bunkDepthDefault: 3,
  grassType: 'cool',
  intermediateCut: 0,
  measurement: Measurement.IMPERIAL,
  roughHeight: 0,
  stimpFeet: 3,
  stimpInches: 0,
  'wind_1-9': 0,
  'wind_10-18': 0,
}

function getCountOfUniqueHoleIds(holes: RatingHole[]): number {
  const uniqueIds: Set<string> = new Set()

  // Iterate over each object in the array
  holes.forEach((hole) => {
    if (hole.physicalID) {
      uniqueIds.add(hole.physicalID)
    }
  })

  return uniqueIds.size
}

export const RatingTemplateContext = createContext<RatingTemplateContextType>({
  holes: 9,
  setHoles: () => undefined,
  ratingHoles: ratingHolesInitialState,
  setRatingHoleData: () => undefined,
  setCourseRatingHoles: () => undefined,
  removeRatingHole: () => undefined,
  holeIndexHandler: [0, () => 0],
  courseSettings: courseSettingsInitialState,
  setCourseSettings: () => undefined,
  courseName: '',
  setCourseName: () => undefined,
  createRatingTemplate: async () => {
    return {} as RatingTemplate
  },
})

interface Props {}

export const RatingTemplateProvider: React.FC<Props> = ({ children }) => {
  const [courseName, setCourseName] = useState('')
  const [holes, setHoles] = useState<9 | 18>(9)
  const [ratingHoles, setRatingHoles] = useState<RatingHole[]>(
    createHoles(holes)
  )
  const [selectedHoleIndex, setSelectedHoleIndex] = useState(0)
  const [courseSettings, setCourseSettings] = useState<CourseSettingsCamelCase>(
    courseSettingsInitialState
  )
  const axios = useAxios('/ratings/templates')

  useEffect(() => {
    setRatingHoles(mergeHoleArrays(createHoles(holes), ratingHoles))
  }, [holes])

  const setRatingHoleData = (data: UpdateRatingHole) => {
    if (selectedHoleIndex === undefined) return
    const holeNumber = selectedHoleIndex + 1
    setRatingHoles((prevState) => {
      const updatedRatingHoles = [...prevState]
      updatedRatingHoles[selectedHoleIndex] = {
        ...data,
        ratingHoleNumber: holeNumber,
      }

      return updatedRatingHoles
    })
    if (holeNumber + 1 > holes) {
      return
    }
    setSelectedHoleIndex(holeNumber)
  }

  const removeRatingHole = (index: number) => {
    setRatingHoles((prevState) => {
      const updatedRatingHoles = [...prevState]
      updatedRatingHoles[index] = {
        ratingHoleNumber: index + 1,
      }
      return updatedRatingHoles
    })
  }

  const setCourseRatingHoles = (data: UpdateRatingHole[]) => {
    const ratingHolesToAdd = data
      .map((hole, index) => ({
        ...hole,
        ratingHoleNumber: selectedHoleIndex + index + 1,
      }))
      .slice(0, holes - selectedHoleIndex)

    ratingHolesToAdd.forEach((hole, index) => {
      setRatingHoles((prevState) => {
        const holesWithData = prevState.filter(
          (hole) => hole.physicalID !== undefined
        )
        if (holesWithData.length + 1 > holes) {
          return prevState
        }
        const updatedRatingHoles = [...prevState]
        updatedRatingHoles[selectedHoleIndex + index] = {
          ...hole,
          ratingHoleNumber: selectedHoleIndex + index + 1,
        }
        return updatedRatingHoles
      })
    })

    if (data.length + selectedHoleIndex + 1 > holes) {
      return setSelectedHoleIndex(holes - 1)
    }
    setSelectedHoleIndex(selectedHoleIndex + data.length)
  }

  const handleSetCourseSettings = (data: Partial<CourseSettingsCamelCase>) => {
    return setCourseSettings({
      ...courseSettings,
      ...data,
    })
  }

  const createRatingTemplate = async (clubId: number) => {
    if (holes === 9) {
      const uniqueHoles = getCountOfUniqueHoleIds(ratingHoles)
      if (uniqueHoles < 3) {
        throw '3 or more unique holes required for a 9 hole rating.'
      }
    } else {
      const uniqueHoles = getCountOfUniqueHoleIds(ratingHoles)
      if (uniqueHoles < 6) {
        throw '6 or more unique holes required for an 18 hole rating.'
      }
    }
    const { data } = await axios.post<RatingTemplate>('', {
      clubId,
      name: courseName,
      holeIds: ratingHoles.map((hole) => hole.physicalID),
      defaultCourseSettings: courseSettings,
      mapped: false,
    })
    return data
  }

  const value: RatingTemplateContextType = {
    holes,
    setHoles,
    ratingHoles,
    setRatingHoleData,
    setCourseRatingHoles,
    removeRatingHole,
    holeIndexHandler: [selectedHoleIndex, setSelectedHoleIndex],
    courseSettings,
    setCourseSettings: handleSetCourseSettings,
    courseName,
    setCourseName,
    createRatingTemplate,
  }

  return (
    <RatingTemplateContext.Provider value={value}>
      {children}
    </RatingTemplateContext.Provider>
  )
}

export const useRatingTemplate = () => useContext(RatingTemplateContext)
