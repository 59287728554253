import * as S from './styled'
import { clubDisplayFilter } from '@app/courses/domain/searchFilters'
import React, { useState } from 'react'
import {
  getCountryFlagCode,
  getCountryFlagUrl,
} from '@app/courses/domain/countryFlags'
import ClubPlaceholder from '@app/courses/components/ClubPlaceholder'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { Waypoint } from 'react-waypoint'
import images from '@app/images'
import { navigate } from '@app/navigation'
import theme from '@app/ui/theme'
import { useMediaQuery } from 'react-responsive'

import useUser from '@common/hooks/useUser'
import { View } from 'react-native'
import { useAxios } from '@common/hooks/useAxios'
import Spacer from 'react-styled-spacer'
import { LRClub } from '../../../types/Clubs'
import ClubStatus from '../../../pages/ClubManagmentPage/ClubStatus'
import { MiProjectStatus } from '@app/evaluation-core/types'

interface TableDataProps {
  clubs: LRClub[]
  searchValue: string
  filterStatusValue: number
  regionFilterStatusValue: string
  districtFilterStatusValue: string
  loading?: boolean
}

export const evalButtonTextForStatus = ['Start', 'Continue', 'Continue', 'View']

const Placeholders = () => (
  <>
    <ClubPlaceholder />
    <ClubPlaceholder />
    <ClubPlaceholder />
    <ClubPlaceholder />
    <ClubPlaceholder />
    <ClubPlaceholder />
  </>
)

const TableData = ({
  clubs,
  searchValue,
  filterStatusValue,
  regionFilterStatusValue,
  districtFilterStatusValue,
  loading,
}: TableDataProps) => {
  const user = useUser()
  const [endIndex, setEndIndex] = useState(10)
  const isSmallScreen = useMediaQuery({ query: '(max-width: 960px)' })
  const axios = useAxios('/courses')

  const onEndReached = () => setEndIndex(endIndex + 15)
  const refreshCourses = async () => {
    await axios.post('/clubsAndCourses')
  }

  if (!clubs) return null

  if (loading) {
    return <Placeholders />
  }

  const results = clubs.filter((club, index) =>
    clubDisplayFilter(
      club,
      index,
      searchValue,
      filterStatusValue,
      regionFilterStatusValue,
      districtFilterStatusValue
    )
  )

  if (!results[0]) {
    return (
      <S.ClubsNoResults>
        <FeatherIcon name="frown" size={28} />{' '}
        <span>No clubs or courses matched your search critera.</span>
      </S.ClubsNoResults>
    )
  }

  const displayResults = results.slice(0, endIndex)

  return (
    <>
      {user?.isAdmin && (
        <View
          style={{
            alignItems: 'flex-end',
          }}
        >
          <RoundedButton title="Update courses" onPress={refreshCourses} />
        </View>
      )}
      {displayResults.map((item, index) => {
        return (
          <React.Fragment key={index + item.id}>
            <S.ClubContainer>
              <S.ClubSideCol>
                <S.ClubLogo>
                  <img
                    src={item?.clubLogoUrl || images.png.CourseFallback}
                    onError={(e: any) => {
                      e.target.src = images.png.CourseFallback
                    }}
                    style={{
                      width: 60,
                      objectFit: 'contain',
                    }}
                  />
                </S.ClubLogo>
              </S.ClubSideCol>{' '}
              <S.ClubMainCol>
                <S.ClubHeader>
                  <S.ClubTitle>{item.name}</S.ClubTitle>
                </S.ClubHeader>
                <ClubStatus
                  status={
                    item.projectStatus &&
                    (item.projectStatus.toString() as MiProjectStatus)
                  }
                  showLabel={true}
                  size={20}
                />

                <S.ClubSubHeader>
                  <div style={{ display: 'flex' }}>
                    <S.ClubSubHeaderCountryFlag>
                      <img
                        style={{ width: 22, height: 22 }}
                        src={getCountryFlagUrl(
                          getCountryFlagCode(item.districtCountry)
                        )}
                      />
                    </S.ClubSubHeaderCountryFlag>
                    <Spacer w={15} />
                    <S.ClubSubHeaderSeparator />
                    <span>{item.districtName}</span>
                    <S.ClubSubHeaderSeparator />
                    <span>{item?.regionName || 'No region'}</span>
                  </div>
                  <RoundedButton
                    isDefault={true}
                    style={{
                      minWidth: isSmallScreen ? 115 : 250,
                      height: isSmallScreen ? 34 : 40,
                    }}
                    onPress={() =>
                      navigate('ClubManagementStack', {
                        screen: 'ClubManagement',
                        params: { club: item },
                      })
                    }
                    title="Manage Club"
                  />
                </S.ClubSubHeader>
              </S.ClubMainCol>
            </S.ClubContainer>
            {index + 1 !== displayResults.length && (
              <div
                style={{
                  width: 'calc(100% + 60px)',
                  marginLeft: -30,
                  border: `1px dotted ${theme.colors.primary}`,
                  opacity: 0.3,
                }}
              />
            )}
          </React.Fragment>
        )
      })}
      <Waypoint onEnter={onEndReached}>
        {endIndex < results.length && (
          <div
            style={{
              width: '100%',
              height: '500px',
            }}
          >
            <ClubPlaceholder />
            <ClubPlaceholder />
            <ClubPlaceholder />
            <ClubPlaceholder />
            <ClubPlaceholder />
            <ClubPlaceholder />
          </div>
        )}
      </Waypoint>
    </>
  )
}

export default TableData
