import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup, AdmParameter } from '../../types'
import React from 'react'
import { TreesImg } from './index.styles'
import { getAdjustmentValue } from '@app/evaluation-core'
import images from '@app/images'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

export enum TreeChute_Problem_1Shot {
  NOT_USED = 0,
  MINOR = 1,
  MODERATELY = 2,
  SIGNIFICANTLY = 3,
  EXTREMELY = 4,
}

export enum TreeChute_Problem_2xShot {
  NOT_USED = 0,
  MINOR = 2,
  MODERATELY = 4,
  SIGNIFICANTLY = 6,
  EXTREMELY = 8,
}

export const TreeChute_Problem: Array<
  typeof TreeChute_Problem_1Shot | typeof TreeChute_Problem_2xShot
> = [TreeChute_Problem_1Shot, TreeChute_Problem_2xShot]

enum TreesExist {
  'DO_NOT_EXIST' = 0,
  'EXISTS' = 1,
}

const getTreeFactors = (
  value: string | number | undefined
): AdmFactorGroup[] => [
  {
    factors: [
      {
        label: 'O*',
        itemKey: 'trees_obstructed',
        totalKey: 'trees_obstructed_total',
        format: 'shot',
        type: 'shots',
        shouldRender: (adjustments, view) =>
          getAdjustmentValue(
            'golfer',
            view,
            'trees_exist_used',
            adjustments
          ) === TreesExist.EXISTS,
        items: [
          {
            value: 1,
            text: `If trees OBSTRUCT the shot to the target (landing zone or green). Do not apply if the trees that obstruct the tee shot are used to determine a CHUTE (H) adjustment.`,
          },
        ],
      },
      {
        label: 'H',
        itemKey: 'trees_chuteRating',
        format: 'golfer',
        shouldRender: (adjustments, view) =>
          getAdjustmentValue(
            'golfer',
            view,
            'trees_exist_used',
            adjustments
          ) === TreesExist.EXISTS,
        items: [
          {
            value: value,
            disabled: true,
            text: `If the tee shot must be hit through a narrow CHUTE. The amount of adjustment depends on the width and length of the chute.`,
          },
        ],
      },
    ],
  },
]

export const Trees = () => {
  const adjustments = useAdjustment()
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  if (!evaluation) return null

  const par = getAdjustmentValue('hole', view, 'Par', adjustments)
  const chuteRating = getAdjustmentValue(
    'golfer',
    view,
    'trees_chuteRating',
    adjustments
  )
  const ratingValues = TreeChute_Problem[par === 3 ? 0 : 1]
  const parameters: AdmParameter[] = [
    {
      adjustmentKey: 'trees_exist_default',
      format: 'hole',
      type: 'select',
      label: 'Trees Exists Default',
      selectorValues: [TreesExist.DO_NOT_EXIST, TreesExist.EXISTS],
      selectorLabels: ['Do Not Exist', 'Trees Exist'],
      notExpandible: true,
    },
    {
      adjustmentKey: 'trees_exist',
      format: 'golfer',
      type: 'select',
      label: 'Trees Exist (Player Specific)',
      selectorValues: [TreesExist.DO_NOT_EXIST, TreesExist.EXISTS],
      selectorLabels: ['Not used', 'Trees Exist'],
      notExpandible: true,
    },
    {
      type: 'select',
      label: 'Trees Difficulty Default',
      inputDisabled: true,
      adjustmentKey: 'trees_difficulty_default',
      format: 'hole',
      selectorValues: [
        ratingValues.MINOR,
        ratingValues.MODERATELY,
        ratingValues.SIGNIFICANTLY,
        ratingValues.EXTREMELY,
      ],
      selectorLabels: [
        'Minor Problem',
        'Moderate Problem',
        'Significant Problem',
        'Extreme Problem',
      ],
      notExpandible: true,
      saveMode: 'hole',
    },
    {
      type: 'select',
      label: 'Trees Difficulty (Player Specifc)',
      inputDisabled: true,
      adjustmentKey: 'trees_difficulty',
      format: 'golfer',
      selectorValues: [
        ratingValues.NOT_USED,
        ratingValues.MINOR,
        ratingValues.MODERATELY,
        ratingValues.SIGNIFICANTLY,
        ratingValues.EXTREMELY,
      ],
      selectorLabels: [
        'Not used',
        'Minor Problem',
        'Moderate Problem',
        'Significant Problem',
        'Extreme Problem',
      ],
      notExpandible: true,
      saveMode: 'hole',
    },
    {
      type: 'table-value',
      label: 'Trees Rating',
      inputDisabled: true,
      adjustmentKey: 'trees_difficulty_used',
      format: 'golfer',
    },

    {
      type: 'numeric-input',
      label: 'Chute Width',
      adjustmentKey: 'treesChuteWidth',
      format: 'golfer',
      unit: 'yards',
    },
    {
      type: 'numeric-input',
      label: 'Length to clear the chute',
      adjustmentKey: 'treesChuteDistance',
      format: 'golfer',
      unit: 'yards',
    },
    {
      type: 'numeric-input',
      label: 'Tee Shot Chute Rating',
      inputDisabled: true,
      adjustmentKey: 'trees_chuteRating',
      format: 'golfer',
      notExpandible: true,
    },
  ]

  const shouldRenderImage =
    getAdjustmentValue('golfer', view, 'trees_exist_used', adjustments) ===
    TreesExist.EXISTS

  const renderContent = shouldRenderImage ? (
    <TreesImg>
      <img src={images.png.Trees} style={{ width: 390 }}></img>
    </TreesImg>
  ) : (
    <></>
  )

  return (
    <Adjustment
      useLz={false}
      factors={getTreeFactors(chuteRating)}
      paramsSubText="Player Specific adjustments will take precenedence over the default adjustments."
      parameters={parameters}
      renderContent={() => renderContent}
    />
  )
}
