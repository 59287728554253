import theme from '@app/ui/theme'
import styled from 'styled-components'
import _styled from 'styled-components/native'

export const ContentCard = styled.div`
  margin: 20px;
  box-shadow: 0 0px 4px 0 ${theme.colors.dark1};
  background-color: ${theme.colors.white};
  padding: 30px;
  border-radius: 4px;
  overflow: hidden;
`

export const ContentHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: 460;
  letter-spacing: 0.4px;
  color: #242c2b;
`

export const ContentSeparator = styled.div`
  margin-top: 14px;
  margin-bottom: 25px;
  height: 1px;
  background-color: ${theme.colors.light};
`

export const ContentMain = _styled.ScrollView`  
`

export const TeamTable = styled.div``

interface TeamTableRowProps {
  height?: string
  width: string
}

export const TeamTableRow = styled.div`
  display: grid;
  grid-template-columns: ${({ width }: TeamTableRowProps) => width};

  & > div {
    height: ${({ height = '30px' }: TeamTableRowProps) => height};
  }

  & > div:first-child {
    text-align: left !important;
    justify-content: flex-start;
  }

  & > div:last-child {
    justify-content: flex-end;
  }
`

interface TeamTableColProps {
  isOwnUser?: boolean
}

export const TeamTableCol = styled.div`
  border-bottom: 1px solid ${theme.colors.light};
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-weight: ${(props: TeamTableColProps) =>
    props.isOwnUser ? 600 : 'default'};
`

export const TeamTableColTop = styled(TeamTableCol)`
  display: flex;
`

export const TeamInvitationArea = styled.div`
  margin-top: 40px;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  & > input {
    margin: 0;
    padding: 0;
    width: 200px;
    height: 36px;
    border: none;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.dark1};
    border-radius: ${theme.borderRadius}px;
    outline: none;
    font-size: 16px;
    line-height: 36px;
    padding-left: 25px;
    padding-right: 25px;
    font-family: 'Proxima Nova';
    color: #333;
    margin-right: 10px;
    -webkit-appearance: none;
    appearance: none;
  }
`

interface PrepOverlayProps {
  transform: string
  x?: number
  y?: number
  width: number | string
  zIndex?: number
}

const OL_DEFAULTWIDTH = 210

export const PrepOverlay = styled.div`
  width: ${(props: PrepOverlayProps) =>
    props.width ? props.width : OL_DEFAULTWIDTH}px;
  background: ${theme.colors.white};
  padding: 20px;
  margin-top: calc(50vh - 200px);
  border-radius: ${theme.borderRadius}px;
  position: fixed;
  z-index: ${(props: PrepOverlayProps) =>
    props.zIndex ? props.zIndex : '999999'};
  left: ${(props: PrepOverlayProps) => (props.x ? props.x : 0)}px;
  top: ${(props: PrepOverlayProps) => (props.y ? props.y : 70)}px;
  user-select: none;
  transform: ${(props: PrepOverlayProps) => props.transform};
  transform: translateX(-50%);
  left: 50vw;
  box-shadow: 0 0px 40px 0 ${theme.colors.dark1};
  & > div:last-child {
    border-bottom: 0;
  }
  justify-content: center;
  align-items: center;
  flex: 1;
`

export const GoBackContainer = _styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  max-width: 200px;
  padding: 8px 16px;
`
