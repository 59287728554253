import styled from 'styled-components/native'

export const Container = styled.View`
  height: 30px;
  background-color: ${({ theme }) => theme.colors.secondary2};
  position: absolute;
  bottom: ${({ theme }) => theme.space[2]}px;
  right: ${({ theme }) => theme.space[2]}px;
  z-index: 100;
  justify-content: center;
  align-content: center;
  padding: ${({ theme }) => theme.space[2]}px;
  border-radius: 4px;
  opacity: 0.8;
`

export const Tag = styled.Text`
  font-family: 'Inter';
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
`
