import { adjustments_2020, adjustments_2024 } from './adjustments'
import * as React from 'react'

import { AdjustmentBoundary } from '../boundary'
import { EvaluationState } from '@app/evaluation-core/types'
import { NoAdjustments } from './styles'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

interface AdjustmentGroupsProps {
  selectedGroup: number
  evaluationState: EvaluationState
}

export const AdjustmentGroups = ({ selectedGroup }: AdjustmentGroupsProps) => {
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  if (!view || !evaluation) return null

  const A =
    evaluation.manualVersion === '2020' ? adjustments_2020 : adjustments_2024

  switch (selectedGroup) {
    case 12:
      return (
        <AdjustmentBoundary>
          <A.Psychological />
        </AdjustmentBoundary>
      )
    case 11:
      return (
        <AdjustmentBoundary>
          <A.GreenSurface />
        </AdjustmentBoundary>
      )
    case 10:
      return (
        <AdjustmentBoundary>
          <A.Trees />
        </AdjustmentBoundary>
      )
    case 9:
      return (
        <AdjustmentBoundary>
          <A.LateralObstacles />
        </AdjustmentBoundary>
      )
    case 8:
      return (
        <AdjustmentBoundary>
          <A.CrossingObstacles />
        </AdjustmentBoundary>
      )
    case 7:
      return (
        <AdjustmentBoundary>
          <A.Bunkers />
        </AdjustmentBoundary>
      )
    case 6:
      return (
        <AdjustmentBoundary>
          <A.RecoverRough />
        </AdjustmentBoundary>
      )
    case 5:
      return (
        <AdjustmentBoundary>
          <A.GreenTarget />
        </AdjustmentBoundary>
      )
    case 4:
      return (
        <AdjustmentBoundary>
          <A.Fairway />
        </AdjustmentBoundary>
      )
    case 3:
      return (
        <AdjustmentBoundary>
          <A.Topography />
        </AdjustmentBoundary>
      )
    case 1:
      return (
        <AdjustmentBoundary>
          <A.Dogleg />
        </AdjustmentBoundary>
      )
    case 2:
      return (
        <AdjustmentBoundary>
          <A.Elevation />
        </AdjustmentBoundary>
      )
    case 0:
      return (
        <AdjustmentBoundary>
          <A.Roll />
        </AdjustmentBoundary>
      )
    default:
      return <NoAdjustments>No available adjustments</NoAdjustments>
  }
}
