import * as S from './styled'

import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  SetLiveCRNotifications,
  setLiveCRAdjustmentAll,
} from '@app/evaluation-core'

import { AcceptReject } from '../../molecules/UpdateCard/UpdateCard'
import { EvaluationUserView } from '@app/evaluation-core/types'
import { NotificationActionStatus } from '@common/hooks/useNotification'
import { NotificationEvent } from '@app/notifications/types'
import { Text } from 'react-native'
import UpdateCard from '../../molecules/UpdateCard'
import { useMediaQuery } from 'react-responsive'

type Props = {
  collapsedMode: boolean
  isVisible: boolean
  title: string
  description: string
  holeArray: any[]
  holeObj: any
  setLoading: React.Dispatch<React.SetStateAction<NotificationActionStatus>>
}

type NotificationNotificationEventWithCreatedAt = NotificationEvent & {
  createdAt: number
}

type NotificationsWithDate = Record<
  number,
  NotificationEvent & { createdAt: number }[]
>

const LiveUpdatesModal: FunctionComponent<Props> = ({
  collapsedMode,
  isVisible,
  title,
  description,
  holeArray,
  holeObj,
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const [createdAt, setCreatedAt] = useState<NotificationsWithDate>()
  const [portraitMode, setPortraitMode] = useState(screen.width < screen.height)

  useEffect(() => {
    setPortraitMode(screen.width < screen.height)
  }, [screen.width, screen.height])

  const addNotificationsToCreatedKey = (holeArray: NotificationEvent[]) =>
    holeArray.reduce((memo, x) => {
      if (!memo[x['createdAt']]) {
        memo[x['createdAt']] = []
      }
      memo[x['createdAt']].push(x)
      return memo
    }, {}) as NotificationsWithDate

  const getCreatedAt = () => {
    const result = addNotificationsToCreatedKey(holeArray)

    return result
  }

  useEffect(() => {
    const createdAt = getCreatedAt()
    setCreatedAt(createdAt)
  }, [holeArray])

  const acceptRejectAdjustments =
    (dataArray: NotificationNotificationEventWithCreatedAt) =>
    async (
      view: EvaluationUserView,
      evaluationId: string,
      acceptReject: AcceptReject
    ) => {
      if (acceptReject === AcceptReject.RESOLVED) {
        await setLiveCRAdjustmentAll(dataArray, view, evaluationId)
      }
      return await SetLiveCRNotifications(
        acceptReject,
        dataArray,
        view?.hole,
        evaluationId,
        holeObj
      )
    }

  return (
    <S.ModalContainer visible={isVisible}>
      <S.ModalContent
        collapsedMode={collapsedMode}
        portraitMode={portraitMode}
        isTablet={isTabletOrMobile}
      >
        <S.ModalTitle>{title}</S.ModalTitle>
        <Text>{description}</Text>
        {createdAt &&
          Object.keys(createdAt).length > 0 &&
          Object.entries(createdAt).map(([timeKey, notifications], index) => {
            const acceptRejectOnPress = acceptRejectAdjustments(notifications)

            return (
              <UpdateCard
                key={index}
                portraitMode={portraitMode}
                holeObj={holeObj}
                time={+timeKey}
                items={notifications}
                acceptRejectOnPress={acceptRejectOnPress}
              />
            )
          })}
      </S.ModalContent>
    </S.ModalContainer>
  )
}

export default LiveUpdatesModal
