import {
  EvaluationState,
  EvaluationUser,
  EvaluationUserView,
} from '@app/evaluation-core/types'
import evaluation from '@app/modal/reducers/modal'

import { State } from '@app/state/types'
import { useSelector, shallowEqual } from 'react-redux'

export default () => {
  const state = useSelector((state: State): EvaluationState => {
    const data = {
      ...state.evaluation,
      evaluation: {
        ...state.evaluation.evaluation,
        id: state.evaluation.currentId as string,
      },
    }
    return data
  }, shallowEqual)
  return state
}
