import React, { useState } from 'react'
import Feather from '@ovaeasy/react-native-vector-icons/Feather'
import * as S from './styles'
import { LoadingContainer } from '@app/evaluation-adjustments/components/AdjustmentFactors.styles'
import useAdjustment from '@common/hooks/useAdjustment'

type Value = string | number
interface ButtonProps {
  values: Value[]
  currentValue: number | string
  setValue: React.Dispatch<React.SetStateAction<number | string>> | any
  labels?: string[]
}

const Selector = ({ values, setValue, currentValue, labels }: ButtonProps) => {
  const adjustments = useAdjustment()
  const handleChange = ({ target }) => {
    if (Number.isNaN(parseInt(target[target.selectedIndex].value))) {
      return setValue(target[target.selectedIndex].value)
    }
    return setValue(+target[target.selectedIndex].value)
  }

  const sortedValues = values.every((value) => typeof value === 'number')
    ? (values as number[]).sort((a, b) => a - b)
    : values

  return (
    <S.StepperContainer>
      <S.SelectBoxContainer>
        <S.SelectBox
          onChange={handleChange}
          style={{ width: '100%', textAlignLast: 'left' }}
        >
          {sortedValues.map((value, i) =>
            labels?.length ? (
              <option
                label={labels[i]}
                key={`${value}-i`}
                selected={currentValue === value}
                value={value}
              >
                {labels[i]}
              </option>
            ) : (
              <option
                key={`${value}-i`}
                selected={currentValue === value}
                value={value}
              >
                {!adjustments || !adjustments.adjustments ? (
                  <LoadingContainer>
                    <Feather name="loader" size={16} />
                  </LoadingContainer>
                ) : (
                  value
                )}
              </option>
            )
          )}
        </S.SelectBox>
      </S.SelectBoxContainer>
    </S.StepperContainer>
  )
}

export default Selector
