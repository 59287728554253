import {
  ContentCard,
  ContentHeading,
  ContentMain,
  ContentSeparator,
} from './Preparation.styles'
import React, { useEffect, useState } from 'react'
import CourseInfo from '@app/components/molecules/CourseInfo'
import { CourseSettings } from '@app/evaluation-settings-menu/CourseSettings'

import { PreparationTeam } from './PreparationTeam'
import Spinner from './Spinner'
import { useRoute } from '@react-navigation/native'
import { State } from '@app/state/types'
import { useEvaluation } from '@common/hooks'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'react-simple-snackbar'
import useUser from '@common/hooks/useUser'
import {
  clearEvaluationData,
  clearMixedEvaluationData,
} from '@app/evaluation-core/evaluation'
import { navigate } from '@app/navigation'
import loadingErrorHook from '@common/hooks/loadingErrorHook'
import { LRClub, LRCourse } from '../../types/Clubs'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { useDispatch } from 'react-redux'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import HeaderBar from '@app/courses/components/HeaderBar'
import { useMediaQuery } from 'react-responsive'
import theme from '@app/ui/theme'
import { fetch } from '../../../common/fetch'
import getEnv from '@app/environments/getEnv'
import { useAxios } from '@common/hooks/useAxios'
import { EvalStatus } from '@app/courses/domain/ratingStatus'

let shouldClearTimeout: NodeJS.Timeout | null = null

type Props = { club: LRClub }

export const Preparation: React.FC<Props> = ({ club }) => {
  const { currentId, evaluation } = useEvaluation()
  const evaluationUser = useEvaluationUser()
  const { id: userId, isAdmin } = useUser()
  const [openSnackbar] = useSnackbar()
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery({ query: '(max-width: 960px)' })
  const [fileName, setFileName] = useState('')
  const [showSpinner, setShowSpinner] = useState(false)
  const courses: LRCourse[] = useSelector(
    (state: State) => state.firestore.data.courses
  )
  const copyAxios = useAxios('/evaluations/copy')
  const route = useRoute<any>()

  const [isCopying, setIsCopying] = useState(false)

  const clearEvaluation = async () => {
    if (evaluation.isMixed) {
      await clearMixedEvaluationData()
    } else {
      await clearEvaluationData()
    }
  }

  const handleSave = async () => {
    setShowSpinner(true)
    if (fileName !== '') {
      openSnackbar('Rating saved')
      try {
        await fetch.post(`${getEnv().api}/evaluations/save`, {
          evaluationId: currentId,
          fileName,
        })
        setShowSpinner(false)
      } catch (e) {
        setShowSpinner(false)
        openSnackbar(
          'There was an error saving the evaluation. Please try again.'
        )
      }
      return
    }
    openSnackbar('Please enter an evaluation name')
    setShowSpinner(false)
  }

  const fetchingData =
    !evaluation || !evaluation?.holes || !userId || !currentId

  const clearAndNavigate = () => {
    clearEvaluation()
    return navigate('Courses', null)
  }

  loadingErrorHook(fetchingData, clearAndNavigate)

  useEffect(() => {
    if (!evaluation?.loading) return

    const { loading, message } = evaluation.loading

    if (loading === 3) {
      openSnackbar(message)
      clearEvaluation()
    }
  }, [evaluation?.loading])

  useEffect(() => {
    if (!evaluation?.loading) return
    const { loading } = evaluation.loading
    if (loading !== 1 && shouldClearTimeout) {
      clearTimeout(shouldClearTimeout)
      shouldClearTimeout = null
    }
  }, [evaluation.loading, shouldClearTimeout])

  useEffect(() => {
    if (evaluation?.loading.loading === 1 || isCopying) {
      return
    }
    const originalIdParam = route.params?.copyOriginalId
    if (!originalIdParam) {
      return
    }
    setIsCopying(true)

    copyAxios
      .post('', {
        originalId: originalIdParam,
        newId: currentId,
        userId: evaluationUser.userId,
      })
      .then((resp) => {
        console.log('copy response', resp)
        if (resp.status !== 200) {
          openSnackbar('Error copying rating')
        } else {
          openSnackbar('Rating copied successfully')
        }
        setIsCopying(false)
        route.params.copyOriginalId = null
      })
      .catch(() => {
        openSnackbar('Error copying rating')
        setIsCopying(false)
        route.params.copyOriginalId = null
      })
  }, [evaluation.loading, route.params, currentId])

  function getCourseFromEvaluation() {
    if (!evaluation?.courseId) return null

    const courseId = evaluation.courseId.toString()

    const course = Object.values(courses).find(
      (course: any) => course.id === courseId
    )
    return course
  }

  if (fetchingData) {
    return <Spinner title="Loading data from LiveCR..." />
  }

  if (isCopying) {
    return <Spinner title="Copying rating..." />
  }

  const canEdit =
    evaluation?.status !== EvalStatus.FINALIZED && evaluationUser.isAdmin

  return (
    <ContentMain>
      <PreparationTeam />
      <ContentCard>
        <CourseInfo club={club} evaluation={evaluation} />
      </ContentCard>
      <ContentCard>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <ContentHeading>Course settings</ContentHeading>
          <RoundedButton
            title="Edit Course Settings"
            disabled={!canEdit}
            onPress={() => {
              dispatch({
                type: 'SET_MODAL',
                params: {
                  mode: 'editCourseSettings',
                },
              })
            }}
          />
        </div>
        <ContentSeparator />
        <CourseSettings withValidationLabels interactive={false} />
        <ContentSeparator />
      </ContentCard>
      {canEdit && (
        <ContentCard>
          <HeaderBar
            isSmallScreen={isSmallScreen}
            debounceAmount={0}
            placeholder={isSmallScreen ? 'Name' : 'Rating Name'}
            onChange={({ target }) => setFileName(target.value)}
            value={fileName}
            icon="save"
            iconSize={30}
            color={theme.colors.primary}
            iconOnPress={handleSave}
            loading={showSpinner}
          />
        </ContentCard>
      )}
    </ContentMain>
  )
}
