import { media } from '@app/ui'
import theme from '@app/ui/theme'
import styled from 'styled-components'

interface HeaderContainerProps {
  center?: boolean
}

export const HeaderContainer = styled.div<HeaderContainerProps>`
  background-color: ${theme.colors.white};
  box-shadow: 0 0px 14px 0 ${theme.colors.dark1};
  border-radius: 4px;
  display: flex;
  flex: 1;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  align-items: center;
  margin-top: 24px;
  margin-bottom: 11px;
  margin-left: 20px;
  margin-right: 20px;
  elevation: 999;
  height: 70px;

  & > img {
    margin-left: 25px;
    height: 70px;
  }
`

interface FilterCardProps {
  width?: number | string
  lessMargin?: boolean
}

export const FilterCard = styled.div<FilterCardProps>`
  box-shadow: 0 0px 4px 0 ${theme.colors.dark1};
  transition: 0.3s;
  width: ${(props) => props.width ?? 200}px;
  height: 40px;
  display: flex;
  flex: 1;
  margin-left: ${(props) => (props.lessMargin ? '30px' : '80px')};
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: 5px;
  max-width: 528px;
  ${media.tablet} {
    width: 100%;
    max-width: 100%;
  }
  & > input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    height: 40px;
    width: 240px;
    text-align: left;
    margin-left: 24px;
    font-size: 16px;
    font-weight: 500;
    align-self: flex-start;
    background-color: transparent;
  }
  & > input::placeholder {
    color: #333;
  }
`

export const EvaluationCard = styled.div<FilterCardProps>`
  box-shadow: 0 0px 4px 0 ${theme.colors.dark1};
  transition: 0.3s;
  width: ${(props) => props.width ?? 200}px;
  height: 80px;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.colors.white};
  border-radius: 21px;
  max-width: 340px;
  ${media.tablet} {
    width: 100%;
    max-width: 100%;
  }
  & > input {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    height: 40px;
    width: 240px;
    text-align: left;
    padding-left: 5px;
    font-size: 16px;
    font-weight: 500;
    align-self: flex-start;
    background-color: transparent;
  }
  & > input::placeholder {
    color: #333;
  }
`

export const EvaluationCardIcon = styled.div`
  width: 50px;
`

export const CardIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FilterCardIcon = styled.div`
  margin-right: 24px;
`

export const FilterCardIconSearch = styled.div`
  margin-right: 24px;
`

export const RatingNameInput = styled(EvaluationCard)<{
  width?: string | number
}>`
  display: flex;
  justify-content: flex-start;
  height: 40px;
  border-radius: ${theme.borderRadius}px;
  border: 1px solid ${theme.colors.dark1};
  box-shadow: none;
  max-width: 420px;
`

export const RatingNameButton = styled.button`
  height: 40px;
  background-color: ${theme.colors.secondary2};
  border-radius: ${theme.borderRadius}px;
  border: 1px solid ${theme.colors.secondary2};
  color: ${theme.colors.white};
  max-width: 420px;
  width: 100%;
  cursor: pointer;
`

export const RatingInputSave = styled.div`
  width: 100%;
`
