import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import { AdmFactorTextContainer } from '../../components/AdjustmentFactors.styles'
import React from 'react'
import { getAdjustmentValue } from '@app/evaluation-core'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

const FAIRWAY_FACTORS: AdmFactorGroup[] = [
  {
    factors: [
      {
        label: 'L*',
        itemKey: 'fwy_layup',
        format: 'shot',
        items: [
          {
            disabled: true,
            value: -1,
            text: `If the player LAYS UP (forced or by choice) or hits less than a full shot due to dogleg.`,
          },
        ],
      },
      {
        label: 'V*',
        itemKey: 'fwy_visible',
        format: 'shot',
        items: [
          {
            value: 1,
            text: `If none of the landing zone is VISIBLE and it is difficult to determine the line of play.`,
          },
        ],
      },
      {
        label: 'W*',
        itemKey: 'fwy_widthAdj',
        format: 'shot',
        showOr: true,
        shouldRender: (adjustments, view) =>
          (getAdjustmentValue(
            'shot',
            view,
            'fairwayWidth',
            adjustments
          ) as number) >= 20,
        items: [
          {
            excludedKeys: ['fwy_bounceBack'],
            value: 1,
            renderText: () => (
              <AdmFactorTextContainer>
                If the fairway <span style={{ fontWeight: 800 }}>WIDTH</span> is
                effectively reduced by:
                <ul style={{ marginLeft: 20 }}>
                  <li>a dogleg;</li>
                  <li>overhanging tree branches in the landing zone;</li>
                  <li>
                    contour or tilt so that the shot must be played to one side:
                    or
                  </li>
                  <li>
                    severe obstacles (dense trees, penalty area, OB, etc.)
                    closely border the fairway landing zone.
                  </li>
                </ul>
              </AdmFactorTextContainer>
            ),
          },
          {
            value: 2,
            excludedKeys: ['fwy_bounceBack'],

            renderText: () => (
              <AdmFactorTextContainer>
                If the fairway <span style={{ fontWeight: 800 }}>WIDTH</span> is
                effectively reduced because a majority of the fairway landing
                zone is titled and balls are likely to end up in the rough.
              </AdmFactorTextContainer>
            ),
          },
        ],
      },
      {
        label: 'W*',
        itemKey: 'fwy_bounceBack',
        format: 'shot',
        shouldRender: (adjustments, view) =>
          (getAdjustmentValue(
            'shot',
            view,
            'fairwayWidth',
            adjustments
          ) as number) < 20,
        items: [
          {
            excludedKeys: ['fwy_widthAdj', 'fwy_widthAdj2'],
            value: -1,
            text: `If WIDTH is effectively increased by hillsides or mounding closely bordering the fairway and balls will bounce back into the fairway.`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'fwy_bounceBack',
        format: 'shot',
        shouldRender: (adjustments, view) =>
          (getAdjustmentValue(
            'shot',
            view,
            'fairwayWidth',
            adjustments
          ) as number) >= 20,
        items: [
          {
            excludedKeys: ['fwy_widthAdj', 'fwy_widthAdj2'],
            value: -1,
            text: `If WIDTH is effectively increased by hillsides or mounding closely bordering the fairway and balls will bounce back into the fairway.`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'fwy_narrow',
        format: 'shot',
        showOr: true,
        items: [
          {
            value: -1,
            text: `If there is a narrow landing zone WIDTH (causing a high table value) where shots can be hit from the rough on one side as easily as those from the fairway.`,
          },
          {
            value: -2,
            text: `If there is a narrow landing zone WIDTH (causing a high table value) where shots can be hit from the rough on both sides of the fairway as rasily as those from the fairway.`,
          },
        ],
      },

      {
        label: 'U*',
        itemKey: 'fwy_unpleasant',
        format: 'shot',
        items: [
          {
            value: 1,
            text: `IF UNPLEASANT lies are caused by poor turn conditions. Do not use if preferred lies are in effect during the midseason.`,
          },
        ],
      },
    ],
  },
]

export const Fairway = () => {
  const adjustments = useAdjustment()
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  const intermediateCut = getAdjustmentValue(
    'course',
    view,
    'intermediate_cut',
    adjustments
  )

  const par = getAdjustmentValue('hole', view, 'Par', adjustments)

  const teeNumber = evaluation.holes[view?.hole].tees[view.tee].teeNumber

  return (
    <Adjustment
      useLz={true}
      factors={FAIRWAY_FACTORS}
      parameters={[
        {
          label: 'Fairway Width',
          secondaryLabel: `intermediate cut: ${intermediateCut}`,
          adjustmentKey: 'fairwayWidth',
          format: 'shot',
          unit: 'yards',
        },
        {
          label: 'Hole Length',
          type: 'numeric-input',
          adjustmentKey: `tee${teeNumber}Length`,
          inputDisabled: true,
          notExpandible: true,
          format: 'hole',
          unit: 'yards',
        },
        {
          label: 'Fairway Rating',
          type: 'table-value',
          inputDisabled: true,
          adjustmentKey: 'fwy_rating',
          format: 'shot',
        },
      ]}
      disabled={par === 3}
      disabledText="Fairway values are not applicable to par 3 hole"
    />
  )
}
