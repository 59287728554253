import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  overflow-y: scroll;
`

export const ContentCard = styled.div`
  margin: 20px;
  box-shadow: 0 0px 4px 0 ${({ theme }) => theme.colors.dark1};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  border-radius: 4px;
  overflow: hidden;
`

export const ContentHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: 460;
  letter-spacing: 0.4px;
  color: #242c2b;
`

export const SubHeading = styled.h4`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #242c2b;
`

export const ContentSeparator = styled.div`
  margin-top: 14px;
  margin-bottom: 14px;
  height: 1px;
  background-color: ${theme.colors.light};
`

export const EvaluationStatusText = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  padding-left: 10px;
`

export const EvaluationStatusDescription = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
`
