import React, { useState } from 'react'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import {
  AdminTitle,
  DropDownMenuItem,
} from '@app/ui/dropdown-menu/DropDownMenu.styles'
import { FormExGenderBtn } from '@app/evaluation-settings-menu/HeaderArea.styles'
import { TeamRole } from '@app/evaluation-core/types'
import theme from '@app/ui/theme'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import useReadOnly from '../hooks/useReadOnly'
import { FaSync, FaSave } from 'react-icons/fa'
import { resetCRData, resetHole } from '@app/evaluation-core'
import { resetModifications } from '@app/evaluation-core/modifications'
import TwoButtonModal from '@app/components/organisms/TwoButtonModal'
import { Divider } from './Evaluation.styles'

interface EvaluationActionMenuProps {
  userId: string
}

export const EvaluationActionMenu = ({ userId }: EvaluationActionMenuProps) => {
  const evalUser = useEvaluationUser()
  const { currentId, evaluation } = useEvaluation()
  const isOwnUser = userId === evalUser?.userId?.toString()
  const handleReadOnly = useReadOnly()
  const users = Object.values(evaluation.users)
  const [resetCRDataIsVisible, setResetCRDataIsVisible] = useState(false)
  const [resetCRDataOnAllHolesIsVisible, setResetCRDataOnAllHolesIsVisible] =
    useState(false)
  const [resetHoleIsVisible, setResetHoleIsVisible] = useState(false)
  const [resetHoleOnAllHolesIsVisible, setResetHoleOnAllHolesIsVisible] =
    useState(false)

  const teamLeaders = users
    .filter((user) => user.role === TeamRole.LEADER)
    .map((user) => user.userId)

  const systemAdmins = users
    .filter((user) => user.role === TeamRole.SYSTEM_ADMIN)
    .map((user) => user.userId)

  const isAuthenticatedSystemAdmin = evalUser?.isAdmin
  const isSystemAdmin = systemAdmins?.includes(userId)
  const isTeamLeader = teamLeaders.includes(userId)

  if (!currentId || !evalUser) return null

  const handleResetHole = () => {
    if (!currentId) return
    resetHole(evalUser.view.hole, currentId)
    resetModifications({ evaluationId: currentId, hole: evalUser.view?.hole })

    setResetHoleIsVisible(false)
  }

  const handleResetHoleOnAllHoles = () => {
    if (!currentId) return
    evaluation?.holes.forEach((_, index) => {
      resetHole(index, currentId)
      resetModifications({ evaluationId: currentId, hole: index })
    })

    setResetHoleOnAllHolesIsVisible(false)
  }

  const handleResetCRData = () => {
    if (!currentId) return
    resetCRData(currentId, evalUser.view.hole)

    setResetCRDataIsVisible(false)
  }

  const handleResetCRDataOnAllHoles = () => {
    if (!currentId) return
    evaluation?.holes.forEach((_, index) => {
      resetCRData(currentId, index)
    })

    setResetCRDataOnAllHolesIsVisible(false)
  }

  return (
    <>
      <DropDownMenu
        style={{ marginLeft: '10px' }}
        isMenu={true}
        useShadow={true}
        width={260}
        relative
        xAdj={10}
        disabledState={{
          disabled: !isAuthenticatedSystemAdmin,
          message: 'Only system admins can access this menu.',
        }}
      >
        {false && isAuthenticatedSystemAdmin && (
          <>
            <DropDownMenuItem
              onPress={() => {
                return
              }}
              disabled={!isAuthenticatedSystemAdmin}
            >
              <FormExGenderBtn>
                <span>Save snapshot to storage</span>
                <div>
                  <FaSave size={20} color={theme.colors.primary} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
          </>
        )}
        {isAuthenticatedSystemAdmin && (
          <>
            <AdminTitle>Admin functions</AdminTitle>
            <Divider />
            <DropDownMenuItem
              onPress={() => setResetHoleIsVisible(true)}
              disabled={!isAuthenticatedSystemAdmin}
            >
              <FormExGenderBtn>
                <span>Reset adjustments</span>
                <div>
                  <FaSync size={20} color={theme.colors.primary} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
            <DropDownMenuItem
              onPress={() => setResetHoleOnAllHolesIsVisible(true)}
              disabled={!isAuthenticatedSystemAdmin}
            >
              <FormExGenderBtn>
                <span>Reset adjustments x {evaluation.holeIds?.length}</span>
                <div>
                  <FaSync size={20} color={theme.colors.primary} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
          </>
        )}
        {isAuthenticatedSystemAdmin && (
          <>
            <Divider />
            <DropDownMenuItem
              onPress={() => setResetCRDataIsVisible(true)}
              disabled={!isAuthenticatedSystemAdmin}
            >
              <FormExGenderBtn>
                <span>Reset crdata</span>
                <div>
                  <FaSync size={20} color={theme.colors.primary} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>

            <DropDownMenuItem
              onPress={() => setResetCRDataOnAllHolesIsVisible(true)}
              disabled={!isAuthenticatedSystemAdmin}
            >
              <FormExGenderBtn>
                <span>Reset crdata x {evaluation.holeIds?.length}</span>
                <div>
                  <FaSync size={20} color={theme.colors.primary} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
          </>
        )}
      </DropDownMenu>

      <TwoButtonModal
        isVisible={resetHoleIsVisible}
        title="Reset modifications?"
        description="This will reset all adjustments and greenmarks on the CURRENT hole."
        onPressRight={handleResetHole}
        onPressLeft={() => setResetHoleIsVisible(false)}
        rightButtonText="Reset hole"
        leftButtonText="Cancel"
        destructive
      />

      <TwoButtonModal
        isVisible={resetHoleOnAllHolesIsVisible}
        title="Reset modifications?"
        description="This will reset all adjustments and greenmarks on ALL holes."
        onPressRight={handleResetHoleOnAllHoles}
        onPressLeft={() => setResetHoleOnAllHolesIsVisible(false)}
        rightButtonText="Reset all holes"
        leftButtonText="Cancel"
        destructive
      />

      <TwoButtonModal
        isVisible={resetCRDataIsVisible}
        title="Are you sure you want to reset CRData for the hole?"
        description="This will refresh the latest data from LiveCR on the CURRENT hole."
        onPressRight={handleResetCRData}
        onPressLeft={() => setResetCRDataIsVisible(false)}
        rightButtonText="Reset CRData"
        leftButtonText="Cancel"
        destructive
      />

      <TwoButtonModal
        isVisible={resetCRDataOnAllHolesIsVisible}
        title="Are you sure you want to reset CRData on all holes?"
        description="This will refresh the latest data from LiveCR on ALL holes."
        onPressRight={handleResetCRDataOnAllHoles}
        onPressLeft={() => setResetCRDataOnAllHolesIsVisible(false)}
        rightButtonText="Reset CRData all holes"
        leftButtonText="Cancel"
        destructive
      />
    </>
  )
}
