import theme from '@app/ui/theme'
import styled from 'styled-components'
import S from 'styled-components/native'

interface ButtonContainerProps {
  width?: number | string
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
  padding-left: 18px;
  padding-right: 18px;
  background-color: ${theme.colors.primary};
  color: black;
  overflow: hidden;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  elevation: 20;
  &:hover {
    opacity: 0.6;
  }
  &:active {
    opacity: 0.6;
  }

  ${(props) =>
    props.width &&
    `
  min-width: ${props.width}px;
  width: ${props.width}px;
 `}
`

export const ButtonText = S.Text`
  line-height: 40px;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  color: ${theme.colors.light2};
  text-align: center;
`
