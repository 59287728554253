import { useEvaluation } from '@common/hooks'
import React from 'react'

import * as S from './styled'
import { isColorDark } from '../../utils'
import { ContentCard } from '../../../Preparation.styles'
import {
  getGenderFromGolferType,
  setIncludedInSlopeTable,
} from '@app/evaluation-core'
import { GolferShortName } from '@app/evaluation-core/types'
import { MITee } from '@app/evaluation-core/types'
import { Gender } from '@app/evaluation-core/types'

const TeeSelector: React.FC = () => {
  const { evaluation } = useEvaluation()

  if (!evaluation) return null

  const tees = evaluation.holes[0].tees

  const playerIsPlayingOnTee = (gender: Gender, tee: MITee) => {
    return tee.golfers.some((player) =>
      player.includes(gender.toLocaleUpperCase())
    )
  }

  const getFontColor = (color: string | undefined) => {
    if (!color) return '#000'
    return isColorDark(color) ? '#fff' : '#000'
  }

  const getBGColor = (color: string | undefined) => {
    if (!color) return '#fff'
    return `#${color}`
  }

  const setTeeIsInSlopeTable = (
    tee: number,
    include: boolean,
    gender: 'men' | 'women'
  ) => setIncludedInSlopeTable(tee, include, gender)

  return (
    <ContentCard>
      <S.Header>Select Tees for Slope Table</S.Header>
      <S.TeeContainer>
        {tees.map((tee) => (
          <S.Tee key={tee.name}>
            <S.TeeHeader
              bgColor={getBGColor(tee?.color)}
              style={{ display: 'flex', flex: 1, justifyContent: 'center' }}
            >
              <S.TeeName color={getFontColor(tee?.color)}>{tee.name}</S.TeeName>
            </S.TeeHeader>
            <S.GendersContainer>
              {playerIsPlayingOnTee(Gender.MEN, tee) && (
                <S.Gender>
                  <label htmlFor="menCheckbox">Men</label>
                  <input
                    type="checkbox"
                    id="menCheckbox"
                    defaultChecked={!tee.includedInSlopeTable?.men}
                    onChange={(e) =>
                      setTeeIsInSlopeTable(
                        tee.teeIndex,
                        e.target.checked,
                        'men'
                      )
                    }
                  />
                </S.Gender>
              )}
              {playerIsPlayingOnTee(Gender.WOMEN, tee) && (
                <S.Gender>
                  <label htmlFor="womenCheckbox">Women</label>
                  <input
                    type="checkbox"
                    id="womenCheckbox"
                    defaultChecked={!tee.includedInSlopeTable?.women}
                    onChange={(e) =>
                      setTeeIsInSlopeTable(
                        tee.teeIndex,
                        e.target.checked,
                        'women'
                      )
                    }
                  />
                </S.Gender>
              )}
            </S.GendersContainer>
          </S.Tee>
        ))}
      </S.TeeContainer>
    </ContentCard>
  )
}

export default TeeSelector
