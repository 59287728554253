import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${theme.colors.background};
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`

export const MainContainer = styled.div`
  flex: 1;
  font-family: 'Roboto', sans-serif;
  margin: 40px 24px 0px 24px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  background-color: ${theme.colors.white};
  border-radius: 4px;
  overflow: hidden;
  padding: 29px 40px 16px 40px;
`

export const TopContainer = styled.div`
  & > * {
    align-items: center;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.span`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: ${theme.colors.black};

  padding: 0;
`

export const HeaderButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
export const Line = styled.div`
  border-bottom: 2px solid rgba(196, 196, 196, 0.21);
  margin-top: 35px;
  margin-left: -40px;
  margin-right: -40px;
  padding: 0px;
  width: calc(100%+80);
  height: 2px;
`

export const MiddleContainer = styled.div`
  display: flex;
  height: 71px;
  & > * {
    display: flex;
    flex-direction: column;
  }
`

export const Gender = styled.div``

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const Course = styled.div``

export const Par = styled.div``

export const Label = styled.span`
  font-size: 16px;
  font-weight: 400;
`
export const Input = styled.input`
  padding: 0;
  border: 1px solid ${theme.colors.color13};
  border-radius: 7px;
  width: 300px;
  height: 40px;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  align-self: flex-start;
  background-color: transparent;
  box-sizing: border-box;
  text-indent: 16px;
  justify-content: space-around;

  & :focus {
    outline: none;
    border: 1px solid ${theme.colors.primary};
  }
  & ::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #a5a5a5;
  }
`

export const BottomContainer = styled.div`
  height: 656px;
`

export const TeeContainer = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
`
export const AddTeeCard = styled.div`
  width: 224px;
  height: 147px;
  background-color: ${theme.colors.white};
  border: 2px dashed ${theme.colors.color13};
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  cursor: pointer;
`

export const TeeAddButton = styled.div`
  background-color: ${theme.colors.secondary2};
  width: 67px;
  height: 67px;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: auto;
  & > span {
    font-size: 40px;
    font-weight: 500;
    color: ${theme.colors.white};
  }
`
export const TeeButtonTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  margin: auto;
  color: #282828;
  text-align: center;
`
