import { Golfer } from '@app/evaluation-core/types'
import { State } from '@app/state/types'
import { useEvaluation } from '@common/hooks'
import { useSelector } from 'react-redux'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'

import { useSnackbar } from '../../app/snackbar'

export default () => {
  const { adjustments } = useSelector((state: State) => ({
    adjustments: state.adjustments,
  }))
  return adjustments
}
