import { RATING_URL, getPageForAdjustment } from '../domain/ratingManualConfig'

import React from 'react'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { useEvaluation } from '@common/hooks'

type Props = {
  type: string
}

export const AdjustmentInfoButton: React.FC<Props> = ({ type }) => {
  const { view } = useEvaluationUser()
  const { evaluation } = useEvaluation()
  const openInfo = () => {
    const page = getPageForAdjustment(
      view?.adjustment,
      type,
      evaluation?.manualVersion
    )
    const url = RATING_URL(page, type, evaluation?.manualVersion)

    window.open(url, '_blank')
  }
  return type === 'guide' ? (
    <RoundedButton title="Guide" onPress={openInfo} style={{ width: '80px' }} />
  ) : (
    <RoundedButton
      title="Manual"
      onPress={openInfo}
      style={{ width: '80px' }}
    />
  )
}
