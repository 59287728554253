import Spacer from '@app/components/atoms/Spacer'
import getEnv from '@app/environments/getEnv'
import { updateEvaluationDate } from '@app/evaluation-core/evaluation'
import { Eval, Gender, Golfer, MITee } from '@app/evaluation-core/types'
import { useSnackbar } from '@app/snackbar'
import theme from '@app/ui/theme'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import useUser from '@common/hooks/useUser'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import FoundationIcon from '@ovaeasy/react-native-vector-icons/Foundation'
import firebase from 'firebase/app'
import React, { useEffect, useState, useCallback } from 'react'
import { ScrollView, Text, TouchableOpacity, View } from 'react-native'
import {
  CourseLengthErrorContainer,
  CourseLengthWarningContainer,
  FormExGenderBtn,
  InnerTeeContianer,
  MenuDiv,
  MenuItem,
  TeeButton,
  TeeContainer,
  TitleText,
} from './styled'

type FormURLObject = {
  evaluation: Eval
  genderSuffix: string
  tee: string
  courseId?: number
  clubId?: number
  golfer?: Golfer
  date?: number
}

type ExportFormItem = {
  title: string
  formUrl: ({
    evaluation,
    genderSuffix,
    tee,
    courseId,
    golfer,
    date,
    clubId,
  }: FormURLObject) => string
}

const BASE_URL_TMP = getEnv().api + '/ratings'
const MIN_COURSE_LENGTH_2024 = 1500

const getMinCourselength = (manualVersion?: string, holes?: number) => {
  if (!manualVersion || !holes) return undefined
  if (manualVersion === '2020') {
    switch (holes) {
      case 9:
        return 1500
      case 18:
        return 3000
      default:
        return undefined
    }
  }

  if (manualVersion === '2024') {
    switch (holes) {
      case 9:
        return 750
      case 18:
        return 1500
      default:
        return undefined
    }
  }
}

const getBaseURL = (manualVersion: string): string => {
  let ret = BASE_URL_TMP
  if (manualVersion !== '2020') ret += '/v2'
  return ret
}

const getExportFormItems = (
  evaluation: Eval,
  manualVersion: string
): ExportFormItem[] => {
  if (manualVersion === '2020')
    return [
      {
        title: 'Form 1-1',
        formUrl: ({ evaluation: Eval, genderSuffix, tee, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/1/1/${
            evaluation.id
          }/${clubId}/${tee}/${genderSuffix}/`,
      },
      {
        title: 'Form 1-2',
        formUrl: ({ evaluation: Eval, genderSuffix, tee, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/1/2/${
            evaluation.id
          }/${clubId}/${tee}/${genderSuffix}/`,
      },
      {
        title: 'Form 2',
        formUrl: ({ evaluation: Eval, genderSuffix, tee, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/2/${
            evaluation.id
          }/${clubId}/${tee}/${genderSuffix}/`,
      },
      {
        title: 'Form 3',
        formUrl: ({ evaluation: Eval, genderSuffix, tee, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/3/${
            evaluation.id
          }/${clubId}/${tee}/${genderSuffix}/`,
      },
      {
        title: 'Slope Tables',
        formUrl: ({
          evaluation: Eval,
          genderSuffix,
          tee,
          courseId,
          date,
          clubId,
        }) =>
          `${getBaseURL(manualVersion)}/forms/slopetables/${
            evaluation.id
          }/${genderSuffix}/?clubId=${clubId}&date=${date}`,
      },
    ]
  else {
    return [
      {
        title: 'Form 1-1',
        formUrl: ({ evaluation: Eval, genderSuffix, tee }) =>
          `${getBaseURL(manualVersion)}/forms/1/1/${
            evaluation.id
          }/${tee}/${genderSuffix}/?holeIds=${evaluation.holeIds}`,
      },
      {
        title: 'Form 1-2',
        formUrl: ({ evaluation: Eval, genderSuffix, tee }) =>
          `${getBaseURL(manualVersion)}/forms/1/2/${
            evaluation.id
          }/${tee}/${genderSuffix}/?holeIds=${evaluation.holeIds}`,
      },
      {
        title: 'Form 2',
        formUrl: ({ evaluation: Eval, genderSuffix, tee }) =>
          `${getBaseURL(manualVersion)}/forms/2/${
            evaluation.id
          }/${tee}/${genderSuffix}/?holeIds=${evaluation.holeIds}`,
      },
      {
        title: 'Form 3',
        formUrl: ({ evaluation: Eval, genderSuffix, tee }) =>
          `${getBaseURL(manualVersion)}/forms/3/${
            evaluation.id
          }/${tee}/${genderSuffix}/?holeIds=${evaluation.holeIds}`,
      },
      {
        title: 'Slope Tables',
        formUrl: ({ evaluation, genderSuffix, tee, courseId, date, clubId }) =>
          `${getBaseURL(manualVersion)}/forms/slopetables/${
            evaluation.id
          }/${genderSuffix}/?clubId=${clubId}&date=${date}&holeIds=${
            evaluation.holeIds
          }`,
      },
    ]
  }
}

const FormItem = ({
  item,
  tee,
  indexOfTee,
  startDate,
  disabled = false,
}: {
  item: ExportFormItem
  tee: MITee
  indexOfTee: number
  startDate: Date
  disabled?: boolean
}) => {
  const [openSnackbar] = useSnackbar()
  const { currentId, courseId, evaluation } = useEvaluation()
  const checkForValues = (gender: Gender) => {
    return tee.golfers.some((item) => item.includes(gender))
  }

  if (item.title === 'Slope Tables') {
    return (
      <MenuItem>
        <FormExGenderBtn>
          <span style={{ fontWeight: 'bold', fontSize: 20 }}>{item.title}</span>
          <div style={{ display: 'flex', opacity: disabled ? '60%' : '100%' }}>
            <TouchableOpacity
              disabled={disabled}
              onPress={() =>
                window.open(
                  item.formUrl({
                    evaluation: evaluation,
                    genderSuffix: Gender.MEN,
                    tee: '0',
                    courseId,
                    date: startDate.getTime(),
                    clubId: +evaluation.clubId,
                  }),
                  '_blank'
                )
              }
            >
              <FoundationIcon name="male-symbol" size={40} color={'#000'} />
            </TouchableOpacity>
            <Spacer width={15} />
            <TouchableOpacity
              disabled={disabled}
              onPress={() =>
                window.open(
                  item.formUrl({
                    evaluation: evaluation,
                    genderSuffix: Gender.WOMEN,
                    tee: '0',
                    courseId,
                    date: startDate.getTime(),
                    clubId: +evaluation.clubId,
                  }),
                  '_blank'
                )
              }
            >
              <FoundationIcon name="female-symbol" size={40} color={'#000'} />
            </TouchableOpacity>
          </div>
        </FormExGenderBtn>
      </MenuItem>
    )
  }

  return (
    <MenuItem disabled={disabled}>
      <FormExGenderBtn>
        <TitleText>{item.title}</TitleText>
        <div
          style={{
            display: 'flex',
          }}
        >
          <TouchableOpacity
            onPress={() => {
              if (disabled) {
                openSnackbar(
                  'The combined length of this tee is less then 1500yd, please move tees in LiveCR'
                )
                return
              }

              if (checkForValues(Gender.MEN)) {
                window.open(
                  item.formUrl({
                    evaluation: evaluation,
                    genderSuffix: Gender.MEN,
                    tee: indexOfTee.toString(),
                    clubId: +evaluation.clubId,
                  }),
                  '_blank'
                )
              }
            }}
          >
            <FoundationIcon
              name="male-symbol"
              size={40}
              color={checkForValues(Gender.MEN) ? '#000' : '#c4c4c4'}
              style={{ opacity: checkForValues(Gender.MEN) ? 1 : 0.4 }}
            />
          </TouchableOpacity>
          <Spacer width={15} />
          <TouchableOpacity
            onPress={() => {
              if (disabled) {
                openSnackbar(
                  'The combined length of this tee is less then 1500yd, please move tees in LiveCR'
                )
                return
              }
              if (checkForValues(Gender.WOMEN)) {
                window.open(
                  item.formUrl({
                    evaluation: evaluation,
                    genderSuffix: Gender.WOMEN,
                    tee: indexOfTee.toString(),
                    clubId: +evaluation.clubId,
                  }),
                  '_blank'
                )
              }
            }}
          >
            <FoundationIcon
              name="female-symbol"
              size={40}
              color={checkForValues(Gender.WOMEN) ? '#000' : '#c4c4c4'}
              style={{ opacity: checkForValues(Gender.WOMEN) ? 1 : 0.4 }}
            />
          </TouchableOpacity>
        </div>
      </FormExGenderBtn>
    </MenuItem>
  )
}

type TeeDiff = {
  tee: string
  diff: number | null
}

const FormsTab: React.FC = () => {
  const { currentId, evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const user = useUser()
  const [tee, settee] = useState<number>(0)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [showSpinner, setShowSpinner] = useState(false)
  const [invalidCourseLength, setInvalidCourseLength] = useState<
    TeeDiff[] | null
  >(null)

  if (!evaluation || !user?.id || !view || !currentId) return null

  const calculateCourseLength = useCallback(
    (adjustments: any) => {
      const n: TeeDiff[] = []
      const teeMap = new Map<number, number>()
      const teeLength = evaluation.holes[0].tees.length

      if (!adjustments?.adjustment?.holes) {
        return null
      }

      for (let i = 0; i < teeLength; i++) {
        teeMap.set(i + 1, 0)
      }

      for (let i = 0; i < evaluation.holes.length; i++) {
        const hole = evaluation.holes[i]
        const adjHole = adjustments.adjustment.holes[i]
        for (const tee of hole.tees) {
          const key = `tee${tee.teeNumber}Length_HOLE_${i}`
          const len = adjHole[key] ?? 0
          const val = teeMap.get(tee.teeNumber) + len
          teeMap.set(tee.teeNumber, val)
        }
      }

      if (evaluation.holes.length === 9) {
        for (let i = 0; i < teeLength; i++) {
          const val = teeMap.get(i + 1)! * 2
          teeMap.set(i + 1, val)
        }
      }

      for (let i = 0; i < teeLength; i++) {
        const tee = evaluation.holes[0].tees[i]
        const len = teeMap.get(tee.teeNumber)!

        const minLength = getMinCourselength(
          evaluation.manualVersion,
          evaluation.holes.length
        )

        if (!minLength) {
          const divergentTee = {
            tee: tee.name,
            diff: null,
          }
          n.push(divergentTee)
        } else if (len < minLength) {
          const invalidTee = {
            tee: tee.name,
            diff: minLength - len,
          }
          n.push(invalidTee)
        }
      }
      return n
    },
    [evaluation]
  )

  useEffect(() => {
    setStartDate(new Date(evaluation?.evaluationDate || new Date()))
  }, [evaluation?.evaluationDate])

  useEffect(() => {
    handleSaveDate()
  }, [startDate])

  const handleSaveDate = async () => {
    setShowSpinner(true)
    if (startDate) {
      await updateEvaluationDate(currentId, startDate)
    }
    setShowSpinner(false)
  }

  useEffect(() => {
    const db = firebase.database()
    const ref = db.ref(`adjustments/${evaluation.id}`)

    ref.get().then((snapshot) => {
      if (snapshot.exists()) {
        if (invalidCourseLength?.length === 0) {
          const adjustments = snapshot.val()
          const l = calculateCourseLength(adjustments)
          setInvalidCourseLength(l)
        }
      }
    })

    ref.on('value', (snapshot) => {
      if (snapshot.exists() && window.location.pathname.endsWith('Forms')) {
        const adjustments = snapshot.val()
        const l = calculateCourseLength(adjustments)
        setInvalidCourseLength(l)
      }
    })

    return () => {
      ref.off()
      setInvalidCourseLength(null)
    }
  }, [evaluation.id])

  if (evaluation.holes) {
    const manualVersion = evaluation?.manualVersion
      ? evaluation?.manualVersion
      : '2024'
    const tees = evaluation.holes[view?.hole].tees

    const formsToRender = getExportFormItems(evaluation, manualVersion).filter(
      (item) => !(evaluation.holes.length <= 9 && item.title === 'Form 1-2')
    )

    const formIsDisabled = (form: ExportFormItem) => {
      return false
      const toDisable = ['Form 2', 'Form 3']
      return !!(toDisable.includes(form.title) && invalidCourseLength?.length)
    }

    const hasCourseLengthError =
      !!invalidCourseLength?.length && invalidCourseLength.length > 0

    return (
      <ScrollView>
        <Spacer height={16} />
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <TitleText>WHS: {evaluation?.manualVersion}</TitleText>
          {hasCourseLengthError && (
            <>
              {invalidCourseLength.some((item) => item.diff === null) ? (
                <CourseLengthWarningContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <FeatherIcon name="warning" size={20} color={'yellow'} />
                    <span>
                      This course does not have a standard amount of holes (
                      9/18 ) or the course length is not able to be calculated.
                      <strong>
                        This might result in unwanted behavior in the forms or
                        incorrect calculations.
                      </strong>
                    </span>
                  </div>
                </CourseLengthWarningContainer>
              ) : (
                <CourseLengthErrorContainer>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <FeatherIcon
                      name="alert-triangle"
                      size={20}
                      color={'red'}
                    />
                    <span style={{ marginLeft: '10px' }}>
                      Course does not meet the minimum length requirement of
                      1500 yards for a rating:
                    </span>
                  </div>
                  <Spacer width={10} />
                  {invalidCourseLength?.length &&
                    invalidCourseLength.map((item) => (
                      <ul style={{ marginLeft: '12px' }} key={item.tee}>
                        <li style={{ listStyleType: 'disc' }}>
                          {item.tee} is {item.diff} yards short
                        </li>
                        <Spacer width={10} />
                      </ul>
                    ))}
                </CourseLengthErrorContainer>
              )}
            </>
          )}
          <TeeContainer>
            <MenuDiv>
              <TitleText>Tee</TitleText>
              <InnerTeeContianer>
                {tees.map((miTee, i) => (
                  <TeeButton
                    key={i}
                    selected={tee === i}
                    onPress={() => settee(i)}
                  >
                    <span style={{ color: theme.colors.white }}>
                      {miTee.teeNumber}
                    </span>
                  </TeeButton>
                ))}
              </InnerTeeContianer>
            </MenuDiv>
            {formsToRender.map((item: ExportFormItem, index: number) => {
              return (
                <FormItem
                  item={item}
                  key={index}
                  tee={tees[tee]}
                  indexOfTee={tee}
                  startDate={startDate}
                  disabled={formIsDisabled(item)}
                />
              )
            })}
          </TeeContainer>
        </View>
        <Spacer height={8} />
      </ScrollView>
    )
  }

  return null
}

export default FormsTab
