import { EvaluationUserView, GolferShortName } from '@app/evaluation-core/types'

enum Adjustments {
  COURSE = 'course',
  TOPOGRAPHY = 'topography',
  FAIRWAY = 'fairway',
  GREENTARGET = 'greentarget',
  BUNKERS = 'bunkers',
  CROSSINGOBSTACLES = 'crossingobstacles',
  LATERALOBSTACLES = 'lateralobstacles',
  TREES = 'trees',
  RECOVERROUGH = 'recoverrough',
  GREENSURFACE = 'greensurface',
  PSYCHOLOGICAL = 'psychological',
  ROLL = 'roll',
  ELEVATION = 'elevation',
  DOGLEG = 'dogleg',
}

export type NotificationEventType =
  | 'evaluation_error'
  | 'user_evaluation_started'
  | 'user_evaluation_prepared'
  | 'livecr_update'
  | 'user_adjustment_changed'
  | 'evaluation_adjustment_error'

export type EvaluationChangeData = {
  tee: number
  golfer: GolferShortName | 'values'
  shot: number
  factor: string
  adjustment?: string
  newValue?: any
  golferIndex?: number
  holeNumber?: number
}

export type AdjustmentChangeData = {
  holeNumber: number
  tee: number
  golfer: number
  shot: number
  adjustment: number
  newValue?: number
}

export type AdjustmentErrorData = {
  holeNumber: number
  tee: number
  golfer: GolferShortName
  adjustment: number
  shot: number
  error: any
  info?: any
}

// export type NotificationEvent = {
//   key?: string
//   courseId: string
//   evaluationId: string
//   userId: string
//   displayName: string
//   createdAt: number
//   type: NotificationEventType
//   data?: EvaluationChangeData | AdjustmentChangeData | AdjustmentErrorData
// }

export type NotificationEvent = {
  key?: string
  userId: string
  createdAt: number
  type: NotificationEventType
  data?: LiveCRChange
  status: LiveCRNotificationStatus
}

export type LiveCRChange = {
  tee: number
  golfer: GolferShortName
  adjustment: Adjustments
  newValue: string | number
  oldValue: string | number
  context: EvaluationUserView
  key: string
  timestamp: string
}

export enum LiveCRNotificationStatus {
  UNHANDLED = 0,
  REJECTED = 1,
  RESOLVED = 2,
}

export type NotificationsState = Record<string, NotificationEvent>[]

export type NotificationsAction = {
  type: string
  value: NotificationsState
}
