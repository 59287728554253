import theme from '@app/ui/theme'
import styled from 'styled-components'
import styledNative from 'styled-components/native'

export const ModalContainer = styled.div<{ visible: boolean }>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

export const ModalContentContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
`

export const ModalContent = styled.div`
  text-align: center;
  border-radius: 4px;
  border-color: transparent;
  background-color: ${theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  padding: 32px;
`

export const ModalTitle = styledNative.Text`
  font-weight: bold;
  font-size: 16px;
`
