import { AccountType, UserAction, UserGender, UserState } from '../types'

const USER_INITIAL_VALUE: UserState = {
  id: 0,
  forename: '',
  email: '',
  lastname: '',
  handicap: 0,
  golfID: '',
  sessionID: '',
  gender: UserGender.Female,
  clubname: '',
  accountType: AccountType.AppUser,
  activated: '',
  homeClubID: '',
  country: '',
  localization: '',
  privacySetting: '',
  distanceUnit: '',
  distanceTo: '',
  profilePictureFileID: '',
  profilePictureURL: '',
}

class User {
  static reduce(state = USER_INITIAL_VALUE, action: UserAction) {
    if (User[action.type]) {
      return User[action.type](state, action)
    } else {
      return state
    }
  }

  static ['SET_USER'](state: UserState, action: UserAction) {
    const data = { ...state, value: action.value }
    return data.value
  }
  static ['RESET'](state: UserState) {
    return null
  }
}

export default User.reduce
