import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import React from 'react'
import { getAdjustmentValue } from '@app/evaluation-core'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

export type GreenType = 'S' | 'A' | 'C' | 'B' | 'D' | null

enum UseTransitionZone {
  NO = 0,
  YES = 1,
}

export const GREEN_TARGET_FACTORS: AdmFactorGroup[] = [
  {
    factors: [
      {
        label: 'V*',
        itemKey: 'gt_visible',
        format: 'golfer',
        showOr: true,
        items: [
          {
            value: 1,
            text: `If less than half of the green surface is VISIBLE.`,
          },
          {
            value: 2,
            text: `If the green is blind (i.e. the flagstick is not VISIBLE).`,
          },
        ],
      },
      {
        label: 'O*',
        itemKey: 'gt_obstructed',
        format: 'golfer',
        items: [
          {
            value: 1,
            text: `If the approach to the green is OBSTRUCTED by a tree in front of, or overhanging the green.`,
          },
        ],
      },
      {
        label: 'T',
        itemKey: 'tiered',
        format: 'hole',
        saveMode: 'hole',
        modificationMode: 'silent',
        items: [
          {
            value: 1,
            text: `If the green is TIERED (see definition on page 8).`,
          },
        ],
      },
      {
        label: 'F',
        itemKey: 'gt_firm',
        format: 'hole',
        saveMode: 'hole',
        modificationMode: 'silent',
        showOr: true,
        items: [
          {
            value: 1,
            text: `If the green is unusually FIRM. (e.g. a wellstruck shot by scratch players will frequently bounce over the green).`,
          },
          {
            value: -1,
            text: `If the green is unusually SOFT (e.g. shots to the green leave deep pitch marks or even plug).`,
          },
        ],
      },
    ],
  },
]

export const GreenTarget = () => {
  const adjustments = useAdjustment()
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  if (!evaluation) return null

  const greenType = getAdjustmentValue(
    'hole',
    view,
    'greenType',
    adjustments
  ) as GreenType

  const portionPercentage = getAdjustmentValue(
    'hole',
    view,
    'd_portion_percentage',
    adjustments
  ) as number

  const secondPortion = 100 - portionPercentage

  return (
    <Adjustment
      useLz={false}
      factors={GREEN_TARGET_FACTORS}
      parameters={[
        {
          type: 'numeric-input',
          label: 'Effective Diameter of Green',
          adjustmentKey: 'effectiveGreenDiamater',
          format: 'hole',
          inputDisabled: true,
          notExpandible: true,
          unit: 'yards',
        },
        {
          type: 'numeric-input',
          label: 'Green Measurement 1',
          format: 'hole',
          adjustmentKey: 'greenTargetDepth',
          shouldRender: (adjustment, view) => greenType !== 'D',
        },
        {
          type: 'numeric-input',
          label: 'Green Measurement 2',
          format: 'hole',
          adjustmentKey: 'greenTargetWidth',
          shouldRender: (adjustment, view) => greenType !== 'D',
        },
        {
          label: 'Green Type',
          adjustmentKey: 'greenType',
          format: 'hole',
          type: 'select',
          inputDisabled: true,
          selectorValues: ['S', 'B', 'C', 'D'],
          selectorLabels: ['S', 'B', 'C', 'D'],
          notExpandible: true,
        },
        {
          type: 'numeric-input',
          label: 'Green 1 Measurement 1',
          format: 'hole',
          adjustmentKey: 'd_first_greenTargetDepth',
          shouldRender: (adjustment, view) => greenType === 'D',
        },
        {
          type: 'numeric-input',
          label: 'Green 1 Measurement 2',
          format: 'hole',
          adjustmentKey: 'd_first_greenTargetWidth',
          shouldRender: (adjustment, view) => greenType === 'D',
        },
        {
          type: 'numeric-input',
          label: 'Portion of first green area (percent)',
          format: 'hole',
          adjustmentKey: 'd_portion_percentage',
          shouldRender: (adjustment, view) => greenType === 'D',
        },
        {
          type: 'numeric-input',
          label: 'Green 2 Measurement 1',
          format: 'hole',
          adjustmentKey: 'd_second_greenTargetDepth',
          shouldRender: (adjustment, view) => greenType === 'D',
        },
        {
          type: 'numeric-input',
          label: 'Green 2 Measurement 2',
          format: 'hole',
          adjustmentKey: 'd_second_greenTargetWidth',
          shouldRender: (adjustment, view) => greenType === 'D',
        },
        {
          type: 'numeric-input',
          label: 'Portion of second green area (percent)',
          format: 'hole',
          adjustmentKey: 'd_second_portion_percentage',
          inputDisabled: true,
          shouldRender: (adjustment, view) => greenType === 'D',
        },
        {
          type: 'numeric-input',
          label: 'Approach Shot Length',
          adjustmentKey: 'approachShot',
          format: 'golfer',
          inputDisabled: true,
          notExpandible: true,
        },
        {
          type: 'numeric-input',
          label: 'Change in Elevation from the Tee to Green',
          unit: 'feet',
          adjustmentKey: 'measuredElevation',
          format: 'tee',
          inputDisabled: true,
          notExpandible: true,
        },
        {
          adjustmentKey: 'use_transitionZone',
          format: 'golfer',
          type: 'select',
          label: 'Use Transition Zone',
          selectorValues: [UseTransitionZone.NO, UseTransitionZone.YES],
          selectorLabels: ['No', 'Yes'],
          notExpandible: true,
          shouldRender: (adjustments, view) =>
            !!getAdjustmentValue('golfer', view, 'transitionZone', adjustments),
        },
        {
          type: 'numeric-input',
          label: 'Transition Zone',
          inputDisabled: true,
          adjustmentKey: 'transitionZone',
          format: 'golfer',
          notExpandible: true,
          shouldRender: (adjustments, view) =>
            !!getAdjustmentValue(
              'golfer',
              view,
              'transitionZone',
              adjustments
            ) &&
            !!getAdjustmentValue(
              'golfer',
              view,
              'use_transitionZone',
              adjustments
            ),
        },
        {
          type: 'table-value',
          label: 'Table Value',
          inputDisabled: true,
          adjustmentKey: 'gt_rating',
          format: 'golfer',
        },
      ]}
    />
  )
}
