export const sizes = {
  desktop: 1400,
  tablet: 768,
  phone: 576,
}

export const media = {
  desktop: `@media (max-width: ${sizes.desktop}px)`,
  tablet: `@media (max-width: ${sizes.tablet}px)`,
  phone: `@media (max-width: ${sizes.phone}px)`,
}

export const transform: (value: string) => string = (value: string) => `
    -webkit-transform: ${value};
    -moz-transform: ${value};
    transform: ${value};
`

export const animation: (value: string) => string = (value: string) => `
    -webkit-animation: ${value};
    -moz-animation: ${value};
    animation: ${value};
`

export const keyframes: (name: string, value: string) => string = (
  name: string,
  value: string
) => `
    @-webkit-keyframes ${name} {
        ${value}
    }
    @-moz-keyframes ${name} {
        ${value}
    }
    @keyframes ${name} {
        ${value}
    }
`

export const clearfix = `
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
`
