import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AdmSectionSideItem } from '../Adjustment.styles'

const SideItemSkeleton = () => {
  return (
    <AdmSectionSideItem>
      <Skeleton style={{ minWidth: 230, minHeight: 16 }} height="100%" />
      <AdmSectionSideItem>
        <Skeleton
          height={20}
          width="80%"
          style={{
            minWidth: 230,
            minHeight: 16,
          }}
        />
      </AdmSectionSideItem>
    </AdmSectionSideItem>
  )
}

export default SideItemSkeleton
