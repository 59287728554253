import fb from '@app/firebase'
import { getPlayerShotsFromEval } from '..'

import { Eval, Evaluation, GolferShortName, MIShot, MITee } from '../types'

type Position = {
  tee: number
  golfer: GolferShortName
  shot: number
}

export enum Modifications {
  CONFIRMED = 'CONFIRMED',
  EDITED = 'EDITED',
  ADJUSTED = 'ADJUSTED',
  UPDATED = 'UPDATED',
  NONE = 'NONE',
}

export type Modification = {
  [key: number]: {
    [key in GolferShortName]: {
      [key: number]: {
        type: Modifications
      }[]
    }
  }
}

type SaveAdjustment = {
  evaluationId: string
  hole: number
  tee: number
  golfer: GolferShortName
  shot: number
  adjustmentIndex: number
}

type ResetAdjustment = {
  evaluationId: string
  hole?: number
  tee?: number
  golfer?: GolferShortName
  shot?: number
  adjustmentIndex?: number
}

const refKey = (
  hole: number,
  tee: number,
  golfer: GolferShortName,
  shot: number,
  adjustmentIndex: number
) => `${hole}/${tee}/${golfer}/${adjustmentIndex}/${shot}/`

const resetRefKey = (
  hole: number,
  tee: number,
  golfer: GolferShortName,
  adjustmentIndex: number
) => `${hole}/${tee}/${golfer}/${adjustmentIndex}`

const getModificationType = (
  modifications: Modification,
  adjustmentIndex: number,
  position: Position,
  isShot: boolean
) => {
  if (!modifications[position.tee]) {
    return Modifications.NONE
  }
  if (!modifications[position.tee][position.golfer]) {
    return Modifications.NONE
  }
  if (!modifications[position.tee][position.golfer][adjustmentIndex]) {
    return Modifications.NONE
  }

  if (modifications[position.tee][position.golfer][adjustmentIndex]) {
    const shotModifications = Object.values(
      modifications[position.tee][position.golfer][adjustmentIndex]
    )
    if (!shotModifications) {
      return Modifications.NONE
    }

    const hasAdjustedValue = shotModifications
      .filter((item) => item)
      .some((mod) => mod.type !== Modifications.NONE)
    const hasConfirmedValue = shotModifications
      .filter((item) => item)
      .some((mod) => mod.type === Modifications.CONFIRMED)

    return hasConfirmedValue ? Modifications.CONFIRMED : Modifications.ADJUSTED
  }
  if (
    !modifications[position.tee][position.golfer][adjustmentIndex][
      position.shot
    ]
  ) {
    return Modifications.NONE
  }
  if (
    modifications[position.tee][position.golfer][adjustmentIndex][position.shot]
  ) {
    return modifications[position.tee][position.golfer][adjustmentIndex][
      position.shot
    ].type
  }
  return Modifications.NONE
}

export const getModificationPercentage = (
  modifications: Modification,
  adjustmentIndex: number,
  position: Position,
  isLandingZone: boolean,
  isShots: boolean
) => {
  if (!modifications) {
    return 0
  }
  if (!modifications[position.tee]) {
    return 0
  }
  if (!modifications[position.tee][position.golfer]) {
    return 0
  }
  if (!modifications[position.tee][position.golfer][adjustmentIndex]) {
    return 0
  }
  if (modifications[position.tee][position.golfer][adjustmentIndex]) {
    if (!isShots) {
      return 100
    }
    const shots = getPlayerShotsFromEval(position.golfer)
    const adjustmentLength = Object.values(
      modifications[position.tee][position.golfer][adjustmentIndex]
    ).length

    return (adjustmentLength / (shots - (isLandingZone ? 1 : 0))) * 100
  }
  return 0
}

export const getModification = (
  modifications: Modification,
  adjustmentIndex: number,
  position: Position,
  isShot: boolean
): Modifications => {
  if (!modifications) {
    return Modifications.NONE
  }

  const exists = (item) => item || item === 0

  const paramsExist =
    exists(position.tee) &&
    exists(position.golfer) &&
    exists(position.shot) &&
    exists(adjustmentIndex)

  if (paramsExist) {
    const currentModifcation = getModificationType(
      modifications,
      adjustmentIndex,
      position,
      isShot
    )

    return currentModifcation
  }
  return Modifications.NONE
}

export const saveAdjustment = async ({
  evaluationId,
  adjustmentIndex,
  hole,
  tee,
  golfer,
  shot,
}: SaveAdjustment) => {
  const key = `adjustments/${evaluationId}/modifications/${refKey(
    hole,
    tee,
    golfer,
    shot,
    adjustmentIndex
  )}`
  await fb.ref(key).set({
    type: Modifications.CONFIRMED,
  })
}

export const adjustAdjustment = async ({
  evaluationId,
  adjustmentIndex,
  hole,
  tee,
  golfer,
  shot,
}: SaveAdjustment) => {
  const key = `adjustments/${evaluationId}/modifications/${refKey(
    hole,
    tee,
    golfer,
    shot,
    adjustmentIndex
  )}`

  console.log(`kkkkey ${key}`)
  await fb.ref(key).set({
    type: Modifications.ADJUSTED,
  })
}

export const resetModifications = async ({
  evaluationId,
  adjustmentIndex,
  hole,
  tee,
  golfer,
}: ResetAdjustment) => {
  if (!adjustmentIndex && tee) {
    const key = `adjustments/${evaluationId}/modifications/${hole}/${tee}`
    return await fb.ref(key).remove()
  }
  if (!adjustmentIndex) {
    const key = `adjustments/${evaluationId}/modifications/${hole}`
    return await fb.ref(key).remove()
  }
  const key = `adjustments/${evaluationId}/modifications/${resetRefKey(
    hole as number,
    tee as number,
    golfer as GolferShortName,
    adjustmentIndex as number
  )}`
  return await fb.ref(key).remove()
}
