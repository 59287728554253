import React from 'react'
import { Helmet } from 'react-helmet'

interface DocumentHelmetProps {
  title?: string
  description?: string
}

export const DocumentHelmet = ({ title, description }: DocumentHelmetProps) => (
  <Helmet>
    <meta charSet="utf-8" />
    {title && <title>{title} - Mapping Industries</title>}
    {description && <meta name="description" content={description} />}
  </Helmet>
)
