import React, { useState, FunctionComponent } from 'react'
import { TeeColorEllipse, TeeLengthContainer } from './TeeItem.styles'
import {
  DropDownMenuItem,
  DropDownMenuSubItem,
  DropDownSubMenuContainer,
} from '@app/ui/dropdown-menu/DropDownMenu.styles'
import { FormExGenderBtn } from '../screen/FormsTab/styled'
import { FaChevronRight } from 'react-icons/fa'
import { MITee } from '@app/evaluation-core/types'
import { copyTee } from '@app/evaluation-core'

type OwnProps = {
  evaluationId: string
  currentHoleIndex: number
  currentTeeIndex: number
  tees: MITee[]
  getTeeLengthFn: any
}

type Props = OwnProps

export const CopyTeeSubMenu: FunctionComponent<Props> = ({
  evaluationId,
  currentHoleIndex,
  currentTeeIndex,
  tees,
  getTeeLengthFn,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <DropDownMenuItem onPress={() => setIsOpen(!isOpen)}>
      <FormExGenderBtn>
        <span>Copy all adjustments to</span>
        <div>
          <FaChevronRight size={20} />
        </div>
      </FormExGenderBtn>
      {isOpen && (
        <DropDownSubMenuContainer useShadow={true} rounded={true}>
          {tees.map((tee) => {
            const teeLength = getTeeLengthFn(tee.teeNumber)
            return (
              <DropDownMenuSubItem
                key={tee.id}
                disabled={currentTeeIndex === tee.teeIndex}
                onPress={async () => {
                  await copyTee(
                    evaluationId,
                    currentHoleIndex,
                    currentTeeIndex,
                    tee.teeIndex
                  )
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <TeeColorEllipse color={`#${tee.color!}`} />
                  Tee {tee.teeNumber}: ({tee.name})
                  <TeeLengthContainer>{teeLength}</TeeLengthContainer>
                </div>
              </DropDownMenuSubItem>
            )
          })}
        </DropDownSubMenuContainer>
      )}
    </DropDownMenuItem>
  )
}

export default CopyTeeSubMenu
