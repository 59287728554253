import { useEffect, useState } from 'react'

import { State } from '@app/state/types'
import { useFirestoreConnect } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { LRClub, LRCourse } from '../../types/Clubs'

export const SWEDEN_REGIONS = [
  {
    name: 'Region A',
    clubs: [24, 22, 23, 32, 19],
  },
  {
    name: 'Region B',
    clubs: [29, 26, 18, 28, 31, 33, 19, 17],
  },
  { name: 'Region C', clubs: [27, 34, 30, 16, 13, 15] },
  { name: 'Region D', clubs: [20, 21, 25] },
]

const addRegionToClub = (club: LRClub) => {
  if (club.districtCountry === 'Sweden') {
    return {
      ...club,
      regionName: SWEDEN_REGIONS.find((region) =>
        region.clubs.includes(+club.districtID)
      )?.name,
    }
  }
  return club
}

export const useClubData = () => {
  useFirestoreConnect([
    {
      collection: 'courses',
    },
    { collection: 'clubs', where: ['districtCountry', '==', 'Sweden'] },
  ])

  const data = useSelector((state: State) => ({
    courseDocs: state.firestore.data.courses,
    clubDocs: state.firestore.data.clubs,
    loadingClubs: state.firestore.status.requesting.clubs,
  }))

  const courses: LRCourse[] = data.courseDocs && Object.values(data.courseDocs)
  const clubs: LRClub[] = data.clubDocs && Object.values(data.clubDocs)
  const loadingClubs = data.loadingClubs

  const unmeasuredClubs =
    clubs &&
    courses &&
    clubs.filter((club) =>
      courses.every((course) => course.golfClubID !== club.id)
    )

  const clubsWithCourses: LRClub[] =
    data.clubDocs &&
    data.courseDocs &&
    clubs
      .map((club) => ({
        ...addRegionToClub(club),
        courses: courses
          .filter((course) => course.golfClubID === club.id)
          .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
          .map((course) => {
            if (club.branchStatus) {
              return {
                ...course,
                branchStatus: club.branchStatus,
              }
            }
            return course
          }),
      }))
      .sort((a, b) => a.name.localeCompare(b.name, 'sv'))

  return { courses, clubs, loadingClubs, clubsWithCourses, unmeasuredClubs }
}

export const useLoading = (loadingClubs) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!loadingClubs) {
      setTimeout(() => setLoading(false), 1600)
    }
  }, [loadingClubs])

  return { loading }
}
