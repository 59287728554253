import {
  CourseOptionSelect,
  CourseOptionSelectItem,
} from '@app/evaluation-settings-menu/HeaderArea.styles'
import { getActiveAdjustment, getAdjustmentValue } from '@app/evaluation-core'

import React from 'react'
import useAdjustment from '@common/hooks/useAdjustment'
import useEvaluation from '@common/hooks/useEvaluation'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import useSetAdjustment from '@common/hooks/useSetAdjustment'

interface TweenerProps {
  useLz?: boolean
}

export const Tweener = ({ useLz }: TweenerProps) => {
  const adjustments = useAdjustment()
  const [setAdjustment] = useSetAdjustment()

  const { currentId } = useEvaluation()
  const { view } = useEvaluationUser()

  const currentAdjustment = getActiveAdjustment()
  const adjustmentFormat =
    currentAdjustment?.shots || currentAdjustment?.landingZones
      ? 'shot'
      : 'golfer'

  const value = getAdjustmentValue(
    adjustmentFormat,
    view,
    `${currentAdjustment?.valueKey}_tweener`,
    adjustments
  )

  return (
    <div
      style={{
        width: '160px',
        overflow: 'hidden',
      }}
    >
      <CourseOptionSelect>
        <CourseOptionSelectItem
          selected={value === 1}
          onPress={() =>
            setAdjustment(
              adjustmentFormat,
              view,
              `${currentAdjustment?.valueKey}_tweener`,
              currentId as string,
              value === 1 ? 0 : 1
            )
          }
        >
          +1
        </CourseOptionSelectItem>
        <CourseOptionSelectItem
          selected={value === -1}
          onPress={() =>
            setAdjustment(
              adjustmentFormat,
              view,
              `${currentAdjustment?.valueKey}_tweener`,
              currentId as string,
              value === -1 ? 0 : -1
            )
          }
        >
          -1
        </CourseOptionSelectItem>
      </CourseOptionSelect>
    </div>
  )
}
