import { OverlaySubHeader, OverlaySubHeaderTab } from '../../index.styles'

import React from 'react'
import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'
import { useDispatch } from 'react-redux'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

type Props = {
  amount: number
}

const Shots = ({ amount }: Props) => {
  const dispatch = useDispatch<any>()
  const { view } = useEvaluationUser()

  const shotsArray = new Array(amount).fill(true)

  const isLastIndex = shotsArray.length - 1

  const getTitle = (shotIndex: number) =>
    shotIndex === isLastIndex ? 'Approach Shot' : `Shot ${shotIndex + 1}`

  if (amount === 1) return <></>

  return (
    <OverlaySubHeader>
      {shotsArray.map((shot, shotIndex: number) => (
        <OverlaySubHeaderTab
          key={shotIndex}
          selected={view.shot === shotIndex}
          onClick={() => {
            dispatch(
              setSelectedHole(
                view?.hole,
                view.tee,
                view.golfer,
                view.adjustment,
                shotIndex
              )
            )
          }}
        >
          {getTitle(shotIndex)}
        </OverlaySubHeaderTab>
      ))}
    </OverlaySubHeader>
  )
}

export default Shots
