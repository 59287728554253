import * as types from './actionTypes'
import {
  EvaluationGroup,
  EvaluationState,
  Gender,
  Golfer,
  GolferShortName,
} from '../types'
import {
  FBREF_COURSE_EVALUATION,
  FBREF_HOLE_TEE,
  FBREF_USER_EVALUATION_VIEW,
  ref,
} from '../refs'
import { getUserViewFromState, initAdjustments } from './adjustmentsActions'
import { initialGolfer, initialGroups } from '../initialValues'
import { State } from '@app/state/types'
import firebase from 'firebase'
import { getEvaluationState } from '../evaluation'
import initialState from '@app/state/reducers/initialState'
import { setSelectedShot } from '..'
import store from '@app/state'
import { fetch } from '../../../common/fetch'
import getEnv from '@app/environments/getEnv'

export function setEvaluationAction(value: Partial<EvaluationState>) {
  return { type: types.SET_EVALUATION, value }
}

export type AppDispatch = typeof store.dispatch

export const getGolferShortName = (golferIndex: number) =>
  initialGolfer().find((glfr) => glfr.typeIndex === golferIndex)
    ?.shortName as GolferShortName

export const getGolferTypeFromIndex = (golferIndex: number) =>
  golferIndex % 2 === 0 ? Golfer.SCRATCH : Golfer.BOGEY

export const getGolferPlayerFromShortName = (shortName: GolferShortName) =>
  shortName.includes('S') ? Golfer.SCRATCH : Golfer.BOGEY

export const getGolferGenderFromIndex = (golferIndex: number): Gender =>
  golferIndex > 1 ? Gender.WOMEN : Gender.MEN

export const getGolferGroup = (groupIndex: number) =>
  initialGroups().find(
    (grp) => grp.groupIndex === groupIndex
  ) as EvaluationGroup

export const getShotName = (
  index: number,
  arrayLength: number,
  shorten?: boolean
) => {
  const approachShotText = shorten ? 'Appr.' : 'Approach Shot'
  return index === arrayLength - 1 ? approachShotText : `Shot ${index + 1}`
}

export function getNotificationsHolePath(evaluationId: string) {
  return `adjustments/${evaluationId}/notifications/`
}

interface ActionListeners {
  evaluation?: any
  ref?: any
  notifications?: any
}

let listeners: ActionListeners = {}

export function clearEvaluationAction() {
  return { type: types.SET_EVALUATION, value: initialState.evaluation }
}

export function clearEvaluation() {
  return (dispatch) => {
    if (listeners.evaluation && listeners.evaluation !== null) {
      listeners.ref.off('value', listeners.evaluation)
      listeners = {}
    }

    return dispatch(clearEvaluationAction())
  }
}

export function startEvaluation(value: Partial<EvaluationState>) {
  return (dispatch, getState) => {
    firebase
      .database()
      .ref(`evaluations/${value.currentId}`)
      .once('value', (snapshot) => {
        const state = getState().evaluation
        const evaluation = snapshot.val()
        dispatch(setEvaluation({ ...state, evaluation }))
      })

    const path = `evaluations/${value.currentId}`

    listeners.ref = firebase.database().ref(path)

    listeners.evaluation = listeners.ref.on('value', (snapshot) => {
      const state = getState().evaluation

      if (!snapshot) return

      const evaluation = snapshot.val()

      return dispatch(
        setEvaluation({
          ...state,
          evaluation: { ...state.evaluation, ...evaluation },
        })
      )
    })
  }
}

export function initEvaluation(value: Partial<EvaluationState>): any {
  return (dispatch: any) => {
    dispatch(clearEvaluation())
    dispatch(startEvaluation(value))
    dispatch(setEvaluation(value))
    return dispatch(initAdjustments(true)) as any
  }
}

export function setEvaluation(value: Partial<EvaluationState>) {
  return (dispatch, getState: () => State) => {
    return dispatch(setEvaluationAction(value))
  }
}

export function setSelectedHoleAction(
  hole: number,
  tee?: number,
  golfer?: GolferShortName,
  group?: number,
  shot?: number,

  userId?: string
) {
  return {
    type: types.SET_SELECTED_HOLE,
    hole,
    tee,
    golfer,
    group,
    shot,
    userId,
  }
}

export function setCollapsedTeesAction(tees: number[]) {
  return { type: types.SET_COLLAPSED_TEES, tees }
}

export function setCollapsedTees(tees: number[], userId: string) {
  return (dispatch, getState: () => State) => {
    const { currentId } = getEvaluationState()
    ref(
      `${FBREF_COURSE_EVALUATION(currentId)}/${FBREF_USER_EVALUATION_VIEW(
        userId
      )}/collapsedTees`
    ).set(tees)

    return dispatch(setCollapsedTeesAction(tees))
  }
}

export function addCollapsedTee(tee: number) {
  return { type: types.ADD_COLLAPSED_TEE, tee }
}

export function addACollapsedTee(tee: number, userId: string) {
  return (dispatch, getState: () => State) => {
    const { currentId } = getEvaluationState()
    const view = getUserViewFromState(getState())
    ref(
      `${FBREF_COURSE_EVALUATION(currentId)}/${FBREF_USER_EVALUATION_VIEW(
        userId
      )}/collapsedTees`
    ).set([...(view?.collapsedTees || []), tee])

    return dispatch(addCollapsedTee(tee))
  }
}

export function setUserColorAction(color: string) {
  return { type: types.SET_COLOR, color }
}

export function setUserColor(color: string, userId: string) {
  return (dispatch, getState: () => State) => {
    const { currentId } = getEvaluationState()

    ref(
      `${FBREF_COURSE_EVALUATION(currentId)}/${FBREF_USER_EVALUATION_VIEW(
        userId
      )}/color`
    ).set(color)

    return dispatch(setUserColorAction(color))
  }
}

export function setSelectedHole(
  hole: number,
  tee: number | undefined = undefined,
  golfer: GolferShortName = GolferShortName.ScratchMen,
  group: number | undefined = undefined,
  shot: number | undefined = undefined
) {
  return (dispatch, getState: () => State) => {
    const { currentId, view } = getEvaluationState()
    const userId = store.getState().firebase.auth.uid
    const isHoleChange = hole > -1 && !tee && !golfer && !group && !shot

    setSelectedShot(0)

    ref(
      `${FBREF_COURSE_EVALUATION(
        currentId
      )}/${FBREF_USER_EVALUATION_VIEW()}/hole`
    ).set(hole)

    if (tee !== undefined) {
      if (isHoleChange) {
        ref(
          `${FBREF_COURSE_EVALUATION(
            currentId
          )}/${FBREF_USER_EVALUATION_VIEW()}/tee`
        ).set(0)
      } else {
        ref(
          `${FBREF_COURSE_EVALUATION(
            currentId
          )}/${FBREF_USER_EVALUATION_VIEW()}/tee`
        ).set(tee)
      }
    }

    if (golfer !== undefined) {
      if (isHoleChange) {
        ref(
          `${FBREF_COURSE_EVALUATION(
            currentId
          )}/${FBREF_USER_EVALUATION_VIEW()}/golfer`
        ).set(GolferShortName.ScratchMen)
      } else {
        ref(
          `${FBREF_COURSE_EVALUATION(
            currentId
          )}/${FBREF_USER_EVALUATION_VIEW()}/golfer`
        ).set(golfer)
      }
    }

    if (group !== undefined) {
      const previousAdjustment = view?.adjustment
      if (previousAdjustment !== -1 || isHoleChange) {
        ref(
          `${FBREF_COURSE_EVALUATION(
            currentId
          )}/${FBREF_USER_EVALUATION_VIEW()}/prevAdj`
        ).set(view.adjustment)
      }

      ref(
        `${FBREF_COURSE_EVALUATION(
          currentId
        )}/${FBREF_USER_EVALUATION_VIEW()}/adjustment`
      ).set(group)
    }

    if (shot !== undefined) {
      if (isHoleChange) {
        ref(
          `${FBREF_COURSE_EVALUATION(
            currentId
          )}/${FBREF_USER_EVALUATION_VIEW()}/shot`
        ).set(0)
      } else {
        ref(
          `${FBREF_COURSE_EVALUATION(
            currentId
          )}/${FBREF_USER_EVALUATION_VIEW()}/shot`
        ).set(shot)
      }
    }

    dispatch(setSelectedHoleAction(hole, tee, golfer, group, shot, userId))
    if (view?.hole !== hole) {
      return dispatch(initAdjustments())
    }
  }
}

export function updateCourseName(courseName: string, currentId: string) {
  return ref(`${FBREF_COURSE_EVALUATION(currentId)}/courseName`).set(courseName)
}

export function updateClubName(clubName: string, currentId: string) {
  return ref(`${FBREF_COURSE_EVALUATION(currentId)}/clubName`).set(clubName)
}

export async function sendInvitation(
  evaluationId: string,
  userId: number,
  username: string
) {
  await fetch.post(`${getEnv().api}/evaluations/invitation`, {
    evaluationId,
    inviterId: userId,
    username,
  })
}
