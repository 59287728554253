const parseReactEnv = (definitions) =>
  definitions
    .map((env) => [env[0].replace('REACT_APP_', ''), env[1]])
    .filter((i) => i)

export default () => {
  const env = process.env

  const parsedEnv = parseReactEnv(Object.entries(env))

  const environment = {}

  parsedEnv.forEach((entry) => {
    const key = entry[0]
    const value = entry[1]
    environment[key] = value
  })

  return environment as Env
}

type Env = {
  env: string
  api: string
  USE_EMULATORS?: boolean
  NODE_ENV: string
  VERSION_TAG?: string
}
