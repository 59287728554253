import { user } from '@app/auth/reducers'
import drawer from '@app/components/organisms/Drawer/reducers'
import features from '@app/features/reducers'
import modal from '@app/modal/reducers'
import notifications from '@app/notifications/reducers'
import evaluationReducers from './evaluation'

export default {
  user,
  drawer,
  modal,
  notifications,
  features,
  ...evaluationReducers,
}
