import React, { useState } from 'react'
import theme from '@app/ui/theme'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import sv from 'date-fns/locale/sv'
import { ButtonProps } from 'react-native'
import * as S from './styled'
import CourseScorecard from '../CourseScorecard/CourseScorecard'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { RatingTemplate } from '../../../types/Templates'

registerLocale('sv', sv)

type Props = {
  isVisible: boolean
  disabled?: boolean
  courseId?: number
  branchId?: string
  projectCourse?: any
  holeIds?: string[]
  closeFn?: any
  isComposite?: boolean
  template?: RatingTemplate
}

const CourseScorecardModal: React.FC<Props> = ({
  isVisible = false,
  disabled = false,
  courseId,
  branchId,
  projectCourse = null,
  holeIds,
  closeFn,
  isComposite = false,
  template,
}) => {
  return (
    <S.ModalContainer visible={isVisible}>
      <S.ModalContentContainer>
        <S.ModalContent>
          <CourseScorecard
            holeIds={holeIds}
            courseId={courseId}
            branchId={branchId}
            projectCourse={projectCourse}
            isComposite={isComposite}
            template={template}
          />
          <RoundedButton
            onPress={closeFn}
            title={'Close'}
            testId="btn-start-course"
            style={{
              width: '90%',
              height: '34px',
              marginTop: '24px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          />
        </S.ModalContent>
      </S.ModalContentContainer>
    </S.ModalContainer>
  )
}

export default CourseScorecardModal
