import theme from '@app/ui/theme'
import { FaAngleRight } from 'react-icons/fa'
import styled, { keyframes } from 'styled-components'
import S from 'styled-components/native'

export const TotalText = styled.span`
  font-family: 'Proxima Nova';
  font-size: 20px;
`

export const TotalValue = styled.span`
  font-family: 'Proxima Nova';
  font-size: 24px;
  font-weight: 600;
`

export const AdmFactorGroupContainer = S.View`
    flex: 1;
    max-width: 800px;
    margin: 0 auto;
`

export const AdmFactorContainer = S.View`
    flex-direction: row;
    width: 100%;
    padding-bottom: 30px;
    padding-left: 15px;

`

export const AdmFactorLabel = S.View`
    padding: 10px;
`

export const AdmFactorLabelContainer = S.View`
    width: 80px;
    height: 40px;
`

interface AdmFactorLabelBackgroundProps {
  collapsed: boolean
  expandable?: boolean
}

export const AdmFactorLabelBackground = S.View<AdmFactorLabelBackgroundProps>`
    background-color: ${(props) =>
      props.expandable ? theme.colors.primary : theme.colors.light2};
    width: 100%;
    height: 100%;
    border-radius: 50px;
    justify-content: center;
    align-items: flex-end;
    transform: rotate(${(props) => (props.collapsed ? 0 : 90)}deg);
    transition: all 0.3s ease;
`

export const AdmFactorLabelItem = S.View<{ automated?: boolean }>`
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: -40px;
    cursor: default;
    position: relative;
`

export const AdmFactorLabelItemText = S.Text<{ expandable?: boolean }>`
    color: ${(props) =>
      props.expandable ? theme.colors.white : theme.colors.primary};
        font-weight: 600;
    font-size: 16px;
`

export const AdmFactorLabelIcon = styled(FaAngleRight)`
  color: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  height: 40px;
  margin: 8px;
`

interface AdmFactorButtonsProps {
  collapsed?: boolean
  expandable?: boolean
}

export const AdmFactorButtons = S.View<AdmFactorButtonsProps>`
    flex: 1;
    max-height: ${(props) =>
      props.collapsed && props.expandable ? '140px' : '600px'};
    transition: 0.1s all ease;
    overflow: hidden;
`

export const AdmFactorButtonWrapper = S.View<{ isShotAdjustment?: boolean }>`
    flex-direction: ${({ isShotAdjustment }) =>
      isShotAdjustment ? 'column' : 'row'};
`

export const AdmFactorButton = S.View`
    width: 70px;
    height: 60px;
    padding: 8px;
`

export const AdmFactorTextWrapper = S.View`
    max-height: 200px;
    overflow: hidden;
    height: 100px;
    background-color: #999;
`

export const AdmFactorText = S.View`
    padding: 15px;
    flex-wrap: wrap;
    max-width: 600px;
`

export const AdmFactorTextLabel = S.Text`
    font-size: 15px;
    font-family: 'Proxima Nova';
`

export const AdmFactorTextContainer = styled.div`
  font-size: 15px;
`

export const ButtonHeader = S.Text`
padding-right: 8px;
text-align: center;
`

const breatheAnimation = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
`
export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: ${breatheAnimation};
  animation-duration: 4s;
  animation-iteration-count: infinite;
`
export const LoadingDefaultInputContainer = styled.div<{ isReadOnly: boolean }>`
  width: 60px;
  height: 36px;
  border: 1px solid ${({ theme }) => theme.colors.light3};
  background-color: ${({ theme, isReadOnly }) =>
    isReadOnly ? theme.colors.light4 : theme.colors.light3};
  border-radius: ${theme.borderRadius}px;
  border-color: ${({ theme }) => theme.colors.light4};
  display: flex;
  justify-content: center;
  align-items: center;
`
