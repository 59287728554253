import React, { FunctionComponent } from 'react'
import Spacer from '../Spacer'
import * as S from './styled'

interface ButtonProps {
  onPress: () => void
  holeNr: number
  par: number
  selected: boolean
  ghNr: number
}

const HoleButton: FunctionComponent<ButtonProps> = ({
  onPress,
  holeNr,
  selected,
  ghNr,
  par,
}) => {
  return (
    <S.Container selected={selected} onClick={onPress}>
      <Spacer height={4} />
      <S.HoleText selected={selected}>
        Hole <S.HoleValueText selected={selected}>{holeNr}</S.HoleValueText>
      </S.HoleText>
      <S.GHNText>
        Par: <S.GHNValueText>{par}</S.GHNValueText>
      </S.GHNText>
    </S.Container>
  )
}

export default HoleButton
