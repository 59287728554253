import { RatingHole } from './types'

export const createHoles = (holes: number): RatingHole[] => {
  return Array(holes)
    .fill(null)
    .map((_, index) => ({ ratingHoleNumber: index + 1 }))
}

export const mergeHoleArrays = (array1: RatingHole[], array2: RatingHole[]) => {
  return array1.map((item, index) => ({ ...item, ...array2[index] }))
}
