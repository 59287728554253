import getEnv from '@app/environments/getEnv'
import type * as Axios from 'axios'
import { fetch } from '../../common/fetch'
import { UserState } from '@app/auth/types'
import store from '@app/state'

export const attachInterceptors = (axiosInstance: Axios.AxiosInstance, user) =>
  axiosInstance.interceptors.request.use(async (req) => {
    if (user) {
      req.headers.authorization = getToken('access', user)
      req.headers.refreshtoken = getToken('refresh', user)
    }
    return req
  })

const getToken = (type: 'refresh' | 'access', user: UserState) =>
  type === 'access' ? user?.token : user?.refreshToken

export const apiClient = (path: string) => {
  const api = getEnv().api + path

  const axios = fetch.create({
    baseURL: api,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
  const user = store.getState().user as UserState

  attachInterceptors(axios, user)

  return axios
}
