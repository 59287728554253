import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import React from 'react'

import { ButtonContainer, ButtonText, HSpacing } from './RoundedButton.styles'
import { ActivityIndicator } from 'react-native'
import theme from '../theme'
import { FaChevronUp } from 'react-icons/fa'

interface ButtonProps {
  onPress?: () => void
  title?: string
  style?: React.CSSProperties
  testId?: string
  disabled?: boolean
}

const RoundedCollapseButton = (props: ButtonProps) => {
  return (
    <ButtonContainer
      onClick={props.onPress}
      className={props.testId}
      style={{
        background: 'transparent',
        minWidth: '20px',
      }}
    >
      <>
        <FaChevronUp size={20} color="#000" />
      </>
    </ButtonContainer>
  )
}

export default RoundedCollapseButton
