import { OverlaySubHeader, OverlaySubHeaderTab } from '../../index.styles'

import React, { useEffect, useState } from 'react'
import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'
import { useDispatch } from 'react-redux'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { getPlayerShots } from '@common/hooks/getPlayerShots'

type Props = {
  amount: number
}

const requiredShots = 2

const Topography = ({ amount: amountOfShots }: Props) => {
  const dispatch = useDispatch<any>()
  const { view } = useEvaluationUser()

  /** Topography is not rated on the first shot */
  const shotsArray = new Array(amountOfShots - 1).fill(true)

  const shouldShowShots = amountOfShots >= requiredShots

  useEffect(() => {
    if (amountOfShots > requiredShots && view.shot === 0) {
      dispatch(
        setSelectedHole(view?.hole, view.tee, view.golfer, view.adjustment, 1)
      )
    }
  }, [shouldShowShots])

  const isLastIndex = shotsArray.length - 1

  const topographyOffset = 1
  const indexNormalization = 1

  const getTitle = (shotIndex: number) =>
    shotIndex === isLastIndex
      ? 'Approach Shot'
      : `Shot ${shotIndex + indexNormalization + topographyOffset}`

  if (!shouldShowShots) {
    return <></>
  }

  return (
    <OverlaySubHeader>
      {shotsArray.map((shot, shotIndex: number) => (
        <OverlaySubHeaderTab
          key={shotIndex}
          selected={view.shot === shotIndex + topographyOffset}
          disabled={amountOfShots < 3}
          onClick={() => {
            dispatch(
              setSelectedHole(
                view?.hole,
                view.tee,
                view.golfer,
                view.adjustment,
                shotIndex + topographyOffset
              )
            )
          }}
        >
          {getTitle(shotIndex)}
        </OverlaySubHeaderTab>
      ))}
    </OverlaySubHeader>
  )
}

export default Topography
