import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import React from 'react'
import { getAdjustmentValue } from '@app/evaluation-core'
import { getPlayerShots } from '@common/hooks/getPlayerShots'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

export enum Roll_Slope {
  EXTREME_DH = -4,
  SIGNIFICANT_DH = -3,
  MODERATE_DH = -2,
  MINOR_DH = -1,
  LEVEL = 0,
  MINOR_UH = 1,
  MODERATELY_UH = 2,
  SIGNIFICANT_UH = 3,
  EXTREME_UH = 4,
}

export const Roll_Slope_Labels = [
  'Extreme Slope (+20 or more)',
  'Significant Slope (+15 to +19)',
  'Moderately Slope (+10 to +14)',
  'Minor Slope (+5 to +9)',
  'Level',
  'Minor Slope (-5 to -9)',
  'Moderately Slope (-10 to -14)',
  'Significantly Slope (-15 to -19)',
  'Extreme Slope (-20 or more)',
]

export const ROLL_FACTORS: AdmFactorGroup[] = [
  {
    factors: [
      {
        label: 'F*',
        itemKey: 'roll_firm',
        format: 'golfer',
        showOr: true,
        items: [
          {
            value: 1,
            text: `If SOFT fairway conditions result in excessive loss of roll on the tee shot or if the tee shot landing zone is not cut to fairway height and results in less roll than the assumed 15-25 yards per shot`,
          },
          {
            value: -1,
            text: `If FIRM fairway conditions result in excessive extra roll on the tee shot.`,
          },
        ],
      },
      {
        label: '2*',
        itemKey: 'roll_shot',
        format: 'golfer',

        showOr: true,
        items: [
          {
            value: 1,
            text: `If a subsequent full shot results in less roll than the assumed 15-25 yards per shot.`,
          },
          {
            value: -1,
            text: `If a subsequent full shot results in more roll than the assumed 15-25 yards per shot.`,
          },
        ],
      },
    ],
  },
]

export const Roll = () => {
  const adjustments = useAdjustment()
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()
  const shots = getPlayerShots()
  if (!evaluation) return null
  const par = getAdjustmentValue('hole', view, 'Par', adjustments)

  return (
    <Adjustment
      useLz={false}
      factors={ROLL_FACTORS}
      parameters={[
        {
          type: 'select',
          label: 'Downhill/Uphill',
          inputDisabled: true,
          adjustmentKey: 'roll_rating',
          format: 'golfer',
          selectorValues: [
            Roll_Slope.EXTREME_DH,
            Roll_Slope.SIGNIFICANT_DH,
            Roll_Slope.MODERATE_DH,
            Roll_Slope.MINOR_DH,
            Roll_Slope.LEVEL,
            Roll_Slope.MINOR_UH,
            Roll_Slope.MODERATELY_UH,
            Roll_Slope.SIGNIFICANT_UH,
            Roll_Slope.EXTREME_UH,
          ],
          selectorLabels: Roll_Slope_Labels,
          notExpandible: true,
        },
        {
          type: 'table-value',
          label: 'Tee-Shot Roll Rating',
          inputDisabled: true,
          adjustmentKey: 'roll_rating',
          notExpandible: true,
          format: 'golfer',
        },
      ]}
      disabled={
        par === 3 &&
        (view.golfer.includes('S') ||
          (view.golfer.includes('B') && shots === 1))
      }
      disabledText="The Roll rating on par-3 holes is zero unless the bogey player cannot reach the centre of the green in one shot. In this case, Roll is evaluated for the bogey player only."
    />
  )
}
