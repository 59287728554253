import * as S from './styled'

import React, { FunctionComponent } from 'react'

import theme from '@app/ui/theme'

type Props = {
  accept?: boolean
  reject?: boolean
  onPress: () => void
  text: string
  sameSize?: boolean
  pressed?: boolean
  destructive?: boolean
}

const AcceptRejectButton: FunctionComponent<Props> = ({
  accept,
  reject,
  onPress,
  text,
  sameSize,
  pressed,
  destructive,
}) => {
  return (
    <S.ButtonContainer
      onPress={pressed ? null : onPress}
      backgroundColor={accept ? theme.colors.accept : theme.colors.reject}
      width={sameSize ? 128 : accept ? 128 : 105}
      pressed={pressed}
      destructive={destructive}
    >
      <S.ButtonText color={reject}>{text}</S.ButtonText>
    </S.ButtonContainer>
  )
}

export default AcceptRejectButton
