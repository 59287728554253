import { getAdjustmentValue } from '@app/evaluation-core'
import { CourseSettings, EvaluationUserView } from '@app/evaluation-core/types'
import { getPlayerShots } from '@common/hooks/getPlayerShots'
import { CourseSettingsCamelCase } from '../types/Templates'

type OriginalValueArgs = {
  view: EvaluationUserView
  adjustments: any
  defaultCourseSettings?: CourseSettings | CourseSettingsCamelCase
}

type OriginalValueFunction = (param: OriginalValueArgs) => any

const bogeyGolfers = ['BM', 'BW']
const scratchGolfers = ['SM', 'SW']

export const originalValueFunctions: Record<
  string,
  OriginalValueFunction | (() => any)
> = {
  fairwayWidth: getOriginalFairwayWidth,
  greenTargetDepth: getOriginalGreenTargetDepth,
  greenTargetWidth: getOriginalGreenTargetWidth,
  shotLength: getOriginalShotLengths,
  waterHazardGreenEdgeFractionPercentage:
    getOriginalGreenEdgeFractionPercentage,
  greenElevation: getOriginalTopographyGreenElevation,
  gt_perimeter: getOriginalGreenTargetPerimeter,
  bunkerFractionValue: getOriginalBunkerFractionValue,
  crossingDistance: getOriginalCrossingDistance,
  cross_safely_carry: getOriginalCrossSafelyDistance,
  lateralDistance: getOriginalLateralDistance,
  dogleg: getOriginalDogleg,
  bunk_depth: getOriginalBunkerDepth,
  measuredElevation: getOriginalMeasuredElevation,
  forcedLayup: getOriginalForcedLayup,
  layupByChoice: getLayupByChoice,
  waterHazardGreenEdge: getOriginalWaterHazardGreenEdge,
}

//TODO: Refactor all these functions to be more clean and reuse logic
function getOriginalFairwayWidth({ view, adjustments }: OriginalValueArgs) {
  if (view.shot === 0 && bogeyGolfers.includes(view.golfer)) {
    return getAdjustmentValue('golfer', view, 'Fairway_DriveBogey', adjustments)
  }
  if (view.shot === 0 && scratchGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Fairway_DriveScratch',
      adjustments
    )
  }
  if (view.shot === 1 && bogeyGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Fairway_2ndShotBogey',
      adjustments
    )
  }
  if (view.shot === 1 && scratchGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Fairway_2ndShotScratch',
      adjustments
    )
  }
  if (view.shot === 2 && bogeyGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Fairway_3ndShotBogey',
      adjustments
    )
  }
  if (view.shot === 2 && scratchGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Fairway_3ndShotScratch',
      adjustments
    )
  }
}

function getOriginalGreenTargetDepth({ view, adjustments }: OriginalValueArgs) {
  return getAdjustmentValue('hole', view, 'GreenTarget_Depth', adjustments)
}

function getOriginalGreenTargetPerimeter({
  view,
  adjustments,
}: OriginalValueArgs) {
  return getAdjustmentValue('hole', view, 'GreenTarget_Perimeter', adjustments)
}

function getOriginalGreenTargetWidth({ view, adjustments }: OriginalValueArgs) {
  return getAdjustmentValue('hole', view, 'GreenTarget_Width', adjustments)
}

function getOriginalShotLengths({ view, adjustments }: OriginalValueArgs) {
  if (bogeyGolfers.includes(view.golfer)) {
    const val = getAdjustmentValue(
      'golfer',
      view,
      'ShotLengthsBogey',
      adjustments
    )
      ?.toString()
      ?.split(',')
    const result = val && val[view.shot]?.toString()?.trim()
    return result ?? ''
  }
  if (scratchGolfers.includes(view.golfer)) {
    const val = getAdjustmentValue(
      'golfer',
      view,
      'ShotLengthsScratch',
      adjustments
    )
      ?.toString()
      ?.split(',')
    const result = val && val[view.shot]?.toString()?.trim()
    return result ?? ''
  }
}

function getOriginalGreenEdgeFractionPercentage({
  view,
  adjustments,
}: OriginalValueArgs) {
  const val = getAdjustmentValue(
    'hole',
    view,
    'WH_GreenEdgeFraction',
    adjustments
  )?.toString()
  return val?.split('/').at(1)?.trim().replace('%', '')
}

function getOriginalTopographyGreenElevation({
  view,
  adjustments,
}: OriginalValueArgs) {
  if (bogeyGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Topography_GreenElevationBogey',
      adjustments
    )
  }
  if (scratchGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Topography_GreenElevationScratch',
      adjustments
    )
  }
}

function getOriginalBunkerFractionValue({
  view,
  adjustments,
}: OriginalValueArgs) {
  const val = getAdjustmentValue(
    'hole',
    view,
    'Bunkers_Fraction',
    adjustments
  )?.toString()
  return val?.split('/').at(0)?.trim().replace('yd', '')
}

function getOriginalCrossingDistance({ view, adjustments }: OriginalValueArgs) {
  if (bogeyGolfers.includes(view.golfer)) {
    const val = getAdjustmentValue(
      'golfer',
      view,
      'CrossingDistancesBogey',
      adjustments
    )
    const result = val?.toString().split(',').at(view.shot)?.trim()
    return result !== '-' ? result : '0'
  }
  if (scratchGolfers.includes(view.golfer)) {
    const val = getAdjustmentValue(
      'golfer',
      view,
      'CrossingDistancesScratch',
      adjustments
    )
    const result = val?.toString().split(',').at(view.shot)?.trim()
    return result !== '-' ? result : '0'
  }
}

function getOriginalCrossSafelyDistance({
  defaultCourseSettings: _,
}: OriginalValueArgs) {
  return 10 // This is the default value in the backend if not changed manually
}

function getOriginalLateralDistance({ view, adjustments }: OriginalValueArgs) {
  if (bogeyGolfers.includes(view.golfer)) {
    const val = getAdjustmentValue(
      'golfer',
      view,
      `LateralDistancesBogey`,
      adjustments
    )
    const result = val?.toString().split(',').at(view.shot)?.trim()
    return result !== '-' ? result : '0'
  }
  if (scratchGolfers.includes(view.golfer)) {
    const val = getAdjustmentValue(
      'golfer',
      view,
      `LateralDistancesScratch`,
      adjustments
    )

    const result = val?.toString().split(',').at(view.shot)?.trim()
    return result !== '-' ? result : '0'
  }
}

function getOriginalDogleg({ view, adjustments }: OriginalValueArgs) {
  if (bogeyGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Dogleg_Bogey',
      adjustments
    )?.toString()
  }
  if (scratchGolfers.includes(view.golfer)) {
    return getAdjustmentValue(
      'golfer',
      view,
      'Dogleg_Scratch',
      adjustments
    )?.toString()
  }
}

function getOriginalBunkerDepth({ defaultCourseSettings }: OriginalValueArgs) {
  if (defaultCourseSettings) {
    if (Object.hasOwn(defaultCourseSettings, 'bunk_depth_default')) {
      return (defaultCourseSettings as CourseSettings).bunk_depth_default
    }
    if (Object.hasOwn(defaultCourseSettings, 'bunkDepthDefault')) {
      return (defaultCourseSettings as CourseSettingsCamelCase).bunkDepthDefault
    }
  }
  return undefined
}

function getOriginalMeasuredElevation({
  view,
  adjustments,
}: OriginalValueArgs) {
  return getAdjustmentValue('tee', view, 'Elevation_Hole', adjustments)
}

function getOriginalForcedLayup({ view, adjustments }: OriginalValueArgs) {
  const defaultForcedLayup = '0'
  const getShotFLValue = (key: string) => {
    const split = key.split(',')
    const shotVal = split.at(view.shot)?.trim()
    if (!shotVal) {
      return defaultForcedLayup
    }

    if (shotVal.startsWith('FL')) {
      return shotVal.replace('FL', '')
    }
    return defaultForcedLayup
  }

  if (scratchGolfers.includes(view.golfer)) {
    const key = getAdjustmentValue(
      'golfer',
      view,
      'DoglegLayup_Scratch',
      adjustments
    )?.toString()
    if (!key) {
      return defaultForcedLayup
    }
    return getShotFLValue(key)
  }

  if (bogeyGolfers.includes(view.golfer)) {
    const key = getAdjustmentValue(
      'golfer',
      view,
      'DoglegLayup_Bogey',
      adjustments
    )?.toString()

    if (!key) {
      return defaultForcedLayup
    }
    return getShotFLValue(key)
  }
  return defaultForcedLayup
}

function getLayupByChoice({ view, adjustments }: OriginalValueArgs) {
  const defaultLayupByChoice = '0'
  const getShotBCValue = (key: string) => {
    const split = key.split(',')
    const shotVal = split.at(view.shot)?.trim()
    if (!shotVal) {
      return defaultLayupByChoice
    }

    if (shotVal.startsWith('BC')) {
      return shotVal.replace('BC', '')
    }
    return defaultLayupByChoice
  }

  if (scratchGolfers.includes(view.golfer)) {
    const key = getAdjustmentValue(
      'golfer',
      view,
      'DoglegLayup_Scratch',
      adjustments
    )?.toString()
    if (!key) {
      return undefined
    }
    return getShotBCValue(key)
  }

  if (bogeyGolfers.includes(view.golfer)) {
    const key = getAdjustmentValue(
      'golfer',
      view,
      'DoglegLayup_Bogey',
      adjustments
    )?.toString()

    if (!key) {
      return defaultLayupByChoice
    }
    return getShotBCValue(key)
  }
  return defaultLayupByChoice
}

function getOriginalWaterHazardGreenEdge({
  view,
  adjustments,
}: OriginalValueArgs) {
  return getAdjustmentValue('hole', view, 'WH_GreenEdge', adjustments) ?? '0'
}
