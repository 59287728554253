import styled, { keyframes, css } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  cursor: pointer;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-top: 2px;
  border: 1px solid #d1dce2;
  position: fixed;
  z-index: 1;
`

export const IconContainer = styled.div<{ isOpen?: boolean }>`
  transition: transform 0.2s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`
