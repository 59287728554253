import styled from 'styled-components/native'
import theme from '@app/ui/theme'

export const ButtonContainer = styled.TouchableOpacity<{
  backgroundColor: string
  width: number
  pressed: boolean
  destructive?: boolean
}>`
  background-color: ${({ backgroundColor, pressed }) =>
    pressed ? theme.colors.secondary : backgroundColor};
  ${({ destructive }) =>
    destructive && `background-color: ${theme.colors.warning}`};
  cursor: ${({ pressed }) => (pressed ? 'default' : 'pointer')};
  width: ${({ width }) => width}px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 15px;
  border: 1px solid ${theme.colors.accept};
  border-color: ${({ destructive }) =>
    destructive ? theme.colors.warning : theme.colors.accept};
`

export const ButtonText = styled.Text<{
  color: boolean
}>`
  color: ${({ color }) => (color ? theme.colors.accept : theme.colors.reject)};
  font-weight: 500;
  font-size: 14px;
`
