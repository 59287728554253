import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0px;
  height: 100%;
  overflow: scroll;
`

export const ContentCard = styled.div`
  margin: 20px;
  box-shadow: 0 0px 4px 0
    ${({ theme }) => {
      return theme.colors.dark1
    }};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  border-radius: 4px;
  overflow: hidden;
`

export const ContentHeading = styled.h2`
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: 460;
  letter-spacing: 0.4px;
  color: #242c2b;
`

export const ContentSeparator = styled.div`
  margin-top: 14px;
  margin-bottom: 25px;
  height: 1px;
  background-color: ${theme.colors.light};
`

export const HoleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  align-content: flex-start;
  overflow-y: auto;
  padding: 6px;
  padding-top: 20px;
  @media (max-width: 820px) {
    align-content: center;
    align-items: center;
  }
  margin-right: 20px;
  width: 100%;
`
export const HoleSelectorContainer = styled.div`
  position: sticky;
  top: 0;
  right: 0;

  @media (max-width: 820px) {
    position: relative;
  }
`
export const ComponentsContainer = styled.div`
  display: flex;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding: 8px;
  @media (max-width: 820px) {
    flex-direction: column-reverse;
  }
`

export const Input = styled.input`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  width: 376px;
  outline: 1px solid ${theme.colors.color13};
  border-radius: 5px;
  border: none;
  padding: 12px 16px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);

  &::placeholder {
    color: ${theme.colors.color13};
  }
`

export const InputLabel = styled.label<{ showSubLabel?: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-bottom: ${({ showSubLabel }) => (showSubLabel ? 12 : 12)}px;
`

export const InputSubLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  margin-bottom: 12px;
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`
