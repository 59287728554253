/* eslint-disable react/prop-types */
import { LoadingContainer } from '@app/evaluation-adjustments/components/AdjustmentFactors.styles'
import { AdjustmentItem, EvaluationUserView } from '@app/evaluation-core/types'
import theme from '@app/ui/theme'
import getHighestIndexAndValue from '@common/hooks/getHighestIndexAndValue'
import useAdjustment from '@common/hooks/useAdjustment'
import Feather from '@ovaeasy/react-native-vector-icons/Feather'

import React from 'react'

type Props = {
  adjustment: AdjustmentItem
  isMultipleShots?: boolean
  view?: EvaluationUserView
}

const TotalValue: React.FC<Props> = ({ adjustment, isMultipleShots }) => {
  const { value } = getHighestIndexAndValue(adjustment)
  const adjustments = useAdjustment()
  const totalValue =
    !adjustments || !adjustments.adjustments ? (
      <LoadingContainer>
        <Feather name="loader" size={16} color={theme.colors.light} />
      </LoadingContainer>
    ) : (
      value
    )
  const getTitle = () => {
    return 'Rating'
  }
  return (
    <>
      <div>{getTitle()}</div>
      <div
        style={{
          fontSize: 24,
          fontWeight: 800,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {totalValue}
      </div>
    </>
  )
}

export default TotalValue
