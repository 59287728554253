import React, { FunctionComponent, ReactElement } from 'react'
import * as S from './styled'
import ScreenContainer from '../../atoms/ScreenContainer'
import Spacer from 'react-styled-spacer'
import GoBackButton from '../../atoms/GoBackButton'
import { goBack, navigate } from '@app/navigation'
import ScreenWrapper from '../../atoms/ScreenWrapper'
import ContentCard from '../../atoms/ContentCard'

type OwnProps = {
  LeftComponent: ReactElement
  RightComponent: ReactElement
  title?: string
  navigateBack?: () => void
}

type Props = OwnProps

const TwoComponentPage: FunctionComponent<Props> = ({
  LeftComponent,
  RightComponent,
  title,
  navigateBack,
}) => {
  return (
    <ScreenWrapper style={{ display: 'flex', flexDirection: 'column' }}>
      {typeof navigateBack === 'function' && (
        <div>
          <Spacer h={24} />
          <GoBackButton
            text="Go back"
            onPress={goBack}
            portraitMode={screen.width < screen.height}
            style={{ marginLeft: 24 }}
          />
        </div>
      )}

      <ContentCard>
        {title && <S.Title>{title}</S.Title>}
        <S.ComponentContainer>
          {LeftComponent}
          {RightComponent}
        </S.ComponentContainer>
      </ContentCard>
    </ScreenWrapper>
  )
}

export default TwoComponentPage
