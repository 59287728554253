import styled from 'styled-components/native'

export const HeaderText = styled.Text`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
`
export const OuterContainer = styled.View`
  flex-direction: column;
`

export const UpperContainer = styled.View`
  flex-direction: row;
`
export const OuterLowerContainer = styled.View`
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`
export const InnerLowerContainer = styled.View`
  flex-basis: 46%;
`
