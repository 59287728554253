import React, { FunctionComponent } from 'react'
import { FlexColumn, Input, InputLabel, InputSubLabel } from '../../styled'
import { useRatingTemplate } from '../../hooks/useRatingTemplate'

const CourseNameInput: FunctionComponent<{ sublabel?: string }> = ({
  sublabel,
}) => {
  const context = useRatingTemplate()
  return (
    <FlexColumn>
      <InputLabel showSubLabel={!!sublabel}>Course name</InputLabel>
      <InputSubLabel>{sublabel}</InputSubLabel>
      <Input
        placeholder="Type here"
        value={context.courseName}
        onChange={(e) => context.setCourseName(e.target.value)}
      />
    </FlexColumn>
  )
}

export default CourseNameInput
