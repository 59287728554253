import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  font-family: 'Roboto';
  border-radius: 12px;
  max-width: 1000px;
`
export const Header = styled.div`
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`
export const CloseButton = styled.div`
  height: 36px;
  width: 36px;
  background-color: #a5a5a5;
  border-radius: 5px;
  margin: 0px 10px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  color: white;
  cursor: pointer;
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin-left: 25px;
  color: #282828;
`

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #282828;
`
export const Line = styled.div`
  border-bottom: 1px solid #cecece;
`
export const Content = styled.div`
  padding: 30px 26px;
  flex: 1;
  display: flex;
  flex-direction: column;
`
export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`
export const Input = styled.input`
  background: white;
  border: 1px solid #cecece;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  margin-top: 12px;
  text-indent: 16px;

  & :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }
  & ::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #a5a5a5;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > .button {
    flex: 1;
  }
`
export const Source = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  color: black;
  border: 1px solid #ccc;
  background: #f9f9f9;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  vertical-align: middle;
  padding: 8px;
  height: 44px;
  text-align: center;
  align-items: center;

  &:active {
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.6);
    background: #f4f4f4;
  }
`

export const HoleContainer = styled.div<{ isFilled: boolean }>`
  opacity: ${({ isFilled }) => (isFilled ? 1 : 0.5)};
  background-color: #ffffff;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justifycontent: center;
  alignitems: center;
`

export const SpinnerText = styled.span`
  margin-left: 8;
  position: absolute;
  bottom: 80px;
  font-size: 25px;
`

export const HoleGrid = styled.div`
  padding: 12px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  margin-bottom: 20px;
`

export const RatingHole = styled.div<{ selected: boolean }>`
  flex: 1 0 45%;
  border-radius: 5px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  outline: ${({ selected }) =>
    selected ? `3px solid ${theme.colors.secondary2}` : 'none'};

  @media (max-width: 820px) {
    flex-basis: 95%;
    max-width: 100%;
  }
`

export const RatingHoleHeader = styled.div`
  display: flex;
  padding: 8px 16px 2px 16px;
  justify-content: space-between;
  align-items: center;
`

export const RatingHoleTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

export const RatingHoleCheckContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${theme.colors.secondary2};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RatingHoleHoleInfoText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`

export const CheckContainer = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: ${theme.colors.secondary2};
  display: flex;
  justify-content: center;
  align-items: center;
`
