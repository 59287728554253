/*
import { getEvaluationView } from "@app/evaluation-core";
import {
  AdjustmentValue,
  AdjustmentValueGroup,
  CourseEvaluation,
} from "@app/evaluation-core/types";
import { State } from "@app/state/types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useEvaluation } from ".";

type EvaluationState = {
  selectedHole: number;
  values: AdjustmentValueGroup;
  golferTypeName: string;
  golfer: {
    [evaluationGroupAdjustment: string]: AdjustmentValue;
  };
  selectedGroup: string;
  valueGroup: AdjustmentValue;
};
// eslint-disable-next-line
export default () => {
  const INITIAL_STATE = {
    selectedHole: 0,
    values: {},
    golferTypeName: "",
    golfer: {},
    selectedGroup: "",
    valueGroup: {},
  };
  const { evaluation } = useEvaluation();
  const userId = useSelector((state: State) => state.firebase.auth?.uid);

  const { view } = useEvaluation();

  const [state, setState] = useState<EvaluationState>(INITIAL_STATE);

  useEffect(() => setState(getEvaluationProperties()), [
    evaluation,
    evaluation?.adjustment.values,
  ]);

  const getEvaluationProperties = () => {
    if (!evaluation || !userId) {
      return state;
    }
    const landingZone = 0;
    if (view) {
      const { selectedHole, tee } = view;
      const values =
        evaluation.adjustment.values[selectedHole].values[tee];
      const golferTypeName =
        evaluation.golferTypes[view.selectedGolfer].shortName;
      const golfer = values[golferTypeName][landingZone];
      const selectedGroup = evaluation.groups[view.selectedGroup].adjustment;
      const valueGroup: AdjustmentValue = golfer[selectedGroup];
      return {
        selectedHole,
        values,
        golferTypeName,
        golfer,
        selectedGroup,
        valueGroup,
      };
    }
    return INITIAL_STATE;
  };

  return state;
};

*/

export default () => ({
  valueGroup: {
    bunkerDepth: null,
    crossingCarry: null,
  },
})
