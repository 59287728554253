import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { Tweener } from '../tweener'
import { AdmFactorGroup, AdmParameter } from '../types'
import {
  AdmContainer,
  AdmSectionHeading,
  AdmSectionMain,
  AdmSectionMainContent,
  AdmSectionMainHeader,
  AdmSectionSide,
  AdmSectionSideSm,
  AdmSectionSubText,
  DisabledAdjustmentText,
} from './Adjustment.styles'
import { AdjustmentParameters } from './AdjustmentParameters'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AdjustmentFactors } from './AdjustmentFactors'

export interface AdjustmentProps {
  factors: AdmFactorGroup[]
  parameters?: AdmParameter[]
  useLz?: boolean
  tweener?: boolean
  disabled?: boolean
  disabledText?: string
  subText?: string
  paramsSubText?: string
  renderContent?: () => JSX.Element
}

export const Adjustment = ({
  factors,
  useLz,
  parameters,
  tweener = true,
  disabled,
  disabledText,
  renderContent,
  subText,
  paramsSubText,
}: AdjustmentProps) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1524px)' })
  const subTitle = subText && <AdmSectionSubText>{subText}</AdmSectionSubText>
  return (
    <AdmContainer isTabletOrMobile={isTabletOrMobile}>
      {isTabletOrMobile && (
        <AdmSectionSideSm>
          {!disabled && (
            <AdjustmentParameters
              parameters={parameters}
              useLz={useLz}
              tweener={false}
              subText={paramsSubText}
            />
          )}
        </AdmSectionSideSm>
      )}
      <AdmSectionMain isTabletOrMobile={isTabletOrMobile}>
        <AdmSectionMainHeader>
          <AdmSectionHeading>Adjustments</AdmSectionHeading>
          {!factors && subTitle ? (
            <Skeleton height={12} width={160} />
          ) : (
            subTitle
          )}
        </AdmSectionMainHeader>
        <AdmSectionMainContent>
          {factors && factors[0].factors.length === 0 && (
            <DisabledAdjustmentText>
              There are no adjustments for this correction/factor.
            </DisabledAdjustmentText>
          )}
          {!disabled ? (
            <div>
              <AdjustmentFactors admFactors={factors} useLz={useLz} />
            </div>
          ) : (
            <DisabledAdjustmentText>{disabledText}</DisabledAdjustmentText>
          )}
          {renderContent && renderContent()}
        </AdmSectionMainContent>
        {isTabletOrMobile && tweener && (
          <>
            <AdmSectionMainHeader>
              <AdmSectionHeading>Optional Adjustment</AdmSectionHeading>
            </AdmSectionMainHeader>
            <div
              style={{
                padding: 40,
                paddingBottom: 140,
                width: '100%',
                maxWidth: '760px',
                margin: '0 auto',
              }}
            >
              <Tweener useLz={useLz} />
            </div>
          </>
        )}
      </AdmSectionMain>
      {!isTabletOrMobile && (
        <AdmSectionSide>
          {!disabled && (
            <AdjustmentParameters
              parameters={parameters}
              subText={paramsSubText}
              useLz={useLz}
              tweener={tweener}
            />
          )}
        </AdmSectionSide>
      )}
    </AdmContainer>
  )
}
