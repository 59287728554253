import Spacer from '@app/components/atoms/Spacer'
import { FBREF_COURSE_EVALUATIONS } from '@app/evaluation-core/refs'
import { ClubManagementStackParamList } from '@app/navigation/CoursesStack'
import type { StackScreenProps } from '@react-navigation/stack'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useFirebaseConnect } from 'react-redux-firebase'
import * as S from './styled'
import useAdminTableSort from './useAdminTableSort'
import useFormattedEvaluations from './useFormattedEvaluations'
import useHighlightUsers from './useHighlightUsers'

type OwnProps = {}
enum EvalStatus {
  'IN_PROGRESS' = 2,
  'FINALIZED' = 3,
}

type Props = OwnProps &
  StackScreenProps<ClubManagementStackParamList, 'ClubManagement'>

const ANIMATION_DURATION = 2000

const convertStatusToText = (status: 0 | 1 | 2 | 3 | 4) => {
  switch (true) {
    case status === EvalStatus.IN_PROGRESS:
      return 'In Progress'
    case status === EvalStatus.FINALIZED:
      return 'Finalized'
    default:
      return 'Unknown'
  }
}

// TODO: Remove later
const v1Filter = (evaluation: any) => {
  return evaluation?.holeIds === undefined
}

const AdminPage: FunctionComponent<Props> = ({ route }) => {
  useFirebaseConnect({ path: FBREF_COURSE_EVALUATIONS() })
  const formattedEvaluations = useFormattedEvaluations()

  const usersToHighlight = useHighlightUsers(
    formattedEvaluations,
    ANIMATION_DURATION
  )
  const { sortOrder, sortedColumn, sortEvaluations, handleSort } =
    useAdminTableSort('club')
  const sortedEvaluations =
    sortEvaluations(formattedEvaluations).filter(v1Filter)

  return (
    <S.Wrapper>
      <Spacer height={24} />
      <S.ContentCard>
        <S.Table>
          <thead>
            <S.TableRow>
              <S.TableHead>Key</S.TableHead>
              <S.TableHead onClick={() => handleSort('clubId')}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  Club Id
                  {sortedColumn === 'clubId' && (
                    <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>
                  )}
                </div>
              </S.TableHead>{' '}
              <S.TableHead onClick={() => handleSort('club')}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  Club Name
                  {sortedColumn === 'club' && (
                    <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>
                  )}
                </div>
              </S.TableHead>
              <S.TableHead onClick={() => handleSort('courseId')}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  Course Id
                  {sortedColumn === 'courseId' && (
                    <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>
                  )}
                </div>
              </S.TableHead>{' '}
              <S.TableHead onClick={() => handleSort('course')}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  Course Name
                  {sortedColumn === 'course' && (
                    <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>
                  )}
                </div>
              </S.TableHead>
              <S.TableHead onClick={() => handleSort('date')}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  Date
                  {sortedColumn === 'date' && (
                    <span>{sortOrder === 'asc' ? '▲' : '▼'}</span>
                  )}
                </div>
              </S.TableHead>
              <S.TableHead>Status</S.TableHead>
              <S.TableHead>Users</S.TableHead>
            </S.TableRow>
          </thead>
          <tbody>
            {sortedEvaluations.map((evaluation) => (
              <S.TableRow key={evaluation.key}>
                <S.TableCell>{evaluation.key}</S.TableCell>
                <S.TableCell>{evaluation.clubId}</S.TableCell>
                <S.TableCell>{evaluation.clubName}</S.TableCell>
                <S.TableCell>{evaluation.courseId}</S.TableCell>
                <S.TableCell>{evaluation.courseName}</S.TableCell>
                <S.TableCell>{evaluation.evaluationDate}</S.TableCell>
                <S.TableCell>
                  {convertStatusToText(evaluation.status as any)}
                </S.TableCell>
                <S.TableCell>
                  <S.UsersTable>
                    <thead>
                      <S.TableRow>
                        <S.UsersTableHead>Email</S.UsersTableHead>
                        <S.UsersTableHead>Hole</S.UsersTableHead>
                        <S.UsersTableHead>Tee</S.UsersTableHead>
                      </S.TableRow>
                    </thead>
                    <tbody>
                      {evaluation?.users &&
                        Object.values(evaluation?.users).map((user) => (
                          <S.TableRow key={user.view.email}>
                            <S.UsersTableCell
                              animationDuration={ANIMATION_DURATION}
                              className={
                                usersToHighlight.includes(
                                  user.view.email + evaluation.key
                                )
                                  ? 'highlighted'
                                  : ''
                              }
                            >
                              {user.view.email}
                            </S.UsersTableCell>
                            <S.HighlightedTableCell
                              animationDuration={ANIMATION_DURATION}
                              className={
                                usersToHighlight.includes(
                                  user.view.email + evaluation.key
                                )
                                  ? 'highlighted'
                                  : ''
                              }
                            >
                              {user.view?.hole + 1}
                            </S.HighlightedTableCell>
                            <S.HighlightedTableCell
                              animationDuration={ANIMATION_DURATION}
                              className={
                                usersToHighlight.includes(
                                  user.view.email + evaluation.key
                                )
                                  ? 'highlighted'
                                  : ''
                              }
                            >
                              {user.view.tee + 1}
                            </S.HighlightedTableCell>
                          </S.TableRow>
                        ))}
                    </tbody>
                  </S.UsersTable>
                </S.TableCell>
              </S.TableRow>
            ))}
          </tbody>
        </S.Table>
      </S.ContentCard>
    </S.Wrapper>
  )
}

export default AdminPage
