import React, { FunctionComponent } from 'react'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import * as S from './styled'
import { useRatingTemplate } from '../../hooks/useRatingTemplate'
import theme from '@app/ui/theme'
import { TrashIconContainer } from '../../SelectHoles'

const RatingHoles: FunctionComponent = () => {
  const {
    ratingHoles,
    holeIndexHandler: [holeIndex, setHoleIndex],
    removeRatingHole,
  } = useRatingTemplate()

  return (
    <S.RatingHolesContainer>
      {ratingHoles.map((hole, index) => {
        const isSelected = index === holeIndex
        const isFilled =
          hole.courseName !== undefined && hole.courseHoleNumber !== undefined
        return (
          <S.RatingHole
            key={hole.ratingHoleNumber}
            onClick={() => setHoleIndex(index)}
            selected={isSelected}
            style={{ opacity: isFilled ? 1 : 0.7 }}
          >
            <S.Header>
              <S.Title>Hole: {hole.ratingHoleNumber}</S.Title>
              {isFilled && (
                <S.CheckContainer>
                  <FeatherIcon name="check" size={20} color="white" />
                </S.CheckContainer>
              )}
            </S.Header>
            <hr
              style={{
                padding: 0,
                margin: 0,
                borderColor: theme.colors.color13,
                opacity: 0.3,
              }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 16,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <S.HoleInfoText>Course: {hole.courseName}</S.HoleInfoText>
                <S.HoleInfoText>Hole: {hole.courseHoleNumber}</S.HoleInfoText>
                <S.HoleInfoText>Par: {hole.par}</S.HoleInfoText>
              </div>
              <TrashIconContainer
                disabled={!isFilled}
                onClick={() => {
                  if (isFilled) {
                    removeRatingHole(index)
                  }
                }}
              >
                <FeatherIcon name="trash" size={20} color="white" />
              </TrashIconContainer>
            </div>
          </S.RatingHole>
        )
      })}
    </S.RatingHolesContainer>
  )
}

export default RatingHoles
