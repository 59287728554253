import { StyledSpinner } from '@app/evaluation/components/Spinner'
import images from '@app/images'
import theme from '@app/ui/theme'
import React, { useEffect } from 'react'

import { useLogin } from '../hooks'
import {
  LoginContainer,
  LoginLogo,
  LoginWrapper,
  ErrorText,
  ErrorTextContainer,
  BG,
} from './Login.styles'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import Spacer from '@app/components/atoms/Spacer'

const Login = ({ prefilledEmail }: { prefilledEmail?: string }) => {
  const { signIn, inputs, error, loading } = useLogin()

  useEffect(() => {
    if (prefilledEmail) {
      inputs.setEmail(prefilledEmail)
    }
  }, [])

  return (
    <LoginContainer>
      <div
        style={{
          zIndex: 1,
          position: 'absolute',
          height: '100%',
          width: '100%',
          background: theme.colors.primary,
        }}
      >
        <BG />
      </div>
      <LoginWrapper>
        <LoginLogo src={images.svg.LiveRateIcon} style={{ height: 120 }} />
        <Spacer height={24} />
        <div style={{ maxWidth: 500 }}>
          <input
            placeholder="Email-adress or GolfID"
            value={inputs.email}
            onChange={(event) => inputs.setEmail(event.target.value)}
            className="login-username"
          />
          <input
            type="password"
            placeholder="Password"
            value={inputs.password}
            onChange={(event) => inputs.setPassword(event.target.value)}
            className="login-password"
          />
          <Spacer height={8} />
          <div style={{ paddingLeft: 8, paddingRight: 8 }}>
            <RoundedButton onPress={signIn} loading={loading} title="Sign In" />
          </div>
        </div>
        {error && !loading && (
          <ErrorTextContainer>
            <ErrorText>{error}</ErrorText>
          </ErrorTextContainer>
        )}
      </LoginWrapper>
      <div
        style={{
          width: 200,
          height: 50,
          position: 'absolute',
          bottom: 8,
          left: 8,
          zIndex: 2,
        }}
      >
        <img
          src={images.png.MappingLogo}
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      </div>
    </LoginContainer>
  )
}

export default Login
