import React, { useState } from 'react'
import { View } from 'react-native'
import {
  AdmFactorButtons,
  AdmFactorContainer,
  AdmFactorGroupContainer,
  AdmFactorTextLabel,
  ButtonHeader,
} from './AdjustmentFactors.styles'
import { AdmFactorGroup, AdmGroupFactor } from '../types'
import ExpandableAdjustmentLabel from './ExapandableAdjustmentLabel'
import FactorButton from './FactorButton'
import OrLabel from './OrLabel'
import { getAdjustmentValue } from '@app/evaluation-core'
import { getPlayerShots } from '@common/hooks/getPlayerShots'
import useAdjustment from '@common/hooks/useAdjustment'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import LzAndShotAdjustment from './ lzAndShotAdjustment'
import AdmFactorGroupSkeleton from './skeletons/AdmFactorsGroupContainerSkeleton'

interface AdjustmentFactorsProps {
  admFactors: AdmFactorGroup[]
  useLz?: boolean
}

export const AdjustmentFactors = (props: AdjustmentFactorsProps) => {
  const adjustments = useAdjustment()
  const { view } = useEvaluationUser()
  const [openFactors, setOpenFactors] = useState<Array<string>>([])
  const playerShots = getPlayerShots()

  const handleShowOr = (factor: AdmGroupFactor) =>
    typeof factor.showOr === 'function'
      ? factor.showOr(adjustments, view)
      : factor.showOr

  const renderShotAdjustments = (
    factor: AdmGroupFactor,
    factorIndex: number,
    index: number
  ) => {
    const shots = playerShots - (factor.type === 'landingZones' ? 1 : 0)

    const lzAdjustments = Array(shots)
      .fill('')
      .map(
        (_, shotIndex) =>
          getAdjustmentValue(
            'shot',
            { ...view, shot: shotIndex },
            factor.itemKey as string,
            adjustments
          ) as number
      )

    let shouldRender = true
    if (typeof factor.shouldRender === 'function') {
      shouldRender = factor.shouldRender(adjustments, view)
    }

    const notOr = factor.label !== 'or'

    const totalValue =
      factor.totalKey &&
      getAdjustmentValue('golfer', view, factor.totalKey as string, adjustments)

    const totalValueSign = (totalValue as number) >= 0 ? '+' : ''

    return (
      shouldRender && (
        <LzAndShotAdjustment
          totalValueSign={totalValueSign}
          totalValue={totalValue}
          notOr={notOr}
          factor={factor}
          lzAdjustments={lzAdjustments}
          factorIndex={factorIndex}
          index={index}
          openFactors={openFactors}
          setOpenFactors={setOpenFactors}
          view={view}
          adjustments={adjustments}
        />
      )
    )
  }

  return (
    <>
      {props && props.admFactors && view && adjustments ? (
        props.admFactors.map((item, index) => (
          <AdmFactorGroupContainer key={index}>
            {item.factors
              .filter((f) => !f.golfers || f.golfers.includes(view.golfer))
              .map((factor, factorIndex) => {
                if (factor.customWidget) {
                  return factor.customWidget()
                }
                if (
                  (factor.type === 'shots' || factor.type === 'landingZones') &&
                  playerShots > 1
                ) {
                  return renderShotAdjustments(factor, factorIndex, index)
                }
                return (
                  (typeof factor.shouldRender === 'function'
                    ? factor.shouldRender(adjustments, view)
                    : true) && (
                    <AdmFactorContainer key={factorIndex}>
                      {handleShowOr(factor) && factor.items.length > 2 ? (
                        <OrLabel />
                      ) : (
                        <View style={{ justifyContent: 'space-between' }}>
                          <ExpandableAdjustmentLabel
                            factorIndex={factorIndex}
                            index={index}
                            openFactors={openFactors}
                            setOpenFactors={setOpenFactors}
                            factor={factor}
                          />
                          {handleShowOr(factor) && <OrLabel />}
                        </View>
                      )}
                      <AdmFactorButtons
                        expandable={factor.expandable}
                        collapsed={
                          openFactors.indexOf(index + '_' + factorIndex) === -1
                        }
                        style={{
                          flexDirection: factor.factorText
                            ? 'row-reverse'
                            : 'column',
                        }}
                      >
                        {factor.factorText && (
                          <AdmFactorTextLabel style={{ padding: 15 }}>
                            {factor.factorText}
                          </AdmFactorTextLabel>
                        )}
                        <View>
                          {factor.type === 'green' && (
                            <ButtonHeader
                              style={{ width: 70 }}
                            >{`Green`}</ButtonHeader>
                          )}
                          {factor.items.map((button, buttonIndex) => (
                            <FactorButton
                              key={buttonIndex}
                              buttonIndex={buttonIndex}
                              view={view}
                              adjustments={adjustments}
                              useLz={!!props?.useLz}
                              factor={factor}
                              button={button}
                              showTextOnly={button.value === undefined}
                            />
                          ))}
                        </View>
                      </AdmFactorButtons>
                    </AdmFactorContainer>
                  )
                )
              })}
          </AdmFactorGroupContainer>
        ))
      ) : (
        <AdmFactorGroupSkeleton />
      )}
    </>
  )
}
