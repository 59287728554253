import getEnv from '@app/environments/getEnv'
import { State } from '@app/state/types'
import type * as Axios from 'axios'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fetch } from '../../common/fetch'
import { UserState } from '@app/auth/types'

export const attachInterceptors = (axiosInstance: Axios.AxiosInstance, user) =>
  axiosInstance.interceptors.request.use(async (req) => {
    if (user) {
      req.headers.authorization = getToken('access', user)
      req.headers.refreshtoken = getToken('refresh', user)
    }
    return req
  })

const getToken = (type: 'refresh' | 'access', user: UserState) =>
  type === 'access' ? user?.token : user?.refreshToken

export const useAxios = (path: string) => {
  const { user } = useSelector((state: State) => ({
    user: state.user,
  }))

  const api = getEnv().api + path

  const axios = fetch.create({
    baseURL: api,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })

  useEffect(() => {
    attachInterceptors(axios, user)
  }, [user, axios])

  return axios
}
