import React, { useEffect, useCallback } from 'react'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { useSelector } from 'react-redux'
import { useEvaluation } from '@common/hooks'
import { State } from '@app/state/types'
import * as S from './ConfirmDefaultCourseSettingsModal.styles'
import Spacer from '@app/components/atoms/Spacer'
import { CourseOptionView } from '@app/evaluation-settings-menu/HeaderArea.styles'
import {
  ContentCard,
  ContentHeading,
  ContentSeparator,
} from './Preparation.styles'
import { setCheckedDefaultSettings } from '@app/evaluation-core'

type Props = {
  handleChange: () => void
  onClose: () => void
}

const toCheckWithLabels = {
  bunk_depth_default: {
    label: 'Defualt Bunker Depth',
    postfix: { imperial: 'ft', metric: 'm' },
  },
  intermediate_cut: {
    label: 'Default Intermediate Cut',
    postfix: { imperial: 'yd', metric: 'yd' },
  },
  rough_height: {
    label: 'Default Rough Height',
    postfix: { imperial: 'in', metric: 'mm' },
  },
  stimp_feet: {
    label: 'Stimp Feet',
    postfix: { imperial: 'ft', metric: 'ft' },
  },
  'wind_1-9': {
    label: 'Wind Hole 1-9',
    postfix: { imperial: 'mph', metric: 'm/s' },
  },
  'wind_10-18': {
    label: 'Wind Hole 10-18',
    postfix: { imperial: 'mph', metric: 'm/s' },
  },
}

const ConfirmCourseSettingsModal = ({ onClose, handleChange }: Props) => {
  const { evaluation } = useEvaluation()

  const courseSettings: { [key: string]: string | number | boolean } =
    useSelector((state: State) => state.adjustments.courseSettings)

  const showWind10To18 = (key: string) =>
    key === 'wind_10-18' && evaluation.holes.length < 9

  const getSameAsDefault = () => {
    const defaultSettings = evaluation.defaultCourseSettings
    if (!defaultSettings) return []

    return Object.keys(toCheckWithLabels).reduce((acc, key) => {
      const defaultSetting = defaultSettings[key]
      const value = courseSettings[key]
      if (defaultSetting !== value || showWind10To18(key)) {
        return acc
      }
      const data = toCheckWithLabels[key]
      return [
        ...acc,
        {
          label: data.label,
          val: `${value}`,
          postfix: data.postfix[courseSettings['measurement']],
        },
      ]
    }, [] as { label: string; val: string; postfix: string }[])
  }

  const setDefaultSettingConfirmation = useCallback(async () => {
    await setCheckedDefaultSettings(true)
    return handleChange()
  }, [handleChange])

  useEffect(() => {
    if (getSameAsDefault().length === 0) {
      setDefaultSettingConfirmation()
    }
  }, [])

  return (
    <ContentCard style={{ width: '45%', position: 'relative' }}>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: -8, right: -8 }}>
          <S.CloseButton onClick={onClose}>X</S.CloseButton>
        </div>
        <ContentHeading>Confirm course setting default values</ContentHeading>
        <span style={{ fontSize: 14 }}>
          The following settings have not been altered from the default provided
          values. Please confirm that these are correct before continuing.
        </span>
        <div style={{ padding: 16 }}>
          {getSameAsDefault().map((setting, index) => (
            <CourseOptionView key={index}>
              <div>
                <span>{setting.label}</span>
                <div style={{ display: 'flex' }}>
                  <S.SettingValueText>{setting.val}</S.SettingValueText>
                  <span style={{ marginLeft: 8 }}>{setting.postfix}</span>
                </div>
              </div>
            </CourseOptionView>
          ))}
        </div>
        <>
          <Spacer height={16} />
          <RoundedButton
            title="Confirm"
            onPress={() => {
              setDefaultSettingConfirmation()
            }}
          />
        </>
        <ContentSeparator />
      </div>
    </ContentCard>
  )
}

export default ConfirmCourseSettingsModal
