import theme from '@app/ui/theme'
import styled from 'styled-components'
import S, { DefaultTheme } from 'styled-components/native'

interface LeftMenuProps {
  collapsed?: boolean
  theme: DefaultTheme
}

export const LeftMenu = S.View`
  background-color: ${theme.colors.dark};
  height: 100%;
  z-index: 10;
  overflow: hidden;
  width: ${(props: LeftMenuProps) =>
    !props.collapsed ? 70 : theme.size[10]}px;
  transition: 0.3s ease;
`

export const LeftMenuFixedContainer = S.View`
  width: ${theme.size[10]}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`

export const LeftTabMenu = S.View`
  flex-grow: 1;
  margin-top: ${theme.space[4]}px;
`

export const LogoToggler = S.View`
  width: 70px;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const Logo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  height: 80px;
`

export const LogoText = S.Text`
  font-size: 22px;
  font-weight: 700;
  color: ${theme.colors.white};
  line-height: 22px;
  width: 140px;
`

export const BurgerMenu = styled.div`
  cursor: pointer;
  &:active {
    opacity: 0.5;
  }
`

export const AdminTitle = styled.div`
  color: ${theme.colors.white};
  padding-top: 30px;
  padding-left: 10px;
  margin-bottom: -10px;
  font-size: 0.8em;
`

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 5px;
  margin-inline: 10px;
  // border-top: 1px solid rgba(250, 250, 250, 0.5);
  border-bottom: 1px solid rgba(250, 250, 250, 0.5);
`

export const AvatarContainer = styled.div`
  padding: 0px 15px;
`
export const NameContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  display: flex;
`

export const Name = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${theme.colors.white};
`

export const UserLevel = styled.span`
  color: ${theme.colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  z-index: 22;
  margin: auto;
`
