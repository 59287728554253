import * as S from './styled'

import React, { useState } from 'react'
import {
  getAdjustmentValue,
  getSelectedHole,
  getSelectedTee,
  refreshHole,
} from '@app/evaluation-core'

import EvalTabItem from '../../atoms/EvalTabItem'
import HoleMenu from '@app/evaluation/components/HoleMenu'
import { LockStatus } from '@app/evaluation-core/types'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import SideBarToggleButton from '../../atoms/SideBarToggleButton'
import TwoButtonModal from '../../organisms/TwoButtonModal'
import { setSelectedHole } from '@app/evaluation-core/actions/evaluationActions'
import theme from '@app/ui/theme'
import useAdjustment from '@common/hooks/useAdjustment'
import { useDispatch } from 'react-redux'
import { useEvaluation } from '@common/hooks'
import useEvaluationLockedState from '@common/hooks/useEvaluationLockedState'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { useSnackbar } from '@app/snackbar'
import useReadOnly from '@app/evaluation/hooks/useReadOnly'
import Spacer from 'react-styled-spacer'
import DateTimeView from '../../atoms/DateTimeView'
import Feather from '@ovaeasy/react-native-vector-icons/Feather'
import { EvalStatus } from '@app/courses/domain/ratingStatus'
import { LoadingContainer } from '@app/evaluation-adjustments/components/AdjustmentFactors.styles'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

interface SideBarProps {
  collapsedMode: any
  setCollapsedMode: any
  holeDecrementState: () => void
  holeIncrementState: () => void
  dropMenuRef: any
  openModal: () => void
  setHoleLocked: () => void
  lockAllHoles: () => void
  reference?: React.RefObject<HTMLDivElement>
}

const EvaluationSideBar = ({
  collapsedMode,
  setCollapsedMode,
  holeDecrementState,
  holeIncrementState,
  dropMenuRef,
  openModal,
  setHoleLocked,
  lockAllHoles,
  reference,
}: SideBarProps) => {
  const [openSnackbar] = useSnackbar()
  const evaluationState = useEvaluation()
  const { currentId, evaluation } = evaluationState
  const { view, isAdmin } = useEvaluationUser()
  const adjustments = useAdjustment()
  const dispatch = useDispatch<any>()
  const lockStatus = useEvaluationLockedState()
  const tee = getSelectedTee(evaluation)
  const hole = getSelectedHole(evaluation)
  const par = getAdjustmentValue('hole', view, 'Par', adjustments)
  const [showRefreshModal, setShowRefreshModal] = useState(false)
  const canEdit = lockStatus === LockStatus.UNLOCKED
  const handleReadOnly = useReadOnly()

  const handleRefresh = () => {
    refreshHole(view?.hole, currentId as string)
    setModal(false)()
  }

  const setModal = (visible: boolean) => () => setShowRefreshModal(visible)

  const allHolesIsLocked =
    evaluation?.holes.length > 0 &&
    evaluation?.holes?.filter((x) => x.locked).length ==
      evaluation?.holes.length
  const holeIsLocked = lockStatus === LockStatus.LOCKED
  const isDev = process.env.NODE_ENV === 'development'
  const hasLeaderRights = isAdmin
  const getLockHoleText = () => {
    const collapsedText = holeIsLocked ? 'Unlock' : 'Lock'
    const expandedText = holeIsLocked ? 'Unlock Hole' : 'Lock Hole'

    return collapsedMode ? collapsedText : expandedText
  }

  const getLockAllText = () => {
    const collapsedText = allHolesIsLocked ? 'Unlock all' : 'Lock all'
    const expandedText = allHolesIsLocked ? 'Unlock all' : 'Lock all'

    return collapsedMode ? collapsedText : expandedText
  }

  const teeLength =
    adjustments &&
    tee &&
    getAdjustmentValue('hole', view, `Tee${tee.teeNumber}Length`, adjustments)

  const slopeLength = getAdjustmentValue(
    'hole',
    view,
    'slopeLength',
    adjustments
  )

  const branchRevision = getAdjustmentValue(
    'hole',
    view,
    'BranchRevision',
    adjustments
  )

  const crDate = getAdjustmentValue(
    'hole',
    view,
    'CRDate',
    adjustments
  ) as string

  const convertUTCToLocal = (dateString: string, dateOnly?: boolean) => {
    const date = new Date(dateString + 'Z')
    if (dateOnly) {
      return date.toLocaleDateString()
    }
    const formattedDate = date.toLocaleString('sv-SE')
    return formattedDate
  }

  const getCrDate = () => {
    return convertUTCToLocal(crDate)
  }

  const crVersion = getAdjustmentValue('hole', view, 'CRVersion', adjustments)

  const length =
    view?.adjustment === -1 && slopeLength ? slopeLength : teeLength

  const spinner = (
    <LoadingContainer>
      <Feather name="loader" size={12} />
    </LoadingContainer>
  )

  const parValue = !adjustments || !adjustments.adjustments ? spinner : par

  return (
    <>
      <S.EvalSideBar
        collapsed={collapsedMode}
        ref={reference}
        inAdjustment={view?.adjustment !== -1}
      >
        <S.EvalSideBarTop
          collapsed={collapsedMode}
          ref={reference}
          inAdjustment={view?.adjustment !== -1}
        >
          {!collapsedMode ? (
            <>
              <S.EvalSideBarTopCont>
                <S.EvalSideBarMenus>
                  <div style={{ width: '100%' }}>
                    <S.ParLengthContainer>
                      <S.ParLengthLabel>Par</S.ParLengthLabel>
                      <S.ParLengthValue>{parValue || ''}</S.ParLengthValue>
                    </S.ParLengthContainer>
                    <Spacer h={40} />
                  </div>
                </S.EvalSideBarMenus>
                <S.HoleButtonContainter>
                  <RoundedButton
                    title="<"
                    onPress={() => {
                      holeDecrementState()
                    }}
                  />
                  <div style={{ width: '5px' }} />
                  <HoleMenu reference={dropMenuRef} />
                  <div style={{ width: '5px' }} />
                  <RoundedButton
                    title=">"
                    onPress={() => {
                      holeIncrementState()
                    }}
                  />
                </S.HoleButtonContainter>
              </S.EvalSideBarTopCont>
            </>
          ) : (
            <S.CollapsedSideBarContainer>
              <S.CollapsedInputContainer>
                {' '}
                <S.CollapsedContainer>
                  <S.ParLengthLabel>Hole</S.ParLengthLabel>
                  <S.ParLengthValue>{view?.hole + 1 || ''}</S.ParLengthValue>
                </S.CollapsedContainer>
                <S.ParLengthContainer>
                  <S.ParLengthLabel>Par</S.ParLengthLabel>
                  <S.ParLengthValue>{par || ''}</S.ParLengthValue>
                </S.ParLengthContainer>
                <Spacer h={31} />
                <S.CollapsedContainer>
                  <RoundedButton
                    title="<"
                    onPress={() => {
                      holeDecrementState()
                    }}
                  />
                  <RoundedButton
                    title=">"
                    onPress={() => {
                      holeIncrementState()
                    }}
                  />
                </S.CollapsedContainer>
              </S.CollapsedInputContainer>
            </S.CollapsedSideBarContainer>
          )}
        </S.EvalSideBarTop>
        <S.EvalSideBarMain inAdjustment={view?.adjustment !== -1}>
          {view.adjustment === -1 && (
            <>
              <S.EvalSideBarGroupRow>
                <a>
                  {collapsedMode ? 'EPL' : 'Eff. Playing Length Corrections'}
                </a>
              </S.EvalSideBarGroupRow>
              {evaluation.adjustments.slice(0, 3).map((item, index: number) => {
                const usersOnAdjustment = Object.values(
                  evaluation.users
                ).filter(
                  (user) =>
                    user.view?.hole === view?.hole &&
                    item.adjustmentIndex === user.view?.adjustment
                )

                return (
                  <EvalTabItem
                    key={index}
                    item={
                      collapsedMode ? item.shortName.toUpperCase() : item.name
                    }
                    active={
                      item.adjustmentIndex === view?.adjustment ||
                      (item.adjustmentIndex === view?.prevAdj &&
                        view.adjustment === -1)
                    }
                    onPress={() =>
                      dispatch(
                        setSelectedHole(
                          view?.hole,
                          view?.tee,
                          view?.golfer,
                          item.adjustmentIndex
                        )
                      )
                    }
                    activeUsers={usersOnAdjustment}
                    sideBarCollapsed={collapsedMode}
                  />
                )
              })}
              <S.EvalSideBarGroupRow>
                <a>{collapsedMode ? 'ORF' : 'Obstacle Rating Factors'}</a>
              </S.EvalSideBarGroupRow>
              {evaluation.adjustments.slice(3).map((item, index: number) => {
                const usersOnAdjustment = Object.values(
                  evaluation.users
                ).filter(
                  (user) =>
                    user.view?.hole === view?.hole &&
                    item.adjustmentIndex === user.view?.adjustment
                )
                return (
                  <EvalTabItem
                    key={index}
                    item={
                      collapsedMode ? item.shortName.toUpperCase() : item.name
                    }
                    active={
                      item.adjustmentIndex === view?.adjustment ||
                      (item.adjustmentIndex === view?.prevAdj &&
                        view.adjustment === -1)
                    }
                    onPress={() =>
                      dispatch(
                        setSelectedHole(
                          view?.hole,
                          view?.tee,
                          view?.golfer,
                          item.adjustmentIndex
                        )
                      )
                    }
                    activeUsers={usersOnAdjustment}
                    sideBarCollapsed={collapsedMode}
                  />
                )
              })}
            </>
          )}
          {view.adjustment !== -1 && (
            <>
              <S.EvalSideBarGroupRow>
                <a>
                  {collapsedMode ? 'EPL' : 'Eff. Playing Length Corrections'}
                </a>
              </S.EvalSideBarGroupRow>
              {evaluation.adjustments.slice(0, 3).map((item, index: number) => {
                const usersOnAdjustment = Object.values(
                  evaluation.users
                ).filter(
                  (user) =>
                    user.view?.hole === view?.hole &&
                    item.adjustmentIndex === user.view?.adjustment
                )

                return (
                  <EvalTabItem
                    key={index}
                    item={
                      collapsedMode ? item.shortName.toUpperCase() : item.name
                    }
                    active={
                      item.adjustmentIndex === view?.adjustment ||
                      (item.adjustmentIndex === view?.prevAdj &&
                        view.adjustment === -1)
                    }
                    onPress={() =>
                      dispatch(
                        setSelectedHole(
                          view?.hole,
                          view?.tee,
                          view?.golfer,
                          item.adjustmentIndex
                        )
                      )
                    }
                    activeUsers={usersOnAdjustment}
                    sideBarCollapsed={collapsedMode}
                  />
                )
              })}
              <S.EvalSideBarGroupRow>
                <a>{collapsedMode ? 'ORF' : 'Obstacle Rating Factors'}</a>
              </S.EvalSideBarGroupRow>
              {evaluation.adjustments.slice(3).map((item, index: number) => {
                const usersOnAdjustment = Object.values(
                  evaluation.users
                ).filter(
                  (user) =>
                    user.view?.hole === view?.hole &&
                    item.adjustmentIndex === user.view?.adjustment
                )
                return (
                  <EvalTabItem
                    key={index}
                    item={
                      collapsedMode ? item.shortName.toUpperCase() : item.name
                    }
                    active={
                      item.adjustmentIndex === view?.adjustment ||
                      (item.adjustmentIndex === view?.prevAdj &&
                        view.adjustment === -1)
                    }
                    onPress={() =>
                      dispatch(
                        setSelectedHole(
                          view?.hole,
                          view?.tee,
                          view?.golfer,
                          item.adjustmentIndex
                        )
                      )
                    }
                    activeUsers={usersOnAdjustment}
                    sideBarCollapsed={collapsedMode}
                  />
                )
              })}
            </>
          )}
        </S.EvalSideBarMain>
        <TwoButtonModal
          isVisible={showRefreshModal}
          title="Are you sure you want to refresh?"
          description={`This will overwrite the current evaluation with the latest information from LiveCR for Hole: ${
            view?.hole + 1
          }`}
          onPressLeft={handleRefresh}
          onPressRight={setModal(false)}
          leftButtonText="Refresh"
          rightButtonText="Cancel"
        />
        {isDev && false && (
          <div style={{ padding: 8 }}>
            <span>Eval ID</span>&nbsp;
            <div
              style={{
                padding: 4,
                backgroundColor: theme.colors.light,
              }}
            >
              {currentId}
            </div>
          </div>
        )}
        <S.LiveCRInfoContainer>
          {isAdmin && (
            <>
              <div style={{ margin: 4, width: '80%' }}>
                <RoundedButton
                  title={getLockHoleText()}
                  onPress={handleReadOnly(setHoleLocked)}
                  disabled={lockStatus === LockStatus.READ_ONLY}
                />
              </div>
              <div style={{ margin: 4, width: '80%' }}>
                <RoundedButton
                  title={getLockAllText()}
                  onPress={lockAllHoles}
                  disabled={evaluation.status == EvalStatus.FINALIZED}
                />
              </div>
            </>
          )}
          <S.LiveCRInfoTextContainer>
            {branchRevision === 0 ? (
              <Skeleton height={20} width={'90%'} />
            ) : (
              <S.LiveCRInfoText>
                {collapsedMode ? `Rev: ` : `Revision: `}
                <div style={{ display: 'inline', fontWeight: 600 }}>
                  {branchRevision}
                </div>
              </S.LiveCRInfoText>
            )}
            {crVersion === 0 ? (
              <Skeleton height={20} width={'90%'} />
            ) : (
              <S.LiveCRInfoText>
                {collapsedMode ? `CR v. ` : `LiveCR Version: `}
                <div style={{ display: 'inline', fontWeight: 600 }}>
                  {crVersion}
                </div>
              </S.LiveCRInfoText>
            )}
            <S.LiveCRInfoText>
              {!collapsedMode && 'Last synced: '}
              <DateTimeView
                dateString={getCrDate()}
                showTime={!collapsedMode}
              />
            </S.LiveCRInfoText>
          </S.LiveCRInfoTextContainer>
        </S.LiveCRInfoContainer>
      </S.EvalSideBar>
      {view?.adjustment === -1 && (
        <S.SideBarToggleButtonContainer>
          <SideBarToggleButton
            collapsedMode={collapsedMode}
            setCollapsedMode={setCollapsedMode}
          />
        </S.SideBarToggleButtonContainer>
      )}
    </>
  )
}

export default EvaluationSideBar
