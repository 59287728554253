import React from 'react'
import ContentHeading from '../ContentHeading'
import { CourseOptionView } from '../../molecules/CourseInfo/styled'
import { LRClub } from '../../../types/Clubs'
import images from '@app/images'

type ContentClubHeaderProps = {
  title: string
  optionView: React.ReactNode
  club: LRClub
}

export default function ContentClubHeader({
  title,
  optionView,
  club,
}: ContentClubHeaderProps) {
  return (
    <div style={{ display: 'flex', gap: '18px', alignItems: 'center' }}>
      <div
        style={{
          padding: club?.clubLogoUrl ? '10px' : '0',
          border: '1px solid #ccc',
          borderRadius: '999px',
          width: club?.clubLogoUrl ? 50 : 60,
          height: club?.clubLogoUrl ? 50 : 60,
        }}
      >
        <img
          src={club?.clubLogoUrl || images.png.CourseFallback}
          alt={club.name}
          onError={(e: any) => {
            e.target.src = images.png.CourseFallback
          }}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ContentHeading>{title}</ContentHeading>
        <CourseOptionView style={{ justifyContent: 'normal' }}>
          {optionView}
        </CourseOptionView>
      </div>
    </div>
  )
}
