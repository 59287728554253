import React, { useState } from 'react'
import {
  ContentCard,
  ContentHeading,
} from '@app/evaluation/components/Preparation.styles'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { FunctionComponent } from 'react'
import Spacer from 'react-styled-spacer'
import { InputField } from '../../../pages/Support/styled'

type Props = {
  handleMissingNameFinalize: (name: string) => void
  onClose: () => void
}

const MissingRatingNameModal: FunctionComponent<Props> = ({
  handleMissingNameFinalize,
  onClose,
}) => {
  const [nameInput, setNameInput] = useState('')
  const [error, setError] = useState<string | null>(null)

  const handleConfirm = () => {
    if (nameInput.length === 0) {
      setError('Please provide a name')
      return
    }
    handleMissingNameFinalize(nameInput)
  }

  return (
    <ContentCard style={{ width: '45%', position: 'relative' }}>
      <div style={{ position: 'relative' }}>
        <ContentHeading>Please provide a name for the rating</ContentHeading>
        <span style={{ fontSize: 14 }}>
          The name is used to identify the rating.
        </span>
        <Spacer h={16} />
        <InputField
          placeholder="Rating name"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
        />
        <Spacer h={16} />
        {error && (
          <>
            <span style={{ color: 'red' }}>{error}</span>
            <Spacer h={16} />
          </>
        )}
        <RoundedButton title="Confirm" onPress={handleConfirm} />
      </div>
    </ContentCard>
  )
}

export default MissingRatingNameModal
