import theme from '@app/ui/theme'
import React, { FunctionComponent } from 'react'
import { Text } from 'react-native'
import * as S from './styled'

interface Props {
  value: string
  description?: string
  width?: number
  variant?: 'outlined' | 'prev' | 'new'
  flex?: boolean
}

const Field: FunctionComponent<Props> = ({
  value,
  width,
  variant,
  description,
  flex,
}) => {
  const coloredBackground =
    variant === 'prev' ? theme.colors.prevField : theme.colors.newField
  return (
    <S.OuterContainer flex={flex} width={width ? width : 64}>
      <S.FieldContainer
        borderWidth={variant === 'outlined' ? 2 : 0}
        backgroundColor={
          variant === 'outlined' ? theme.colors.light3 : coloredBackground
        }
      >
        <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{value}</Text>
      </S.FieldContainer>
      <Text style={{ fontSize: 12 }}>{description}</Text>
    </S.OuterContainer>
  )
}

export default Field
