import * as types from './actionTypes'

import { AdjustmentsState, GolferType } from '../types'
import { initialGolfer, userView } from '../initialValues'

import { SET_NOTIFICATIONS } from '@app/notifications/reducers/notifications'
import { State } from '@app/state/types'
import firebase from 'firebase'
import initialState from '@app/state/reducers/initialState'

export function setAdjustmentsAction(value: AdjustmentsState) {
  return { type: types.SET_ADJUSTMENTS, value }
}
export function setCourseSettingsAction(value: AdjustmentsState) {
  return { type: types.SET_COURSE_SETTINGS, value }
}
export function setModificationsAction(value: any) {
  return { type: types.SET_MODIFICATIONS, value }
}

interface ActionListeners {
  ref?: any
  adjustments?: any
  courseSettings?: any
  courseSettingsRef?: any
  modifications?: any
  modificationsRef?: any
  selectedHole?: number
  tee?: number
}

let listeners: ActionListeners = {}

export function clearAdjustmentsAction() {
  return { type: types.SET_ADJUSTMENTS, value: initialState.adjustments }
}

export function clearAdjustments() {
  return (dispatch) => {
    if (listeners.adjustments && listeners.adjustments !== null) {
      listeners.ref.off('value', listeners.adjustments)
      listeners.ref.off('value', listeners.courseSettings)
      listeners.ref.off('value', listeners.modifications)
      listeners = {}
    }

    return dispatch(clearAdjustmentsAction())
  }
}

export function getAdjustmentsRootPath(evaluationId: string) {
  return `adjustments/${evaluationId}/adjustment/values`
}
export function getAdjustmentsHolePath(evaluationId: string, hole: number) {
  return `adjustments/${evaluationId}/adjustment/holes/${hole}`
}
export function getCourseSettingsPath(evaluationId: string) {
  return `adjustments/${evaluationId}/adjustment/course/`
}
export function getModificationsPath(evaluationId: string, hole: number) {
  return `adjustments/${evaluationId}/modifications/${hole}`
}

export function getUserViewFromState(state: State) {
  if (!state || !state.user) {
    return userView()
  }
  const evalUser = state.evaluation?.evaluation?.users[state?.user.id]
  if (!evalUser) {
    return userView()
  }
  return evalUser?.view
}

export function startAdjustments() {
  return (dispatch, getState: () => State) => {
    const state = getState().evaluation
    const view = getUserViewFromState(getState())

    if (!state.currentId) {
      return
    }
    const path = getAdjustmentsHolePath(state.currentId, view?.hole)

    listeners.ref = firebase.database().ref(path)
    listeners.adjustments = listeners.ref.on('value', (snapshot) => {
      const adjustments = snapshot.val()
      dispatch(setAdjustments(adjustments))
    })

    listeners.courseSettingsRef = firebase
      .database()
      .ref(getCourseSettingsPath(state.currentId))
    listeners.courseSettings = listeners.courseSettingsRef.on(
      'value',
      (snapshot) => {
        const adjustments = snapshot.val()
        dispatch(setCourseSettings(adjustments))
      }
    )
    if (view?.hole || view?.hole === 0) {
      listeners.modificationsRef = firebase
        .database()
        .ref(getModificationsPath(state.currentId, view?.hole))
      listeners.modifications = listeners.modificationsRef.on(
        'value',
        (snapshot) => {
          const adjustments = snapshot.val()
          dispatch(setModifications(adjustments))
        }
      )
    }
  }
}

export function initAdjustments(init = false) {
  return (dispatch, getState) => {
    // Check if we need to update other listeners
    const view = getUserViewFromState(getState())

    if (
      listeners.adjustments &&
      listeners.selectedHole === view?.hole &&
      listeners.tee === view?.tee &&
      !init
    ) {
      return
    }

    dispatch(clearAdjustments())
    return dispatch(startAdjustments())
  }
}

export function setAdjustments(value: any) {
  return (dispatch, getState: () => State) => {
    dispatch(setAdjustmentsAction(value))
    const view = getUserViewFromState(getState())
    listeners.selectedHole = view?.hole
    listeners.tee = view?.tee
  }
}
export function setCourseSettings(value: any) {
  return (dispatch, getState: () => State) => {
    dispatch(setCourseSettingsAction(value))
  }
}

export function setModifications(value: any) {
  return (dispatch, getState: () => State) => {
    dispatch(setModificationsAction(value))
  }
}
