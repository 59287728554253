import {
  ContentCard,
  ContentHeading,
  ContentSeparator,
  TeamInvitationArea,
  TeamTable,
  TeamTableCol,
  TeamTableColTop,
  TeamTableRow,
} from './Preparation.styles'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import FoundationIcon from '@ovaeasy/react-native-vector-icons/Foundation'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { TeamActionMenu } from './TeamActionMenu'
import { TeamRole } from '@app/evaluation-core/types'
import { setUserColor } from '@app/evaluation-core/actions/evaluationActions'
import theme from '@app/ui/theme'
import { useEvaluation } from '@common/hooks'
import useInvite from '@common/hooks/useInvite'
import useUser from '@common/hooks/useUser'
import useReadOnly from '../hooks/useReadOnly'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { EvalStatus } from '@app/courses/domain/ratingStatus'

const validateEmail = (value: string) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  )

const MEMBER_STATUS = ['Pending Invitation', 'Active', 'Declined', 'Expired']
const MEMBER_ROLE = ['Leader', 'Member']

export const PreparationTeam: React.FC = () => {
  const { currentId, evaluation } = useEvaluation()
  const evalUser = useEvaluationUser()
  const user = useUser()
  const [email, setEmail] = useState('')
  const userId = useUser()?.id
  const [color, setColor] = useState(theme.colors.primary)
  const [changingId, setChangingId] = useState('')
  const handleReadOnly = useReadOnly()
  const [loadingInvite, setLoadingInvite] = useState(false)
  const canEdit = evaluation.status !== EvalStatus.FINALIZED

  const dispatch = useDispatch<any>()
  const { sendInvite } = useInvite()

  if (!evaluation || !userId || !currentId) return null

  async function sendInvitation() {
    setLoadingInvite(true)
    await sendInvite(email, currentId, () => setEmail('')).finally(() =>
      setLoadingInvite(false)
    )
  }

  const handleChangeComplete = (color: any, userId?: string) => {
    setColor(color.hex)
    dispatch(setUserColor(color.hex, userId ?? changingId))
  }

  const tableRowWidths = '35% 20% 20% 5% 20%'

  const sortedUsers = Object.entries(evaluation.users)
    .sort(([userIdA], [userIdB]) => {
      if (+userIdA === +userIdB) {
        return 0
      }
      return +userIdA < +userIdB ? -1 : 1
    })
    .map(([_userId, user]) => user)
    .filter((user) => user.role !== TeamRole.READ_ONLY)
  //TODO: This prohibits users to see themself in new values. But maybe there is a thinking to this?
  // .filter(
  //   (listUser) => user.isAdmin || listUser.role !== TeamRole.SYSTEM_ADMIN
  // )

  return (
    <>
      <ContentCard>
        <ContentHeading>Team</ContentHeading>
        <ContentSeparator />

        <TeamTable>
          <TeamTableRow height="40px" width={tableRowWidths}>
            <TeamTableColTop>Email</TeamTableColTop>
            <TeamTableColTop>Role</TeamTableColTop>
            <TeamTableColTop>Color</TeamTableColTop>
            <TeamTableColTop></TeamTableColTop>
            <TeamTableColTop></TeamTableColTop>
          </TeamTableRow>
          {sortedUsers.map((user, index: number) => {
            const defaultColor = user?.view?.color ?? theme.colors.primary
            const isOwnUser = userId == +user.userId

            return (
              <TeamTableRow height="60px" width={tableRowWidths} key={index}>
                <TeamTableCol isOwnUser={isOwnUser}>
                  {user?.view?.email}
                </TeamTableCol>

                <TeamTableCol>
                  {user.role === TeamRole.LEADER && (
                    <>
                      <FoundationIcon
                        name="crown"
                        color={defaultColor}
                        size={50}
                      />
                    </>
                  )}
                  {user.role === TeamRole.SYSTEM_ADMIN && (
                    <div style={{ width: 50, textAlign: 'left' }}>
                      <span style={{ fontWeight: 'bold', fontSize: 10 }}>
                        ADMIN
                      </span>
                    </div>
                  )}
                </TeamTableCol>
                <TeamTableCol>
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      background: defaultColor,
                      borderRadius: 500,
                    }}
                    onClick={() => {
                      dispatch({
                        type: 'SET_MODAL',
                        params: {
                          mode: 'colorOverlay',
                          defaultColor,
                          userId: user.userId,
                          handleChange: handleChangeComplete,
                        },
                      })
                      if (user.userId) {
                        setChangingId(user.userId)
                      }
                    }}
                  />
                </TeamTableCol>
                <TeamTableCol></TeamTableCol>
                <TeamTableCol>
                  {user.userId && <TeamActionMenu userId={user.userId} />}
                </TeamTableCol>
              </TeamTableRow>
            )
          })}
        </TeamTable>
        {(evalUser.isAdmin || user.isAdmin) && (
          <TeamInvitationArea>
            {/* <input */}
            {/*   type="text" */}
            {/*   value={email} */}
            {/*   placeholder="Enter E-mail/Golf-ID" */}
            {/*   autoCapitalize="off" */}
            {/*   onChange={(event) => setEmail(event.target.value)} */}
            {/*   disabled={!canEdit} */}
            {/* /> */}
            <RoundedButton
              loading={loadingInvite}
              onPress={() => {
                dispatch({
                  type: 'SET_MODAL',
                  params: {
                    mode: 'searchInvitableRaters',
                  },
                })
                // handleReadOnly(async () => sendInvitation())
              }}
              title={'Invite Team Member'}
              width={200}
              disabled={!canEdit}
            />
          </TeamInvitationArea>
        )}
      </ContentCard>
    </>
  )
}
