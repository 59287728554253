const BUILD_ENV: 'localhost' | 'test' | 'production' = 'production'

const MAPBOX_ACCESS_TOKENS = {
  localhost:
    'pk.eyJ1IjoibWFwcGluZ2luZHVzdHJpZXMiLCJhIjoiY2tidWxibDU0MGt1ZzJxcXo0cGkyazM2OCJ9.LCLcvn7MSokLV2oJdDjKjg',
  test: 'pk.eyJ1IjoibWFwcGluZ2luZHVzdHJpZXMiLCJhIjoiY2tidWxibDU0MGt1ZzJxcXo0cGkyazM2OCJ9.LCLcvn7MSokLV2oJdDjKjg',
  production:
    'pk.eyJ1IjoibWFwcGluZ2luZHVzdHJpZXMiLCJhIjoiY2tidWxibDU0MGt1ZzJxcXo0cGkyazM2OCJ9.LCLcvn7MSokLV2oJdDjKjg',
}

export const MAPBOX_ACCESS_TOKEN = MAPBOX_ACCESS_TOKENS[BUILD_ENV]

export const liveGisCourseUrl = (cid: string) =>
  `https://www.livegis.com/course-map.php?course=${cid}`

export const MAP_INIT_CENTER = [14.667289, 59.697116]
export const MAP_INIT_ZOOM = [4]
export const MAP_STYLE = 'mapbox://styles/mapbox/streets-v9'
