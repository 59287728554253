import {
  RESET,
  SET_ADJUSTMENTS,
  SET_COURSE_SETTINGS,
  SET_MODIFICATIONS,
} from '@app/evaluation-core/actions/actionTypes'
import { AdjustmentsAction, AdjustmentsState } from '@app/evaluation-core/types'

import initialState from './initialState'

export default function evaluation(
  state: AdjustmentsState = { adjustments: '', modifications: '', values: '' },
  action: AdjustmentsAction
) {
  switch (action.type) {
    case SET_ADJUSTMENTS:
      return { ...state, adjustments: action.value }
    case SET_COURSE_SETTINGS:
      return { ...state, courseSettings: action.value }
    case SET_MODIFICATIONS:
      return { ...state, modifications: action.value }
    case RESET:
      return initialState
    default:
      return state
  }
}
