import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import React from 'react'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'

const ELEVATION_FACTORS: AdmFactorGroup[] = [
  {
    factors: [],
  },
]

export const Elevation = () => {
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  if (!evaluation || !view) return null

  return (
    <Adjustment
      useLz={false}
      tweener={false}
      factors={ELEVATION_FACTORS}
      parameters={[
        {
          type: 'numeric-input',
          label: 'Change in Elevation from the Tee to Green',
          adjustmentKey: 'measuredElevation',
          format: 'tee',
          unit: 'feet',
        },
        {
          type: 'numeric-input',
          label: 'Rounded Elevation',
          inputDisabled: true,
          format: 'tee',
          adjustmentKey: 'el_final',
          notExpandible: true,
        },
      ]}
    />
  )
}
