import React from 'react'
import * as S from './styled'

import { FBREF_COURSE_EVALUATIONS } from '@app/evaluation-core/refs'
import { useFirebaseConnect } from 'react-redux-firebase'
import { LRClub } from '../../../types/Clubs'
import CourseListItem from './CourseListItem'
import { RatingTemplate } from '../../../types/Templates'
import { navigate } from '@app/navigation'
import { InLineSpinner } from '@app/evaluation/components/Spinner'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import CompositeListItem from './CompositeListItem'
import { useAxios } from '@common/hooks/useAxios'
import { useApi } from '@common/hooks/useApi'
import { MiProjectBranch } from '../../../types/Project'

type Props = {
  club: LRClub
  templates: RatingTemplate[] | undefined
  loading: boolean
  composite?: boolean
}

const TemplateList: React.FC<Props> = ({
  club,
  templates,
  loading,
  composite,
}) => {
  // WTF Is this xD
  //useFirebaseConnect([{ path: FBREF_COURSE_EVALUATIONS() }])

  const { data, isLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${club.id}`
  )

  async function createMappedRatingTemplate(template: RatingTemplate) {
    const holeIds = template.holeIds.map((hole) => hole)
    const route = composite ? 'CompositeCoursePage' : 'CreateRating'
    navigate(route, {
      holeIds,
      templateId: template.id,
      club,
      branchId: club.branchID,
    })
  }

  if (loading || isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InLineSpinner size={32} />
      </div>
    )
  }

  if (!templates || (templates?.length <= 0 && !loading)) {
    return <span>No composite courses exist for this golf club.</span>
  }

  return (
    <S.TableContainer>
      <S.Table>
        <S.TableRow>
          <S.TableHeadCol style={{ width: '25%' }}>Name</S.TableHeadCol>
          <S.TableHeadCol style={{ width: '15%' }}>Holes</S.TableHeadCol>
          <S.TableHeadCol style={{ width: '30%' }}>No. Forms</S.TableHeadCol>
          <S.TableHeadCol style={{ width: '30%' }}></S.TableHeadCol>
        </S.TableRow>
        <S.TableBody className="courses-list">
          {templates
            .sort((a, b) => +a.name - +b.name)
            .map((template) => (
              <CompositeListItem
                key={template.id}
                template={template}
                branchId={data?.projectBranchID}
                club={club}
                createMappedRatingTemplate={createMappedRatingTemplate}
              />
            ))}
        </S.TableBody>
      </S.Table>
    </S.TableContainer>
  )
}
export default TemplateList
