import styled from 'styled-components/native'
import theme from '@app/ui/theme'

export const OuterContainer = styled.View<{ portraitMode: boolean }>`
  background-color: ${theme.colors.white};
  width: ${({ portraitMode }) => (portraitMode ? '700px' : '850px')};
  margin-top: 16;
  border-radius: 7px;
  border-width: 1px;
  border-color: ${theme.colors.light4};
`

export const UpperContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-left: 28px;
  margin-top: 12px;
`

export const TitleText = styled.Text`
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
`
export const AcceptOrDeclineAllContainer = styled.View`
  flex-direction: row;
`
