import React, { useState } from 'react'

import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'
import { GolferShortName } from '@app/evaluation-core/types'
import theme from '@app/ui/theme'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'

export const Psychological = () => {
  const { evaluation } = useEvaluation()
  const [psychObj, setPsychObj] = useState({
    rating: 0,
    ratingTotal: 0,
    greaterThanFiveValues: 0,
    greaterThanTenValues: 0,
    extreme: false,
    extraordinary: false,
    obstacleValue: 0,
  })

  const CHECK_SIZE = 28
  const PSYCH_FACTORS: AdmFactorGroup[] = [
    {
      factors: [
        {
          label: 'E',
          itemKey: 'psych_extreme',
          format: 'golfer',
          disabled: true,
          items: [
            {
              disabled: true,
              selected: psychObj.extreme,
              value: 1,
              text: `If any other obstacle(s) have an EXTREME rating of 10`,
              renderButtonText: () => (
                <FeatherIcon
                  name="x"
                  size={CHECK_SIZE}
                  color={theme.colors.white}
                />
              ),
            },
          ],
        },
        {
          label: 'X',
          golfers: [GolferShortName.BogeyWomen],
          itemKey: 'psych_extraordinary',
          format: 'golfer',
          items: [
            {
              disabled: true,
              selected: psychObj.extraordinary,
              text: `The minimum Psychological rating for women bogey players is dependent on the bogey women’s Crossing Obstacle or Lateral Obstacle rating`,
              renderButtonText: () => (
                <FeatherIcon
                  name="x"
                  size={CHECK_SIZE}
                  color={theme.colors.white}
                />
              ),
            },
          ],
        },
      ],
    },
  ]

  if (!evaluation) return null

  return (
    <Adjustment
      tweener={false}
      factors={PSYCH_FACTORS}
      parameters={[
        {
          type: 'numeric-input',
          label: 'Psychological Rating',
          format: 'golfer',
          inputDisabled: true,
          adjustmentKey: 'psych_final',
          notExpandible: true,
        },
        {
          type: 'numeric-input',
          label: 'Sum of Obstacle Values Rated 5 or Greater',
          format: 'golfer',
          inputDisabled: true,
          adjustmentKey: 'psych_ratingTotal',
          notExpandible: true,
        },
        {
          type: 'numeric-input',
          label: 'Number of Obstacles Rated 5 or Greater',
          format: 'golfer',
          inputDisabled: true,
          adjustmentKey: 'psych_greaterThanFiveValues',
          notExpandible: true,
        },
        {
          type: 'numeric-input',
          label: 'Number of Obstacles Rated 10',
          format: 'golfer',
          inputDisabled: true,
          adjustmentKey: 'psych_greaterThanTenValues',
          notExpandible: true,
        },
        {
          type: 'numeric-input',
          label: 'Crossing/Lateral Ratings',
          format: 'golfer',
          inputDisabled: true,
          adjustmentKey: 'psych_obstacleValue',
          notExpandible: true,
          shouldRender: (adjustment, view) =>
            view.golfer === GolferShortName.BogeyWomen,
        },
        {
          type: 'table-value',
          label: 'Table Rating',
          format: 'golfer',
          inputDisabled: true,
          adjustmentKey: 'psych_rating',
          notExpandible: true,
        },
      ]}
    />
  )
}
