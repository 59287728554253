import Spacer from '@app/components/atoms/Spacer'
import HoleCard from '@app/components/molecules/HoleCard/HoleCard'
import getEnv from '@app/environments/getEnv'
import { useSnackbar } from '@app/snackbar'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import theme from '@app/ui/theme'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import * as S from './Slope.style'
import Tees from './Tees'

type Tee = {
  title: string
  slopeRating: number
  courseRating: number
}

type Gender = 'MEN' | 'WOMEN'

const BASE_URL_TMP = getEnv().api + '/ratings'

function openWindowAsPost(url, windowoption, name, params) {
  const form = document.createElement('form')
  form.setAttribute('method', 'post')
  form.setAttribute('action', url)
  form.setAttribute('target', name)
  for (const i in params) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = i
    if (typeof params[i] === 'object') {
      input.value = JSON.stringify(params[i])
    } else {
      input.value = params[i]
    }
    form.appendChild(input)
  }
  document.body.appendChild(form)
  //note I am using a post.htm page since I did not want to make double request to the page
  //it might have some Page_Load call which might screw things up.
  window.open('post.htm', name, '_blank')
  form.submit()
  document.body.removeChild(form)
}

const Slope = () => {
  const [tees, setTees] = useState<Tee[]>([])
  const [gender, setGender] = useState<Gender>('MEN')
  const [courseName, setCourseName] = useState<string>()
  const [par, setPar] = useState<number>(3)
  const smallDevice = screen.width < 1300
  const [openSnackbar] = useSnackbar()
  const selectGender = (gender: Gender) => () => setGender(gender)
  const INPUT_PLACEHOLDER = 'Tap here to start typing'

  const snackbarText = 'Please fill in all the fields and add at least one tee'

  const addTee = (tee: Tee) => setTees([...tees, tee])

  const deleteTee = (teeToDelete: Tee) => {
    const filteredTees = tees.filter((tee) => tee.title !== teeToDelete.title)
    setTees(filteredTees)
    dispatch({
      type: 'SET_MODAL',
      params: null,
    })
  }

  const editTee = (teeToUpdate: Tee, update: Tee) => {
    const filteredTees = tees.filter((tee) => tee.title !== teeToUpdate.title)
    setTees([...filteredTees, update])
  }

  const dispatch = useDispatch<any>()
  return (
    <S.Wrapper>
      <S.MainContainer>
        <S.TopContainer>
          <S.Header>
            <S.Title>Create Slope Tables</S.Title>
            <S.HeaderButtonContainer>
              <RoundedButton
                width={124}
                title="Clear all"
                onPress={() => setTees([])}
              />
              <Spacer width={20} />
              <RoundedButton
                width={189}
                title="Generate slope table "
                onPress={() => {
                  if (!tees.length || !par || !courseName || !gender) {
                    openSnackbar(snackbarText)
                    return
                  }
                  openWindowAsPost(
                    `${BASE_URL_TMP}/forms/slopetable/`,
                    null,
                    'Slope Table',
                    { tees, par, courseName, gender }
                  )
                }}
                style={{
                  backgroundColor: theme.colors.secondary2,
                }}
              />
            </S.HeaderButtonContainer>
          </S.Header>
        </S.TopContainer>
        <Spacer height={44} />
        {smallDevice ? (
          <>
            <S.MiddleContainer>
              <S.Gender>
                <S.Label>Gender</S.Label>
                <Spacer height={12} />
                <S.ButtonContainer>
                  <RoundedButton
                    title="MEN"
                    onPress={selectGender('MEN')}
                    width={160}
                    style={{
                      backgroundColor:
                        gender === 'MEN' ? theme.colors.secondary2 : '',
                    }}
                  />
                  <Spacer width={12} />
                  <RoundedButton
                    title="WOMEN"
                    onPress={selectGender('WOMEN')}
                    style={{
                      backgroundColor:
                        gender === 'WOMEN' ? theme.colors.secondary2 : '',
                    }}
                    width={160}
                  />
                </S.ButtonContainer>
              </S.Gender>
            </S.MiddleContainer>
            <Spacer height={24} />
            <S.MiddleContainer>
              <S.Course>
                <S.Label>Course name</S.Label>
                <Spacer height={12} />
                <S.Input
                  placeholder={INPUT_PLACEHOLDER}
                  onChange={({ target }) => setCourseName(target.value)}
                />
              </S.Course>
              <Spacer width={12} />
              <S.Par>
                <S.Label>Par</S.Label>
                <Spacer height={12} />
                <S.Input
                  type="number"
                  min="3"
                  max="6"
                  defaultValue={3}
                  placeholder={INPUT_PLACEHOLDER}
                  onChange={({ target }) => setPar(+target.value)}
                />
              </S.Par>
            </S.MiddleContainer>
          </>
        ) : (
          <S.MiddleContainer>
            <S.Gender>
              <S.Label>Gender</S.Label>
              <Spacer height={12} />
              <S.ButtonContainer>
                <RoundedButton
                  title="MEN"
                  onPress={selectGender('MEN')}
                  width={160}
                  style={{
                    backgroundColor:
                      gender === 'MEN' ? theme.colors.secondary2 : '',
                  }}
                />
                <Spacer width={12} />
                <RoundedButton
                  title="WOMEN"
                  onPress={selectGender('WOMEN')}
                  style={{
                    backgroundColor:
                      gender === 'WOMEN' ? theme.colors.secondary2 : '',
                  }}
                  width={160}
                />
              </S.ButtonContainer>
            </S.Gender>
            <Spacer width={12} />
            <S.Course>
              <S.Label>Course name</S.Label>
              <Spacer height={12} />
              <S.Input
                placeholder={INPUT_PLACEHOLDER}
                onChange={({ target }) => setCourseName(target.value)}
              />
            </S.Course>
            <Spacer width={12} />
            <S.Par>
              <S.Label>Par</S.Label>
              <Spacer height={12} />
              <S.Input
                type="number"
                min="3"
                max="6"
                defaultValue={3}
                placeholder={INPUT_PLACEHOLDER}
                onChange={({ target }) => setPar(+target.value)}
              />
            </S.Par>
          </S.MiddleContainer>
        )}
        <Spacer height={20} />
        <S.Line />
        <S.BottomContainer>
          <Spacer height={37} />
          <S.TeeContainer>
            {tees.map((tee, index) => (
              <Tees
                key={index}
                title={tee.title}
                courseRating={tee.courseRating}
                slopeRating={tee.slopeRating}
                onClick={() => {
                  dispatch({
                    type: 'SET_MODAL',
                    params: {
                      props: {
                        type: 'edit',
                        addTee,
                        deleteTee,
                        editTee,
                        tee,
                      },
                      mode: 'addTee',
                    },
                  })
                }}
              />
            ))}
            <S.AddTeeCard
              onClick={() => {
                dispatch({
                  type: 'SET_MODAL',
                  params: {
                    mode: 'addTee',
                    props: {
                      addTee,
                      deleteTee,
                      editTee,
                    },
                  },
                })
              }}
            >
              <div>
                <S.TeeAddButton>
                  <span>+</span>
                </S.TeeAddButton>
                <Spacer height={12} />
                <S.TeeButtonTitle>Add a tee</S.TeeButtonTitle>
              </div>
            </S.AddTeeCard>
          </S.TeeContainer>
        </S.BottomContainer>
      </S.MainContainer>
    </S.Wrapper>
  )
}
export default Slope
