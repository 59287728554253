import theme from '@app/ui/theme'
import React from 'react'
import FeatherIcon from '@ovaeasy/react-native-vector-icons/Feather'

import * as S from './styled'
interface SideBarToggleButtonProps {
  collapsedMode: boolean
  setCollapsedMode: React.Dispatch<React.SetStateAction<boolean>>
}

const SideBarToggleButton = ({
  collapsedMode,
  setCollapsedMode,
}: SideBarToggleButtonProps) => {
  return (
    <S.Container onClick={() => setCollapsedMode(!collapsedMode)}>
      <S.IconContainer isOpen={collapsedMode}>
        <FeatherIcon
          name="chevrons-right"
          size={42}
          color={theme.colors.secondary2}
          fill={theme.colors.secondary2}
        />
      </S.IconContainer>
    </S.Container>
  )
}

export default SideBarToggleButton
