import { Eval } from '@app/evaluation-core/types'
import { useEffect, useRef, useState } from 'react'

type EvalWithKey = Eval & { key: string }

const useHighlightUsers = (
  evaluations: EvalWithKey[],
  timeoutDuration: number
) => {
  const [usersViewIsChanged, setUsersViewIsChanged] = useState<string[]>([])
  const prevEvaluationsRef = useRef<EvalWithKey[]>(evaluations)

  useEffect(() => {
    prevEvaluationsRef.current = evaluations
    if (usersViewIsChanged.length === 0) return

    const timeout = setTimeout(() => {
      setUsersViewIsChanged([])
    }, timeoutDuration)

    return () => clearTimeout(timeout)
  }, [usersViewIsChanged, timeoutDuration])

  useEffect(() => {
    const changedUsers: string[] = []

    evaluations.forEach((evaluation) => {
      const { key, users } = evaluation
      if (!users) {
        console.log('No users in evaluation', evaluation)
        return
      }
      const previousUsers =
        prevEvaluationsRef.current.find((e) => e.key === key)?.users || {}

      Object.values(users).forEach((user) => {
        const previousUser = previousUsers[user.userId as string]
        const isHoleChanged =
          previousUser?.view?.hole !== user.view?.hole && user.view?.hole !== 0
        const isTeeChanged =
          previousUser?.view.tee !== user.view.tee && user.view.tee !== 0

        if (isHoleChanged || isTeeChanged) {
          changedUsers.push(user.view.email + key)
        }
      })
    })

    if (changedUsers.length === 0) return
    setUsersViewIsChanged(changedUsers)
  }, [evaluations])

  return usersViewIsChanged
}

export default useHighlightUsers
