import { CourseOptionSelect } from '@app/evaluation-settings-menu/HeaderArea.styles'
import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const OptionalAdjustmentSkeleton = () => {
  return (
    <div
      style={{
        width: '80px',
        height: '36px',
      }}
    >
      <CourseOptionSelect>
        <Skeleton width={160} style={{ minHeight: 26 }} borderRadius={5} />
        <Skeleton width={160} style={{ minHeight: 26 }} borderRadius={5} />
      </CourseOptionSelect>
    </div>
  )
}

export default OptionalAdjustmentSkeleton
