import React, { useState } from "react";
import FeatherIcon from "@ovaeasy/react-native-vector-icons/Feather";
import Tooltip from "react-tooltip";
import RoundedButton from "@app/ui/rounded-button/RoundedButton";
import theme from "@app/ui/theme";
import * as S from "./styled";
import { ProjectCourse } from "../../../types/Project";
import { LRCourse } from "../../../types/Clubs";
import { MiProjectStatus } from "@app/evaluation-core/types";
import DateTimeView from "../../atoms/DateTimeView";
import GenderIcon from "../../atoms/GenderIcon";
import { Text } from "react-native";
import { EvaluationDoc } from "../../../pages/CreateRatingPage/CreateRatingPage";
import { useApi } from "@common/hooks/useApi";
import EvalStatusIndicator from "./EvalStatusIndicator";
import { EvalStatus } from "@app/courses/domain/ratingStatus";
import { DropDownMenu } from "@app/ui/dropdown-menu/DropDownMenu";
import { DropDownMenuItem } from "@app/ui/dropdown-menu/DropDownMenu.styles";
import TwoButtonModal from "../../organisms/TwoButtonModal";
import CourseScorecardModal from "../CourseScorecardModal/CourseScorecardModal";
import { useRealTimeEvals } from "@common/hooks/useRealTimeEvals";

type CourseListItemProps = {
  hasActiveEval: boolean;
  startEvaluation: () => Promise<void>;
  buttonText: string;
  projectCourse: Partial<ProjectCourse>;
  clubId: string;
  projectStatus?: MiProjectStatus;
  lrCourse?: LRCourse;
  courseRateable?: boolean;
};

const CourseListItem: React.FC<CourseListItemProps> = ({
  projectCourse,
  lrCourse,
  clubId,
  startEvaluation,
  buttonText,
  courseRateable,
}) => {
  const holeIds = projectCourse.holes?.map((hole) => hole.physicalID);
  const [scorecardModalVisible, setScorecardModalVisible] = useState(false);

  const { data: evals, isLoading } = useApi<EvaluationDoc[]>(
    `/evaluations/import?holeId=${holeIds}&clubId=${
      clubId ?? lrCourse?.golfClubID
    }`,
    undefined,
  );

  // gets the highest status below FINALIZED
  const highestStatus = evals
    ?.filter((e) => e.status !== EvalStatus.FINALIZED)
    ?.sort((a, b) => {
      return b.status - a.status;
    })
    .at(0)?.status;

  const latestFinalizedEval = evals
    ?.filter((e) => e.finalizedAt !== undefined && e.finalizedAt !== null)
    .sort((a, b) => {
      if (!a.finalizedAt && !b.finalizedAt) return 0;
      if (!a.finalizedAt) return 1;
      if (!b.finalizedAt) return -1;
      return (
        new Date(b.finalizedAt).getTime() - new Date(a.finalizedAt).getTime()
      );
    })
    .at(0);

  const getStatus = () => {
    if (highestStatus) return highestStatus;
    else if (latestFinalizedEval) return EvalStatus.FINALIZED;
    return EvalStatus.NOT_STARTED;
  };

  return (
    <>
      <CourseScorecardModal
        projectCourse={projectCourse}
        isVisible={scorecardModalVisible}
        closeFn={() => setScorecardModalVisible(false)}
      />
      <S.TableRow>
        <S.TableCol>
          <div style={{ display: "flex", alignItems: "center" }}>
            {courseRateable !== undefined && !courseRateable && (
              <>
                <a data-for="not-rateable" data-tip="Please check LiveCR.">
                  <FeatherIcon
                    id="course-not-rateable-tooltip"
                    name="alert-triangle"
                    color={theme.colors.warning}
                    fill={theme.colors.warning}
                    size={24}
                    style={{ marginRight: 4 }}
                  />
                </a>
                <Tooltip
                  id="not-rateable"
                  className="not-rateable"
                  delayHide={1000}
                  textColor="white"
                  backgroundColor={theme.colors.primary}
                  effect="solid"
                  place="right"
                />
              </>
            )}

            {projectCourse.name}
          </div>
        </S.TableCol>

        <S.TableCol>
          {!highestStatus || isLoading || !evals
            ? <S.ItemStatusMainText>-</S.ItemStatusMainText>
            : <EvalStatusIndicator status={highestStatus} />}
        </S.TableCol>
        <S.TableCol>
          {isLoading || !evals || !latestFinalizedEval
            ? <S.ItemStatusMainText>-</S.ItemStatusMainText>
            : (
              <EvalStatusIndicator
                status={latestFinalizedEval?.status as EvalStatus}
                finalizedAt={latestFinalizedEval?.finalizedAt}
              />
            )}
        </S.TableCol>
        <S.TableCol>
          <S.TableColStatus>
            {lrCourse?.branchStatus.latestTimeSyncedDate &&
              (window.innerWidth > 1080
                ? (
                  <DateTimeView
                    dateString={lrCourse?.branchStatus.latestTimeSyncedDate}
                  />
                )
                : (
                  <DateTimeView
                    dateString={lrCourse?.branchStatus.latestTimeSyncedDate}
                    showTime={false}
                  />
                ))}
          </S.TableColStatus>
        </S.TableCol>

        <S.TableCol>
          <S.ClubHoleCount>{projectCourse.holeCount}</S.ClubHoleCount>
        </S.TableCol>
        <S.TableCol>
          <TeeListItem projectCourse={projectCourse} />
        </S.TableCol>
        <S.TableCol>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 4 }}>
            <RoundedButton
              onPress={() => setScorecardModalVisible(true)}
              title={"Scorecard"}
              testId="btn-start-course"
              style={{ width: "150px", height: "34px" }}
              disabled={projectCourse !== undefined && !projectCourse}
            />
            <RoundedButton
              isDefault={true}
              onPress={startEvaluation}
              title={buttonText}
              testId="btn-start-course"
              style={{
                width: "150px",
                height: "34px",
              }}
              disabled={courseRateable !== undefined && !courseRateable}
            />
          </div>
        </S.TableCol>
      </S.TableRow>
    </>
  );
};
export default CourseListItem;

function TeeListItem({
  projectCourse,
}: {
  projectCourse: Partial<ProjectCourse>;
}) {
  if (window.innerWidth > 1080) {
    return (
      <div
        style={{
          display: "flex",
          flexFlow: "row wrap",
          gap: 8,
        }}
      >
        {projectCourse.tees?.map((tee) => (
          <div
            style={{
              position: "relative",
              display: "flex",
              gap: 4,
              whiteSpace: "nowrap",
              flex: "1 0 auto",
            }}
            key={tee.id}
          >
            {["1", "2", "3"].includes(tee.gender) && (
              <GenderIcon genderKey={tee.gender} />
            )}
            {tee.name}
          </div>
        ))}
      </div>
    );
  }
  return (
    <DropDownMenu
      style={{
        width: "max-content",
        fontWeight: "600",
        height: "32px",
        boxShadow: "none",
        border: "1px solid #ccc",
        lineHeight: "32px",
        paddingLeft: "4px",
        paddingRight: "4px",
      }}
      value={`${projectCourse?.tees?.length ?? "?"}`}
      width={140}
    >
      {projectCourse.tees?.map((tee, idx) => (
        <DropDownMenuItem
          style={{
            textAlign: "left",
            paddingBottom: "0px",
            paddingTop: "0px",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          key={tee.id}
        >
          <Text style={{ marginRight: "4px" }}>{`${idx + 1}.`}</Text>
          <Text style={{ marginRight: "4px", fontWeight: "700" }}>
            {tee.name}
          </Text>
          {["1", "2", "3"].includes(tee.gender)
            ? (
              <GenderIcon
                style={{ marginLeft: "auto", border: "none" }}
                genderKey={tee.gender}
              />
            )
            : (
              <GenderIcon
                genderKey="1"
                style={{ marginLeft: "auto", border: "none", opacity: 0 }}
              />
            )}
        </DropDownMenuItem>
      ))}
    </DropDownMenu>
  );
}
