import theme from '@app/ui/theme'
import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
`

export const ContentCard = styled.div`
  margin: 20px;
  box-shadow: 0 0px 4px 0 ${({ theme }) => theme.colors.dark1};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  border-radius: 4px;
  overflow: scroll;
  height: calc(100% - 100px);
`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: scroll;
  padding-bottom: 26px;
`
export const FlexBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CourseContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 15px 0px 0px;
  margin-right: 30px;
`
export const CourseArrayContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 500px;
`

export const CourseName = styled.div`
  display: flex;
  flex-direction: column;
`
export const CourseNameInput = styled.input`
  width: 438px;
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 8px;
  z-index: 10;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
`

export const HolesCard = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 2px;
`

export const Title = styled.span`
  font-weight: 500;
  font-size: 24px;
`
export const HoleSelection = styled.div`
  width: 385px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 1px solid #cecece;
  padding: 8px 34px 8px 34px;
  z-index: 10;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
`

export const BottomBar = styled.div`
  width: 100%;
  height: 56px;
  background-color: ${theme.colors.white};
  position: absolute;
  bottom: 0px;
  z-index: 10;
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.25);
`

export const GoBackContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

export const BackArrowContainer = styled.div`
  margin-top: 2px;
`

export const BackText = styled.span`
  margin-left: 10px;
`

export const ComposedCourseHeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 2px;
`

export const ComposedCourseHeaderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
`

export const ComposedCourseHeaderItem = styled.div`
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.1);
`
