import React, { FunctionComponent } from 'react'
import { TeeRowTeeExpand } from './TeeItem.styles'
import { GrExpand } from 'react-icons/gr'
import { DropDownMenu } from '@app/ui/dropdown-menu/DropDownMenu'
import {
  AdminTitle,
  DropDownMenuItem,
} from '@app/ui/dropdown-menu/DropDownMenu.styles'
import { FormExGenderBtn } from '../screen/FormsTab/styled'
import { FaSync } from 'react-icons/fa'
import CopyTeeSubMenu from './CopyTeeSubMenu'
import { Eval, EvaluationUserView, MITee } from '@app/evaluation-core/types'

type OwnProps = {
  collapsed: boolean
  evaluation: Eval
  view: EvaluationUserView
  tee: MITee
  tees: MITee[]
  getTeeLength: (teeNumber: number) => number
  collapseTee: (e: any) => void
  handleResetTee: (teeIndex: number) => void
  handleResetTeeOnAllHoles: (teeIndex: number) => void
}

type Props = OwnProps

export const TeeOptionsModal: FunctionComponent<Props> = ({
  collapsed,
  evaluation,
  view,
  tees,
  tee,
  getTeeLength,
  collapseTee,
  handleResetTee,
  handleResetTeeOnAllHoles,
}) => {
  return (
    <>
      {collapsed ? (
        <TeeRowTeeExpand onClick={collapseTee}>
          <GrExpand />
        </TeeRowTeeExpand>
      ) : (
        <TeeRowTeeExpand>
          <DropDownMenu
            iconColor={'#000'}
            backgroundColor={'transparent'}
            value={''}
            isMenu={true}
            width={300}
            relative
            useShadow={false}
          >
            <DropDownMenuItem onPress={collapseTee}>
              <FormExGenderBtn>
                <span>Collapse</span>
                <div>
                  <GrExpand size={20} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
            <AdminTitle>Team leader functions</AdminTitle>
            {(evaluation.clubId === 459 || evaluation.clubId === 1409) && (
              <CopyTeeSubMenu
                evaluationId={evaluation.id!}
                currentHoleIndex={view?.hole}
                currentTeeIndex={tee.teeIndex}
                tees={tees}
                getTeeLengthFn={getTeeLength}
              />
            )}
            <DropDownMenuItem onPress={() => handleResetTee(tee.teeIndex)}>
              <FormExGenderBtn>
                <span>Reset tee</span>
                <div>
                  <FaSync size={20} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
            <DropDownMenuItem
              onPress={() => handleResetTeeOnAllHoles(tee.teeIndex)}
            >
              <FormExGenderBtn>
                <span>Reset tee x {evaluation?.holes.length}</span>
                <div>
                  <FaSync size={20} />
                </div>
              </FormExGenderBtn>
            </DropDownMenuItem>
          </DropDownMenu>
        </TeeRowTeeExpand>
      )}
    </>
  )
}

export default TeeOptionsModal
