const guidePages_2020 = [15, 17, 19, 23, 25, 27, 29, 31, 33, 35, 37, 39, 41]
const manualPages_2020 = [43, 44, 46, 51, 53, 55, 58, 61, 64, 65, 68, 71, 74]
const guidePages_2024 = [50, 52, 54, 59, 61, 64, 66, 68, 71, 72, 74, 76, 79]
const manualPages_2024 = [50, 52, 54, 59, 61, 64, 66, 68, 71, 72, 74, 76, 79]

export const getPageForAdjustment = (adjustment, type, manualVersion) => {
  const guidePages = manualVersion === 2020 ? guidePages_2020 : guidePages_2024
  const manualPages =
    manualVersion === 2020 ? manualPages_2020 : manualPages_2024
  const pages = type === 'guide' ? guidePages : manualPages
  return pages[adjustment]
}

const guideUrl_2020 = `https://firebasestorage.googleapis.com/v0/b/redmind-mapping-files/o/Course%20Rating%20System%20Guide%20Jan%202020_uppslag.pdf?alt=media&token=446116f2-a96d-4a6d-adca-40337ceb376d`
const manualUrl_2020 = `https://firebasestorage.googleapis.com/v0/b/redmind-dev-mapping-qa.appspot.com/o/pdf%2Fmanual.pdf?alt=media&token=19d65306-a44a-4deb-b38c-ad9b66a883d3`
const guideUrl_2024 =
  'https://storage.googleapis.com/redmind-mapping.appspot.com/2024%20WHS%20Course%20Rating%20System%20Manual.pdf'
const manualUrl_2024 =
  'https://storage.googleapis.com/redmind-mapping.appspot.com/2024%20WHS%20Course%20Rating%20System%20Manual.pdf'

export const RATING_URL = (page: number, type, manualVersion) => {
  const guideUrl = +manualVersion === 2020 ? guideUrl_2020 : guideUrl_2024
  const manualUrl = +manualVersion === 2020 ? manualUrl_2020 : manualUrl_2024
  return `${type === 'guide' ? guideUrl : manualUrl}#page=${page}`
}
