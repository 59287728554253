import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import { Heading } from '@app/ui/text'
import styled from 'styled-components'
import theme from '@app/ui/theme'

export const MenuHeading = styled(Heading)`
  margin-bottom: 0px;
  font-size: 22px;
`

export const MenuOverlay = styled.div<{ height: string | number }>`
  height: ${({ height }) =>
    typeof height === 'string' ? height : `${height}px`};
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;
`

export const MenuArea = styled.div`
  max-height: 240px;
  overflow: auto;
  overflow-x: hidden;

  & > a {
    display: block;
    color: ${theme.colors.primary2} !important;
    border-bottom: 1px solid ${theme.colors.light};
    line-height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  & > a > div {
    background-color: ${theme.colors.light};
    width: 20px;
    height: 20px;
    border-radius: ${theme.borderRadius}px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > a > div > div.active {
    background-color: transparent;
    width: 8px;
    height: 8px;
    border-radius: ${theme.borderRadius}px;
  }

  & > a > div > div.active {
    background-color: ${theme.colors.primary2};
  }

  & > a:last-child {
    border-bottom: none;
  }
`

export const TopLeft = styled.div`
  width: 130px;
`

export const TopTitle = styled.h2`
  font-size: 24px;
  font-weight: 550;
  line-height: 40px;
  display: block;
  text-align: center;
  color: ${theme.colors.primary2};
  word-wrap: break-word;
  white-space: break-spaces;
`

export const ArrowButton = styled.a`
  background-color: ${theme.colors.light};
  height: 36px;
  line-height: 36px;
  color: ${theme.colors.white};
  display: inline-block;
  border-radius: ${theme.borderRadius}px;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 36px;

  & > span {
    line-height: 36px;
    display: block;
    margin: 10px;
  }

  &:active {
    opacity: 0.7;
  }
`

export const ArrowIconNext = styled(FaAngleRight)`
  color: rgba(0, 0, 0, 0.7);
  font-size: 10px;
  height: 40px;
  margin: 10px;
`

export const ArrowIconPrev = styled(FaAngleLeft)`
  color: rgba(0, 0, 0, 0.7);
  font-size: 10px;
  height: 40px;
  margin: 10px;
`

interface OverlayTopProps {
  showLandingZones?: boolean
}

export const OverlayTop = styled.div`
  flex: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direcion: row;
  background-color: #fff;
  height: ${(props: OverlayMainProps) =>
    props.showLandingZones ? '100px' : '80px'};
  border-bottom: 1px solid ${theme.colors.light};
`
export const TopInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const OverlayTopNav = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`

export const OverlaySubHeader = styled.div`
  flex: 1;
  height: 26px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  display: flex;
`

interface OverlaySubHeaderTabProps {
  selected?: boolean
  isPortrait?: boolean
  disabled?: boolean
}

export const OverlaySubHeaderTab = styled.a`
  display: block;
  margin-left: 5px;
  margin-right: 5px;
  background-color: ${(props: OverlaySubHeaderTabProps) =>
    props.selected ? theme.colors.primary : theme.colors.light};
  color: ${(props: OverlaySubHeaderTabProps) =>
    props.selected ? theme.colors.white : 'rgba(0,0,0,.8)'};
  padding-left: 10px;
  padding-right: 10px;
  height: 34px;
  line-height: 34px;
  font-size: 15px;
  font-weight: 460;
  width: ${({ isPortrait }) => (!isPortrait ? 130 : 80)}px;
  transition: all 0.2s ease-in-out;
  border-radius: ${theme.borderRadius}px;
  margin-top: 7px;
  text-align: center;
  letter-spacing: 0.6;
  cursor: pointer;
  z-index: 1;
  ${(props: OverlaySubHeaderTabProps) =>
    props.disabled && `pointer-events: none;`};
`

interface OverlayMainProps {
  showLandingZones?: boolean
}

export const OverlayMain = styled.div`
  height: inherit;
  overflow-y: hidden;
  overflow-x: auto;
`
// Test code to disable adjustmets when readonly
/*pointer-events: ${({ disabled }) => (disabled ? 'none !important' : 'unset')};
  cursor: ${({ disabled }) => {
    if (disabled) return 'not-allowed'
    return 'pointer'
  }};
`*/

export const OverlayFooter = styled.div`
  background-color: ${theme.colors.white};
  height: 70px;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #eee;
  display: flex;
  position: sticky;
  bottom: 0px;
  justify-content: space-around;
  align-items: center;
  border-bottom-right-radius: 8px;

  & > div:nth-child(3) {
    max-width: 200px;
  }

  & > div:nth-child(2) {
    flex: 1;
    display: flex;
  }
`

export const OverlayFooterContent = styled.div<{
  leftAlign?: boolean
  portraitMode?: boolean
}>`
  flex: 1;
  display: flex;
  justify-content: ${({ leftAlign }) =>
    leftAlign ? 'flex-start' : 'flex-end'};
  align-items: center;
  flex-direction: row;
  height: 100%;
  margin: 12px;
  margin-top: 0px;
  padding-right: ${({ portraitMode }) => (portraitMode ? '4' : '10')}px;
  width: 130px;

  & > div:first-child {
    font-weight: bold;
    font-size: 15px;
    margin-right: 5px;
    font-weight: 460;
  }
  & > div:last-child {
    background-color: ${theme.colors.primary};
    border-radius: ${theme.borderRadius}px;
    height: 40px;
    line-height: 40px;
    font-weight: 460;
    width: 60px;
    text-align: center;
    color: white;
  }
`

export const ModalCloseButton = styled.div`
  cursor: pointer;
  background-color: ${theme.colors.light};
  width: 40px;
  height: 40px;
  border-radius: ${theme.borderRadius}px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
`

export const ModalNavigateContainer = styled.div`
  width: 160px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 20px;
`

export const ResetLoadingContainer = styled.div`
  border-radius: ${theme.borderRadius}px;
  border: 1px solid #ccc;
  height: 100%;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`
