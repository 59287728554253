import { MITee, WomenMenStatusOptions } from '@app/evaluation-core/types'
import { useEffect, useMemo, useState } from 'react'
import useEvaluation from './useEvaluation'
import useEvaluationUser from './useEvaluationUser'

const useFilterTees = (): [
  MITee[],
  MITee[],
  React.Dispatch<React.SetStateAction<MITee[]>>,
  WomenMenStatusOptions,
  React.Dispatch<React.SetStateAction<WomenMenStatusOptions>>
] => {
  const { evaluation, currentId } = useEvaluation()
  const { view } = useEvaluationUser()

  const [filterStatusValue, setFilterStatusValue] = useState<MITee[]>(() => {
    const storedFilterStatusValue = localStorage.getItem(
      `filterStatusValue_${currentId}`
    )
    return storedFilterStatusValue
      ? (JSON.parse(storedFilterStatusValue) as MITee[])
      : []
  })

  const [womenMenStatusValue, setWomenMenStatusValue] =
    useState<WomenMenStatusOptions>(() => {
      const storedWomenMenStatusValue = localStorage.getItem(
        `womenMenStatusValue_${currentId}`
      )
      return storedWomenMenStatusValue
        ? JSON.parse(storedWomenMenStatusValue)
        : 'all'
    })

  useEffect(() => {
    localStorage.setItem(
      `filterStatusValue_${currentId}`,
      JSON.stringify(filterStatusValue)
    )
  }, [filterStatusValue, currentId])

  useEffect(() => {
    localStorage.setItem(
      `womenMenStatusValue_${currentId}`,
      JSON.stringify(womenMenStatusValue)
    )
  }, [womenMenStatusValue, currentId])

  const allTees = useMemo(() => {
    if (!view || view?.hole < 0 || !evaluation.holes?.length) {
      return []
    }
    return evaluation.holes[view?.hole]?.tees || []
  }, [view?.hole, evaluation.holes, evaluation.holes[view?.hole]?.tees])

  const getFilteredTees = () => {
    const teesToShow = allTees.filter(
      (tee) => !filterStatusValue.some((t) => t.teeIndex === tee.teeIndex)
    )

    if (womenMenStatusValue === 'all') {
      return teesToShow
    }

    const genderSuffix = womenMenStatusValue === 'men' ? 'M' : 'W'
    const genderFilteredTees = teesToShow
      .map((tee) => {
        const golfers = tee.golfers.filter((golfer) =>
          golfer.includes(genderSuffix)
        )
        if (!golfers?.length) {
          return null
        }
        return {
          ...tee,
          golfers,
        }
      })
      .filter(Boolean) as MITee[]

    return genderFilteredTees
  }

  const filteredTees = useMemo(
    () => getFilteredTees(),
    [view?.hole, womenMenStatusValue, filterStatusValue, allTees]
  )
  return [
    filteredTees,
    filterStatusValue,
    setFilterStatusValue,
    womenMenStatusValue,
    setWomenMenStatusValue,
  ]
}

export default useFilterTees
