import { Eval } from '@app/evaluation-core/types'
import { State } from '@app/state/types'
import { useSelector } from 'react-redux'

type EvalWithKey = Eval & { key: string }

const useFormattedEvaluations = (): EvalWithKey[] => {
  const evaluations = useSelector(
    (state: State) => state.firebase.data.evaluations
  ) as Record<string, Eval>

  if (!evaluations) return []

  return Object.entries(evaluations).map(([key, evaluation]) => ({
    ...evaluation,
    key,
  }))
}

export default useFormattedEvaluations
