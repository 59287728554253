import React, { useState } from 'react'
import * as S from './styled'
import { RatingTemplate } from '../../../types/Templates'
import { LRClub } from '../../../types/Clubs'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { useApi } from '@common/hooks/useApi'
import { EvaluationDoc } from '../../../pages/CompositeCoursePage/CompositeCoursePage'
import CourseScorecardModal from '../CourseScorecardModal/CourseScorecardModal'

type CompositeListItemProps = {
  template: RatingTemplate
  branchId?: string
  club: LRClub
  createMappedRatingTemplate: (template: RatingTemplate) => void
}

const CompositeListItem: React.FC<CompositeListItemProps> = ({
  template,
  branchId,
  club,
  createMappedRatingTemplate,
}) => {
  const [scorecardModalVisible, setScorecardModalVisible] = useState(false)

  console.log('composeitelistItem', branchId)

  return (
    <>
      <CourseScorecardModal
        projectCourse={null}
        branchId={branchId}
        holeIds={template.holeIds}
        isVisible={scorecardModalVisible}
        closeFn={() => setScorecardModalVisible(false)}
        isComposite={true}
        template={template}
      />
      <S.TableRow key={template.id}>
        <S.TableCol>{template.name}</S.TableCol>

        <S.TableCol>
          <S.ClubHoleCount>{template.holeIds.length}</S.ClubHoleCount>
        </S.TableCol>
        <S.TableCol>
          <S.ClubHoleCount>{template.finalizedForms.length}</S.ClubHoleCount>
        </S.TableCol>

        <S.TableCol>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 4 }}>
            <RoundedButton
              onPress={() => setScorecardModalVisible(true)}
              title={'Scorecard'}
              testId="btn-start-course"
              style={{ width: '150px', height: '34px' }}
              disabled={false}
            />
            <RoundedButton
              isDefault={true}
              onPress={() => createMappedRatingTemplate(template)}
              title={'Manage Ratings'}
              testId="btn-start-course"
              style={{
                width: '150px',
                height: '34px',
              }}
            />
          </div>
        </S.TableCol>
      </S.TableRow>
    </>
  )
}

export default CompositeListItem
