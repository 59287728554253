import styled from 'styled-components'
export const ContentCard = styled.div`
  margin: 20px;
  box-shadow: 0 0px 4px 0
    ${({ theme }) => {
      return theme.colors.dark1
    }};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px;
  border-radius: 4px;
  overflow: hidden;
`
