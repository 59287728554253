import * as S from './styled'

import React, { useEffect, useState } from 'react'
import { getCurrentRouteName, goBack, navigationRef } from '.'

import ClubManagementPage from '../pages/ClubManagmentPage'
import Courses from '@app/courses'
import Evaluation from '@app/evaluation/components/Evaluation'

import FormsTab from '@app/evaluation/screen/FormsTab/FormsTab'
import { TeamRole } from '@app/evaluation-core/types'
import Map from '@app/evaluation/screen/Map'
import NavigationButton from '@app/components/atoms/NavigationButton'
import { Preparation } from '@app/evaluation/components/Preparation'
import Spacer from '@app/components/atoms/Spacer'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createStackNavigator } from '@react-navigation/stack'
import { screenOptions } from '@app/navigation/config'
import theme from '@app/ui/theme'
import { useRoute } from '@react-navigation/native'
import { useEvaluation } from '@common/hooks'
import { useSelector } from 'react-redux'
import { State } from '@app/state/types'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { Spinner } from '@app/evaluation/components/Spinner'
import { useSnackbar } from '@app/snackbar'
import getEnv from '@app/environments/getEnv'
import { LRClub, LRCourse } from '../types/Clubs'
import GoBackButton from '@app/components/atoms/GoBackButton'
import { RatingTemplateProvider } from '../pages/CreateRatingTemplatePage/tabs/SelectHoles/hooks/useRatingTemplate'
import { SelectHolesPage } from '../pages/CreateRatingTemplatePage'
import CreateRatingPage from '../pages/CreateRatingPage'
import StartEvaluationPage from '../pages/StartEvaluationPage'
import Finalize from '@app/evaluation/components/Finalize'
import CompositeCoursePage from '../pages/CompositeCoursePage'
import { useDispatch } from 'react-redux'
import { EvalStatus } from '@app/courses/domain/ratingStatus'

export type CourseStackParamList = {
  Courses: {}
  CourseEvaluation: undefined
  ClubManagementStack: undefined
}

export type ClubManagementStackParamList = {
  ClubManagement: { club: LRClub; wait: boolean }
  SelectHoles: { clubId: string }
  MixedEvaluation: { courses: LRCourse[]; club: string }
  StartEvaluationPage: { clubId: string }
  CompositeCoursePage: {
    holeIds: string[]
    templateId: string
    club: LRClub
    branchId: string
    isRateable?: boolean
  }
  CreateRating: {
    holeIds: string[]
    templateId: string
    club: LRClub
    branchId: string
    isRateable?: boolean
  }
}

export type CourseEvaluationTabParamList = {
  Overview: undefined
  Evaluation: undefined
  CourseMap: undefined
  Forms: undefined
  Finalize: undefined
}

const Stack = createStackNavigator<CourseStackParamList>()
const ClubManagementStack = createStackNavigator<ClubManagementStackParamList>()
const Tab = createBottomTabNavigator<CourseEvaluationTabParamList>()

export const CoursesEvaluationTab = () => {
  const [activeTab, setTab] = useState('Overview')
  const [portraitMode, setPortraitMode] = useState(screen.width < screen.height)
  const evaluationUser = useEvaluationUser()

  const [openSnackbar] = useSnackbar()
  const dispatch = useDispatch()
  const courseSettings = useSelector(
    (state: State) => state.adjustments.courseSettings
  )
  useEffect(() => {
    setPortraitMode(screen.width < screen.height)
  }, [screen.width, screen.height])

  const route = useRoute()
  const evaluation = useEvaluation()

  if (
    !evaluation ||
    !evaluation?.evaluation ||
    !evaluation?.evaluation?.users
  ) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <Spinner />
      </div>
    )
  }

  type Params =
    | {
        direction?: string | undefined
      }
    | undefined

  const params: Params = route?.params

  const tabBarOptions = {
    activeTintColor: theme.colors.secondary2,
    inactiveTintColor: theme.colors.primary2,
    indicatorStyle: {
      backgroundColor: theme.colors.secondary2,
      height: 8,
      borderRadius: 4,
    },
    pressOpacity: 1,
    labelStyle: { fontSize: 16 },
  }

  const users = Object.values(evaluation?.evaluation?.users)
  const evaluators = users.filter((user) => user.role < 3)
  const hasSystemAdmin = users.some(
    (user) => user.role === TeamRole.SYSTEM_ADMIN
  )

  function goBackLocal() {
    goBack()

    const currentRoute = getCurrentRouteName()

    if (currentRoute != undefined) setTab(currentRoute)
  }

  function navigateToRating(name: string, params?: any) {
    //TODO: Check evaloject for flag
    const defaultSettingsChecked = evaluation?.evaluation?.settingsChecked
    const goToRatingTab = () => {
      navigationRef.current?.navigate(name, params)
      setTab(name)
    }
    if (!defaultSettingsChecked) {
      dispatch({
        type: 'SET_MODAL',
        params: {
          mode: 'confirmDefaultCourseSettings',
          handleChange: goToRatingTab,
        },
      })
      return
    }
    goToRatingTab()
  }

  function navigateToTab(name: string, params?: any) {
    /*
    if (evaluators.length < 3 && !hasSystemAdmin && getEnv().env === 'PROD') {
      return openSnackbar(
        'A rating team must be comprised of at least three raters.'
      )
    }*/
    navigationRef.current?.navigate(name, params)
    setTab(name)
  }

  return (
    <>
      <S.TopContainer>
        {/* <Loading
          loading={!!course?.finalizingStatus?.inProgress}
          text={course?.finalizingStatus?.text}
          progress={course?.finalizingStatus?.progress}
        /> */}
        <GoBackButton
          portraitMode={portraitMode}
          onPress={goBackLocal}
          text="Go back"
          style={{ marginLeft: 24, alignItems: 'flex-end' }}
        />
        <Spacer width={8} />
        <S.NavigationContainer>
          <NavigationButton
            title="Overview"
            onPress={navigateToTab}
            navTitle="Overview"
            selected={activeTab === 'Overview'}
          />
          <NavigationButton
            title="Rating"
            onPress={navigateToRating}
            navTitle="Evaluation"
            selected={activeTab === 'Evaluation'}
          />
          {/* Removed until functionallity is implemented correctly
          <NavigationButton
            title="Map"
            onPress={navigateToTab}
            navTitle="CourseMap"
            selected={activeTab === 'CourseMap'}
          /> */}
          {evaluationUser.isAdmin && (
            <NavigationButton
              title="Forms"
              onPress={navigateToTab}
              navTitle="Forms"
              selected={activeTab === 'Forms'}
            />
          )}
          {evaluation?.evaluation?.status !== EvalStatus.FINALIZED &&
            evaluationUser.isAdmin && (
              <NavigationButton
                title="Finalize"
                onPress={navigateToTab}
                navTitle="Finalize"
                selected={activeTab === 'Finalize'}
              />
            )}
          <Spacer width={52} />
        </S.NavigationContainer>
      </S.TopContainer>
      <Tab.Navigator
        tabBarOptions={tabBarOptions}
        lazy={true}
        tabBar={() => null}
      >
        <Tab.Screen
          name="Overview"
          component={Preparation}
          options={() => ({
            ...screenOptions,
            params: route.params,
          })}
        />
        <Tab.Screen
          name="Evaluation"
          component={Evaluation}
          options={({ route }) => ({
            ...screenOptions,
            title: 'Rating',
          })}
        />
        <Tab.Screen
          name="CourseMap"
          component={Map}
          options={() => ({
            ...screenOptions,
            title: 'Map',
          })}
        />
        {evaluationUser.isAdmin && (
          <Tab.Screen
            name="Forms"
            component={FormsTab}
            options={() => ({
              ...screenOptions,
            })}
          />
        )}
        {evaluationUser.isAdmin && (
          <Tab.Screen
            name="Finalize"
            component={Finalize}
            options={() => ({
              ...screenOptions,
            })}
          />
        )}
      </Tab.Navigator>
    </>
  )
}

const CreateCompositeCourse = () => {
  return (
    <RatingTemplateProvider>
      <SelectHolesPage />
    </RatingTemplateProvider>
  )
}

const ClubManagement = () => {
  return (
    <ClubManagementStack.Navigator>
      <ClubManagementStack.Screen
        name="ClubManagement"
        component={ClubManagementPage}
        options={() => ({
          ...screenOptions,
          title: 'Club Management',
          headerStyle: {
            borderBottomWidth: 0,
          },
          headerShown: false,
        })}
      />
      <ClubManagementStack.Screen
        name="CreateRating"
        component={CreateRatingPage}
        options={() => ({
          ...screenOptions,
          title: 'Create Rating',
          headerShown: false,
        })}
      />
      <ClubManagementStack.Screen
        name="CompositeCoursePage"
        component={CompositeCoursePage}
        options={() => ({
          ...screenOptions,
          title: 'Create Rating',
          headerShown: false,
        })}
      />
      <ClubManagementStack.Screen
        name="SelectHoles"
        component={CreateCompositeCourse}
        options={() => ({
          ...screenOptions,
          title: 'Club Management',
          headerShown: false,
        })}
      />
      <ClubManagementStack.Screen
        name="StartEvaluationPage"
        component={StartEvaluationPage}
        options={() => ({
          ...screenOptions,
          title: 'Club Management',
          headerShown: false,
        })}
      />
    </ClubManagementStack.Navigator>
  )
}

export const CoursesStack: React.FC = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Courses"
        component={Courses}
        options={{
          ...screenOptions,
          headerStyle: {
            borderBottomWidth: 0,
          },
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ClubManagementStack"
        component={ClubManagement}
        options={{
          ...screenOptions,
          headerStyle: {
            borderBottomWidth: 0,
          },
          headerShown: false,
          title: 'Club Management',
        }}
      />
      <Stack.Screen
        name="CourseEvaluation"
        component={CoursesEvaluationTab}
        options={{
          ...screenOptions,
          headerStyle: {
            borderBottomWidth: 0,
          },
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  )
}

export default CoursesStack
