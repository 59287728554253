import styled from 'styled-components'

export const MaleLetter = styled.span`
  width: 24px;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  color: #001acd;
`

export const FemaleLetter = styled.span`
  width: 24px;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  color: #d9318e;
`

export const MaleAndFemaleLetter = styled.span`
  width: 24px;
  padding: 2px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  color: #6b817c;
`
