import Spacer from '@app/components/atoms/Spacer'
import { useSnackbar } from '@app/snackbar'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'styled-components'
import * as S from './AddTeeOverlay.styles'
import { Theme } from '@app/ui/theme'

const renderButtons = (
  type: string,
  theme: Theme,
  acceptFunction: () => void,
  deleteFunction: () => void,
  editFunction: () => void
) => {
  if (type === 'edit') {
    return (
      <>
        <RoundedButton
          title="DELETE TEE"
          onPress={deleteFunction}
          style={{
            marginTop: '25px',
            height: '50px',
            backgroundColor: theme.colors.warning,
          }}
          testId={'button'}
        />
        <Spacer width={10} />
        <RoundedButton
          title="SAVE CHANGES"
          onPress={editFunction}
          style={{
            marginTop: '25px',
            height: '50px',
            backgroundColor: theme.colors.secondary2,
          }}
          testId={'button'}
        />
      </>
    )
  }
  return (
    <RoundedButton
      title="CREATE"
      onPress={acceptFunction}
      style={{
        marginTop: '25px',
        height: '50px',
        flex: 1,
        backgroundColor: theme.colors.secondary2,
      }}
    />
  )
}

const AddTeeOverlay = ({
  type,
  addTee,
  deleteTee,
  editTee,
  tee,
}: {
  type: string
  addTee: any
  deleteTee: any
  editTee: any
  tee: any
}) => {
  const INPUT_PLACEHOLDER = 'Tap here to start typing'
  const dispatch = useDispatch<any>()
  const theme = useTheme() as Theme

  const [teeName, setTeeName] = useState(tee?.title || '')
  const [courseRating, setCourseRating] = useState(tee?.courseRating || 0)
  const [slopeRating, setSlopeRating] = useState(tee?.slopeRating || 0)

  const [updateTeeName, setUpdateTeeName] = useState(teeName)
  const [updateCourseRating, setUpdateCourseRating] = useState(courseRating)
  const [updateSlopeRating, setUpdateSlopeRating] = useState(slopeRating)
  const [openSnackbar] = useSnackbar()

  const snackbarText = 'Please fill in all the fields before creating a new tee'

  const closeModal = () => {
    dispatch({
      type: 'SET_MODAL',
      params: null,
    })
  }

  const editTeeState = () => {
    const originalTee = { title: teeName, courseRating, slopeRating }
    const newTee = {
      title: updateTeeName,
      courseRating: updateCourseRating,
      slopeRating: updateSlopeRating,
    }
    if (!newTee.title || !newTee.courseRating || !newTee.slopeRating) {
      openSnackbar(snackbarText)
      return
    }
    editTee(originalTee, newTee)
    closeModal()
  }

  const addTeeToState = () => {
    if (!teeName || !courseRating || !slopeRating) {
      openSnackbar(snackbarText)
      return
    }
    addTee({ title: teeName, courseRating, slopeRating })
    setTeeName('')
    setCourseRating(0)
    setSlopeRating(0)
    closeModal()
  }

  const removeTeeFromState = () => {
    deleteTee({ title: teeName, courseRating, slopeRating })
  }

  return (
    <S.TeeWrapper>
      <S.TeeHeader>
        <S.TeeTitle>{type === 'edit' ? 'Edit Tee' : 'Add Tee'}</S.TeeTitle>
        <S.CloseButton
          onClick={() => {
            dispatch({
              type: 'SET_MODAL',
              params: null,
            })
          }}
        >
          X
        </S.CloseButton>
      </S.TeeHeader>
      <S.TeeLine></S.TeeLine>
      <S.TeeContent>
        <S.Label>Tee name</S.Label>
        <S.Input
          placeholder={INPUT_PLACEHOLDER}
          defaultValue={teeName}
          onChange={({ target }) =>
            type === 'edit'
              ? setUpdateTeeName(target.value)
              : setTeeName(target.value)
          }
        />
        <Spacer height={15} />
        <S.Label>Course rating</S.Label>
        <S.Input
          type="number"
          defaultValue={courseRating}
          placeholder={INPUT_PLACEHOLDER}
          onChange={({ target }) =>
            type === 'edit'
              ? setUpdateCourseRating(+target.value)
              : setCourseRating(+target.value)
          }
        />
        <Spacer height={15} />
        <S.Label>Slope rating</S.Label>
        <S.Input
          type="number"
          placeholder={INPUT_PLACEHOLDER}
          defaultValue={slopeRating}
          onChange={({ target }) =>
            type === 'edit'
              ? setUpdateSlopeRating(+target.value)
              : setSlopeRating(+target.value)
          }
        />
        <Spacer height={15} />

        <S.ButtonContainer>
          {renderButtons(
            type,
            theme,
            addTeeToState,
            removeTeeFromState,
            editTeeState
          )}
        </S.ButtonContainer>
      </S.TeeContent>
    </S.TeeWrapper>
  )
}

export default AddTeeOverlay
