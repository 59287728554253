import {
  Adjustment,
  Adjustments,
  CRUpdate,
  Eval,
  EvaluationUserView,
  Gender,
  GolferShortName,
  LockStatus,
  MIShot,
} from '@app/evaluation-core/types'
import {
  FBREF_ADJUSTMENT_CR_UPDATE_QUEUE,
  FBREF_ADJUSTMENT_RESET_QUEUE,
  FBREF_ADJUSTMENT_UPDATE_QUEUE,
  FBREF_COURSE_EVALUATION,
  FBREF_EVALUATION_HOLE_STATUS,
  FBREF_HOLE_REFRESH_QUEUE,
  FBREF_HOLE_RESET_CRDATA_QUEUE,
  FBREF_HOLE_RESET_QUEUE,
  FBREF_HOLE_TEE,
  FBREF_NOTIFICATIONS_UPDATE_SET,
  FBREF_TEE_COPY_QUEUE,
  FBREF_TEE_REFRESH_QUEUE,
  FBREF_TEE_RESET_QUEUE,
  FBREF_USER_EVALUATION_VIEW,
  ref,
} from './refs'

import { AcceptReject } from '@app/components/molecules/UpdateCard/UpdateCard'
import firebase from 'firebase'
import { getEvaluationState } from './evaluation'
import { initialGroups } from './initialValues'
import store from '@app/state'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { getUserViewFromState } from './actions/adjustmentsActions'

type Adj = { [key: string]: Adjustment }

export const getEvaluationView = (): EvaluationUserView | undefined =>
  useEvaluationUser().view

export const getSelectedHole = (evaluation: Eval) => {
  const userId = store.getState().user.id
  if (evaluation.users && evaluation.holes) {
    if (!evaluation.users[userId]) {
      return null
    }
    const view = evaluation?.users[userId].view
    return evaluation.holes[view?.hole]
  }
  return null
}

export const getActiveAdjustment = () => {
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  return evaluation.adjustments.find(
    (adj) => adj.adjustmentIndex === view.adjustment
  )
}

export const getSelectedTee = (evaluation: Eval) => {
  const userId = store.getState().user.id
  if (evaluation.users && evaluation.holes) {
    if (!evaluation.users[userId]) {
      return null
    }
    const view = evaluation?.users[userId].view
    return evaluation.holes[view?.hole].tees[view.tee]
  }
  return null
}

export type AdjustmentTypes = 'course' | 'hole' | 'tee' | 'golfer' | 'shot'

export const getAdjustmentValue = (
  type: AdjustmentTypes,
  view: EvaluationUserView,
  key: string,
  adjustments: any
): string | number | undefined => {
  if (type === 'course') {
    if (!adjustments.courseSettings) {
      return 0
    }
    return adjustments.courseSettings[key]
  }
  if (!adjustments || !adjustments.adjustments) {
    return 0
  }
  if (type === 'hole') {
    return adjustments.adjustments[`${key}_HOLE_${view?.hole}`]
  }
  if (type === 'tee') {
    return adjustments.adjustments[`${key}_HOLE_${view?.hole}_TEE_${view.tee}`]
  }
  if (type === 'golfer') {
    return adjustments.adjustments[
      `${key}_HOLE_${view?.hole}_TEE_${view.tee}_GOLFER_${view.golfer}`
    ]
  }
  if (type === 'shot') {
    return adjustments.adjustments[
      `${key}_HOLE_${view?.hole}_TEE_${view.tee}_GOLFER_${view.golfer}_SHOT_${view.shot}`
    ]
  }
  return undefined
}

export const getCourseSetting = (
  key: string,
  adjustments: any
): string | number | undefined => {
  if (
    adjustments.courseSettings &&
    adjustments.courseSettings[key] !== undefined
  ) {
    return adjustments.courseSettings[key]
  }
  return 0
}

export const getAdjustmentKey = (
  key: string,
  view: EvaluationUserView,
  type: AdjustmentTypes,
  evaluationId?: string
): string => {
  if (type === 'hole') {
    return `${key}_HOLE_${view?.hole}`
  }
  if (type === 'tee') {
    return `${key}_HOLE_${view?.hole}_TEE_${view.tee}`
  }
  if (type === 'golfer') {
    return `${key}_HOLE_${view?.hole}_TEE_${view.tee}_GOLFER_${view.golfer}`
  }
  if (type === 'shot') {
    return `${key}_HOLE_${view?.hole}_TEE_${view.tee}_GOLFER_${view.golfer}_SHOT_${view.shot}`
  }
  return ''
}

export const resetAdjustment = async (
  adjustment: Adjustments,
  view: EvaluationUserView,
  evaluationId: string,
  shots: number
) => {
  const queueRefString = FBREF_ADJUSTMENT_RESET_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  await ref(queueRefString).push({
    data: {
      adjustment,
      shots,
      type: 'adjustment',
      context: {
        hole: view?.hole,
        tee: view.tee,
        golfer: view.golfer,
        shot: view.shot,
      },
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const resetHole = async (hole: number, evaluationId: string) => {
  const queueRefString = FBREF_HOLE_RESET_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  await ref(queueRefString).push({
    data: {
      hole,
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}
export const resetTee = async (
  hole: number,
  teeIndex: number,
  evaluationId: string
) => {
  const queueRefString = FBREF_TEE_RESET_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  await ref(queueRefString).push({
    data: {
      hole,
      tee: teeIndex,
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const refreshHole = async (hole: number, evaluationId: string) => {
  const queueRefString = FBREF_HOLE_REFRESH_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  await ref(queueRefString).push({
    data: {
      hole,
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const refreshTee = async (
  hole: number,
  tee: number,
  evaluationId: string
) => {
  const queueRefString = FBREF_TEE_REFRESH_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  await ref(queueRefString).push({
    data: {
      hole,
      tee,
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const copyTee = async (
  evaluationId: string,
  hole: number,
  fromTee: number,
  toTee: number,
  golfer?: string
) => {
  const queueRefString = FBREF_TEE_COPY_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  await ref(queueRefString).push({
    data: {
      hole,
      fromTee,
      toTee,
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const resetCRData = async (evaluationId: string, hole: number) => {
  const queueRefString = FBREF_HOLE_RESET_CRDATA_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  await ref(queueRefString).push({
    data: {
      hole,
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const setLiveCRNotification = async (
  status: AcceptReject,
  item,
  hole,
  evaluationId,
  holeObj
) => {
  holeObj[item.key]['status'] = status

  const queueRefString = FBREF_NOTIFICATIONS_UPDATE_SET(evaluationId, hole)

  if (queueRefString === '') {
    return null
  }

  // change status on item

  await ref(queueRefString).set(holeObj)
}

export const SetLiveCRNotifications = async (
  status: AcceptReject,
  items,
  hole,
  evaluationId,
  holeObj
) => {
  items.forEach((item) => {
    if (holeObj[item.key] == null) {
      return null
    }
    holeObj[item.key]['status'] = status
  })

  const queueRefString = FBREF_NOTIFICATIONS_UPDATE_SET(evaluationId, hole)

  if (queueRefString === '') {
    return null
  }
  await ref(queueRefString).set(holeObj)
}

export const setLiveCRAdjustment = async (
  item: CRUpdate,
  view,
  evaluationId
) => {
  const queueRefString = FBREF_ADJUSTMENT_CR_UPDATE_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  const context = item.data.context.reduce((acc, context) => {
    acc[context.key] = context.value
    return acc
  }, {})

  await ref(queueRefString).push({
    data: {
      notifications: { [item.data.key]: item },
      type: 'adjustment',
      context,
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const setLiveCRAdjustmentAll = async (
  items,
  view: EvaluationUserView,
  evaluationId
) => {
  const queueRefString = FBREF_ADJUSTMENT_CR_UPDATE_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  const notifications = items.reduce((acc, item: CRUpdate) => {
    if (item.key) {
      const context = item.data.context.reduce((acc, context) => {
        acc[context.key] = context.value
        return acc
      }, {})
      acc[item.key] = { ...item, context }
      return acc
    }
    return acc
  }, {})

  await ref(queueRefString).push({
    data: {
      notifications,
      type: 'adjustment',
      hole: view?.hole,
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const setAdjustment = async (
  type: AdjustmentTypes,
  view: EvaluationUserView,
  key: string,
  evaluationId: string,
  value: number | string | undefined
) => {
  if (!type) {
    console.log('No type added for key:', key)
    return null
  }
  const queueRefString = FBREF_ADJUSTMENT_UPDATE_QUEUE(evaluationId)

  if (queueRefString === '') {
    return null
  }

  const adjustmentKey = getAdjustmentKey(key, view, type, evaluationId)
  await ref(queueRefString).push({
    data: {
      adjustment: { [adjustmentKey]: value },
      type: 'adjustment',
      context: {
        hole: view?.hole,
      },
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })

  /* also sets _green key if changing approach shot
   * also sets shot key for approach shot if changing _green key
  const shots = getPlayerShotsFromEval(view.golfer)
  if (adjustmentKey == 'lat_stroke') {
    if (view.shot == shots - 1 || shots == 1)
      setAdjustment(type, view, 'lat_stroke_green', evaluationId, value)
  } else if (adjustmentKey == 'lat_stroke_green') {
    view.shot = shots - 1

    setAdjustment(type, view, 'lat_stroke', evaluationId, value)
  }*/

  // const adjustmentKeyCopied = getAdjustmentKey(
  //   key,
  //   { ...view, tee: 2 },
  //   type,
  //   evaluationId
  // )

  // await ref(queueRefString).push({
  //   data: {
  //     adjustment: { [adjustmentKeyCopied]: value },
  //     type: 'adjustment',
  //     context: {
  //       hole: view?.hole,
  //     },
  //   },
  //   timestamp: firebase.database.ServerValue.TIMESTAMP,
  // })
}

export const setCourseSetting = async (
  key: string,
  evaluationId: string,
  value: number | string | undefined
) => {
  const queueRefString = FBREF_ADJUSTMENT_UPDATE_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }
  await ref(queueRefString).push({
    data: {
      adjustment: { [key]: value },
      value,
      type: 'course',
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const saveCourseSettings = async (
  courseSettings: {
    [key: string]: number | string | undefined
  },
  evaluationId: string
) => {
  const queueRefString = FBREF_ADJUSTMENT_UPDATE_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }
  await ref(queueRefString).push({
    data: {
      adjustment: courseSettings,
      type: 'course',
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const setHoleStatus = async (
  evaluationId: string,
  status: LockStatus,
  hole: number
) => {
  const statusRef = FBREF_EVALUATION_HOLE_STATUS(evaluationId, hole)

  await ref(statusRef).set(status)
}

const getKey = (
  type: AdjustmentTypes,
  key: string,
  view: EvaluationUserView
) => {
  if (type === 'course') {
    return key
  }
  if (type === 'hole') {
    return `${key}_HOLE_${view?.hole}`
  }
  if (type === 'tee') {
    return `${key}_HOLE_${view?.hole}_TEE_${view.tee}`
  }
  if (type === 'golfer') {
    return `${key}_HOLE_${view?.hole}_TEE_${view.tee}_GOLFER_${view.golfer}`
  }
  if (type === 'shot') {
    return `${key}_HOLE_${view?.hole}_TEE_${view.tee}_GOLFER_${view.golfer}_SHOT_${view.shot}`
  }
}

const getKeys = (
  keys: string[],
  type: AdjustmentTypes,
  view: EvaluationUserView
) => keys.map((key) => getKey(type, key, view))

export const setExcludedKeys = async (
  type: AdjustmentTypes,
  view: EvaluationUserView,
  key: string,
  evaluationId: string,
  value: number | string,
  excludedKeys: string[]
) => {
  const queueRefString = FBREF_ADJUSTMENT_UPDATE_QUEUE(evaluationId)
  if (queueRefString === '') {
    return null
  }

  const itemKey = getKey(type, key, view) as string
  const keys = getKeys(excludedKeys, type, view) as string[]
  const updatedObject = keys.reduce((acc, curr) => ({ [curr]: 0, ...acc }), {})
  const adjustment = { ...updatedObject, [itemKey]: value }
  await ref(queueRefString).push({
    data: {
      adjustment,
      type: 'adjustment',
      context: {
        hole: view?.hole,
      },
    },
    timestamp: firebase.database.ServerValue.TIMESTAMP,
  })
}

export const setSelectedShot = (shotIndex: number): void => {
  const { currentId } = getEvaluationState()

  ref(
    `${FBREF_COURSE_EVALUATION(currentId)}/${FBREF_USER_EVALUATION_VIEW()}/shot`
  ).set(shotIndex)
}

export const getGenderFromGolferType = (golferShortName: GolferShortName) =>
  golferShortName.includes('W') ? Gender.WOMEN : Gender.MEN

export const getAdjustment = (adjustment: string) => {
  return initialGroups().find((group) => group.adjustment === adjustment)
}

export const setSelectedGolfer = (typeIndex: number): void => {
  const { currentId, evaluation } = getEvaluationState()

  ref(
    `${FBREF_COURSE_EVALUATION(
      currentId
    )}/${FBREF_USER_EVALUATION_VIEW()}/golfer`
  ).set(typeIndex)

  setSelectedShot(0)
}

export const setCheckedDefaultSettings = async (value: boolean) => {
  const { currentId } = getEvaluationState()
  const refStr = FBREF_COURSE_EVALUATION(currentId)
  if (refStr === '') {
    return null
  }
  await ref(`${refStr}/settingsChecked`).set(value)
}

export const settee = (tee: number): void => {
  const { currentId } = getEvaluationState()

  ref(
    `${FBREF_COURSE_EVALUATION(currentId)}/${FBREF_USER_EVALUATION_VIEW()}/tee`
  ).set(tee)

  setSelectedShot(0)
}

const FBREF_SELECTED_GROUP = (currentId: string | null | undefined) =>
  `${FBREF_COURSE_EVALUATION(
    currentId
  )}/${FBREF_USER_EVALUATION_VIEW()}/adjustment`

export const selectValue = (row: number, column: number, tee: number): void => {
  const { currentId } = getEvaluationState()

  ref(FBREF_SELECTED_GROUP(currentId)).set(row)

  settee(tee)
  setSelectedGolfer(column)
}

export const selectTab = (tab: number): void => {
  const { currentId } = getEvaluationState()

  ref(FBREF_SELECTED_GROUP(currentId)).set(tab)
  setSelectedShot(0)
}

type SaveMode = 'normal' | 'player' | 'gender' | 'hole' | 'tee'

export const setAdjustmentValue = async (
  _key: string,
  value: string
): Promise<void> => {
  console.log(_key)
}

export const getAdjustmentUpdateValue = (
  adj: any,
  value: any,
  saveMode: string | null = null
) => {
  if (value?.selectorType) {
    return {
      ...adj.adjusted,
      value: value.value,
      option: 0,
      saveMode,
    }
  }

  const updateValue =
    value?.option !== undefined && adj.adjusted?.option === value?.option
      ? {
          ...adj.adjusted,
          value: adj.adjusted.value - (value.value as number),
          option: 0,
          saveMode,
        }
      : { ...value, saveMode }

  return updateValue
}
export const getPlayerShotsFromEval = (golfer?: GolferShortName) => {
  const { evaluation, view } = getEvaluationState()
  if (!evaluation.holes) {
    return 0
  }

  if (golfer) {
    if (evaluation?.holes[view?.hole]?.tees[view.tee]?.shots[golfer]) {
      return (
        evaluation?.holes[view?.hole]?.tees[view.tee]?.shots[golfer] as MIShot[]
      ).length
    }
  }

  const shots = evaluation?.holes[view?.hole]?.tees[view.tee]?.shots
  const shotKeys = Object.keys(shots)
  if (!shotKeys.includes(view.golfer)) {
    return (shots[shotKeys[0]] as MIShot[]).length ?? 1
  }

  return (shots[view.golfer] as MIShot[]).length ?? 1
}

export const setIncludedInSlopeTable = (
  tee: number,
  include: boolean,
  gender: 'men' | 'women'
) => {
  const { currentId } = getEvaluationState()
  ref(
    `${FBREF_COURSE_EVALUATION(currentId)}/${FBREF_HOLE_TEE(
      0,
      tee
    )}/includedInSlopeTable`
  ).update({
    [gender]: include,
  })

  return
}
