import theme from '@app/ui/theme'
import styled from 'styled-components'

export const RatingHolesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
`
export const Header = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
`

export const RatingHole = styled.div<{ selected: boolean }>`
  cursor: pointer;
  height: 128px;
  width: 49%;
  min-width: 49%;
  max-width: 49%;
  flex: 1 0 45%;
  border-radius: 12px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  outline: ${({ selected }) =>
    selected ? `3px solid ${theme.colors.secondary2}` : 'none'};

  @media (max-width: 820px) {
    flex-basis: 95%;
    max-width: 100%;
  }
`

export const Title = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`

export const CheckContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${theme.colors.secondary2};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const HoleInfoText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`
