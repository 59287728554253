import theme from '@app/ui/theme'
import styled from 'styled-components/native'

export const LowerContainer = styled.View`
  flex-direction: row;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-width: 1px;
  border-radius: 5px;
  border-color: ${theme.colors.light4};
`

export const ValueAndButtonContainer = styled.View`
  flex: 1;
  flex-direction: row;
  margin-left: 12px;
  margin-top: 15px;
  margin-bottom: 15px;
`
