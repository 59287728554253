import styled from 'styled-components'

export const Wrapper = styled.div`
  font-family: 'Roboto';
  width: 500px;
  border-radius: 12px;
  background-color: #f9f9f9;
`
export const Header = styled.div`
  height: 65px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const CloseButton = styled.div`
  height: 36px;
  width: 36px;
  background-color: #a5a5a5;
  border-radius: 5px;
  margin: 0px 10px 0px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  color: white;
  cursor: pointer;
`

export const Title = styled.div`
  font-size: 24px;
  font-weight: 700px;
  line-height: 28px;
  margin-left: 173px;
  color: #282828;
`

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #282828;
`
export const Line = styled.div`
  border-bottom: 1px solid #cecece;
`
export const Content = styled.div`
  padding: 30px 26px;
`
export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`
export const Input = styled.input`
  background: #f9f9f9;
  border: 1px solid #cecece;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  margin-top: 12px;
  text-indent: 16px;

  & :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }
  & ::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #a5a5a5;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > .button {
    flex: 1;
  }
`
