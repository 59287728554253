import {
  ErrorText,
  ErrorTextContainer,
  LoginContainer,
  LoginLogo,
  LoginWrapper,
  SubmitButton,
  SubmitButtonText,
} from '@app/auth/components/Login.styles'
import { CourseEvaluation, Gender } from '@app/evaluation-core/types'
import firebase from '@app/firebase'
import images from '@app/images'
import { navigate } from '@app/navigation'
import { State } from '@app/state/types'
import BackgroundVideo from '@app/video-bg/index.web'
import React, { useState, useEffect } from 'react'
import { TextInput } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import FoundationIcon from '@ovaeasy/react-native-vector-icons/Foundation'
import { useLogin } from '@app/auth/hooks'

import {
  AlreadyHaveAccountLink,
  GenderButton,
  GenderContainer,
  LoginWrapperBottomContainer,
} from './SignUp.styles'
import { handleGolfID } from '@app/auth/hooks/useLogin'
import { Spinner, StyledSpinner } from '@app/evaluation/components/Spinner'
import theme from '@app/ui/theme'
import useInvite from '@common/hooks/useInvite'

export interface LoginProps {}

interface SignUpProps {
  evaluation: CourseEvaluation
  userId: string
}

const backgroundVideo = require('../../images/video/video.mp4')

const SignUp = ({ evaluation, userId }: SignUpProps) => {
  const dispatch = useDispatch<any>()
  const { getInvite } = useInvite()
  const users = evaluation.users
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [loading, setLoading] = useState(false)
  const [golfID, setGolfID] = useState<string>('')

  const invitation = getInvite()

  const [gender, setGender] = useState<Gender | null>(null)
  const login = useLogin()

  useEffect(() => {
    const user = users[userId]
    if (!user) return
    if (user.email) {
      login.inputs.setEmail(user.email)
    }
  }, [users, userId])

  const signUp = async () => {
    setLoading(true)
    await login.signUp({
      email: login.inputs.email,
      password: login.inputs.password,
      userData: {
        forename: firstName,
        lastname: lastName,
        accountType: '7',
        activated: '1',
        country: 'Sweden',
        golfID,
      },
    })
    setLoading(false)
    navigate('Courses')
  }

  return (
    <LoginContainer>
      <BackgroundVideo source={backgroundVideo} />

      <LoginWrapper>
        <LoginLogo src={images.png.Logo} style={{ height: 44 }} />
        <form
          style={{ maxWidth: 500 }}
          onSubmit={(event) => {
            event.preventDefault()
            signUp()
          }}
        >
          <input
            className="login-username"
            placeholder="Email-address"
            value={login.inputs.email}
            onChange={({ target }) => login.inputs.setEmail(target.value)}
          />
          <input
            className="login-password"
            type="password"
            placeholder="Choose password"
            value={login.inputs.password}
            onChange={({ target }) => login.inputs.setPassword(target.value)}
          />
          <input
            className="login-password"
            type="password"
            placeholder="Repeat password"
            value={login.inputs.repeatPassword}
            onChange={({ target }) =>
              login.inputs.setRepeatPassword(target.value)
            }
          />
          <input
            placeholder="First name"
            value={firstName}
            onChange={({ target }) => setFirstName(target.value)}
          />

          <input
            placeholder="Last name"
            value={lastName}
            onChange={({ target }) => setLastName(target.value)}
          />
          <input
            placeholder="GolfID"
            value={golfID}
            onChange={({ target }) => {
              const string = handleGolfID(target.value)
              setGolfID(string)
            }}
          />
          {/* <GenderContainer>
            <GenderButton
              selected={gender === Gender.MEN}
              onClick={selectGender(Gender.MEN)}
            >
              <FoundationIcon
                name="male-symbol"
                size={32}
                color={gender === Gender.MEN ? '#000' : '#fff'}
              />
            </GenderButton>
            <GenderButton
              selected={gender === Gender.WOMEN}
              onClick={selectGender(Gender.WOMEN)}
            >
              <FoundationIcon
                name="female-symbol"
                size={32}
                color={gender === Gender.WOMEN ? '#000' : '#fff'}
              />
            </GenderButton>
          </GenderContainer> */}

          <SubmitButton type="submit">
            {!loading && <SubmitButtonText>Sign Up</SubmitButtonText>}
            {loading && <Spinner inInput />}
          </SubmitButton>
        </form>
        <LoginWrapperBottomContainer>
          <AlreadyHaveAccountLink onClick={() => navigate('Login')}>
            Already have an account? <b>Login here</b>
          </AlreadyHaveAccountLink>
        </LoginWrapperBottomContainer>
        {login.error && !login.loading && (
          <ErrorTextContainer>
            <ErrorText>{login.error}</ErrorText>
          </ErrorTextContainer>
        )}
        {login.loading && (
          <StyledSpinner
            color={theme.colors.primary}
            style={{ height: 38, width: 38 }}
            viewBox="0 0 50 50"
          >
            <svg
              width="38"
              height="38"
              viewBox="0 0 38 38"
              xmlns="http://www.w3.org/2000/svg"
              stroke={theme.colors.white}
            >
              <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="2">
                  <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      from="0 18 18"
                      to="360 18 18"
                      dur="1s"
                      repeatCount="indefinite"
                    />
                  </path>
                </g>
              </g>
            </svg>
          </StyledSpinner>
        )}
      </LoginWrapper>
    </LoginContainer>
  )
}

export default SignUp
