import * as React from 'react'
import {
  Placeholder,
  PlaceholderLine,
  PlaceholderMedia,
  ShineOverlay,
} from 'rn-placeholder'

import {
  ClubContainer,
  ClubHeader,
  ClubLogo,
  ClubMainCol,
  ClubSideCol,
  ClubSubHeader,
  ClubTitle,
  TableBody,
  TableCol,
  TableContainer,
  TableRow,
} from './Courses.styles'

const ClubPlaceholder = () => {
  return (
    <Placeholder Animation={ShineOverlay}>
      <ClubContainer>
        <ClubSideCol>
          <ClubLogo>
            <PlaceholderMedia />
          </ClubLogo>
        </ClubSideCol>
        <ClubMainCol>
          <ClubHeader>
            <ClubTitle>
              <PlaceholderLine />
            </ClubTitle>
          </ClubHeader>
          <ClubSubHeader>
            <PlaceholderLine />
            <PlaceholderLine />
          </ClubSubHeader>

          <TableContainer>
            <TableBody className="courses-list">
              <TableRow>
                <TableCol>
                  <PlaceholderLine />
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>
                  <PlaceholderLine />
                </TableCol>
              </TableRow>
              <TableRow>
                <TableCol>
                  <PlaceholderLine />
                </TableCol>
              </TableRow>
            </TableBody>
          </TableContainer>
        </ClubMainCol>
      </ClubContainer>
    </Placeholder>
  )
}

export default ClubPlaceholder
