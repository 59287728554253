import styled from 'styled-components'

export const BackArrowContainer = styled.div`
  margin-top: 2px;
`

export const BackText = styled.span`
  margin-left: 10px;
`

export const GoBackContainer = styled.div<{ portraitMode: boolean }>`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`
