import GoBackButton from '@app/components/atoms/GoBackButton'
import Spacer from '@app/components/atoms/Spacer'
import CourseList from '@app/components/molecules/CoursesList/CoursesList'
import TemplateList from '@app/components/molecules/CoursesList/TemplateList'
import { Statuses } from '@app/courses/domain/ratingStatus'
import { getClubStatus } from '@app/courses/domain/ratingStatus2'
import { MiProjectStatus } from '@app/evaluation-core/types'
import Spinner from '@app/evaluation/components/Spinner'
import { navigate } from '@app/navigation'
import { ClubManagementStackParamList } from '@app/navigation/CoursesStack'
import { useSnackbar } from '@app/snackbar'
import RoundedButton from '@app/ui/rounded-button/RoundedButton'
import { useApi } from '@common/hooks/useApi'
import type { StackScreenProps } from '@react-navigation/stack'
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { MiProjectBranch } from '../../types/Project'
import { RatingTemplate } from '../../types/Templates'
import { EvaluationDoc } from '../CreateRatingPage/CreateRatingPage'
import ClubStatus from './ClubStatus'
import * as S from './styled'
import ContentClubHeader from '@app/components/atoms/ContentClubHeader'

type OwnProps = {}

type Props = OwnProps &
  StackScreenProps<ClubManagementStackParamList, 'ClubManagement'>

const ClubManagementPage: FunctionComponent<Props> = ({ route }) => {
  const [openSnackbar] = useSnackbar()
  const club = route?.params?.club

  const { data, isLoading: branchLoading } = useApi<MiProjectBranch>(
    `/courses/projects/${club.id}`
  )

  const { data: templates, isLoading: templateLoading } = useApi<
    RatingTemplate[]
  >(`/ratings/templates/${club.id}`, [], {
    refreshInterval: 5000,
  })

  if (!club) {
    navigate('Courses')
    return null
  }

  const checkClubStatus = (cb: () => void) => () => {
    const clubStatus = getClubStatus(club.branchStatus)
    if (clubStatus !== Statuses.READY_FOR_RATING) {
      return openSnackbar(
        'The club is not ready to rate. Please refer to LiveCR.'
      )
    }
    cb()
  }

  return (
    <S.Wrapper>
      {route.params.wait === true && (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <Spinner />
        </div>
      )}
      <Spacer height={24} />
      <GoBackButton
        text="Go back to courses"
        onPress={() => navigate('Courses', { direction: 'return' })}
        portraitMode={screen.width < screen.height}
        style={{ marginLeft: 24 }}
      />
      <S.ContentCard>
        <ContentClubHeader
          title={club.name}
          optionView={
            <ClubStatus
              size={20}
              status={
                club.projectStatus &&
                (club.projectStatus.toString() as MiProjectStatus)
              }
            />
          }
          club={club}
        />
      </S.ContentCard>

      <S.ContentCard>
        <S.ContentHeading>Mapped Courses</S.ContentHeading>
        <CourseList club={club} />
      </S.ContentCard>
      <S.ContentCard>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <S.ContentHeading>Composite Courses</S.ContentHeading>
          <RoundedButton
            disabled={
              branchLoading || (data?.courses && data?.courses?.length <= 0)
            }
            title="Create Composite Course"
            onPress={checkClubStatus(() => navigate('SelectHoles', { club }))}
          />
        </div>
        <TemplateList
          templates={templates?.filter((template) => !template.mapped)}
          club={club}
          loading={templateLoading}
          composite
        />
      </S.ContentCard>
    </S.Wrapper>
  )
}

export default ClubManagementPage
