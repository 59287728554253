import theme from '@app/ui/theme'
import React from 'react'
import styled from 'styled-components'

type OwnProps = {
  style?: React.CSSProperties
  inInput?: boolean
  title?: string
}

type Props = OwnProps

export const Spinner: React.FunctionComponent<Props> = ({
  inInput,
  style,
  title,
}) => (
  <div
    style={{
      backgroundColor: inInput ? 'transparent' : theme.colors.background,
      height: inInput ? '20px' : '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    {title && (
      <div
        style={{
          textAlign: 'center',
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <span style={{ fontSize: 32, fontWeight: 500 }}>{title}</span>
      </div>
    )}
    <StyledSpinner
      style={style}
      color={theme.colors.primary}
      viewBox="0 0 38 38"
    >
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
        stroke={theme.colors.primary}
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </StyledSpinner>
  </div>
)

export const InLineSpinner: React.FC<{ size: number }> = ({ size }) => (
  <StyledSpinner
    style={{
      height: size,
      width: size,
    }}
    color={theme.colors.primary}
    viewBox="0 0 38 38"
  >
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke={theme.colors.primary}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  </StyledSpinner>
)

export const StyledSpinner = styled.svg<{ color: string }>`
  animation: rotate 2s linear infinite;
  width: 100%;
  height: 50%;
  max-height: 250px;
  & .path {
    stroke: ${({ color }) => color};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
`

export const SpinnerAbsolute: React.FunctionComponent<Props> = ({
  inInput,
  style,
  title,
}) => (
  <Spinner
    title={title}
    style={{
      maxHeight: 250,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      position: 'absolute',
      ...style,
    }}
  />
)

export default SpinnerAbsolute
