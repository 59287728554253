import * as S from './styled'

import { ActivityIndicator } from 'react-native'
import React from 'react'
import theme from '@app/ui/theme'
import useAvatar from './useAvatar'

interface AvatarProps {
  userId?: string
  userColor?: string
  isLastIndex?: boolean
  useIndicator?: boolean
  zIndex?: number
  size?: number
  userIndicatorSize?: number
}
const avatarSize = 34
const indicatorSize = 8

const renderImage = (
  url: string | null,
  loading: boolean,
  initial: string,
  size: number
) => {
  if (loading) {
    return <ActivityIndicator />
  }

  return !url ? (
    <S.AvatarText size={size}>{initial}</S.AvatarText>
  ) : (
    <S.ImgContainer src={url} />
  )
}

const Avatar = ({
  userColor = theme.colors.primary,
  useIndicator,
  isLastIndex,
  zIndex,
  userId,
  size = avatarSize,
  userIndicatorSize = indicatorSize,
}: AvatarProps) => {
  const { user, data, avatarBackup, mappingAvatar } = useAvatar(userId)

  return (
    <S.OuterContainer zIndex={zIndex ?? 0}>
      {useIndicator && (
        <S.UserColorIndicator
          userColor={userColor}
          zIndex={zIndex ? zIndex + 1 : 0}
          size={userIndicatorSize}
        />
      )}
      <S.AvatarContainer
        userColor={userColor}
        isLastIndex={isLastIndex}
        avatarSize={size}
      >
        {renderImage(mappingAvatar, data.loading, avatarBackup, size)}
      </S.AvatarContainer>
    </S.OuterContainer>
  )
}

export default Avatar
