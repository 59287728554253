import { Adjustment } from '../../components/Adjustment'
import { AdmFactorGroup } from '../../types'
import { GolferShortName, GrassType } from '@app/evaluation-core/types'
import React from 'react'
import { getAdjustmentValue } from '@app/evaluation-core'
import useAdjustment from '@common/hooks/useAdjustment'
import { useEvaluation } from '@common/hooks'
import useEvaluationUser from '@common/hooks/useEvaluationUser'
import { getGolferPlayerFromShortName } from '@app/evaluation-core/actions/evaluationActions'
import { AdmFactorTextContainer } from '../../components/AdjustmentFactors.styles'

export const RECOVER_ROUGH_FACTORS = (
  roughText: string,
  carryTotal: number
): AdmFactorGroup[] => [
  {
    factors: [
      {
        label: 'L*',
        itemKey: 'fwy_layup',
        totalKey: 'fwy_layup_total',
        format: 'shot',
        type: 'landingZones',
        items: [
          {
            value: -1,
            text: `If the player LAYS UP (forced or by choice) or hits less than a full shot on a dogleg hole.`,
          },
        ],
      },
      {
        label: 'M*',
        itemKey: 'rr_mounds_fw',
        totalKey: 'rr_mounds_total',
        format: 'shot',
        groupId: 'moundsGroup',
        type: 'landingZones',
        items: [
          {
            excludedKeys: ['rr_mounds_drop'],
            value: 1,
            text: `If grass MOUNDS or hollows are present near the fairway landing zone — consider their severity, number and location.`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'rr_mounds_green',
        totalKey: 'rr_mounds_total',
        format: 'hole',
        groupId: 'moundsGroup',
        type: 'green',
        showOr: true,
        items: [
          {
            value: 1,
            text: `If a significant portion of the green is closely bordered by grass MOUNDS or hollows and/or rise and drop of greater than 5'. Do not factor in any area that is closely bordered by bunkers.`,
          },
          {
            value: 2,
            text: `If a significant portion of the green is closely bordered by severe grass MOUNDS or hollows and/or rise and drop of greater than 10'. Do not factor in any area that is closely bordered by bunkers.`,
          },
        ],
      },
      {
        label: 'E',
        itemKey: 'rr_extreme',
        saveMode: 'hole',
        format: 'shot',
        items: [
          {
            value: 1,
            text: `If a punitive feature (stone wall, boulders, integral part of course, etc.) that is in the landing zone or closely bordering a landing zone or green would likely result in EXTREMEly difficult recovery shots. The punitive feature must not have been rated under another obstacle.`,
          },
        ],
      },
      {
        label: 'I*',
        itemKey: 'rr_inconsistent',
        totalKey: 'rr_inconsistent_total',
        groupId: 'softOrFirm',
        format: 'shot',
        type: 'landingZones',
        showOr: true,
        items: [
          {
            value: 1,
            text: `The rough near a landing zone  is much more severe than the rough height of the hole (e.g. 2” {1”} longer).`,
          },
          {
            value: -1,
            text: `The rough near a landing zone  is much less severe than the rough height of the hole (e.g. 2” {1”} shorter).`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'rr_inconsistent_green',
        format: 'hole',
        type: 'green',
        showOr: true,
        items: [
          {
            value: 1,
            text: `The rough closely bordering the green is much more severe than the rough height of the hole (e.g. 2” {1”} longer).`,
          },
          {
            value: -1,
            text: `The rough closely bordering the green is much less severe than the rough height of the hole (e.g. 2” {1”} shorter).`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'rr_inconsistent_bordered',
        format: 'hole',
        type: 'green',
        groupId: 'softOrFirm',
        items: [
          {
            value: 1,
            text: `a significant portion of the green is closely bordered by sloping ground cut to a height that results in shots just missing the green ending up much farther from the green, making recovery more difficult.`,
          },
        ],
      },
      {
        label: '',
        itemKey: 'rr_inconsistent_hardpan',
        format: 'hole',
        type: 'green',
        items: [
          {
            value: 1,
            text: `If extensive areas of hardpan near the green create difficult recovery issues.`,
          },
        ],
      },

      {
        label: 'S',
        itemKey: 'rr_surrounded',
        format: 'shot',
        items: [
          {
            value: 1,
            disabled: true,
            text: `If a SURROUNDED adjustment of +2 has been applied to Lateral Obstacles.`,
          },
        ],
      },

      {
        label: '2',
        itemKey: 'rr_twoRough',
        format: 'golfer',
        saveMode: 'hole',
        shouldRender: (adjustments, view) =>
          getAdjustmentValue('hole', view, 'Par', adjustments) === 5,
        items: [
          {
            value: 1,
            text: `If the rough closely bordering two (2) or more landing zones on a par-5 (three-shot) hole is at least ${roughText}`,
          },
        ],
      },
      {
        label: '3',
        itemKey: 'rr_parThree',
        format: 'golfer',
        golfers: [GolferShortName.BogeyMen, GolferShortName.BogeyWomen], // bogey
        saveMode: 'player',
        shouldRender: (adjustments, view) =>
          getAdjustmentValue('hole', view, 'Par', adjustments) === 3,
        items: [
          {
            value: 1,
            text: `If the bogey player cannot reach the centre of the green of a PAR-3 hole in one shot or lays up and the area short of the green is at least 20 yards wide and is cut to fairway height.`,
          },
          {
            value: 2,
            text: `...and the area short of the green is less than 20 yards wide and is cut to fairway height, or no such area exists.`,
          },
        ],
      },
      // {
      //   label: 'C*',
      //   itemKey: 'rr_carry_total',
      //   format: 'golfer',
      //   items: [
      //     {
      //       disabled: true,
      //       selected: true,
      //       value: carryTotal,
      //       renderText: (adjustment, view) => (
      //         <AdmFactorTextContainer>
      //           <div style={{ marginLeft: 15 }}>
      //             <span>
      //               If CARRY over rough (not Crossing Obstacles or desert) is
      //               required to reach the target —
      //             </span>
      //           </div>
      //         </AdmFactorTextContainer>
      //       ),
      //     },
      //   ],
      // },
    ],
  },
]

export const RecoverRough = () => {
  const adjustments = useAdjustment()
  const { evaluation } = useEvaluation()
  const { view } = useEvaluationUser()

  if (!evaluation || !view) return null
  const grassType = getAdjustmentValue(
    'hole',
    view,
    'grasstype',
    adjustments
  ) as GrassType
  const roughHeight = getAdjustmentValue(
    'course',
    view,
    'rough_height',
    adjustments
  ) as GrassType
  const carryTotal = getAdjustmentValue(
    'golfer',
    view,
    'rr_carry_total',
    adjustments
  ) as number

  const isMen = view.golfer.includes('M')
  const roughHeightMen = grassType === GrassType.COOL ? '3"' : '4"'
  const roughHeightWomen = grassType === GrassType.WARM ? '2"' : '3½"'
  const roughText = isMen ? roughHeightMen : roughHeightWomen

  return (
    <Adjustment
      useLz={false}
      factors={RECOVER_ROUGH_FACTORS(roughText, carryTotal)}
      subText="Adjustments marked with an asterisk (*) are cumulative and are added to the RECOVERABILITY AND ROUGH RATING to determine the Evaluated Rating seen below."
      parameters={[
        {
          type: 'numeric-input',
          label: 'Average Rough Height of Hole',
          inputDisabled: true,
          adjustmentKey: 'rough_height',
          format: 'course',
          notExpandible: true,
          unit: 'inches',
        },
        {
          type: 'numeric-input',
          label: 'Green Target Rating',
          inputDisabled: true,
          notExpandible: true,
          adjustmentKey: 'gt_final',
          format: 'golfer',
        },
        {
          type: 'numeric-input',
          label: 'Grass Type',
          inputType: 'text',
          inputDisabled: true,
          adjustmentKey: 'grasstype',
          format: 'course',
          notExpandible: true,
        },
        {
          type: 'table-value',
          label: 'Recoverability and Rough Rating',
          inputDisabled: true,
          adjustmentKey: 'rr_rating',
          format: 'golfer',
        },
      ]}
    />
  )
}
