import { color7, color8, color10, primary, color13 } from '@app/ui/colors'
import styled, { css, keyframes } from 'styled-components'

import theme from '../theme'

const BounceAnimation = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-500px);
    opacity: 0;
  }
`

const animationCss = css<{ duration?: number }>`
  animation-name: ${BounceAnimation};
  animation-duration: ${({ duration }) => duration ?? '1s'};
`

export const InputContainer = styled.div<{
  showAnimation?: boolean
  duration?: number
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.space[3]}px;
  ${(props) => props.showAnimation && animationCss};
`

export const Label = styled.span`
  max-width: 200px;
  margin-right: ${theme.space[3]}px;
  text-align: left;
  font-size: 15px;
  font-weight: bold;
`

export const ValueContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  gap: 10%;
  width: 80%;
  height: 100%;
`
export const OriginalValueContainer = styled.div`
  width: 30%;
`
export const NewValueContainer = styled.div`
  width: 45%;
`
